@import '../../../../../assets/mixins.scss';

$max-content-size: 1440px;

$white: #FFFFFF;
$grey: #5d6164;
$orange: #ea5504;


.app-layout--v1 {
    .DesktopSubNavigation1 {
        background-color: $white;
        display: none;
    
        @media (min-width: 1024px) {
            display: block;
        }
      
        .SubNavigation1 {
            max-width: $max-content-size;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            list-style-type: none;
    
            @supports (display: grid) {
                gap: 1px;
            }
        
            li {
                a {
                    color: $grey;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    height: 50px;
                    padding: 0 1.5em;
                    text-decoration: none;
    
                    @supports (display: grid) {
                        display: grid;
                        align-content: center;
                        align-items: unset;
                    }
                    
                    &.active,
                    &:hover {
                        background-color: $grey;
                        color: $white;
                    }
                }
            }
        }
    }
    
    
    .DesktopSubNavigation2 {
        background: $grey;
        display: none;
    
        @media (min-width: 1024px) {
            display: block;
        }
    
        .SubNavigation2 {
            max-width: $max-content-size;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            list-style-type: none;
    
            @supports (display: grid) {
                gap: 1px;
            }
        
            li {
                padding: 0 1.5em 0 0;
    
                @include tabletMin {
                    display: block;
                    padding: 0 1em;
                }
    
                a {
                    color: $white;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    height: 45px;
                    border-bottom: 5px $grey solid;
                    text-decoration: none;
                    margin-top: 5px;
    
                    @supports (display: grid) {
                        display: grid;
                        align-content: center;
                        align-items: unset;
                    }
                    
                    &.active,
                    &:hover {
                        border-bottom: 5px $orange solid;
                    }
                }
            }
        }
    }
}
.app-layout--v2 {
    .DesktopSubNavigation1 {
        background-color: #FEF6F2;
        display: none;
    
        @include tabletMin {
            display: block;
        }
      
        .SubNavigation1 {
            max-width: $max-content-size;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            list-style-type: none;
    
            @supports (display: grid) {
                gap: 1px;
            }
        
            li {
                a {
                    color: #333333;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    height: 50px;
                    padding: 0 1.5em;
                    text-decoration: none;
    
                    @supports (display: grid) {
                        display: grid;
                        align-content: center;
                        align-items: unset;
                    }
                    
                    &.active,
                    &:hover {
                        background-color: #FDEEE6;
                    }
                    &.active {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        &::before {
                            content: "";
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            background-color: #EA5504;
                            margin-right: 4px;
                        }
                    }
                }
            }
        }
    }
    .DesktopSubNavigation2 {
        background: #FDEEE6;
        display: none;
    
        @include tabletMin {
            display: block;
        }
    
        .SubNavigation2 {
            max-width: $max-content-size;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            list-style-type: none;
    
            @supports (display: grid) {
                gap: 1px;
            }
        
            li {
                padding: 0 1.5em 0 0;
    
                @include tabletMin {
                    display: block;
                    padding: 0 1em;
                }
    
                a {
                    color: #333333;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    height: 45px;
                    text-decoration: none;
                    margin-top: 5px;
    
                    @supports (display: grid) {
                        display: grid;
                        align-content: center;
                        align-items: unset;
                    }
                    
                    &.active{
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        &::before {
                            content: "";
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            background-color: #EA5504;
                            margin-right: 4px;
                            margin-bottom: 4px;
                        }
                    }
                }
            }
        }
    }  
}