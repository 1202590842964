@import 'style/mixins/_responsive.scss';
@import 'style/variables';

.keynote-speaker {
  display: flex;
  margin-bottom: 21px;
  margin-right: 21px;

  &__profile {
    height: 60px;
    width: 60px;
    min-width: 60px;
    margin-right: 21px;
    img {
      height: 100%;
    }
  }

  &__detail {
    display: flex;
    flex-direction: column;
    margin: auto 0;
    padding: 12px 0;
    
    &__name {
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: 700;
      // line-height: 7px;
      letter-spacing: 0px;
    }
  }
}
