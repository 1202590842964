@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import '../../assets/mixins.scss';

.logo {
  &__holder {
    margin: 0;
    padding: 10px 0;
    img {
      max-width: 125px;
    }

    @include desktopOnly {
      img {
        max-width: 175px;
      }
    }
  }
}
