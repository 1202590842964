@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import '../../assets/mixins.scss';

.hero {
    &__outer {
        height: 205px;
        background-color: $kk-primary-light;

        @include tabletOnly {
            height: 290px;
        }

        @include desktopOnly {
            height: 512px;
        }
    }
}