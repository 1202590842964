@import 'style/mixins/_responsive.scss';
@import 'style/variables';

#webinar-sign-up {
  h3 {
    margin-top: 0;
  }

  .form-component {
    max-width: 600px;
    @include screen-width-small {
      margin-bottom: 0;
    }
    @include screen-width-medium {
      margin-bottom: 0;
    }
  }
  .form-field-input {
    input {
      margin-bottom: 20px;
    }
  } 
  .form-field {
    .input-section {
      margin-bottom: 0;
    }
  }
  .form-field-button {
    button {
      width: 262px;
      margin-top: 20px;
      p {
        margin: 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 1px;
        text-align: left;
      }
    }
  }
}
