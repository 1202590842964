@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.ImagePanelFooterCopy {
  padding: 20px;
  margin-bottom: 30px;

  @include screen-width-small {
    margin-bottom: 20px;
  }

  &__title {
    h4 {
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0px;
      text-align: left;
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  &__sections {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @include screen-width-small {
      flex-direction: column;
    }

    .left {
      width: 50%;
      padding-right: 10px;
      display: flex;
      flex-direction: column;

      &.full-width {
        width: 100%;

        a {
          bottom: 0;
          position: relative;
          @include screen-width-small {
            margin-bottom: 0;
          }
        }
      }

      @include screen-width-small {
        width: 100%;
        padding: 0;
      }

      .left-image {
        width: 100%;
        img {
          width: 100%;
          border-radius: 15px;
        }
      }

      .left-footer-text {
        margin-top: 10px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0px;
        text-align: left;

        @include screen-width-small {
          margin-bottom: 20px;
        }
      }

      a {
        display: flex;
        width: 262px;
        border-radius: 30px;
        background-color: #ea5504;
        justify-content: space-between;
        border: none;
        padding: 12px 19px;
        align-items: center;
        cursor: pointer;
        color: white;
        text-decoration: none;

        p {
          margin: 0;
          text-transform: uppercase;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 19px;
          letter-spacing: 1px;
          text-align: left;
          color: white;
        }

        @include screen-width-small {
          position: relative;
          margin-bottom: 20px;
        }

        svg {
          color: white;
          height: 17px;
          width: 10px;
        }
      }
    }

    .right {
      width: 50%;
      padding-left: 10px;

      &.d-none {
        display: none;
      }

      @include screen-width-small {
        width: 100%;
        padding: 0;
      }

      .right-image {
        width: 100%;
        img {
          width: 100%;
          border-radius: 15px;
        }
      }

      .right-footer-text {
        margin-top: 10px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0px;
        text-align: left;
      }
    }
  }
}
