@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_titleWithIcon.scss';
@import 'assets/mixins.scss';

.CardCategory {

    // #region Block
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0px 0px 1em 0px;

    // #endregion



    // #region Elements
    svg {
        color: $kk-primary;
        width: 18px;
        height: 18px;
        margin-right: 13px;
    }

    p {
        margin: 0;
        margin-top: 5px;
        font-family: $hindMadurai-sb;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0px;
    }

    // #endregion



    // #region Modifiers
    &--theme {
        &-primary {
            svg {
                color: $kk-primary;
            }
        }

        &-secondary {
            svg {
                color: $button-grey;
            }
        }

        &-redesign {
            svg {
                color: $body-text-color;
            }
        }
    }

    &--color-inherit {
        &.CardCategory {
            &--theme {
                &-primary {
                    span {
                        color: $kk-primary;
                    }
                }

                &-secondary {
                    span {
                        color: $button-grey;
                    }
                }

                &-redesign {
                    span {
                        color: $body-text-color;
                    }
                }
            }
        }
    }

    &--fluid-style {
        svg {
            width: 15px;
            height: 15px;

            @include desktopOnly {
                width: 20px;
                height: 20px;
            }
        }

        p {
            text-transform: capitalize;

            @include desktopOnly {
                font-size: 14px;
            }
        }

        @extend .CardCategory--color-inherit
    }

    // #endregion
}