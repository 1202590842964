@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_buttons.scss';


.form-field-link-button {
  margin-bottom: 30px;
  &-blank {
    .chevron-button-component.chevron-button-component-link {
      @include white-button-with-hover();
      display: flex;
      width: 100%;
      p {
        color: #333333;
      }
    }
  }
}

