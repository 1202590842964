@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

$container-width: 1114px;

.img-responsive {
    @media only screen and (max-width: 768px) { 
        width: 100%;
        height: auto;
    }
}

.mdt-main {
    position: relative;
    padding: 65px 0 30px 0;
    // background-color: #3D3F5B;
    color: #FFFFFF;
    overflow: hidden;

    .mdt-main-wrapper {
        margin: auto;
        @media only screen and (min-width: 1200px) { 
            width: $container-width;
        }
    }

    h2 {
        font-size: 27px;
        font-weight: normal;
        
        span {
            display: inline;

            @media only screen and (min-width: 1200px) { 
                display: block;
            }
        }
    }

    .subtitle {
        font-size: 28px;
        font-weight: normal;

        sup {
            font-size: 18px;
        }
    }

    .info-container {

        @media only screen and (min-width: 1200px) { 
            margin: 60px 0 30px 0;
        }

        &.active {
            position: relative;
            opacity: 1;
            cursor: pointer;
            transition: opacity 0.5s ease-in-out;

            &:hover {
               opacity: 0.8;
            }

            &::before {
                content: '';
                display: block;
                width: 22px;
                height: 22px;
                background: url('../../../../assets/images/infographics-assets/add-icon.svg') no-repeat;
                position: absolute;
                top: 20px;
                right: 10px;
            }
        }
    }

    .captiontext {
        padding: 25px 0 25px 0;
        font-size: 15px;
    }
}

.mdt__infographics {
    position: relative;
    margin: 40px 0;
    background-color: #FFFFFF;
    border-radius: 20px;

    // @media only screen and (min-width: 1200px) { 
    //     display: flex;
    //     justify-content: center;
    // }

    &.bg-white {
        background-color: #FFFFFF;
    }

    .mdt__infographics-image-container {
        padding: 50px 8px 0 8px;
        text-align: center;
        color: #3D3F5B;
        width: fit-content;
        margin: auto;

        @media only screen and (min-width: 1200px) { 
            margin: auto 0 auto 90px;
        }

        
        img {
            @media only screen and (max-width: 1024px) { 
                width: 100%;
                height: auto;
            }
        }

        p {
            padding: 30px 0 30px 20px;
        }

        .caption-container {
            position: relative;

            @-moz-document url-prefix() {
                width: 758px;
            }

            > span {
                position: absolute;
                width: 55px;
                height: 55px;
                // font-family: Prata-Regular;
                font-size: 26px;
                color: #FFFFFF;
                text-align: center;
                background-color: #E07A5F;
                border-radius: 50%;
                transition: opacity 0.5s ease-in-out;
                opacity: 1;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover {
                    opacity: 0.8;
                }

                @media only screen and (max-width: 600px) { 
                    width: 7%;
                    height: 9%;
                    font-size: 25px;
                }
            }

            &.active {

                > span {
                    background-color: #3D3F5B;
                }
            }

            .pos-1 {
                bottom: 17%;
                left: 10%;
            }

            .pos-2 {
                bottom: 26%;
                left: 23%;
            }

            .pos-3 {
                top: 37%;
                left: 38%;
            }

            .pos-4 {
                bottom: 23%;
                left: 50%;
            }

            .pos-5 {
                bottom: 36%;
                left: 57%;
            }

            .pos-6 {
                top: 27%;
                right: 14%;
            }

            .pos-7 {
                bottom: 17%;
                right: 8%;
            }

            .timepopups {
                top: 14%;
                left: 6%;

                @media only screen and (max-width: 640px) { 
                    top: -20%;
                    left: -11%;
                    padding: 15px 15px 20px 15px;

                    .timepopups__scroll {
                        width: 90%;
                    }
                }
           }

        }

        .caption-container__symptoms {

            .caption-symptoms {
                display: none;
                position: absolute;
                border:1px solid #E07A5F;
                background-color: #E07A5F;
                opacity: 0.2;
                cursor: pointer;

                &:hover {
                    background-color: #FFFFFF;
                }

                &.show-caption {
                    display: block;
                }
            }

            &.symtoms_all {
                .caption-symptoms {
                    display: block; 
                }
            }

            &.symtoms_1 {
                .constipation, .eds, .rdb, .hyposmia {
                    display: block;
                }
            }

            &.symtoms_2 {
                .depression, .fatigue, .pain, .mci, .othersleepdisturbance {
                    display: block;
                }
            }

            &.symtoms_3 {
                .urinary-symtoms, .dementia, .oh, .bradykinesia, .insomia {
                    display: block;
                }
            }

            &.symtoms_4 {
                .rigidity, .dysphagia, .postural-instability {
                    display: block;
                }
            }

            &.symtoms_5 {
                .fog, .fluctuations, .falls, .dyskinsia, .psychosis {
                    display: block;
                }
            }

            &.treatment {
                
            }

            .hyposmia {
                width: 13%;
                height: 3.5%;
                top: 36.7%;
                left: 3.4%;
            }

            .rdb {
                width: 13%;
                height: 3.5%;
                top: 41.1%;
                left: 3.4%;
            }

            .constipation {
                width: 13%;
                height: 3.5%;
                top: 46.4%;
                left: 3.4%;
            }

            .depression {
                width: 13%;
                height: 4%;
                top: 51%;
                left: 3.4%;
            }

            .insomia {
                width: 10.5%;
                height: 3.4%;
                bottom: 18.5%;
                left: 26.5%;
                background-color: #464863;
            }

            .eds {
                width: 6.5%;
                height: 3.2%;
                bottom: 32%;
                left: 34.8%;
                background-color: #464863;
            }

            .bradykinesia {
                width: 96px;
                height: 18px;
                top: 145px;
                left: 197px;
            }

            .rigidity {
                width: 12.5%;
                height: 2.8%;
                top: 30.8%;
                left: 26%;
            }
            
            .pain {
                width: 5.5%;
                height: 3.5%;
                top: 38%;
                left: 29.8%;
            }

            .fatigue {
                width: 8.5%;
                height: 3.2%;
                top: 41.8%;
                left: 28%;
            }

            .mci {
                width: 6.5%;
                height: 3.2%;
                top: 46%;
                left: 29%;
            }

            .dysphagia {
                width: 17%;
                height: 2.8%;
                top: 6.1%;
                right: 19.5%;
            }

            .falls {
                width: 8%;
                height: 3.3%;
                top: 17.2%;
                right: 27%;
            }

            .psychosis {
                width: 11%;
                height: 3.2%;
                top: 16.7%;
                right: 6.5%;
                opacity: 0.4;
            }

            .fluctuations {
                width: 12%;
                height: 3%;
                top: 37.5%;
                right: 18.4%;
            }

            .dyskinsia {
                width: 12%;
                height: 3%;
                top: 40.7%;
                right: 18.4%;
            }

            .dementia {
                width: 10%;
                height: 3%;
                top: 21.5%;
                right: 16%;
                background-color: #464863;
            }

            .oh {
                width: 12%;
                height: 7%;
                bottom: 18.5%;
                right: 15.5%;
            }

            .urinary-symtoms {
                width: 12%;
                height: 7%;
                bottom: 27%;
                right: 15.5%;
            }

            .postural-instability {
                width: 17%;
                height: 3.8%;
                top: 9.3%;
                right: 19.5%;
            }

            .othersleepdisturbance {
                width: 12.5%;
                height: 7.2%;
                bottom: 24.2%;
                left: 31.5%;
                background-color: #464863;
            }

            .fog {
                width: 15%;
                height: 4%;
                top: 24.5%;
                right: 38.5%;
            }

            .tremor {
                width: 9%;
                height: 3.8%;
                top: 34%;
                left: 28%;
            }
        }

        .caption-button {

            @media only screen and (min-width: 1200px) { 
                position: absolute;
                top: 38%;
                right: 48px;
            }

            .button {
                position: relative;
                padding: 3px 40px;
                font-size: 18px;

                &::before {
                    content: '';
                    display: block;
                    width: 22px;
                    height: 22px;
                    background: url('../../../../assets/images/infographics-assets/add-icon-orange.png') no-repeat; 
                    position: absolute;
                    top: 10px;
                    right: 10px;
                }
            }
        }

    }

    .mdt__infographics-selection {
        margin-right: 10px;
        padding: 0 0 30px 15px;

        // @media only screen and (max-width: 1024px) { 
        //     padding: 40px 0 30px 50px; 
        // }

        label {
            display: block;
            margin-top: 2px;
            padding-bottom: 5px;
            font-size: 16px;
            font-weight: bold;
            color: #3D3F5B;
            cursor: pointer;

        }
    }

    .caption-container__footer {
        margin-top: 0;
        text-align: center;

        @media only screen and (max-width: 640px) { 
            margin-top: 20px;
        }

        @media only screen and (min-width: 1024px) { 
            display: flex;
        }

        .caption-container__footer-img {

            @media only screen and (min-width: 1024px) { 
                margin-left: 50px;
                text-align: left;
            }
        }

        .mdt__infographics-selection {
            width: fit-content;
            margin: 20px auto 0 auto;

            @media only screen and (min-width: 1024px) { 
                margin: 0;
            }

            label {
                text-align: left;
            }
        }
    }

    .caption-container__footer-text {
        padding-bottom: 30px;
        margin-top: -15px;
        color: #3D3F5B;
        text-align: center;
        font-size: 15px;

        @media only screen and (min-width: 1024px) { 
            margin-left: 55px;
            text-align: left;
        }
    }
}

//Accordion CSS
.accordion-section {

    .accordion-item {
        &.visible {
            overflow: visible;
        }
    }

    .accordion-content {

        &.remove-space {
            padding: 40px 0;
    
            .accordion-content__title {
                padding-left: 30px;
                padding-right: 30px;
            }
        }

        &.remove-top-border {
            border-top: none;
        }

        &.remove-bottom-border {
            border-bottom: none;
        }

        &.remove-all-space {
            padding: 0;
        }
    }
    
    .accordion-content__header {
        text-align: center;

        @media only screen and (min-width: 1024px) {
            display: flex;
            width: 940px;
            justify-content: space-around;
        }
    }
    
    .accordion-content__image-wrap {
        padding-top: 20px;
        background-color: #FFFFFF;

        @media only screen and (max-width: 768px) {
            overflow-x: scroll;
        }
    
        p {
            padding: 0 30px 40px 30px;
            color:#3D3F5B;
        }
    }
    
    .accordion-content__footer {
        padding: 40px 30px 0 30px;
    }

    .accordion-content__title {

        span {
            @media only screen and (min-width: 1024px) { 
                display: block;
            }
        }

        &.top-space {
            @media only screen and (min-width: 1024px) { 
                padding-top: 88px;
            }
        }
    }
}

/* Mechanism section */
.mechanism-section__title {

    .title {
        padding-top:50px;
        font-size: 27px;
    }

    p:not(.title) {
        font-size: 20px;
        font-weight: bold;
    }
}

.mechanism-section {
    position: relative;
    padding:15px 0 30px 0;

    @media only screen and (min-width: 1200px) {
        width: 1020px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        background: url('../../../../assets/images/infographics-assets/treatment-deco.png') no-repeat;
        background-position: 126px 30px;
    }

    .mechanism-type {
        position: relative;
        width: 300px;
        height: 65px;
        margin:0 auto 30px auto;
        font-size: 19px;
        color: #3D3F5B;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 35px;
        cursor: pointer;
        transition: all .1s;
        border: 3px solid transparent;

        @media only screen and (min-width: 1200px) { 
            margin:0 auto 50px auto;
        }

        &.add-icon {
            
            &::before {
                content: '';
                display: block;
                width: 22px;
                height: 22px;
                background: url('../../../../assets/images/infographics-assets/add-icon.svg') no-repeat;
                position: absolute;
                top: 20px;
                right: 10px;
            }

            &:hover::before {
                // top: 18px;
                // right: 10px;
            }
        }

        &.no-cursor {
            cursor: default;
        }

        &.text-bold {
            font-weight: bold;
        }

        &.bg-yellow {
            background-color: #F2CC8F;

            &:not(.no-cursor):hover {
                border:3px solid #FFFFFF;
            }
        }

        &.bg-orange {
            background-color: #E07A5F;

            &:not(.no-cursor):hover {
                border:3px solid #FFFFFF;
            }
        }

        &.bg-lime {
            background-color: #F4F1DE;

            &:not(.no-cursor):hover {
                border:3px solid #E07A5F;
            }
        }

        &.bg-white {
            background-color: #FFFFFF;

            &:not(.no-cursor):hover {
                border:3px solid #E07A5F;
            }
        }

        &.bg-grey {
            background-color: #9E9FAD;

            &:not(.no-cursor):hover {
                border:3px solid #E07A5F;
            }
        }
    }

    .timepopups {
        left: 20%;
        top: 16% !important;

        @media only screen and (max-width: 640px) {
            width: 300px;
            top: 28% !important;
            left: -15px;

            .timepopups__scroll {
                width: 90%;
            }
        }
    }
}

/* Dopaminergic therapy graph */
.therapic-section {
    position: relative;
    
    .therapic-section-wrap {
        position: relative;
        width: fit-content;

        .therapygraph-img {
            width: 100%;
        }

        .timepopups {
            top: 8%;
            left: 14%;

            @media only screen and (max-width: 640px) { 
                width: 300px;
                left: -16px;

                .timepopups__scroll {
                    width: 90%;
                }
            }
        }
    }

    .button {
        display: inline-block;
        padding: 12px 40px;
        border:1px solid #E07A5F;
        border-radius: 25px;
        background-color: transparent;
        font-size: 20px;
        color: #E07A5F;
        text-decoration: none;
        cursor: pointer;
        transition: opacity 0.5s ease-in-out;
        opacity: 1;

        &:hover {
            opacity: 0.6;
        }
    }

    .therapic1 { 
        position: absolute;
        width: 19%;
        height: 10%;
        top: 74%;
        left: 1%;
        transition: background-color 0.5s ease-in-out;
        cursor: pointer;
        &:hover {
            background-color: rgba(255, 117, 117, 0.48);
        }
    }

    .therapic2 {
        position: absolute;
        width: 18%;
        height: 10%;
        top: 74%;
        left: 21%;
        transition: background-color 0.5s ease-in-out;
        cursor: pointer;
        &:hover {
            background-color: rgba(255, 117, 117, 0.48);
        }
    }

    .therapic3 {
        position: absolute;
        width: 18%;
        height: 10%;
        top: 74%;
        left: 40.5%;
        transition: background-color 0.5s ease-in-out;
        cursor: pointer;
        &:hover {
            background-color: rgba(255, 117, 117, 0.48);
        }
    }

    .therapic4 {
        position: absolute;
        width: 16%;
        height: 9%;
        top: 65%;
        left: 80%;
        transition: background-color 0.5s ease-in-out;
        cursor: pointer;
        &:hover {
            background-color: rgba(255, 117, 117, 0.48);
        }
    }

    &.therapic-section--white {
        padding-bottom: 25px;
        background-color: #FFFFFF;
        color: #3D3F5B;

        @media only screen and (min-width: 1200px) {  
            display: flex;
        }

        .therapic-item {

            &:last-of-type {
                width: 500px;
                padding-top: 30px;
                padding-left: 32px;

                @media only screen and (max-width: 768px) { 
                    width: 100%;
                }
            }

            &.center-content {
                position: relative;
                width: 100%;
                text-align: center;
                justify-content: center;
            }

            ul li {
                padding-bottom: 20px;
                font-size: 18px;
            }

            p {
                font-size: 20px;
                font-weight: bold;
                line-height: 24px;
            }

            .overlay-text {
                font-size: 17px;
                line-height: 22px;

                @media only screen and (min-width: 1200px) { 
                    position: absolute;
                    top: 30px;
                    right: 125px;
                }

                span {
                    display: block;
                }
            }

            img {
                @media only screen and (max-width: 768px) { 
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .therapic-cta {
        @media only screen and (min-width: 1200px) { 
            position: absolute;
            top: 205px;
            right: 0;
        }
    }

    .therapic-section_desc {

        @media only screen and (min-width: 1200px) { 
            display: flex;
        }
    }

    .therapic-section_desc-item {
        padding: 20px 0;
        font-size: 18px;
        line-height: 24px;
        text-align: center;

        @media only screen and (min-width: 1200px) { 
            width: 250px;
        }
    }

    &.therapic-marge {
        padding: 0 30px;
    }

    .title {
        padding-top: 30px;
        font-size: 20px;
        line-height: 26px;
        font-weight: bold;
        color: #3D3F5B;

        span {
            @media only screen and (min-width: 768px) { 
                display: block;
            } 
        }
    }
   
}

/* Symtoms content */
.symtom-content {
    padding-top: 30px;

    @media only screen and (min-width: 768px) { 
        display: flex;
        justify-content: space-between;
    }

    .title {
        font-size: 20px;
        font-weight: bold;
    }

    p:not(.title) {
        font-size: 18px;
        line-height: 28px;
    }
}

.symptom-section {
    padding-top: 70px;

    @media only screen and (max-width: 640px) { 
        padding-top: 0;
    }

    @media only screen and (min-width: 1200px) { 
        display: flex;
        justify-content: space-between;

        &.small {
            justify-content:initial;

            .symptom-item { 

                &:first-of-type { 
                    width: 370px;
                }
            }
        }
    }

    &.remove-pad {
        margin-top: -50px;  
        padding-top: 0;
    }

    .title {
        font-size: 27px;
    }

    .text-caption {
        padding-top: 30px;
    }

    .symptom-item {

        p {
            padding-bottom: 20px;
            font-size: 20px;
            line-height: 28px;
        }

        &:first-of-type {
            @media only screen and (min-width: 1200px) { 
                width: 452px;
            }
        }

        .info-container {
            font-size: 22px;
            line-height: 28px;
            color:#3D3F5B;
        }

        &.info-graph {
            position: relative;

            @media only screen and (min-width: 1200px) { 
                width: 532px;
                margin-top: -36px;
            }

            .timepopups { 
                top: 12px;
                left: 2%;

                @media only screen and (max-width: 640px) { 
                    width: 300px;
                    left: -17px;

                    .timepopups__scroll {
                        width: 90%;
                    }
                }
            }
        }
    }

    .symptom-item__cart {
        
        .symptom-item__cart-wrap {
            position:relative;
            width: fit-content;

            .pdagegraph-img {
                width: 100%;
                height: auto;
            }

            .timepopups {
                top: 22%;
                left: 4%;

                @media only screen and (max-width: 640px) { 
                    width: 300px;
                    top: 2%;
                    left: -20px;
                    padding: 15px 15px 20px 15px;

                    .timepopups__scroll {
                        width: 90%;
                    }
                }
            }
        }

        span {
            position: absolute;
            display: block;
            width: 54px;
            height: 54px;
            line-height: 54px;
            font-size: 35px;
            color: #FFFFFF;
            text-align: center;
            background-color: #E07A5F;
            border-radius: 50%;
            cursor: pointer;
            transition: opacity 0.5s ease-in-out;
            opacity: 1;

            &:hover {
                opacity: 0.8;
            }
        }

       .pos-1 {
            bottom: 23%;
            right: 44%;
        }

       .pos-2 {
            top: 14%;
            right: 19%;
       }
    }

    ul {
        padding: 0;
        list-style: none;
        
        li {
            padding-bottom: 15px;
            font-size: 18px;

            &::before {
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #E07A5F;
            }

            span {
                padding-left: 15px;
            }

            &.no-bullet {
                margin-top: -15px;
                padding-bottom: 0;
                padding-left: 24px;

                &::before {
                    background-color: transparent;
                }
            }
        }
    }
}

.symptom-title {
    padding:30px 0 40px 0;
    font-size: 27px;
}

.round-wrapper {
    width: 310px;
    height: 310px;
    background-color: #E07A5F;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 25px;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

   &.marge-left {
        @media only screen and (min-width: 1200px) { 
            margin: 0 0 80px 75px;
        }
      
   }
}

.grid {
    display: grid;
    grid-template-columns: auto auto;
    @media only screen and (max-width: 640px) {
        grid-template-columns: auto;
    }
}

.main-content-section {
    display: flex;
    //flex-wrap: wrap;
    flex-flow: column wrap;
    flex-direction: row;
    justify-content: space-between;

    .mdt-main-section {
        padding-bottom: 30px;
    }

    #mdt-section-members .mdt-section-members-content__wrapper {
        height: 100%;
    }
}

.icon-views-picker-grid {
    display: grid;
    grid-template-columns: auto auto auto auto;
    @media only screen and (max-width: 640px) {
        grid-template-columns: auto;
    }
}
.main-content-section-image{
    display: flex;
    flex-flow: column wrap;
    flex-direction: row;
    justify-content: flex-start;
    
}
.icon-center{
    .icon-text-popup__item{
        width: 50%;
        margin: 0 auto;
    }
}
.callout_section{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .callout_Bullet_Image:nth-child(3){
        width:63%;
        order:3;
    }

}










