@import 'style/variables.scss';

@mixin title-with-icon($color: $kk-primary, $margin: 0) {
  margin: $margin;
  display: flex;
  align-items: center;
  svg {
    color: $color;
    padding-right: 8px;
  }
}