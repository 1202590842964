@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.field-radio-buttons {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  .form-inline-error-text {
    margin-top: 20px;
  }

  .consent-label {
    font-family: $hindMadurai-reg;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  }

  .radio-buttons {
    margin-top: 15px;
    display: flex;
    min-width: 190px;

    .radio-button-wrap {
      display: flex;
      align-items: center;
      .radio-button {
        background-color: white;
        width: 24px;
        height: 24px;
        margin-right: 16px;
        border-radius: 15px;
        border: 1px solid gray;
        display: flex;
        justify-content: space-around;
        align-items: center;

        &:hover {
          border: 1px solid #EA570D;
        }

        &.active {
          border: 1px solid #EA570D;

          .radio-bg {
            width: 12px;
            height: 12px;
            border-radius: 16px;
            background-color: #EA570D;
          }
        }
      }

      label {
        margin-right: 34px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
.form-field-radio-eapc {
  margin-bottom: 40px;
  
  .radio-button-section {
    margin-top: 16px;

    .input-radio-section {
      display: flex;

      .options {
        display: flex;
        align-items: center;
        justify-content: center;

        .round-div {
          width: 24px;
          height: 24px;
          border: 1px solid #c4c4c4;
          border-radius: 50%;
          margin-right: 16px;
        }

        .radio-option-label {
          margin-right: 34px;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
        }

        &.checked {
          .round-div {
            border: 1px solid #ea5504;
            display: flex;
            justify-content: center;
            align-items: center;

            .background-div {
              width: 14px;
              height: 14px;
              background-color: #ea5504;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
