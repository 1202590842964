@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'assets/mixins.scss';


.module-section-card-container {
    margin-bottom: 40px;
    .Cards {
        width: auto;
        margin: 12px;
        border-radius: 5px;
        height: 100%;

        @include desktopOnly {
            width: 31%;
        }

        .CardHeading {
            flex-direction: row-reverse;

            p {
                margin-left: 10px;
            }

            img {
                margin: 0;
            }
        }

        .bgimage {
            max-width: 100%;
            height: 200px;
            border-radius: 5px 5px 0 0;
        }
    }

    .Cards:hover {
        box-shadow: -5px 5px 0px 0px #EA5504, 0px 2px 4px rgba(52, 58, 64, 0.25);
    }


}