.collapsible-details {
  button {
    width: 100%;
    height: 40px;
    padding: 0 15px;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    svg {
      margin-left: auto;
    }
  }
}