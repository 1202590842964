@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_buttons.scss';

.form-redesign  {
  border-radius: 4px !important;
}

.chevron-button-component {
  height: 48px;
  border-radius: 60px;
  @include orange-button-with-hover();
  display: inline-flex; // the content dictates size
  text-decoration: none;
  border-radius: 30px;
  padding: 12px 20px;
  align-items: center;
  border: none;
  text-transform: uppercase;
  img {
    width: 80px;
    margin-right: 20px;
  }
  &.blank {
    @include white-button-with-hover();
  }
  .chevron-button-text {
    font-family: $call-to-action-font;
    font-size: 16px;
    line-height: 16px;
    text-decoration: none;
    color: inherit;
    margin: 0px auto 0px 0px;
  }
  &--text-link {
    background: none;
    border: none;
    color: $kk-primary;
    border-radius: 0;
    .chevron-button-text {margin-right: 20px;}
    &:hover {
      background:none;

      .chevron-button-text {text-decoration: underline;}
    }
  }
  .chevron-button-svg-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: inherit;
    padding-left: 10px;

    svg {
      display: block;
      width: 10px;
    }

    &--down {
      svg {
        width: 17px;
      }
    }
  }

  // VARIANTS
  &--paywall-btn {
    min-width: 200px;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;

    & > .chevron-button-text {
      font-size: 16px;
      line-height: 19px;
    }

    .chevron-button-svg-container {
      margin-left: auto;
    }
  }
}
