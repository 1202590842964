/*
  Import base theme css props for approved KANSO components
*/ 
@import '../../styles/mixin.scss';
@import './designTokens/base.scss';
@import './designTokens/atoms.scss';
@import './designTokens/components.scss';
@import './designTokens/patterns.scss';

/*
  Add custom styles below
*/

body {
  //fallback font whilst google font loads to reduce cls
  font-family: verdana;
  font-size: 14px;
  line-height: 1.85;
  margin: 0;
  padding: 0;
  
  img {
    max-width: 100%;
    height: auto;
  }   
}

//page section element spacing
body > div > main > div > section > div > div {
  margin: 0 0 32px;
}

.modal-open {
  overflow: hidden;
}

.modal-feedback {
  --modal-content-margin: 0 0 12px;
}

.pagesection-feedback-section {
  --et-text-color: #FFFFFF;
  --et-headline-font-size: 28px;
  --et-headline-font-weight: 600;
  --et-headline-line-height: 33.6px;
  --et-headline-margin: 0 0 24px 0;
  --et-secondary-btn-bgcolor: transparent;
  --et-secondary-btn-text-color: #fff;
  --et-secondary-btn-border-color: #fff;
  --et-secondary-btn-hover-bgcolor: #fff;
  --et-secondary-btn-hover-text-color: #2F345A;
  --et-secondary-btn-hover-border-color: #fff;
  --et-secondary-btn-active-bgcolor: var(--secondary-btn-active-bgcolor);
  --et-secondary-btn-active-text-color: var(--secondary-btn-active-text-color);
  --et-secondary-btn-active-border-color: var(--secondary-btn-active-border-color);
}

.pagesection-home-main{
  --et-headline-font-weight: 600;
}

.pagesection-yd-section-1{
  --st-headline-font-size: 1.4em;
  --st-headline-line-height: 1.4em;
  --st-text-color: #2f345a;
  --et-headline-font-weight: 600;
}

.pagesection-yd-section-03{
  --st-headline-font-size: 1.4em;
  --st-headline-line-height: 1.4em;
  --st-text-color: #2f345a;
  --et-headline-font-weight: 600;
}

.pagesection-st-section-5{
  --st-headline-font-size: 1.4em;
  --st-headline-line-height: 1.4em;
  --st-text-color: #2f345a;
  --et-headline-font-weight: 600;
}

.pagesection-ot-section-3{
  --st-headline-font-size: 1.4em;
  --st-headline-line-height: 1.4em;
  --st-text-color: #2f345a;
  --et-headline-font-weight: 600;
}

.pagesection-ot-section-4{
  --st-headline-font-size: 1.4em;
  --st-headline-line-height: 1.4em;
  --st-text-color: #2f345a;
  --et-headline-font-weight: 600;
}

.pagesection-lwc-section-2 {
  --hh-headline-font-size-lg: 40px;
  --hh-headline-line-height-lg: 45px;
}

.pagesection-lwc-section-3 {
  --tertiary-btn-text-color: #EA5724;
  --tertiary-btn-icon-color: #EA5724;
  --tertiary-btn-text-decoration: none;
  --tertiary-btn-bgcolor: transparent;
  --tertiary-btn-hover-text-color: #EA5724;
  --tertiary-btn-hover-icon-color: #EA5724;
  --tertiary-btn-hover-text-decoration: underline;
  --tertiary-btn-hover-bgcolor: transparent;
  --tertiary-btn-active-text-color: #EA5724;
  --tertiary-btn-active-icon-color: #EA5724;
  --tertiary-btn-active-text-decoration: underline;
  --tertiary-btn-active-bgcolor: #F5F5F6;
  --tertiary-btn-focus-text-color: #4B4C53;
  --tertiary-btn-focus-icon-color: #4B4C53;
  --tertiary-btn-focus-text-decoration: none;
  --tertiary-btn-focus-bgcolor: #FFDD00;
  --tertiary-btn-disabled-text-color: #4B4C53;
  --tertiary-btn-disabled-icon-color: #4B4C53;
  --tertiary-btn-disabled-text-decoration: none;
  --tertiary-btn-disabled-bgcolor: transparent;
  --cctile-item-tertiary-btn-text-color: #EA5724;
  --cctile-item-tertiary-btn-icon-color: #EA5724;
  --cctile-item-tertiary-btn-hover-text-color: #EA5724;
  --cctile-item-tertiary-btn-hover-icon-color: #EA5724;
}

.pagesection-lwc-section-4{
  --st-headline-font-size: 1.4em;
  --st-headline-line-height: 1.4em;
  --st-text-color: #2f345a;
  --et-headline-font-weight: 600;
}

.pagesection-share-block{
  --et-headline-font-size: 28px;
  --et-headline-font-weight: 600;
}

.pagesection-res-sign-off{
  --et-headline-font-size: 28px;
  --et-headline-font-weight: 600;
}

@media print {
  body * {
    color: #000 !important;
    background: none !important;
    padding: 0 !important;
    text-align: left;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    .pagesection-feedback-section {
      display: none;
    }
  }
  body > div > main > div > section > div > div {
    margin: 0;
  }
}
