@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

footer {
  margin-top: auto;
  background-color: $footer-grey;
  padding-bottom: 30px;

  &.loaded {
    display: block !important;
    height: auto !important;
  }

  &.events-footer {
    .RichText {
      max-width: 684px;
    }

    .footer-top {
      margin-bottom: 18px;
      @include screen-width-small { 
        margin-bottom: 0;
      }
    }

    .footer-nav {
      margin-bottom: 70px;
      @include screen-width-small { 
        margin-bottom: 0;
      }
    }
  }

  .footer-top {
    display: flex;
    align-items: center;
    justify-content: stretch;
    padding: 32px 0;
    margin-bottom: 43px;
    .logo {
      width: 168px;
      img {
        max-width: 100%;
      }
    }
    .scroll-to-top {
      background: none;
      border: none;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ced0d1;
      margin-left: auto;
      border-radius: 50%;
      &:hover {
        color: white;
      }
    }
  }
  .footer-nav {
    margin: 0 0 60px;
    a {
      display: inline-block;
      text-transform: uppercase;
      color: white;
      font-size: 16px;
      font-weight: bold;
      padding: 0 18px 0 0;
      margin: 0 18px 14px 0;
      text-decoration: none;
      border-right: solid 2px white;
      &:last-child {
        border-right: none;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  section {
    color: #ced0d1;
    margin: 27px 0;
    p {
      margin: 0;
      word-wrap: break-word;
    }
    h4 {
      margin: 0;
      font-family: $subtitle-font;
    }
    .mailto {
      margin-top: 5px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 5px;
      }
      a {
        color: #fff;
      }
    }
  }
  a {
    color: #fff;
  }
  .adverse-footer{
    margin-top: -40px;
    .adverse-text{
      border: 1px solid #F9F4F2;
      padding: 10px 18px;
      max-width: 831px;
    }
    .adverse-text p{
      font-family: $hindMadurai-reg;
      font-size: 18px;
      line-height: 20px;
      color: #D8D8D8;
      a{
        color: #D8D8D8;
      }
    }
  }
}
