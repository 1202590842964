@import "style/variables.scss";
@import "style/mixins/_responsive.scss";

.chapters-pagination {
  display: block;
  position: relative;

  &__button {
    position: absolute;
    top: 50%;
    display: block;
    margin-top: -22px;
    padding: 11px 16px;
    color: $kk-primary;

    &--prev {
      left: 0;
      transform: rotate(180deg);
    }
    &--next {
      right: 0;
    }
  }

  &__chapter-count {
    display: block;
    text-align: center;
    color: #c4c4c4;
  }

  &__chapter-text {
    display: block;
    text-align: center;
    color: #000000;
    font-weight: bold;
    margin: 8px 0 0;
  }
}
