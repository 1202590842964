@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.my-hub-tabs {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 16px;
  cursor: pointer;
  transition: all 0.5s ease;

  @include screen-width-small {
    height: 56px;
    width: 100%;
    min-width: 137px;
    border-radius: 30px;
    margin-right: 16px;
    border: 1px solid #979797;
    .title {
      color: #979797;
    }
    &.active {
      border: none;
    }
  }

  &:hover {
    background-color: #ea5504;

    .no-hover {
      display: none;
    }

    .hover {
      display: block;
    }
    .title {
      color: white;
    }

    .no-hover--no-image {
      display: none;
    }

    .hover--no-image {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      outline: 1px solid #ffffff;
      border-radius: 2px;

      svg {
        fill: #ffffff;
      }
    }
  }

  .hover {
    display: none;
  }

  .hover--no-image {
    display: none;
  }

  img {
    margin-right: 8px;
  }


  .title {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0px;
    text-align: left;
  }

  .hover--no-image {
    display: none;
  }

  .no-hover--no-image {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 1px solid #979797;
    border-radius: 2px;
    margin-right: 8px;
  }

  &.active {
    background-color: #ea5504;
    .no-hover {
      display: none;
    }

    .hover {
      display: block;
    }
    .title {
      color: white;
    }
    .no-hover--no-image {
      display: none;
    }
    .hover--no-image {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      margin-right: 8px;
      border-radius: 2px;
      justify-content: center;
      outline: 1px solid #ffffff;

      svg {
        fill: #ffffff;
      }
    }
  }
}
