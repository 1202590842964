@import "style/variables.scss";
@import "style/mixins/_responsive.scss";
@import "style/mixins/_titleWithIcon.scss";
@import "src/style/mixins/_videoFigure.scss";

.dynamic-content-section {
  .container {
    p {
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 30px;
    }
    p:first-of-type {
      margin: 30px 0 30px;
    }
    section {
      padding-bottom: 16px;
    }
    .media-container {
      figure {
        @include video-figure();
        .video {
          width: 100%;
        }
      }
      img {
        max-width: 100%;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }
  }
  img {
    max-width: 100%;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  &.no-padding {
    .container {
      padding: 0;
    }
  }
}
