@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.header-banner-with-background {
  &__container {
    width: 100%;
    height: 100%;
    margin-bottom: -5px;

    img {
      width: 100%;
      height: 100%;
    }

    &.mobile {
      display: none;
    }

    @media (min-width: 0px) and (max-width: 500px) {
      &.desktop {
        display: none;
      }
      &.mobile {
        display: block;
      }
    }
  }
}
