@import 'assets/kanso/styles/global.scss';
@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.form-radio {
  // font-family: $base-font-family;
  font-style: normal;
  // color: $light-black;
  display: flex;
  cursor: pointer;
  background: #faf6f4;
  padding: 16px;
  border-radius: 8px;
  height: max-content;
  // align-items: center;
}

.input--hidden {
  display: none;
}

.form-radio__input:checked + .form-radio__button::after {
  transform: scale(1);
}

.form-radio__button {
  border-radius: 50%;
  outline: 2px solid $kk-primary;
  box-sizing: border-box;
  min-width: 26px;
  height: 26px;
  padding: 4px;
  display: flex;
  justify-content: space-evenly;
  background-color: white;
  align-items: center;
  margin: 0 2px;
  
  &:after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    background: $kk-primary;
    border-radius: 50%;
    transform: scale(0);
    transition: transform(0.15ms);
  }
}

.form-radio__texts__title {
  // font-family: $base-font-family;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0;
  margin: 0 10px;
  // color: $light-black;
}

[dir="rtl"] .form-radio__texts__title {
  margin-left: 0;
  margin-right: 10px;
}

.form-radio__texts__assistive {
  font-size: 16px;
  font-weight: 300;
  margin-left: 10px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 0;

  p {
    margin: 0;
  }
}

[dir="rtl"] .form-radio__texts__assistive {
  margin-left: 0;
  margin-right: 10px;
}

.p--margin-reset {
  margin: 0;
}

.form-radio--error {
  // outline: 2px solid $input-error;

  .form-radio__button {
    // outline: 2px solid $input-error;
  }
}

.form-radio--disabled {
  pointer-events: none;
  opacity: 0.6;
  // background: $off-white;
  .form-radio__button { 
    // outline: 2px solid $grey;
  }
}
