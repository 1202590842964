@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.content-gate-anonymous__overlay-anonymous {
  overflow-y: scroll;
  position: absolute;
  bottom: 0;
  width: 100%;
  // height: calc(100% - 262px);
  z-index: 1003;
  display: block;
  align-items: flex-start;
  justify-content: center;

  svg#svgBlur {
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  img.blur {
    width: 100%;
    filter: url(#blur);
  }
}

.content-gate-market__overlay {
  overflow-y: scroll;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: calc(100% - 80px);
  z-index: 1003;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  svg#svgBlur {
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  img.blur {
    width: 100%;
    filter: url(#blur);
  }
}

.content-gate-market {
  bottom: 0;
  z-index: 1003;
  width: 100%;
  padding: 24px 0;
  background-color: #fff;
  margin-top: auto;

  .container {
    .content-gate-market__title {
      h3 {
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        line-height: 72px;
        letter-spacing: 0px;
        text-align: left;
        color: $kk-primary;
        @include screen-width-small {
          margin-top: 20px;
          font-style: normal;
          font-weight: normal;
          font-size: 25px;
          line-height: 30px;
        }
      }

      p {
        margin-bottom: 20px;
        margin-top: 40px;
        color: $kk-primary;
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        line-height: 72px;
        letter-spacing: 0px;
        text-align: left;
        @include screen-width-small {
          margin-top: 20px;
          font-style: normal;
          font-weight: normal;
          font-size: 25px;
          line-height: 30px;
        }
      }
    }

    .content-gate-market__body {
      .desc {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;

        @include screen-width-small {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0px;
          text-align: left;
        }
      }

      .form {
        .anonymous-market-form {
          .form-component {
            margin-bottom: 0;

            .consent-label {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 19px;
              letter-spacing: 0px;
              text-align: left;
              margin-bottom: 30px;

              @include screen-width-small {
                margin-bottom: 35px;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0px;
                text-align: left;
              }
            }

            .anonymous-consent {
              margin-top: 35px;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 19px;
              letter-spacing: 0px;
              text-align: left;

              @include screen-width-small {
                margin-top: 20px;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0px;
                text-align: left;
              }

              p {
                margin-bottom: 10px;
              }
              a {
                color: inherit;
              }
            }

            button {
              margin-top: 20px;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 19px;
              letter-spacing: 0.699999988079071px;
              text-align: left;
              width: 245px;
            }
          }
        }
      }

      .reject-message {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        margin-bottom: 40px;

        @include screen-width-small {
          margin-bottom: 20px;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0px;
          text-align: left;
        }
      }
    }
  }
}

.content-gate-anonymous {
  bottom: 0;
  z-index: 1003;
  width: 100%;
  padding: 24px 0;
  background-color: #fff;
  margin-top: auto;

  .container {
    .content-gate-anonymous__content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 40px;
      @include screen-width-small {
        display: block;
        margin-bottom: 20px;
      }
      &__desc {
        width: calc(50% - 40px);

        .RichText {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0px;
          text-align: left;
          margin-bottom: 30px;
        }

        button {
          margin-bottom: 40px;
          width: 245px;
        }

        @include screen-width-small {
          width: 100%;
        }
        @include screen-width-medium {
          width: calc(50% - 20px);
        }
      }
      &__form {
        width: calc(50% - 40px);
        padding: 30px;
        background-color: #faf6f4;
        border-radius: 30px;
        @include screen-width-small {
          width: 100%;
        }
        @include screen-width-medium {
          width: calc(50% - 20px);
        }
      }
    }
  }

  &__overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc(100% - 80px);
    z-index: 1003;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    svg#svgBlur {
      width: 0;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }

    img.blur {
      width: 100%;
      filter: url(#blur);
    }
  }

  &__title {
    margin-top: 60px;
    margin-bottom: 40px;
    font-family: $myriad-reg;
    color: $kk-primary;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: 72px;
    letter-spacing: 0px;
    text-align: left;
    p {
      margin-top: 60px;
      margin-bottom: 40px;
      font-family: $myriad-reg;
      color: $kk-primary;
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: 72px;
      letter-spacing: 0px;
      text-align: left;
    }
    @include screen-width-small {
      margin-top: 20px;
      margin-bottom: 25px;
      font-style: normal;
      font-weight: normal;
      font-size: 25px;
      line-height: 30px;

      p {
        margin-top: 20px;
        margin-bottom: 25px;
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        line-height: 30px;
      }
    }
  }

  &__text {
    color: $body-text-color;
    font-size: 18px;
    line-height: 20px;
    font-weight: normal;
    margin: 0 0 10px;
    padding: 0 8px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
  }

  &__cta {
    &-wrapper {
      width: 100%;
      margin: 16px 0 0;
      max-width: 310px;
      display: flex;
      flex-direction: column;
      padding: 0 8px;

      & > .chevron-button-component {
        text-align: left;
        margin-bottom: 8px;
        width: 100%;
        height: 44px;
      }
    }

    &-smallprint {
      font-size: 10px;
      line-height: 12px;
      font-family: $myriad-reg;
      margin: 0;
      padding: 0 5px;
    }
  }
}

@include screen-width-desktop {
  .content-gate {
    padding: 40px 0;

    &__title {
      font-size: 60px;
      font-weight: 400;
      line-height: 72px;
      margin: 0 0 24px;
      padding: 0;
    }

    &__text {
      margin: 0 0 44px;
      padding: 0;
    }

    &__footer {
      flex-direction: row;
    }

    &__cta {
      &-wrapper {
        margin: 0;
        padding: 0;
        max-width: 395px;

        &:first-of-type {
          margin-right: 24px;
        }
      }

      &-smallprint {
        font-size: 12px;
        line-height: 15px;
        padding: 0;
      }
    }
  }
}

.app-layout--v1 {
  .content-gate-anonymous {
    &__title {
      font-family: $myriad-reg;
      p {
        font-family: $myriad-reg;
      }
    }
    &__cta {
      &-smallprint {
        font-family: $myriad-reg;
      }
    }
  }
}
.app-layout--v2 {
  .content-gate-anonymous {
    &__title {
      font-family: $hindMadurai-sb;
      p {
        font-family: $hindMadurai-sb;
      }
    }

    &__cta {
      &-smallprint {
        font-family: $hindMadurai-reg;
      }
    }
  }
}