@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.module-section-card-container {
    width:320px;
    @media only screen and (min-width:1024px){
        width:100%;
    }
    @include grid-row();
    flex-flow: wrap;
    @include screen-width-mobile {
        flex-direction: column;
    }
    .imageCaption__image{
        width:320px;
        
        margin-top:40px;
        margin-bottom:40px;
        @media only screen and (min-width:1024px){
            margin-right: 20px;
            padding-left: 17px;
            width:362px;
            
        }
        img{
            height:200px;
            width:100%;
            @media only screen and (min-width:1024px){
                height: 221px;
            }
        }
    }
    
    .image-caption-text{
        margin-top:5px;
    }
}