@import 'style/variables.scss';
@import 'assets/mixins.scss';

.ContentGate {
    width: 100%;

    @include desktopOnly {
        max-width: 1176px;
    }

    &_Wrapper {
        bottom: 0;
        padding: 24px;
        width: 100%;
        display: flex;
        z-index: 1003;
        position: absolute;
        align-items: flex-start;
        justify-content: center;
        height: calc(100% - 80px);
        background-color: #ffffff;

        @include desktopOnly {
            align-items: center;
        }
    }

    &_Title {
        font-size: 24px;
        font-weight: 600;
        margin: 0 0 16px;
        line-height: 29px;
        color: $kk-primary;

        @include desktopOnly {
            font-size: 60px;
            font-weight: 400;
            line-height: 72px;
        }
    }

    &_Text {
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
    }

    &_Footer {
        display: flex;
        flex-direction: column;

        @include desktopOnly {
            flex-direction: row;
        }
    }

    &_CTA {
        font-size: 16px;
        text-align: left;
        margin-bottom: 6px;

        &_Wrapper {
            display: flex;
            margin-right: 26px;
            flex-direction: column;

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        &_Smallprint {
            font-size: 10px;

            @include desktopOnly {
                font-size: 12px;
            }
        }
    }

}

.app-layout--v1 {
    .ContentGate--toastbar-present {
        height: calc(100% - 140px);
    }
}
.app-layout--v2 {
    .ContentGate--toastbar-present {
        height: calc(100% - 130px);
    }
}