@import 'assets/kanso/styles/global.scss';

/* Hide the browser's default checkbox */
.checkbox {
  // background: $off-white;
  border-radius: 8px;
  margin-bottom: 10px;
  position: relative;
}

.checkbox-title_list {
  // font-family: $base-font-family;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
}

.checkbox-list-tile {
  line-height: 0;
  padding-bottom: 16px;
  // font-family: $base-font-family;
  font-weight: 600;
  font-size: 16px;
}

.checkbox-input {
  margin-left: 16px;
  margin-right: 16px;
}

.checkbox-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  // font-family: $base-font-family;
  line-height: 26px;
  letter-spacing: 0;
  text-align: left;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  word-break: break-all;
  input {
    display: none;
  }
}

.checkbox-label__title {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  display: block;
}

[dir="rtl"] .checkbox-label__title {
  text-align: right;
}

.checkbox-label__description {
  margin-bottom: 0;
  margin-top: 8px;
  font-weight: 300;
  font-size: 14px;
  display: block;
}

.checkbox-disabled {
  // color: $grey;
  .checkmark {
    // outline: 2px solid $grey;
  }
}

.checkbox-error {
  // outline: 2px solid $input-error;

  .checkmark {
    // outline: 2px solid $input-error;
  }
}

/* Create a custom checkbox */
.checkmark {
  position: relative;
  height: 24px;
  width: 24px;
  // outline: 2px solid $purple;
  border-radius: 4px;
  // background-color: $off-white;
}

/* Show the checkmark when checked */
input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-checkmark {
  margin-right: 10px;
}

[dir="rtl"] .checkbox-checkmark {
  margin-right: 0;
  margin-left: 10px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-tick:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-label input:checked + .checkbox-checkmark {
  .checkmark {
    // background-color: $purple;

    .checkmark-tick:after {
      display: block;
    }
  }
}

/* Style the checkmark/indicator */
.checkmark-tick:after {
  position: absolute;
  top: 6px;
  left: 4px;
  height: 5px;
  width: 14px;
  content: "";
  transform: rotate(-45deg);
  transform-origin: revert;
  // border-bottom: 2px solid $off-white;
  // border-left: 2px solid $off-white;
}

.input-error-text {
  // font-family: $base-font-family;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  margin-top: 4px;
  // color: $input-error;
}
