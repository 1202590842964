@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_titleWithIcon.scss';
@import '../../../../../assets/mixins.scss';

.CardLink {

    // #region Block
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    margin-top: auto;
    text-decoration: none;
    color: $kk-primary;
    letter-spacing: 1px;
    margin-bottom: 0;

    &:hover {
        text-decoration: underline;
    }
    // #endregion



    // #region Elements
    svg {
        display: block;
        height: 16px;
        width: 12px;
        margin-left: 20px;
        margin-bottom: 3px;
    }

    span {
        display: block;
        font-family: $call-to-action-font;
        text-transform: uppercase;
    }
    // #endregion



    // #region Modifiers
    &--theme {
        &-primary {
            color: $kk-primary;
        }

        &-secondary {
            color: $body-text-color;
        }
    }

    // #endregion
}