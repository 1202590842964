@import 'style/variables.scss';


$loader-thickness: 1.5;

$tiny-size: 18px;
$small-size: 50px;
$medium-size: 100px;
$large-size: 150px;

$tiny-thickness: ($tiny-size * $loader-thickness) / 10;
$small-thickness: ($small-size * $loader-thickness) / 10;
$medium-thickness: ($medium-size * $loader-thickness) / 10;
$large-thickness: ($large-size * $loader-thickness) / 10;

.loading-page {
  background-color: #fff;
  position: fixed;
  top: 80px;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 1010;
}

.loader-component {
  display: block;
  border: 15px solid $kk-secondary; /* Light grey */
  border-top: 15px solid $kk-primary;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
  &-tiny {
    width: $tiny-size;
    height: $tiny-size;
    border-width: $tiny-thickness;
  }
  &-small {
    width: $small-size;
    height: $small-size;
    border-width: $small-thickness;
  }
  &-medium {
    width: $medium-size;
    height: $medium-size;
    border-width: $medium-thickness;
  }
  &-large {
    width: $large-size;
    height: $large-size;
    border-width: $large-thickness;
  }
}

.center-aligned-loader {
  margin: 0px auto;
}



@keyframes spin {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(50deg); }
  33% { transform: rotate(180deg); }
  66% { transform: rotate(300deg); }
  100% { transform: rotate(360deg); }
}