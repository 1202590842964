@import 'style/variables.scss';
@import 'style/mixins/_buttons.scss';


.view-more-button-container {
  @include container-rules();
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 30px;
  .view-more-button-component {
    display: inline-flex; // the content dictates it's size
    justify-content: center;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 11px 15px;
    @include screen-width-small {
      display: flex;
      width: 100%;
    }
    p {
      font-family: $hindMadurai-reg;
      font-size: 16px;
      line-height: 19px;
      color: inherit;
      margin: 0px 20px 0px 0px;
    }
    &-svg-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      color: inherit;
      svg {
        display: block;
        height: 4px; // the desings say 2px but it looks wrong. When you zoom into the designs mind you you see that it is in fact 4px
      }
    }
  }
}
