@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.text-and-image-panel {
  margin-bottom: 60px;
  margin-right: auto;
  margin-left: auto;
  max-width: 744px;

  &.full-width {
    margin-bottom: 40px;
  }

  @media (max-width: 740px) {
    margin-bottom: 40px;
  }

  @include screen-width-xl {
    max-width: 100%;
  }

  &__header {
    h4 {
      margin-bottom: 20px;
      font-family: $hindMadurai-reg;
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;

      @media (max-width: 740px) {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }
    }
  }

  &__columns {
    width: 100%;
    display: flex;

    @media (max-width: 740px) {
      flex-direction: column;
    }

    &.full-width {
      .text-and-image-panel__columns__left {
        width: 100%;
        padding-right: 0;

        .RichText {
          height: auto;
          max-width: 100%;
          margin-bottom: 20px;
        }
      }

      .text-and-image-panel__columns__left__link {
        display: block;
      }
    }

    &__left {
      width: 50%;
      padding-right: 30px;

      @media (max-width: 740px) {
        padding-right: 0;
      }

      @media (max-width: 740px) {
        width: 100%;
      }

      .RichText {
        height: 212px;
        max-width: 362px;
        margin-bottom: 20px;

        @include screen-width-xl {
          max-width: 100%;
          width: 100%;
        }

        @media (max-width: 740px) {
          height: 100%;
          max-width: 100%;
          width: 100%;
        }

        p {
          margin-top: 0;
          font-family: $hindMadurai-reg;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
        }
      }

      &__link {
        @media (max-width: 740px) {
          display: none;
        }
        height: 40px;
        width: 262px;
        border-radius: 30px;

        a {
          cursor: pointer;
          height: 40px;
          width: 262px;
          padding: 11px 20px;
          color: #ffffff;
          font-family: $hindMadurai-reg;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 1px;
          text-transform: uppercase;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: space-between;

          svg {
            width: 10px;
            height: 17px;
          }
        }
      }
    }
    &__right {
      width: 50%;
      padding-right: 20px;

      @media (max-width: 740px) {
        padding-right: 0;
        height: 100%;
        width: 100%;
      }
      &__logos {
        display: flex;

        @media (max-width: 740px) {
          display: block;
        }

        .logos-wrap {
          position: relative;
          display: flex;
          justify-content: center;
          img {
            height: 220px;
            border-radius: 15px;
            position: relative;

            @media (max-width: 740px) {
              height: 100%;
              width: 100%;
            }
          }
          .logo {
            height: 22px;
            position: absolute;
            top: calc(50% - 11px);
            @media (max-width: 740px) {
              width: auto;
            }
          }
        }
      }

      p {
        max-width: 362px;
        margin: 0;
        margin-right: 0;
        margin-top: 10px;
        font-family: $hindMadurai-reg;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;

        @media (max-width: 740px) {
          margin-bottom: 20px;
          font-style: normal;
          font-weight: normal;
          font-size: 16.0802px;
          line-height: 19px;
        }
      }

      &__link {
        @media (min-width: 739px) {
          display: none;
        }
        height: 40px;
        width: 262px;
        border-radius: 30px;

        a {
          padding: 12px 20px;
          height: 40px;
          width: 262px;
          color: #ffffff;
          cursor: pointer;
          font-family: $hindMadurai-reg;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 1px;
          text-transform: uppercase;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: space-between;

          svg {
            width: 10px;
            height: 17px;
          }
        }
      }
    }
  }
}
