@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
 
.__react_component_tooltip {
    width: 500px !important;
    display: flex !important;
    pointer-events: initial !important;
    z-index: 999999 !important;

 
    @include screen-width-small {
        width: 300px !important;
    }
 
    &.show {
        padding: 0 30px !important;
        background-color: #FFFFFF;
        box-shadow: 3px 3px 6px #00000029;
        border: 2px solid #3D3F5B;
        border-radius: 20px;
        opacity: 1 !important;
    }
 
    .popup-content {
        padding: 25px 0;
        p {
            padding: 0 !important;
            font-size: 16px;
            color: #3D3F5B;
            margin: 0;
        }

        &.activeScrollBar {
            margin: 15px 10px 15px 0;
            padding-right: 10px;
            height: 165px;
            overflow-y: scroll;
            vertical-align: middle;

            &::-webkit-scrollbar {
                width: 10px;
                margin-right: 15px;
            }
              
              /* Track */
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px rgb(255, 253, 253); 
                border-radius: 10px;
                background-color: #ffff;
                border: 2px solid #3D3F5B;
            }
               
              /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #3D3F5B; 
                border-radius: 10px;
                border:1px;
            }
            & {
                scrollbar-face-color: #3D3F5B;
                scrollbar-track-color: #ffff;
            }
        }

        button {
            margin-top: 10px;
            display: inline-block;
            width: 100%;
            padding: 6px 10px;
            font-size: 14px;
            color: #3D3F5B;
            text-align: center;
            text-decoration: none;
            background-color: transparent;
            border:1px solid #3D3F5B; 
            border-radius: 25px;
            cursor: pointer;
            transition: opacity 0.5s ease-in-out;
            opacity: 1;
 
            &:hover {
                opacity: 0.6;
            } 
 
            span {
                display: block;
            }
        }
    }
 
    p.popup-title {
        padding-bottom: 8px !important;
        font-weight: bold;
    }

    .controlled-example {
        position: relative;
        width: 100%;
    }

    .controlled-example_header  {
        //pointer-events: none !important;
        float: right;
        cursor: pointer;
        position: absolute;
        right: -25px;
        top: 5px;

        img {
            width: 28px !important;
            height: 28px !important;
            float: right;
            cursor: pointer;
        }
    }

    &.place-bottom {
        margin-top: 30px !important;

        &::after {
            content: '';
            display: block;
            width: 85px;
            height: 34px;
            background: url('../../../../assets/images/infographics-assets/arrow-down.png') no-repeat;
            position: absolute;
            top: -34px !important;
            left: 43% !important;
            transform: rotate(180deg);

            //Reset default
            border-right: none !important; 
            margin-left: 0 !important;
            border-bottom-color: transparent;
            border-bottom-style: none !important;
            border-bottom-width: 0 !important;

            @media only screen and (max-width: 640px) { 
                left: 38% !important;
            }
        }
    }

    &.place-top {
        margin-top: -28px !important;

        &::after {
            content: '';
            display: block;
            width: 85px;
            height: 34px;
            background: url('../../../../assets/images/infographics-assets/arrow-down.png') no-repeat;
            position: absolute;
            bottom: -34px !important;
            left: 43% !important;

            //Reset default
            border: none !important;
            margin-left: 0 !important;
            border-top-color: transparent;
            border-top-style: none !important;
            border-top-width: 0 !important;

            @media only screen and (max-width: 640px) { 
                left: 40% !important;
            }
        }
    }

    &.place-right {

        &::after { 
            content: '';
            display: block;
            width: 85px;
            height: 34px;
            background: url('../../../../assets/images/infographics-assets/arrow-down.png') no-repeat;
            position: absolute;
            transform: rotate(90deg);
            left: -59px !important;
            top: 44% !important;
    

            //Reset default
            border: none !important;
            margin-top: 0 !important;
            border-right-color: transparent;
            border-right-style: none !important;
            border-right-width: 0 !important;

        }
    }

    &.place-left {
       
        &::after { 
            content: '';
            display: block;
            width: 85px;
            height: 34px;
            background: url('../../../../assets/images/infographics-assets/arrow-down.png') no-repeat;
            position: absolute;
            transform: rotate(270deg);
            right: -59px !important;
            top: 38% !important;

            //Reset default
            border: none !important;
            margin-top: 0 !important;
            border-left-color: transparent;
            border-left-style: none !important;
            border-left-width: 0 !important;
        }
    }

}