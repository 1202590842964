@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.profile-page-component {
  .profile-form-content {
    @include spans-columns(6);
    @include spans-columns-small(4);
    margin-left: auto;
    margin-right: auto;
    .account-details {
      font-family: $header-font;
      color: #333333;
      font-size: 20px;
      margin: 0px 0px 30px 0px;
    }
    .check-details {
      font-family: $body-font;
      color: #333333;
      font-size: 12px;
      line-height: 14px;
      margin: 0px 0px 30px 0px;
      padding: 0px 0px 20px 0px;
      border-bottom: 1px dashed $kk-primary;
    }
    .veeva-code {
      padding-bottom: 20px;
      font-size: 12px;
    }
  }

  .withdraw-consent-component {
    margin-top: 0;
  }
}






