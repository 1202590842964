@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.CardDate {
  font-family: $hindMadurai-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 36px;
}
