@import '../../../../../assets/mixins.scss';
@import 'style/variables.scss';

.form-redesign {
  .search-box {
    border-radius: 4px !important;
    background-color: #F6F6F6 !important;
  }
  
}
.SearchBox {
  display: none;

  &-Cancel {
    display: none;
  }

  @include desktopOnly {
    display: block;
    position: relative;
    align-self: center;
    max-height: 32px;
    max-width: 280px;
    margin-right: 44px;

    @supports (display: grid) {
      grid-area: searchBox;
    }

    .custom-focus {
      margin: 0;
      padding: 5px 32px 3px 15px;
      color: #666666;
      background-color: #fff;
      border: 1px solid #333333;
      
      &:focus {
        box-shadow: none;
      }
    }

    .custom-focus--border {
      border: none;
    }
    // IE: Hide in-built 'X' clear button
    .custom-focus::-ms-clear {
      display: none;
    }

    button {
      display: contents;
      // IE fixes (display: contents not working in IE)
      border: none;
      background-color: transparent;
      svg {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 14px;
        transform: translateY(-50%);
      }
    }
  }

  .RecentHistory {
    position: absolute;
    background-color: white;
    width: 100%;
    border-radius: 20px;
    margin: 0 -15px;
    padding: 25px 20px 15px 15px;
    min-height: 300px;
    z-index: 1000;

    @include tabletMin {
      margin: 10px 0 0;
      padding: 15px 20px 15px 15px;
      min-height: auto;
    }

    &-Head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h5 {
        margin: 0;
        padding: 0 0 5px 8px;
        font-size: 16px;
        color: #000000;
        font-family: $hindMadurai-reg;
        line-height: 19px;
        font-weight: 600;
      }

      button {
        font-size: 12px;
        font-family: $hindMadurai-reg;
        color: #ea5504;
        line-height: 14px;
        font-weight: 600;
      }
    }

    &-Body {
      margin: 10px 0 0;
    }

    &-List {
      padding: 0;
      margin: 0;
      list-style-type: none;

      &-Item {
        display: table;
        cursor: pointer;
        margin: 0 0 5px;

        img {
          padding: 0 8px 0 0;
        }

        span {
          display: table-cell;
          vertical-align: top;
          word-break: break-word;
        }
      }
    }
  }
}

//This seems wrong but it is right, we have a desktop nav and a mobile nav and search box needs to be in both
.MobileNavigation {
  .SearchBox {
    position: relative;
    display: block;
    width: 100%;
    padding: 0 15px;
    margin: 10px 0 20px;
    height: 32px;

    &-Inner {
      display: flex;
      align-items: center;
      justify-content: left;
      .form-field--100 {
        width: 100%;
        position: relative;
      }

      .custom-focus {
        margin-bottom: 0;
        padding: 12px 38px 12px 20px;
        &:focus {
          box-shadow: none;
        }
      }

      .custom-focus::-ms-clear {
        display: none;
      }

      button {
        display: contents;
        border: none;
        background-color: transparent;

        svg {
          display: inline-block;
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translateY(-50%);
        }
      }
    }

    &-Cancel {
      display: block;
    }
  }
}
