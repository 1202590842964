@import '../../../../assets/kanso/styles/global.scss';

.sectionTitle {
  font-family: var(--base-font-family);
  font-size: var(--st-font-size, var(--base-font-size));
  font-weight: var(--st-font-weight, var(--base-font-weight));
  line-height: var(--st-line-height, var(--base-line-height));
  color: var(--st-text-color, var(--base-font-color));
}

.sectionTitle.sectionTitle.sectionTitle-left {
  justify-content: left;
  text-align: left;
}

.sectionTitle.sectionTitle-center {
  justify-content: center;
  text-align: center;    
  position: relative;
  left: 50%;
  transform: translate(-50%);
  p {
    margin: 0 auto;
  }
}

.sectionTitle p {
  margin: 0;
  @include media(medium) {
    max-width: 66%;
  }
  @include media(large, extraLarge, extraExtraLarge) {
    max-width: 60%;
  }
}

.sectionTitle .headline {
  font-size: var(--st-headline-font-size, 64px);
  font-weight:  var(--st-headline-font-weight, 700);
  line-height:  var(--st-headline-line-height, 76.8px);
  margin: 0 0 16px 0;
}

@media print {
  .sectionTitle {
    .headline {
      font-size: 22px;
      line-height: 1.5rem;
      margin: 0;
      padding-bottom: 0.15in !important;
    }
  }
}