@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.external-form-inner {
    background-color: #ffffff;
    border-radius: 30px 30px;

    .form-title {
        display: block;
        width: 100%;
        font-family: $hindMadurai-reg;
        font-size: 25px;
        line-height: 29px;
        text-align: left;
        color: #333333;
        margin: 0px;
        padding: 30px;
    }

    .form-p {
        display: block;
        width: 100%;
        // font-family: $myriad-reg;
        font-family: $hindMadurai-reg;
        font-size: 16px;
        line-height: 21px;
        text-align: left;
        color: #5D6164;
        padding: 0px 30px 12px 30px;
        margin: 0px;
    }

    hr {
        height: 1px;
        background-color: #EA5504;
        margin: 0px 30px;
        padding: 0px;
        border: 0px;
    }
}

.external-form-component--scroll {
    height: 100%;
    overflow: auto;
}

.external-form-component--new {
    .external-form-inner {
        .form-title {
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 48px;
            letter-spacing: 0;
            color: #EA5504;
        }
    }
    .form-component-wrapped {
        box-shadow: none;
    }

    .form-field-button {
        button {
            width: 245px;

            @media (max-width: 600px) {
                width: 100%;
            }
        }
    } 
}
.app-layout--v1 {
    .external-form-inner {
        .form-title {
            font-family: $myriad-reg;
        }
    
        .form-p {
            font-family: $myriad-reg;
        }
    }
}
.app-layout--v2 {
    .external-form-inner {
        .form-title {
            font-family: $hindMadurai-reg;
        }
    
        .form-p {
            font-family: $hindMadurai-reg;
        }
    }
}