.iframe {
    &-preview {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #5d6164;
        border-radius: 32px;
        max-width:331px;
        height:190px;
        margin: 0 auto;
        @media (min-width: 992px) {
            max-width: 784px;
            height:479px;
        }
        .iframe-preview--success{
            text-align: center;
            
        }
        .iframe-preview--success {
            text-align: center;
            padding: 20px;
            flex: 1;
            height: 100%;
            
        }
        .iframe-image-sucess {
            position: relative;
            display: flex;
            align-items: center;
            overflow: hidden;
            height: 100%;

            img {
                display: block;
                max-width: 100%;
            }
            
            &:after {
                content: '';
                position: absolute;
                background: rgba(0, 0, 0, 0.1);
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
        }
       
    }

    &-grey {
        background-color: #5d6164;
        color: #fff;
        padding: 5px 20px;
    }

    &-button {
        color: #fff;
        padding: 15px 10px;
        border-radius: 20px;
        background-color: #A42A2E;
        border: 1px solid #A42A2E;
        display: block;
        margin: 25px auto;
       
    }
    &-button[disabled] {
        background-color: #F3F2F1;
        border: 1px solid #F3F2F1;
    }
    &-preview--error{
        text-align: center;
    }
   
}

.edetailer {
    flex-flow: column;
    align-items: center;

    &-modal {
        border-radius: unset;
        width:90%;
        .veeva-page-component {
            width: 100%;
        }

        .veeva-container {
            padding: 0;
        }

        .veeva-aspect-ratio {
            margin: 0 ;
        }
    }
}
#edetailer{
    .taskbar{
        background: #F9F4F2;
        padding-bottom:6%;
        position: relative;
        .close-button-container{
            position:absolute;
            right:0;
            margin:2%;
        }
    
    }
    .list-content-page{
        padding-top:40px;
    }
    .column-left{
        @media only screen and (min-width:992px){
            display:flex;
        }
        
    }
    .content-page-promo-subnav{
        display:none;
        @media only screen and (min-width:992px){
            width:25%;
            display:block;
            padding: 0px 0px 14px 80px;
        }
    }
    
    .main-page-section{
        @media only screen and (min-width:992px){
            width:54%;
        }
       
    }
    .content-page-article{
        line-height: 20px;
        padding-bottom: 40px;
        margin-left:21px;
        margin-right:23px;
        p{
            font-size:16px;
        }
    }
    
    .brand_logo_section{
        margin-top:6px;
        margin-bottom:43px;
    }
    .header__inner{
        flex-flow: column;
        .header__column--title{
            order:2;
        }
    }
    .header__column{
        width:100%;
    }
    .Related_Content_Title{
        padding-top:25px;
        padding-left: 25px;
        @media only screen and (min-width:992px){
            padding-left:100px;
        }
        
    }
    .Carousel_Container{
        width:90%;
        margin:auto;
    }
    .imagebutton{
        padding-left:10px;
    }
    .brand-article-page__content{
        width:unset;
    }
    .content-item--date{ 
        color: #ea5504;
        margin-bottom: 0px;
        @media only screen and (min-width:992px){
            display:flex;
            flex-flow:row;
        }
    }
    .date{
        @media only screen and (max-width:992px){
            padding-left:25px;
        }
    }
    .readingTime{
        padding-left:25px;
        @media only screen and (min-width:992px){
            padding-left:100px;
        }
      
    }
    .content__outer:first-of-type .content__inner{
        margin-top:25px;
        padding:0px;
    }
    .brand-article-page__content{
        padding-left:25px;
        padding-right:25px;
        @media only screen and (min-width:992px){
            padding-left:0;
        }
    }
    .container-references{
        margin-top:70px;
        @media only screen and (max-width:992px){
            margin-top:40px;
        }
    }
    .slick-dots{
        @media only screen and (max-width:992px){
            background-color: #FAF6F4;
            width:115%;
        }
       
    }
    .tooltiptext {
        visibility: hidden;
        width: 120px;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        right: -18px;
        padding-top: 2px;
    }
    
    .button-frame:hover .tooltiptext {
        visibility: visible;
    }
}

.edetailer-player {
    background-color: #fff;
}