@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_videoFigure.scss';
@import '../../assets/mixins.scss';

.brand-content-list-page {
  &__logo {
    @include container-rules();
    margin: 0 auto;
    display: flex;
    max-width: 1600px;
  }

  &__header {
    .header__subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 29px;
      text-transform: uppercase;
    }

    p a {
      color: inherit;
    }

    .header__title {
      @include tabletMin {
        font-style: normal;
        font-weight: normal;
        font-size: 60px;
        line-height: 72px;
        font-family: $title-font;
      }
    }
  }

  &__content-section {
    margin-bottom: 40px;
    .container {
      @include tabletMax {
        padding: 0;
      }
    }
  }

  &__content-section-inner {
    display: flex;
    flex-direction: column;

    @include desktopOnly {
      flex-direction: row;
    }
  }

  &__subnavigation {
    display: none;
    .content-page-promo-subnav {
      padding: 0;
    }
    @include desktopOnly {
      padding: 40px 0;
      display: block;
      @include spans-columns(2);
      padding-left: 0 !important;
    }
  }

  &__content {
    @include spans-columns(12);
    padding: 0;

    @include desktopOnly {
      display: block;
      @include spans-columns(10);
    }

    .content {
      &__title:first-of-type {
        margin-top: 0;
      }

      @include desktopOnly {
        padding: 40px 20px 0 20px;
      }
    }

    .content__divider {
      height: 0;
    }

    .content__inner {
      .Related_Content_Section {
        padding: 0 0 20px;
      }
    }

    .Related_Content_Section {
      padding: 40px 0;
    }
  }
}
