@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_titleWithIcon.scss';
@import 'assets/mixins.scss';

.app-layout--v1 {
    .CardRichText {

        // #region Block
        margin-bottom: 30px;
        word-break: break-word;
        overflow-wrap: break-word;
        flex-shrink: 1;
        // #endregion
    
    
    
        // #region Elements
        p {
            margin-top: 0px;
    
            &:not(:last-child) {
                margin-bottom: 24px;
            }
        }
        // #endregion
    
    
    
        // #region Modifiers
        // #endregion
    }
}
.app-layout--v2 {
    .CardRichText {

        // #region Block
        margin-bottom: 30px;
        word-break: break-word;
        overflow-wrap: break-word;
        flex-shrink: 1;
        // #endregion
    
    
    
        // #region Elements
        p {
            margin-top: 0px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // -webkit-box-orient: vertical;
            // -webkit-line-clamp: 5;
            // display: -webkit-box;
    
            &:not(:last-child) {
                margin-bottom: 24px;
            }
        }
        // #endregion
    
    
    
        // #region Modifiers
        // #endregion
    }
}
