@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.circle-call-out__itemWrapper {
    @include spans-columns(12);
    text-align: center;
    margin: 1em 0;

    @include screen-width-small {
        @include spans-columns(12);
        margin-bottom: 15px;
    }
    .circle-call-out__itemContent {
        position: relative;
        width: 430px;
        height: 430px;
        background-color: #ffffff;
        border-radius: 50%;
        box-shadow: 0px 3px 6px #9A3F26;
        display: block;
        margin: 0 auto;
        @include screen-width-small {
            width: 300px;
            height: 300px;
        }
        @include screen-width-medium {
            width: 350px;
            height: 350px;
        }
        > .circle-call-out__content {
            color: #3D3F5B;
            font-size: 30px;
            font-weight: normal;
            margin: 0;
            position: absolute;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            padding: 12px;
            @include screen-width-small {
                font-size: 24px;
            }
            @include screen-width-medium {
                font-size: 26px;
            }
            span {
                color: #E07A5F;
            }
        }
        .circle-call-out__btn {
            border: 2px solid #3D3F5B;
            border-radius: 50px;
            text-transform: uppercase;
            font-size: 15px;
            color: #3D3F5B;
            padding: 6px 28px;
            display: inline-block;
            position: absolute;
            bottom: 8%;
            left: 150px;
            cursor: pointer;
            @include screen-width-small {
                left: 86px;
            }
            @include screen-width-medium {
                left: 113px;
            }
            .RichText {
                * {
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }
}