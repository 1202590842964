@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_buttons.scss';

.form-redesign{
  button {
    border-radius: 4px !important;
  }
} 

.form-field-button {
  margin-bottom: 30px;
  .submission-error {
    cursor: default;
    p {
      color: #FF0000;
    }
  }

  .mandatory-text {
    margin-bottom: 20px;
    margin-top: -25px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #EA5504;
  }

     

  button {
    @include orange-button-with-hover();
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 30px;
    padding: 12px 20px;
    border: 0px;
    width: 100%;
    @include screen-width-mobile {
      width: 100%;
    }
    p {
      color: inherit;
      padding: 0px;
      margin: 0px;
    }
  }
  &-svg-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: inherit;
    svg {
      display: block;
      width: 10px;
    }
  }
  &-blank {
    button {
      @include white-button-with-hover();
    }
  }
  &-submitting {
    button:disabled {
      opacity: 0.4;
      cursor: progress;
    }
  }

}
.custom-btn-form-label {
  .RichText {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      width: 100%;
      margin: 0;
    }
  }
  margin-bottom: 40px;
  margin-top: 30px;

  
}

