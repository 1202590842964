@import 'style/variables.scss';


.approval-code-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 140px 0 60px;

  &__logo{
    display: block;
    max-width: 310px;
    margin-bottom: 20px;
  }

  &__code{
    display: block;
    font-family: $bold-font;
    font-size: 16px;
    line-height: 20px;
    color: $body-text-color;
  }

}