@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_videoFigure.scss';
@import '../../assets/mixins.scss';

.subheader {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: $background-grey;
    color: $body-text-color;

    @include screen-width-small() {
      flex-direction: column;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    &__inner {
        @include grid-row();

        @include screen-width-small {
            flex-direction: column;
        }
    }

    &__column {
        @include spans-columns(6);
        @include spans-columns-small(4);
        // Rich text can return any number of different elements so :first-child is not a solution for "all elements should have Npx
        // between them". However if we give all elements a margin-top of 20px and then just hoist up the .column by 20px, then the
        // extra spacing from the first element is canceled out.
        $space-between-text: 20px;
        margin-top: -$space-between-text;

        p {
            // p from rich text
            font-size: 20px;
        }

        * {
            margin: $space-between-text 0px 0px 0px;
        }
    }

    &__text--light-blue {
        font-size: 20px;
        line-height: 25px;
    }
}