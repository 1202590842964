@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.app-layout--v1 {
  .my-hub-courses-container {
    .my-hub-courses-texts {
      padding-left: 25px;
  
      @include screen-width-small {
        padding: 0 21px;
      }
  
      .courses-title {
        h2 {
          margin-top: 24px;
          margin-bottom: 24px;
          font-family: $myriad-light;
          font-style: normal;
          font-weight: 300;
          font-size: 40px;
          line-height: 48px;
          color: #ea5504;
  
          @include screen-width-small {
            font-size: 25px;
            font-style: normal;
            font-weight: 300;
            line-height: 30px;
            letter-spacing: 0px;
            text-align: left;
          }
        }
      }
  
      .courses-description {
        margin-bottom: 49px;
  
        @include screen-width-small {
          margin-bottom: 24px;
        }
  
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 24px;
  
          @include screen-width-small {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0px;
            text-align: left;
          }
  
          a {
            color: #ea5504;
          }
        }
      }
    }
  
    .my-hub-courses-list {
      padding-left: 20px;
  
      @include screen-width-small {
        padding: 0 21px;
      }
  
      &__tabs {
        margin-bottom: 30px;
        display: flex;
  
        @include screen-width-small {
          margin-bottom: 20px;
        }
  
        &__text {
          line-height: 35px;
          cursor: pointer;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 0px;
          text-align: left;
          margin-right: 28px;
          padding-bottom: 8px;
  
          @media (max-width: 360px) {
            font-size: 14px;
          }
  
          @include screen-width-small {
            margin-right: 10px;
          }
  
          &.active {
            line-height: 35px;
            font-family: $myriad-bold;
            font-size: 16px;
            font-style: normal;
            font-weight: 900;
            letter-spacing: 0px;
            text-align: left;
            border-bottom: 2px solid #333333;
  
            @media (max-width: 360px) {
              font-size: 14px;
            }
          }
        }
      }
      .courses-list {
        display: none;
        min-height: 800px;
        
        @include screen-width-small {
          min-height: auto;
        }
        .Pagination {
          margin: 40px 0 30px;
        }
  
        &.active {
          display: block;
        }
  
        .no-pagination-divider {
          height: 40px;
          width: 100%;
        }
      }
    }
  }
}
.app-layout--v2 {
  .my-hub-courses-container {
    .my-hub-courses-texts {
      padding-left: 25px;
  
      @include screen-width-small {
        padding: 0 21px;
      }
  
      .courses-title {
        h2 {
          margin-top: 24px;
          margin-bottom: 24px;
          font-family: $hindMadurai-sb;
          font-style: normal;
          font-weight: 300;
          font-size: 40px;
          line-height: 48px;
          color: #ea5504;
  
          @include screen-width-small {
            font-size: 25px;
            font-style: normal;
            font-weight: 300;
            line-height: 30px;
            letter-spacing: 0px;
            text-align: left;
          }
        }
      }
  
      .courses-description {
        margin-bottom: 49px;
  
        @include screen-width-small {
          margin-bottom: 24px;
        }
  
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 24px;
  
          @include screen-width-small {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0px;
            text-align: left;
          }
  
          a {
            color: #ea5504;
          }
        }
      }
    }
  
    .my-hub-courses-list {
      padding-left: 20px;
  
      @include screen-width-small {
        padding: 0 21px;
      }
  
      &__tabs {
        margin-bottom: 30px;
        display: flex;
  
        @include screen-width-small {
          margin-bottom: 20px;
        }
  
        &__text {
          line-height: 35px;
          cursor: pointer;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 0px;
          text-align: left;
          margin-right: 28px;
          padding-bottom: 8px;
  
          @media (max-width: 360px) {
            font-size: 14px;
          }
  
          @include screen-width-small {
            margin-right: 10px;
          }
  
          &.active {
            line-height: 35px;
            // font-family: $myriad-bold;
            font-family: $hindMadurai-sb;
            font-size: 16px;
            font-style: normal;
            font-weight: 900;
            letter-spacing: 0px;
            text-align: left;
            border-bottom: 2px solid #333333;
  
            @media (max-width: 360px) {
              font-size: 14px;
            }
          }
        }
      }
      .courses-list {
        display: none;
        min-height: 800px;
        
        @include screen-width-small {
          min-height: auto;
        }
        .Pagination {
          margin: 40px 0 30px;
        }
  
        &.active {
          display: block;
        }
  
        .no-pagination-divider {
          height: 40px;
          width: 100%;
        }
      }
    }
  }
}
