@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_titleWithIcon.scss';
@import '../../assets/grid.scss';
@import '../../assets/mixins.scss';


.Grid {
    @include grid();

    &--items {

        &-sm {
            &-1 {
                &-gap {
                    &-10 {
                        @include gridAuto($items-per-row-sm: 1, $gap-sm: 10px);
                    }

                    &-15 {
                        @include gridAuto($items-per-row-sm: 1, $gap-sm: 15px);
                    }

                    &-20 {
                        @include gridAuto($items-per-row-sm: 1, $gap-sm: 20px);
                    }

                    &-25 {
                        @include gridAuto($items-per-row-sm: 1, $gap-sm: 25px);
                    }
                }
            }

            &-2 {
                &-gap {
                    &-10 {
                        @include gridAuto($items-per-row-sm: 2, $gap-sm: 10px);
                    }

                    &-15 {
                        @include gridAuto($items-per-row-sm: 2, $gap-sm: 15px);
                    }

                    &-20 {
                        @include gridAuto($items-per-row-sm: 2, $gap-sm: 20px);
                    }

                    &-25 {
                        @include gridAuto($items-per-row-sm: 2, $gap-sm: 25px);
                    }
                }
            }

            &-3 {
                &-gap {
                    &-10 {
                        @include gridAuto($items-per-row-md: 3, $gap-md: 10px);
                    }

                    &-15 {
                        @include gridAuto($items-per-row-md: 3, $gap-md: 15px);
                    }

                    &-20 {
                        @include gridAuto($items-per-row-md: 3, $gap-md: 20px);
                    }

                    &-25 {
                        @include gridAuto($items-per-row-md: 3, $gap-md: 25px);
                    }
                }
            }

            &-4 {
                &-gap {
                    &-10 {
                        @include gridAuto($items-per-row-md: 4, $gap-md: 10px);
                    }

                    &-15 {
                        @include gridAuto($items-per-row-md: 4, $gap-md: 15px);
                    }

                    &-20 {
                        @include gridAuto($items-per-row-md: 4, $gap-md: 20px);
                    }

                    &-25 {
                        @include gridAuto($items-per-row-md: 4, $gap-md: 25px);
                    }
                }
            }
        }

        @include tabletOnly {
            &-md {
                &-1 {
                    &-gap {
                        &-10 {
                            @include gridAuto($items-per-row-md: 1, $gap-md: 10px);
                        }

                        &-15 {
                            @include gridAuto($items-per-row-md: 1, $gap-md: 15px);
                        }

                        &-20 {
                            @include gridAuto($items-per-row-md: 1, $gap-md: 20px);
                        }

                        &-25 {
                            @include gridAuto($items-per-row-md: 1, $gap-md: 25px);
                        }
                    }
                }

                &-2 {
                    &-gap {
                        &-10 {
                            @include gridAuto($items-per-row-md: 2, $gap-md: 10px);
                        }

                        &-15 {
                            @include gridAuto($items-per-row-md: 2, $gap-md: 15px);
                        }

                        &-20 {
                            @include gridAuto($items-per-row-md: 2, $gap-md: 20px);
                        }

                        &-25 {
                            @include gridAuto($items-per-row-md: 2, $gap-md: 25px);
                        }
                    }
                }

                &-3 {
                    &-gap {
                        &-10 {
                            @include gridAuto($items-per-row-md: 3, $gap-md: 10px);
                        }

                        &-15 {
                            @include gridAuto($items-per-row-md: 3, $gap-md: 15px);
                        }

                        &-20 {
                            @include gridAuto($items-per-row-md: 3, $gap-md: 20px);
                        }

                        &-25 {
                            @include gridAuto($items-per-row-md: 3, $gap-md: 25px);
                        }
                    }
                }

                &-4 {
                    &-gap {
                        &-10 {
                            @include gridAuto($items-per-row-md: 4, $gap-md: 10px);
                        }

                        &-15 {
                            @include gridAuto($items-per-row-md: 4, $gap-md: 15px);
                        }

                        &-20 {
                            @include gridAuto($items-per-row-md: 4, $gap-md: 20px);
                        }

                        &-25 {
                            @include gridAuto($items-per-row-md: 4, $gap-md: 25px);
                        }
                    }
                }
            }
        }

        @include desktopOnly {
            &-lg {
                &-1 {
                    &-gap {
                        &-10 {
                            @include gridAuto($items-per-row-lg: 1, $gap-lg: 10px);
                        }

                        &-15 {
                            @include gridAuto($items-per-row-lg: 1, $gap-lg: 15px);
                        }

                        &-20 {
                            @include gridAuto($items-per-row-lg: 1, $gap-lg: 20px);
                        }

                        &-25 {
                            @include gridAuto($items-per-row-lg: 1, $gap-lg: 25px);
                        }
                    }
                }

                &-2 {
                    &-gap {
                        &-10 {
                            @include gridAuto($items-per-row-lg: 2, $gap-lg: 10px);
                        }

                        &-15 {
                            @include gridAuto($items-per-row-lg: 2, $gap-lg: 15px);
                        }

                        &-20 {
                            @include gridAuto($items-per-row-lg: 2, $gap-lg: 20px);
                        }

                        &-25 {
                            @include gridAuto($items-per-row-lg: 2, $gap-lg: 25px);
                        }
                    }
                }

                &-3 {
                    &-gap {
                        &-10 {
                            @include gridAuto($items-per-row-lg: 3, $gap-lg: 10px);
                        }

                        &-15 {
                            @include gridAuto($items-per-row-lg: 3, $gap-lg: 15px);
                        }

                        &-20 {
                            @include gridAuto($items-per-row-lg: 3, $gap-lg: 20px);
                        }

                        &-25 {
                            @include gridAuto($items-per-row-lg: 3, $gap-lg: 25px);
                        }
                    }
                }

                &-4 {
                    &-gap {
                        &-10 {
                            @include gridAuto($items-per-row-lg: 4, $gap-lg: 10px);
                        }

                        &-15 {
                            @include gridAuto($items-per-row-lg: 4, $gap-lg: 15px);
                        }

                        &-20 {
                            @include gridAuto($items-per-row-lg: 4, $gap-lg: 20px);
                        }

                        &-25 {
                            @include gridAuto($items-per-row-lg: 4, $gap-lg: 25px);
                        }
                    }
                }
            }
        }

        &-center {
            justify-items: center;
        }
    }
}