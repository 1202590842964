@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_videoFigure.scss';
@import '../../assets/mixins.scss';

.therapy-area-landing-page {
  &__logo {
    @include container-rules();    
    margin: 0 auto;
    display: flex;
    max-width: 1600px;    
  }

  &__holder {
    margin: 0;
    padding: 10px 0;
    img {
      width: 125px;
    }
    
    @include desktopOnly {
      img {
        width: 175px;
      }
    }
  }

  &__header {
    .header__inner {
      flex-direction: column;
      .header__column {
        @include spans-columns(12);
      }
    }
  }

  &__content-section {
    .container.redesign {   
      background: url(../../assets/images/Background-image-phone.png);
      @include desktopOnly {     
        background: url(../../assets/images/Background-image.png);
      }

      @include tabletMax {
        padding: 0;   
      }
    }
  }

  &__content-section-inner {    
    display: flex;
    flex-direction: column;

    @include desktopOnly {      
      flex-direction: row;
    }
  }

  &__subnavigation {
    display: none;
    padding-left: 0 !important;
    .content-page-promo-subnav {
      padding-left: 0 !important;
      &-link {
        &--active {
          color: $kk-primary
        }
      }
    }

    @include desktopOnly {      
      display: block;
      @include spans-columns(2);
    }    
  }

  &__content {    
    padding-top: 40px;
    padding-bottom: 40px;
    @include spans-columns(12);
    padding: 0;

    @include desktopOnly {
      display: block;
      @include spans-columns(10);
    }
  }
}