@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_titleWithIcon.scss';
@import 'assets/mixins.scss';

.VideoCard {
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &_Video {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 170px;
    // background-color: #000000;
    overflow: hidden;

    figure {
      margin: 0;

      .video__poster {
        background-color: transparent;
        height: 100%;

        .video__poster__play {
          display: none;
        }

        .video__poster__img {
          border-radius: 30px 30px 0 0;
          margin: 0;
          width: 100%;
          height: 100%;

          @include tabletOnly {
            border-radius: none;
          }
        }
      }
    }

    video {
      width: 100%;
      height: auto;
      object-fit: inherit;
      background-color: transparent;
      border-radius: 30px 30px 0 0;
    }

    &--Full-Width {
      width: 100%;
      height: 100%;

      figure {
        margin: 0;

        .video__poster__play {
          display: none;
        }
      }

      .shadow {
        display: none;
      }
    }
  }

  &_Image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 170px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  svg {
    height: 28px;
    width: 28px;
    position: absolute;
    z-index: 100;

    &.shadow {
      filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.45));
    }
  }

  &_SubHeading {
    margin-top: 0;
  }

  &_PostDate {
    font-weight: bold;
  }

  .CardContent {
    padding: 10px;
    font-size: 12px;
    line-height: 15px;
    width: 100%;

    &--Full-Width {
      padding: 15px 0;
    }

    @include desktopOnly {
      padding: 15px;
    }
  }

  .CardHeading {
    font-size: 25px;
    margin-bottom: 5px;
  }

  .CardRichText {
    margin-bottom: 0px;
  }
}

.VideoTitleCard {
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  // background-color: $kk-primary;
  border-radius: 30px;
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
  box-shadow: $card-box-shadow;
  color: unset;
  height: 340px;
  cursor: pointer;

  &:visited {
    color: unset;
  }

  &_Video {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 170px;
    // background-color: #000000;
    overflow: hidden;

    figure {
      margin: 0;
      .video__poster {
        top: 50%;
        left: 50%;
        height: 100%;
        transform: translate(-50%, -50%);
        background-color: transparent;

        .video__poster__play {
          display: none;
        }

        .video__poster__img {
          border-radius: 30px 30px 0 0;
          margin: 0;
          width: 100%;
          height: 100%;

          @include tabletOnly {
            border-radius: none;
          }
        }
      }
    }

    video {
      height: 120%;
      object-fit: inherit;
      background-color: transparent;
      display: block;
      position: absolute;
      border-radius: 30px 30px 0 0;
      top: 50%;
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      transform: translate(-50%, -50%);
    }

    &--Full-Width {
      width: 100%;
      height: 100%;

      figure {
        margin: 0;

        .video__poster__play {
          display: none;
        }
      }

      .shadow {
        display: none;
      }
    }
  }

  &_Image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 170px;
    overflow: hidden;

    img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      transform: translate(-50%, -50%);
    }

    svg {
      display: none;
    }
  }

  svg {
    height: 28px;
    width: 28px;
    position: absolute;
    z-index: 100;

    &.shadow {
      filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.45));
    }
  }

  &_SubHeading {
    display: none;
  }

  &_PostDate {
    display: none;
  }

  .CardContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex: 1 1 0px;
    padding: 20px;
    justify-content: flex-start;
  }

  .CardHeading {
    display: none;
  }

  .CardRichText {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
  }
}

.app-layout--v1 {
  .VideoCard {
    .CardContent {
      background-color: $kk-primary;
    }
  }
  .VideoTitleCard {
    .CardContent {
      background-color: $kk-primary;
    }
  }
}
.app-layout--v2 {
  .VideoCard {
    &_Video {
      figure {
        .video__poster {
          .video__poster__img {
            border-radius: 5px 5px 0 0;
          }
        }
      }

      video {
        border-radius: 5px 5px 0 0;
      }
    }
  }

  .VideoTitleCard {
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(52, 58, 64, 0.25);

    &_Video {
      figure {
        .video__poster {
          .video__poster__img {
            border-radius: 5px 5px 0 0;
          }
        }
      }

      video {
        border-radius: 5px 5px 0 0;
      }
    }

    .CardContent {
      strong {
        font-family: inherit;
      }
    }
  }
}