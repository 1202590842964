@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.country-switcher-dropdown-wrapper {
    width: 200px;
    position: relative;
    @include screen-width-mobile {
        width: 170px;
    }
    .country-switcher-dropdown-header {
        padding: 10px 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        @include screen-width-mobile {
            padding: 10px;
        }
        span {
            margin-left: 10px;
            font-size: 16px;
            color: #EA570D;
        }
        &.open::after {
            content: "\25B2";
        }
        &::after {
            content: "\25BC";
            font-size: 10px;
            color: #EA570D;
            position: absolute;
            right: 0;
        }
    }
    .country-switcher-dropdown-body {
        position: absolute;
        padding: 10px 20px 0;
        display: none;
        background-color: #ffffff;
        width: inherit;
        @include screen-width-mobile {
            padding: 10px 10px 0;
        }
        &.open {
            display: block;
        }
        .country-switcher-dropdown-item {
            padding-bottom: 10px;
            a {
                cursor: pointer;
                display: flex;
                align-items: center;
                font-size: 16px;
                color: #333333;
                text-decoration: none;
                span {
                    margin-left: 10px;
                }
                &:hover {
                    color: #EA570D;
                }
                &.current {
                    color: #EA570D;
                }
            }
        }
        .country-switcher-dropdown-item:hover {
            cursor: pointer;
        }
    }
      
}