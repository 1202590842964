@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';






// .forgotten-password-page-component has identical styles. If the styles need to differ though, extract out
.reset-password-page-component, .forgotten-password-page-component {
  flex-grow: 1; // for those ridoculously tall screens
  background-repeat: no-repeat;
  background-size: 250px 218px;
  background-position: 100% -36px;
  background-color: #fff;
  background-image: url('../../../assets/images/login_background_new.png');
  padding-top:40px;

  .reset-password-container {
    @include container-rules();
    .page-content {
      @include grid-row();
      @include screen-width-mobile {
        flex-direction: column-reverse;
      }
      .reset-password-white-space {
        @include screen-width-mobile {
          display: none;
        }
        @include spans-columns(5);
      }
      .reset-password-form {
        @include spans-columns-small(4);
        @include offset-columns-small(0);
        @include spans-columns(6);
        @include offset-columns(1);
        @include screen-width-mobile {
          @include spans-columns(12);
          @include offset-columns(0);
        }
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        .reset-password-inner {
          background-color: #ffffff;
          border-radius: 30px 30px;
          .form-title {
            display: block;
            width: 100%;
            font-family: $hindMadurai-reg;
            font-size: 25px;
            line-height: 29px;
            text-align: left;
            color: #333333;
            margin: 0px;
            padding: 30px;
          }
          .form-p {
            display: block;
            width: 100%;
            font-family: $hindMadurai-reg;
            font-size: 16px;
            line-height: 21px;
            text-align: left;
            color: #5D6164;
            padding: 0px 30px 12px 30px;
            margin: 0px;
          }
          hr {
            height: 1px;
            background-color: #EA5504;
            margin: 0px 30px;
            padding: 0px;
            border: 0px;
          }
        }
      }
    }
  }
}

@include screen-width-desktop {
  .reset-password-page-component, .forgotten-password-page-component {
    background-size: 499px 437px;
    background-position: 102% -80px;
    padding-top:50px;
  }
}






