@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

// Veeva Player
.veeva-container {
  @include container-rules();
  max-width: 100%;

  .veeva-aspect-ratio {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    margin:20px auto;

    .veeva-player {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      iframe {
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
  }

  .veeva-aspect-ratio--dynamic-height {
    max-height: 80vh;
    height: 80vh;
    padding-top: 0;
  }
}

.veeva-page-component {
  .veeva-page {
    @include grid-row();
    .column-left {
      @include spans-columns(3);
      @include spans-columns-small(4);
    }
    .column-right {
      @include spans-columns(3);
      @include spans-columns-small(4);
    }
    @include screen-width-mobile {
      flex-direction: column;
      .column-left {
        @include spans-columns(12);
      }
      .column-right {
        @include spans-columns(12);
      }
      article {
        @include spans-columns(12);
      }
    }
  }
  article {
    @include spans-columns(6);
    @include spans-columns-small(4);
    padding-top: 0px;
    padding-bottom: 0px;
    h2 {
      margin-top: 0;
      font-size: 20px;
      line-height: 35px;
      font-family: $subtitle-font;
      font-weight: 600;
    }
  }
}
