@import '../assets/colors.scss';

// Slick Slider Theme
.slick {

	// Next-Prev Buttons
    &-prev, &-next {
		background: transparent;
		border: 0;
		cursor: pointer;
		display: block;
		height: 56px;
		outline: none;
		padding: 0;
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		width: 56px;
		z-index: 1;
		&:hover, &:focus {
			outline: none;
		}
	}
	// Preview Button
    &-prev {
		left: -15px;
	}
	// Next Button
    &-next {
		right: -15px;
	}
	// If dots exist
    &-dotted.slick-slider {
		margin-bottom: 80px;
	}
	// Dots
    &-dots {
		background-color: transparent;
		border-radius: 30px;
		bottom: -25px;
		display: block;
		left: 50%;
		line-height: 0;
		list-style: none;
		margin: 0;
		padding: 12px;
		position: absolute;
		text-align: center;
        transform: translate(-50%, 0);

		li {
			cursor: pointer;
			display: inline-block;
			height: 12px;
			margin: 0 10px;
			padding: 0;
			position: relative;
            width: 12px;

			button {
				border: 1px solid #000;
				border-radius: 50%;
				background-color: #fff;
				color: transparent;
				cursor: pointer;
				display: block;
				font-size: 0;
				height: 12px;
				line-height: 0;
				outline: none;
				padding: 5px;
                width: 12px;

				&:hover, &:focus {
                    outline: none;

					&:before {
						opacity: .5;
					}
                }

				&:before {
					-moz-osx-font-smoothing: grayscale;
					-webkit-font-smoothing: antialiased;
					background-color: white;
					border-radius: 30px;
					color: white;
					content: "\2022";
					font-size: 100%;
					height: 12px;
					left: 0;
					line-height: 12px;
					opacity: 0.25;
					position: absolute;
					text-align: center;
					top: 0;
					width: 12px;
				}
            }

			&.slick-active button:before {
				opacity: 1;
				background-color: black;
				color: black;
			}
		}
	}
}
