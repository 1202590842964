@import '../../../../assets/mixins.scss';


.docNumber {
    font-size: 12px;
    padding: 22px 45px 32px 19px;

    @include desktopOnly {
        padding: 24px 60px 40px 60px;
        position: absolute;
        bottom: 0;
    }
}