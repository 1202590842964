@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_videoFigure.scss';
@import '../../assets/mixins.scss';

.app-layout--v1 {
  .brand-article-page {
    &__logo {
      @include container-rules();    
      margin: 0 auto;
      display: flex;
      max-width: 1600px; 
      
      .logo {
        .logo__holder img {
          max-width: 125px;
          @include desktopOnly {
            max-width: 175px;
          }
        }
      }
    }

    .anchorsFix{
      overflow-x: visible;
    }
  
    &__header {
      .header__subtitle {
        font-size: 60px;
        font-family: $myriad-light;
        color: $body-text-color;
        margin: 0 0 20px;
        line-height: 100%;
        font-weight: normal;
        text-transform: uppercase;

        @include screen-width-small {
          font-size: 36px;
        }
      }
  
      p a {
        color: inherit;
      }
  
      .header__title {
        color: $kk-primary;
        font-size: 35px;
        margin: 0;
        line-height: 100%;
        font-weight: normal;
        font-family: $title-font;
  
        // @include tabletMin {
        //   font-size: 60px;
        //   line-height: 72px;
        // }
  
        // @include screen-width-small {
        //   font-size: 25px;
        // }
      }
    }
  
    &__content-section {
      .container {   
        
        @include tabletMax {
          padding: 0;   
        }
      }
    }
  
    &__content-section-inner {    
      display: flex;
      flex-direction: column;
  
      @include desktopOnly {      
        flex-direction: row;
      }
    }
  
    &__subnavigation {
      display: none;
      .content-page-promo-subnav {
        padding: 0;
      }
      @include desktopOnly {
        padding: 40px 0;
        display: block;
        @include spans-columns(2);
        padding-left: 0 !important;
      }
    }
  
    &__content {
      @include spans-columns(12);
      padding: 0;
  
      @include desktopOnly {
        display: block;
        @include spans-columns(8);
      }
  
      .content {
        &__title:first-of-type {
          margin-top: 0;
        }
  
        @include desktopOnly {
          padding: 40px 20px 0 20px;
        }
      }

      .quiz-component {
        margin-left: 20px;
        margin-right: 20px;
      }
  
      .Related_Content_Section {
        padding: 40px 0;
      }
    }
  
    &__content-titles {
      display: none;
  
      @include desktopOnly {
        padding: 40px 0;
        display: block;
        @include spans-columns(2);
        padding-right: 0 !important;
      }

      @include screen-width-mobile {
        display: block;

        .anchors {
          display: block;
        }
      }
    } 
  
    // expert video selector (element has been created in Acoustic for this but its not used anywhere yet)
    &__video-selector {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  
    &__video-selector-h3 {
      font-family: $subtitle-font;
      font-size: 25px;
      line-height: 28px;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 30px;
    }  
  }
}
.app-layout--v2 {
  .brand-article-page {
    &__logo {
      @include container-rules();    
      margin: 0 auto;
      display: flex;
      max-width: 1600px;    

      .logo {
        .logo__holder img {
          max-width: 125px;
          @include desktopOnly {
            max-width: 175px;
          }
        }
      }
    }

    .anchorsFix{
      overflow-x: visible;
    }
  
    &__header {
      .header__subtitle {
        font-size: 60px;
        font-family: $myriad-light;
        color: $body-text-color;
        margin: 0 0 20px;
        line-height: 100%;
        font-weight: normal;
        text-transform: uppercase;

        @include screen-width-small {
          font-size: 36px;
        }
      }
  
      p a {
        color: inherit;
      }
  
      .header__title {
        color: $kk-primary;
        font-size: 35px;
        margin: 0;
        line-height: 100%;
        font-weight: normal;
        font-family: $title-font-v2;
  
        // @include tabletMin {
        //   font-size: 60px;
        //   line-height: 72px;
        // }
  
        // @include screen-width-small {
        //   font-size: 37px;
        // }
      }
    }
  
    &__content-section {
      .container {   
        
        @include tabletMax {
          padding: 0;   
        }
      }
    }
  
    &__content-section-inner {    
      display: flex;
      flex-direction: column;
  
      @include desktopOnly {      
        flex-direction: row;
      }
    }
  
    &__subnavigation {
      display: none;
      .content-page-promo-subnav {
        padding: 0;
      }
      @include desktopOnly {
        padding: 40px 0;
        display: block;
        @include spans-columns(2);
        padding-left: 0 !important;
      }
    }
  
    &__content {
      @include spans-columns(12);
      padding: 0;
  
      @include desktopOnly {
        display: block;
        @include spans-columns(8);
      }
  
      .content {
        &__title:first-of-type {
          margin-top: 0;
        }
  
        @include desktopOnly {
          padding: 40px 20px 0 20px;
        }
      }

      .quiz-component {
        margin-left: 20px;
        margin-right: 20px;
      }
  
      .Related_Content_Section {
        padding: 40px 0;
      }
    }
  
    &__content-titles {
      display: none;
  
      @include desktopOnly {
        padding: 40px 0;
        display: block;
        @include spans-columns(2);
        padding-right: 0 !important;
      }

      @include screen-width-mobile {
        display: block;
      }
    } 
  
    // expert video selector (element has been created in Acoustic for this but its not used anywhere yet)
    &__video-selector {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  
    &__video-selector-h3 {
      font-family: $subtitle-font;
      font-size: 25px;
      line-height: 28px;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 30px;
    }  
  }
}
