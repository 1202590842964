@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_titleWithIcon.scss';
@import 'assets/mixins.scss';
.app-layout--v1 {
    .ContentCard {
        padding: 30px;
    
        .CardImage {
            width: 107px;
    
            @include desktopOnly {
                width: 300px;
            }
        }
    
        &--fluid {
            padding: 0px !important;
            justify-content: space-between;
            overflow: hidden;
    
            .CardContent {
                padding: 20px;
    
                @include desktopOnly {
                    padding: 30px;
                }
            }
    
            .CardHeading {
                font-size: 14px;
    
                @include desktopOnly {
                    font-size: 22px;
                }
            }
    
            .CardRichText {
                display: none;
    
                @include desktopOnly {
                    display: block;
                }
            }
    
            .CardLink {
                justify-content: flex-start;
    
                svg {
                    margin-left: 20px;
                }
    
                span {
                    text-transform: capitalize;
                    font-size: 12px;
    
                    @include desktopOnly {
                        font-size: 16px;
                    }
                }
            }
        }
    
        &--with-image {
            padding: 0px !important;
            justify-content: space-between;
            overflow: hidden;
    
            .CardContent {
                padding: 20px;
    
                @include desktopOnly {
                    padding: 30px;
                }
            }
        }
    
        &--center {
            margin: 0px auto;
        }
    }
}
.app-layout--v2 {
    .ContentCard {
        padding: 30px;
        height: 100%;
        .CardImage {
            width: 107px;
    
            @include desktopOnly {
                width: 300px;
            }
        }
    
        &--fluid {
            padding: 0px !important;
            justify-content: space-between;
            overflow: hidden;
    
            .CardContent {
                padding: 20px;
    
                @include desktopOnly {
                    padding: 30px;
                }
            }
    
            .CardHeading {
                font-size: 14px;
    
                @include desktopOnly {
                    font-size: 22px;
                }
            }
    
            .CardRichText {
                display: none;
    
                @include desktopOnly {
                    display: block;
                }
            }
    
            .CardLink {
                justify-content: flex-start;
    
                svg {
                    margin-left: 20px;
                }
    
                span {
                    text-transform: capitalize;
                    font-size: 12px;
    
                    @include desktopOnly {
                        font-size: 16px;
                    }
                }
            }
        }
    
        &--with-image {
            padding: 0px !important;
            justify-content: space-between;
            overflow: hidden;
    
            .CardContent {
                padding: 20px;
    
                @include desktopOnly {
                    padding: 30px;
                }
            }
        }
    
        &--center {
            margin: 0px auto;
        }
    }
}