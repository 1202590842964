@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

// the homepage has a background image that gets smaller depending on the screen width
// and the title and body text need to not overlap it. However I can't use an inline
// image as it needs to go under the carousel below and also to sit at the top right of the screen
// even at large screen sizes. To do this we define a percentage width that the image takes up
// and the remainder width is for the title and body. The percentage is defined by the maximum size of the image
// which is at the xl breakpoint
$home-page-bg-width: 35%;
.app-layout--v1 {
  .home-page {
    .section-title {
      font-size: 25px;
      font-family: $section-title-font;
    }
    .home-page-header {
      background: url('./home-bg.png') top right no-repeat;
      background-size: $home-page-bg-width;
      @include screen-width-xl {
        background-size: auto;
      }
      // lose the background image below container min width
      // it's impossible otherwise to prevent it overflowing the title
      @media (min-width: 0) and (max-width: #{$container-min-width - 1px}) {
        background: none;
      }
    }
    .home-page-carousel {
      .carousel-card-slide {
        display: flex;
        align-items: center;
        padding: 0 20px;
        height: 370px;
        .text-card {
          height: 80%; // to fix IE11 layout
          .learn-more {
            justify-content: space-between;
          }
        }
      }
    }
    .home-page-secondary {
      background-color: $kk-secondary;
      padding: 40px 0;
      .promo-banner-component-background {
        background-color: white;
      }
    }
    .references-section {
      padding-top: 40px;
      padding-bottom: 10px;
    }
  }
}
.app-layout--v2 {
  .home-page {
    // .background-container {
    //   .home-page-secondary {
    //     .container {
    //       .homepage-collapsible-section {
    //         background-color: #faf6f4;
    //         .section-header {
    //           background-color: #faf6f4;
    //         }
    //       }
    //     }
    //   }
    // }
  
    .section-title {
      font-family: $section-title-font;
      font-size: 38px;
      font-weight: 600;
      line-height: 52px;
      letter-spacing: 0px;
      margin-bottom: 20px;
      color: #333333;
    }
    .home-page-header {
      .container-hp {
        margin-bottom: 28px;
        @media (min-width: 768px) {
          display: flex;
          justify-content: space-between;
        }
      }
      .hero-image-container{
        display: none;
        @media (min-width: 768px) {
          display: block;
          padding-left: 21px;
          order: 1;
        }
  
        .hero-image {
          width: 100%;
          @media (max-width: 767px) {
            margin-top: -100px;
          }
          @media (max-width: 700px) {
            margin-top: -100px;
          }
          @media (max-width: 600px) {
            margin-top: -50px;
          }
          @media (min-width: 950px) {
            margin-top: -50px;
          }
        }
  
        .hero-image--headerImage{
          margin-top: 60px;
        }
      }
      
    }
  
    .carousel-section {
  
      .home-page-carousel {
        .carousel-card-slide {
          display: flex;
          align-items: center;
          padding: 0 20px;
          height: 370px;
          @include screen-width-small {
            padding: 0 4px;
          }
          .text-card {
            height: 80%; // to fix IE11 layout
            // .learn-more {
            //   justify-content: space-between;
            // }
          }
        }
      }
  
    }
    
    
    .home-page-secondary {
  
      .promo-banner-component-background {
        background-color: white;
        margin-top: 30px;
      }
  
      .container {
        .colum_section {
          @media (min-width: 920px) {
            flex-direction: row;
          }
  
          .Card {
            margin-bottom: 40px;
            @media (max-width: 923px) {
              background-color: transparent;
            }
            @media (max-width: 923px) {
              margin-bottom: 20px;
            }
            .CardContent {
              .CardLink {
                justify-content: start;
                svg {
                  margin-left: 9px;
                  height: 13px;
                  margin-top: 2px;
                }
              }
            }
          }
          .TitleCard {
            min-height: 266px;
            @media (max-width: 921px) {
              min-height: 190px;
            }
            @media (max-width: 500px) {
              min-height: 210px;
            }
            @media (max-width: 350px) {
              min-height: 270px;
            }
            .CardImage {
              height: 160px;
              flex: none;
              @media (max-width: 921px) {
                height: 106px;
              }
            }
            .CardContent {
              .CardHeading {
                margin-top: 0;
                @media (max-width: 921px) {
                  font-size: 16px;
                  line-height: 30px;
                  p {
                    font-size: 16px;
                    line-height: 30px;
                  }
                }
              }
  
              .CardLink {
                margin-bottom: 0;
                margin-top: 21px;
                @media (max-width: 921px) {
                  margin-top: 10px;
                }
              }
            }
          }
        }
  
        .colum_section {
          .twoColumn_section-first {
            @media (max-width: 921px) {
              margin-left: 0;
              margin-right: 0;
              width: 100%;
            }
          }
  
          .twoColumn_section-second {
            @media (max-width: 921px) {
              margin-left: 0;
              width: 100%;
              margin-right: 0;
            }
          }
          .CardLink {
            @media (max-width: 920px) {
              font-size: 14px;
              font-weight: 600;
              line-height: 19px;
              letter-spacing: 1px;
            }
          }
        }
  
        .colum_section--two-column {
          .CardLink {
            @media (min-width: 920px) {
              margin-bottom: 51px;
            }
          }
        }
  
        .colum_section--three-column {
          .threeColumn_section-second {
            @media (min-width: 920px) {
              margin-left: 32px;
              margin-right: 32px;
            }
          }
        }
      }
    }
    .references-section {
      padding-top: 30px;
      padding-bottom: 10px;
    }
  }
}

.home-page__therapy-quick-access {
  background-color: #EA5504;
  position: fixed;
  bottom: 0;
  width: 100vw;
  @media (min-width: 768px) {
    display: none;
  }

  .quick-access-contents {
    padding: 0 20px 0 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .quick-access-text {
      margin: 9px 0;
      margin-right: 10px;
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      color: #FFFFFF;
    }
  }
}

.aboutUs-section{
  margin-top: 50px;
  .aboutUs-section_title{
    font-family: $section-title-font;
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 26px;
  }

  .RichText {
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.5px;
  }
}