.iframe {
    &-preview {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #5d6164;
        border-radius: 32px;
        max-width:331px;
        height:190px;
        margin: 0 auto;

        @media (min-width: 992px) {
            max-width: 784px;
            height:479px;
        }
        .iframe-preview--success {
            text-align: center;
            padding: 20px;
            flex: 1;
            height: 100%;
            
        }
        .iframe-image-sucess {
            position: relative;
            display: flex;
            align-items: center;
            overflow: hidden;
            height: 100%;
            
            &:after {
                content: '';
                position: absolute;
                background: rgba(0, 0, 0, 0.1);
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
        }
       
    }

    &-grey {
        background-color: #5d6164;
        color: #fff;
        padding: 5px 20px;
    }

    &-button {
        color: #fff;
        padding: 14px 10px;
        border-radius: 60px;
        background-color: #EA570D;
        border: 1px solid #EA570D;
        margin: 25px auto;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
       font-size: 16px;
    }
    &-preview--error{
        text-align: center;
    }
   
}

.edetailer {
    flex-flow: column;
    align-items: center;

    &-modal {
        border-radius: unset;
        width:90%;
        display: block;
        margin: auto;
        .veeva-page-component {
            width: 100%;
        }

        .veeva-container {
            padding: 0;
        }

        .veeva-aspect-ratio {
            margin: 0 ;
        }
    }
    .overlay{
        background-color: rgba(51, 51, 51, 0.8);
    }
}
#third_party-title{
    // .title-section-title{
    //     @media  only screen and (min-width:992px) {
    //         padding-bottom:40px;
    //     }
    // }
    .main-title-section.has-category .title-section-inner .title{
        @media only screen and (min-width:1024px){
            width:100%;
        }
       
    }
    h1{
        color:#333;
    }
}
#third_party{
    .container_inner{
        justify-content: end;
    }
    .content_wrapper--columns-3-left{
        flex:none;
        max-width: 25%;
        word-break: break-word;
    }
    .content_wrapper--columns-3-center{
        flex:none;
        @media only screen and (min-width:1023px){
            width:56%;
        }
    }
    .container-references{
        margin-top:25px;
        @media only screen and (min-width:992px){
            margin-top:40px;
        }
    }
    .taskbar{
        background: #F9F4F2;
        padding-bottom:13%;
        position: relative;
        .close-button-container{
            position:absolute;
            right:0;
            top:0;
            margin:2%;
        }
        @media only screen and (min-width:992px){
            padding-bottom:6%;
        }
    
    }
    .dates{
        @media only screen and (min-width:992px){
            display: flex;
        }
    }
    .date{
        color: #EA5504;
    }
    .clock_space{
        padding-left:23px;
    }
    .timeToRead{
        color:#EA5504;
        @media only screen and (min-width:992px){
            padding-left:5px;
        }
    }
    .clock{
        width:15px;
        height:15px;
        margin-top: 17px;
    }
}