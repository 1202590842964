@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.container-anonymous-tooltip {
  border: 1px solid #c4c4c4;
  width: 100%;
  max-width: 100%;
  background-color: white;
  position: fixed;
  bottom: 15vh;
  z-index: 5;
  @media (max-width: 1024px) {
    bottom: 0;
  }
  .anonymous-tooltip {
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .tooltip-text {
      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: left;
      }
    }

    .tooltip-button {
      button {
        text-transform: uppercase;
        border-radius: 30px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 1px;
        text-align: center;
        color: white;
        background-color: $kk-primary;
        border: none;
        padding: 12px 19px;
      }

			@media (max-width: 1024px) {
				margin-bottom: 40px;
			}
    }
  }
}
