@import '../../../assets/mixins.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/variables.scss';

// Sizing
$header-height: 80px;
$max-content-size: 1440px;
$logo-width-min: 162px;
$logo-width-max: 206px;

// Colors
$header-bg: #ea5504;
$header-bg-global: #FEF6F2;


.Header {
  display: block;
  background-color: $header-bg;
  position: relative;

  // Header Content
  &_Content {
    // IE 11 does not support css grid so by default use flex and if browser supports css grid use css grid
    display: flex;
    height: $header-height;
    max-width: $max-content-size;
    margin: 0 auto;
    padding: 0 1em;
   
    // If browser supports css grid use
    @supports (display: grid) {
      display: grid;
      grid-template-columns: minmax(auto, $logo-width-min) auto 30px;
      grid-template-areas: 'logo userAuth mobileToggle';
      column-gap: 0.5em;
      &.events-header {
        display: flex;
      }
      @include desktopOnly {
        grid-template-columns: $logo-width-max 2fr 1fr auto;
        grid-template-areas: 'logo mainNav searchBox userAuth';
      }
    }

  }

  &--open {
    .MobileNavigation {
      display: block;
      @include desktopOnly {
        display: none;
      }
    }
  }
}

.Header--redesign.Header--bg{ 
  background-color: #FEF6F2;
}

.Header {
  display: block;
  background-color: $header-bg;
  position: relative;

  // Header Content
  &_Content {
    // IE 11 does not support css grid so by default use flex and if browser supports css grid use css grid
    display: flex;
    height: $header-height;
    max-width: $max-content-size;
    margin: 0 auto;
    padding: 0 1em;
    
    // If browser supports css grid use
    @supports (display: grid) {
      display: grid;
      grid-template-columns: minmax(auto, $logo-width-min) auto 30px;
      grid-template-areas: 'logo userAuth mobileToggle';
      column-gap: 0.5em;
      &.events-header {
        display: flex;
      }
      @include desktopOnly {
        grid-template-columns: $logo-width-max 2fr 1fr auto;
        grid-template-areas: 'logo mainNav searchBox userAuth';
      }
    }

  }
}

.Header--redesign {
  display: block;
  position: relative;
  background-color: #fff;

  @media (max-width: 767px) {
    background-color: #FEF6F2;
  }
  // Header Content
  .Header_Content {
    // IE 11 does not support css grid so by default use flex and if browser supports css grid use css grid
    display: flex;
    height: $header-height;
    max-width: $max-content-size;
    margin: 0 auto;
    padding: 0 1em;
    
    // If browser supports css grid use
    @supports (display: grid) {
      grid-template-columns: minmax(auto, $logo-width-min) auto 30px;
      grid-template-areas: 'logo userAuth mobileToggle';
      column-gap: 0.5em;
      .Header.events-header {
        display: flex;
      }
      @include desktopOnly {
        grid-template-columns: $logo-width-max 2fr 1fr auto;
        grid-template-areas: 'logo mainNav searchBox userAuth';
      }
    }
  }
}




.global_padding{
  max-width: 100%;
  margin: 0;
  @include container-rules();
}

.DesktopSubNav1 {
  background: grey;
  height: 50px;
  width: 100%;
  position: relative;
}

.DesktopSubNav2 {
  background: darkgrey;
  height: 50px;
  width: 100%;
  position: relative;
}

.app-layout--v1 {
  .Header {
    &--open {
      .MobileNavigation {
        display: block;
        @include desktopOnly {
          display: none;
        }
      }
    }
  }
  .Header {
    .MobileNavigation {
      .Main {
        height: calc(100% - 140px);
      }
    }
  }
  .Header {
    .MobileNavigation--contact-us {
      .Main {
        height: calc(100% - 200px);
      }
    }
  }
  .Header--search {
    .MobileNavigation {
      .Main {
        height: calc(100% - 200px);
      }
    }
  }
  .Header--search {
    .MobileNavigation--contact-us {
      .Main {
        height: calc(100% - 260px);
      }
    }
  }
}

.app-layout--v2 {
  .Header {
    &--open {
      .MobileNavigation {
        display: block;
        @include desktopOnly {
          display: none;
        }
      }
    }
  }
  .Header {
    .MobileNavigation {
      .Main {
        height: calc(100% - 140px);
      }
    }
  }
  .Header {
    .MobileNavigation--contact-us {
      .Main {
        height: calc(100% - 200px);
      }
    }
  }
  .Header--search {
    .MobileNavigation {
      .Main {
        height: calc(100% - 200px);
      }
    }
  }
  .Header--search {
    .MobileNavigation--contact-us {
      .Main {
        height: calc(100% - 260px);
      }
    }
  }
}
.Header--bg{ 
  background-color: $header-bg;
}
.app-layout--v1, .app-layout--v2 {
  
  .Header--open {
    .toastbar {
      display: none;
    }
  }
  
  .global_header{
    background-color: $header-bg-global;
  }
}