@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_titleWithIcon.scss';
@import 'assets/mixins.scss';

$standard-item-margin-bottom: 16px;
$card-padding: 30px;

.app-layout--v1 {
  .text-card {
    display: flex;
    color: $body-text-color;
    text-decoration: none;
    width: 100%;
    flex-direction: column;
    text-align: left;
    background-color: $kk-secondary;
    border: none;
    border-radius: 20px;
    box-shadow: $card-box-shadow;
    padding: 0;
    &:hover {
      .learn-more, .meta-tags {
        text-decoration: underline;
      }
    }
    .category {
      @include title-with-icon;
      font-size: 12px;
      text-transform: uppercase;
      margin: 0px 0px $standard-item-margin-bottom 0px;
    }
    .text-card-title {
      font-size: 25px;
      font-family: $subtitle-font;
      margin: 0px 0px $standard-item-margin-bottom 0px;
      @include screen-width-large {
        font-size: 20px;
      }
      @include screen-width-small {
        &:lang(de) {
          font-size: 20px;
        }
      }
    }
    .text-card-logo {
      display: block;
      max-width: 100%;
      margin: 0px 0px $standard-item-margin-bottom 0px;
  
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        max-height: 120px;
      }
    }
    .meta-tags {
      font-family: $call-to-action-font;
      margin-top: auto;
      margin-bottom: 0;
      color: $kk-primary;
    }
    .learn-more {
      margin-top: auto;
      color: $kk-primary;
      font-family: $call-to-action-font;
      display: flex;
      width: 100%;
      text-decoration: none;
      .learn-more-text {
        margin-bottom: 0px;
        text-transform: uppercase;
      }
      .svg-container {
        margin-left: 20px;
  
        svg {
          display: block;
          height: 20px;
          width: 10px;
        }
      }
    }
    p {
      margin: 0px 0px 24px 0px;
      word-break: break-word; // needed.for.when.haze.makes.text.content.that.looks.like.this
      line-height: 20px;
    }
    .card-section {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .image-section {
        img {
          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;
          max-width: 300px;
          object-fit: cover;
          height: 100%;
        }
      }
      .text-section {
        display: flex;
        flex-direction: column;
        padding: $card-padding;
        width: 100%;
        // the space between .top-aligned and .bottom-aligned is generated automatically with min-margin being the bottom margin from the
        // last item in .top-aligned and the calculated margin being based on the height of the tallest card in the row.
        .bottom-section {
          width: 100%;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          .learn-more {
            .svg-container {
              padding-left: 45px;
            }
          }
        }
      }
    }
  
    .text-card-link {
      text-decoration: none;
      text-transform: uppercase;
      font-weight: bold;
    }
  
    @include screen-width-small {
      .card-section {
        .image-section {
          img {
            max-width: 100px;
          }
        }
        .text-section {
          .bottom-section {
            .text-element-component {
              display: none;
            }
            .learn-more {
              .svg-container {
                padding-left: 11px;
              }
            }
          }
        }
      }
    }
  }
  
  .text-card--hp-carousel {
    box-shadow: 0px 2px 4px rgba(52, 58, 64, 0.25);
    background-color: #FFFFFF;
    border-radius: 5px;
    &:hover {
      box-shadow: -5px 5px 0px 0px $kk-primary, 0px 2px 4px rgba(52, 58, 64, 0.25);
    }
  }
}

.app-layout--v2 {
  .text-card {
    display: flex;
    color: $body-text-color;
    text-decoration: none;
    width: 100%;
    flex-direction: column;
    text-align: left;
    // background-color: $kk-secondary;
    border: none;
    box-shadow: 0px 2px 4px rgba(52, 58, 64, 0.25);
    padding: 0;
    border-radius: 5px;
    &:hover {
      box-shadow: -5px 5px 0px 0px $kk-primary, 0px 2px 4px rgba(52, 58, 64, 0.25);
    }
    &:hover {
      .learn-more, .meta-tags {
        text-decoration: underline;
      }
    }
    .category {
      @include title-with-icon;
      font-size: 12px;
      text-transform: uppercase;
      margin: 0px 0px $standard-item-margin-bottom 0px;

      svg {
        color: #333;
      }

      .categoryText {
        color: #333!important;
      }
    }
    .text-card-title {
      font-size: 25px;
      font-family: $subtitle-font;
      margin: 0px 0px $standard-item-margin-bottom 0px;
      @include screen-width-large {
        font-size: 20px;
      }
      @include screen-width-small {
        &:lang(de) {
          font-size: 20px;
        }
      }
    }
    .text-card-logo {
      display: block;
      max-width: 100%;
      margin: 0px 0px $standard-item-margin-bottom 0px;
  
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        max-height: 120px;
      }
    }
    .meta-tags {
      font-family: $call-to-action-font;
      margin-top: auto;
      margin-bottom: 0;
      color: $kk-primary;
    }
    .learn-more {
      margin-top: auto;
      color: $kk-primary;
      font-family: $call-to-action-font;
      display: flex;
      width: 100%;
      text-decoration: none;
      .learn-more-text {
        margin-bottom: 0px;
        text-transform: uppercase;
      }
      .svg-container {
        margin-left: 20px;
  
        svg {
          display: block;
          height: 20px;
          width: 10px;
        }
      }
    }
    p {
      margin: 0px 0px 24px 0px;
      word-break: break-word; // needed.for.when.haze.makes.text.content.that.looks.like.this
      line-height: 20px;
    }
    .card-section {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .image-section {
          margin: auto;
          width: 300px;
          @include screen-width-small{
            margin: 0;
            width: auto;
          }
        img {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          max-width: 300px;
          object-fit: cover;
          max-height: 165px;
            @include screen-width-small {
              height: 100%;
              max-height: none;
            }
        }
      }
      .text-section {
        display: flex;
        flex-direction: column;
        padding: $card-padding;
        width: 100%;
        // the space between .top-aligned and .bottom-aligned is generated automatically with min-margin being the bottom margin from the
        // last item in .top-aligned and the calculated margin being based on the height of the tallest card in the row.
        .bottom-section {
          width: 100%;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          .learn-more {
            .svg-container {
              padding-left: 45px;
            }
          }
        }
      }
    }
  
    .text-card-link {
      text-decoration: none;
      text-transform: uppercase;
      font-weight: bold;
    }
  
    @include screen-width-small {
      .card-section {
        .image-section {
          img {
            max-width: 100px;
          }
        }
        .text-section {
          .bottom-section {
            .text-element-component {
              display: none;
            }
            .learn-more {
              .svg-container {
                padding-left: 11px;
              }
            }
          }
        }
      }
    }
  }
  
  .text-card--hp-carousel {
    box-shadow: 0px 2px 4px rgba(52, 58, 64, 0.25);
    background-color: #FFFFFF;
    border-radius: 5px;
    &:hover {
      box-shadow: -5px 5px 0px 0px $kk-primary, 0px 2px 4px rgba(52, 58, 64, 0.25);
    }
  }
}