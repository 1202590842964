@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
.page_bookmark {
    margin-left: auto;
    display: flex;
    align-items: center;
}
.bookmark_text {
    font-family: "Myriad Pro Regular";
    color: #6D7E8D;
    font-size: 12px;
    font-weight: 400;
    vertical-align: super;
    padding-left: 10px;

}
svg {
    margin-top: 1px;
}