@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.cookie-banner {
  padding: 30px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.15);
  z-index: 1005;

  &__text {
    line-height: 20px;
    margin: 0;
    & a {
      color: $body-text-color;
      text-decoration: underline;
    }
  }

  &__controls {
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    & button {
      text-transform: uppercase;
      min-width: 160px;
    }
    &-primary {
      display: flex;
      margin-bottom: 20px;
      & button:first-child {
        margin-right:10px;
      }
    }
    &-secondary {
      display: flex;
    }
  }
}

@include screen-width-desktop {
  .cookie-banner {
    &__controls {
      flex-direction: row;
      justify-content: space-between;

      &-primary {
        margin-bottom: 0;
        & button:first-child {
          margin-right:20px;
        }
      }
    }
  }
}
