@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.carousel {
  .image-carousel {
    margin: 0;
    width: 100%;
    .carousel-nav {
      @include spans-columns(2);
    }
    .slider-container {
      padding: 0;
      margin: 0;
      @include spans-columns(8);
      @include spans-columns-small(4);
      .carousel-image-slide {
        img {
          width: 100%;
          overflow: hidden;
          box-shadow: $card-box-shadow;
          border-radius: 30px;
        }
      }
    }
  }
}
