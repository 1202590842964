@import 'assets/kanso/styles/global.scss';

/* Hide the browser's default checkbox */
.checkbox-tiles {
  input {
    display: none;
  }
}

[dir="rtl"] .checkbox-tiles {
  input {
    left: 26px;
    right: auto;
  }
}

.checkbox-tiles {
  // background: $off-white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-bottom: 25px;
  position: relative;
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.2);
}

.checkbox-tiles_disabled {
  // color: $grey;

  .checkmark {
    // border: 2px solid $grey;
  }
}

.checkbox-tiles_error {
  // outline: 2px solid $input-error;

  .checkmark {
    // border: 2px solid $input-error;
  }
}

.checkbox-tiles-focused {
  outline: 2px solid #c51d55;
}

.checkbox-tiles_input {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: center;
}

.checkbox-tiles_label-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // font-family: $base-font-family;
  word-break: break-all;
  text-align: center;
  width: 100%;
}

.checkbox-tiles_icon {
  display: block;
}

// .checkbox-tiles_icon--image {
  // width: 50%;
// }

.checkbox-tiles_label {
  margin-top: 0;
  margin-top: 19px;
  margin-bottom: 8px;
}

.checkbox-label__title {
  margin-top: 0;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 16px;
}

.checkbox-label_description {
  margin: 0;
  font-weight: 300;
  font-size: 14px;
  display: block;
}

.checkbox-checkmark {
  margin-left: auto;
  margin-bottom: 21px;
}

[dir="rtl"] .checkbox-checkmark {
  margin-left: 0;
  margin-right: auto;
}

/* Create a custom checkbox */
.checkmark {
  height: 22px;
  width: 22px;
  border: 2px solid #c51d55;
  border-radius: 4px;
  // background-color: $off-white;
}

/* Show the checkmark when checked */
.checkbox-tiles-focused{
  .checkmark {
    background-color: #c51d55;

    .checkmark-tick:after {
      display: block;
    }
  }
}

/* Style the checkmark/indicator */
.checkmark:after {
  content: "";
  width: 20px;
  height: 20px;
  background: #c51d55;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-tick:after {
  content: "";
  position: absolute;
  display: none;
}

/* Style the checkmark/indicator */
.checkmark-tick:after {
  position: absolute;
  top: 32px;
  right: 29px;
  height: 5px;
  width: 14px;
  content: "";
  transform: rotate(-45deg);
  transform-origin: revert;
  // border-bottom: 2px solid $off-white;
  // border-left: 2px solid $off-white;
}
[dir="rtl"] .checkbox-tiles {
  .checkmark-tick:after {
    left: 28px;
    right: auto;
  }
}
