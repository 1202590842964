@import '../../../../assets/kanso/styles/global.scss';
@import 'style/variables.scss';

.quiz {
  width: 100%;
  height: 100vh;
  display: inline-grid;
  grid-template-rows: auto 1fr 225px;

  a {
    --primary-btn-border-radius: 30px;
    --primary-btn-box-shadow: none;
    --primary-btn-disabled-text-color: #a19f9d;
    padding: 12px 20px;
    text-transform: uppercase;

    span {
      line-height: 16px;
    }
  }

  @include media(medium, large, extraLarge, extraExtraLarge) {
    grid-template-rows: auto 1fr 100px;
  }

  @include media(small, extraSmall) {
    height: auto;
  }
}

.quiz-embedded {
  width: 100%;
  display: inline-grid;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #979797;
  gap: 20px;
  margin-top: 20px;

  
  &.quiz-submitted {
    grid-template-rows: auto;
  }

  a {
    --primary-btn-border-radius: 30px;
    --primary-btn-box-shadow: none;
    --primary-btn-disabled-text-color: #a19f9d;
    padding: 12px 20px;
    text-transform: uppercase;

    span {
      line-height: 16px;
    }
  }

  @include media(medium, large, extraLarge, extraExtraLarge) {
    grid-template-rows: auto 1fr 100px;
  }

  @include media(small, extraSmall) {
    height: auto;
  }

  .quiz-header {
    min-height: 50px;
    display: flex;
    .nav {
      display: none;
    }

    .quiz-progress {
      margin-top: 8px;
    }
  }

  .quiz-content {
    .quiz-question-wrapper {
      place-content: start;

      .quiz-question {
        display: inline-grid;
        grid-template-columns: 1fr;
        padding: 0;
        gap: 20px;

        .quiz-question-text {
          margin: 0;

          h3 {
            margin: 0;
          }
        }

        .quiz-reference {
          font-weight: 600;
        }
      }

      .quiz-question-answers {
        padding: 0;
        overflow-y: hidden;
        height: auto;

        .form-radio__button {
          background-color: white;
        }
      }

      .kanso-atom-radio,
      .kanso-atom-checkbox {
        grid-template-columns: 1fr 1fr;  
        gap: 20px;

        @include media(small, extraSmall) {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  .quiz-footer {
    position: static;

    .quiz-footer-content {
      padding: 20px 0;
      grid-row-gap: 0;
      row-gap: 0;
      grid-column-gap: 24px;
      column-gap: 24px;
      grid-template-columns: repeat(var(--qt-footer-column-count, 1), 1fr);

      a {
        place-self: start;
        width: max-content;

        &:nth-child(2) {
          place-self: end;
        }
      }
    }
  }
}

.quiz-header {
  background-color: var(--quiz-header-bgcolor, #fff);
  text-align: center;

  .nav {
    // width: calc(100% - 48px);
    width: 100%;
    margin: auto;
    padding: 25px 24px;
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    @include media(medium, large) {
      // width: calc(100% - 144px);
      padding: 25px 72px;
    }

    @include media(extraLarge) {
      // width: calc(100% - 192px);
      padding: 25px 96px;
    }

    @include media(extraExtraLarge) {
      // width: 100%;
      max-width: 1312px;
      padding: 25px 0;
    }
  }

  .nav-logo {
    width: 150px;
  }

  .nav-exit {
    text-align: right;
    a {
      // display: inline-grid;
      // color: #c51d55;
      gap: 3px;
      svg {
        width: 13px;
        height: 13px;
        margin-top: 3px;
        // fill: #c51d55;
      }
      &:active {
        background-color: transparent;
      }
    }
  }

  [dir='rtl'] .nav-logo {
    margin-right: 0;
    margin-left: 25px;
  }

  [dir='rtl'] .nav-exit {
    svg {
      margin-left: 0;
      margin-right: 10px;
    }
  }

    .quiz-progress {
    height: 8px;
    background-color: #C3C4CA;
    position: relative;
    width: 100%;

    .quiz-progress--completed {
      width: var(--qt-header-progress-width, 0%);
      height: 8px;
      background-color: $kk-primary;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }

    .quiz-progress-indications {
      display: flex;
      position: absolute;
      width: 100%;
      // height: 32px;
      background-color: transparent;
      justify-content: space-around;
      left: 0;
      top: 50%;
      z-index: 5;
      transform: translateY(-25%);

      .progress-step {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 60px;
      }

      svg {
        height: 100%;
      }
    }
  }
}

.quiz-content {
  background-color: var(--quiz-content-bgcolor, #fff);
  @include media(medium, large, extraLarge, extraExtraLarge) {
    // overflow-y: scroll;
  }
}

.quiz-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  &-content {
    width: 100%;
    &-text {
      padding: 20px 10px;   
      background-color: #F9F4F2;
      font-size: 24px;
      font-weight: 600;
      border-radius: 8px;
      line-height: normal;
  
      p {
        margin: 0;
      }
    }

    a {
      text-transform: none;
      --tertiary-btn-text-decoration: underline;
      --tertiary-btn-text-color: #333;
      --tertiary-btn-font-weight: 400;
      --tertiary-btn-font-size: 20px;
      margin: 20px 10px;
    }
  }
  
}

.quiz-question-wrapper {
  display: grid;
  place-content: center;
  height: 100%;
}

.quiz-question {
  padding: 32px 24px 24px;

  @include media(medium, large, extraLarge, extraExtraLarge) {
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 48px;
  }

  @include media(medium, large) {
    padding: 48px 72px;
  }

  @include media(extraLarge, extraExtraLarge) {
    padding: 48px 96px;
    max-width: 1312px;
  }
}

.quiz-question-error {
  width: 100%;
  font-size: 24px;
  background-color: #b00020;
  color: #fff;

  p {
    margin: 0;
    padding: 24px;

    @include media(medium, large) {
      padding: 24px 72px;
    }

    @include media(extraLarge, extraExtraLarge) {
      padding: 24px 96px;
      max-width: 1312px;
    }
  }
}

.quiz-question-counter {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0;
  margin: 0 0 24px;
}

.quiz-question-caption {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0;
  margin: 0 0 24px;
}

.quiz-question-text {
  width: 100%;
  margin: 0 0 60px;

  @include media(medium, large, extraLarge, extraExtraLarge) {
    margin: 0 0 24px;
  }

  @include media(small, extraSmall) {
    --et-headline-font-size: 24px;
  }

  .quiz-question-counter {
    font-size: inherit;
    color: inherit;
    line-height: 20px;
  }

  h1, h2, h3, h4, h5, h6, p {
    --et-headline-font-size: 24px;
    --et-headline-line-height: 26px;
    --et-headline-font-weight: 600;
    font-size: 24px;
    font-weight: 600;
    color: $kk-primary;
    line-height: 26px;
  }

  p, h3 {
    text-align: left;
  }

  a {
    margin-top: 5px;
  }

  p {
    margin-bottom: 24px;
  }
}

.quiz-question-answers {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 10px;

  @include media(small, extraSmall) {
    overflow-y: hidden;
  }

  label .form-select .form-select__control {
    background-color: white !important;
    border: 2px solid white;
    width: 50%;
  }
}

.kanso-atom-dropdown {
  display: inline-grid;
  grid-template-columns: 1fr;
  place-content: center;

  @include media(medium, large, extraLarge, extraExtraLarge) {
    width: 80%;
    padding: 0 10%;
  }
}

.kanso-atom-radio,
.kanso-atom-checkbox {
  display: inline-grid;
  grid-template-columns: 1fr;
  place-content: flex-start;
  row-gap: 16px;
  // grid-auto-rows: 1fr;

  label {
    height: 100%;
  }

  @include media(large) {
    // grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    padding-top: 70px;
  }

  @include media(extraLarge, extraExtraLarge) {
    // grid-template-columns: repeat(var(--quiz-answers-column-count, 1), 1fr);
    column-gap: 16px;
    padding-top: 70px;
  }
  
}

.quiz-footer {
  bottom: 0;
  width: 100%;
  text-align: center;
  border-top: 1px solid #c3c4ca;
  background-color: var(--quiz-footer-bgcolor, #fff);

  @include media(medium, large, extraLarge, extraExtraLarge) {
    position: fixed;
  }

  &-content {
    // width: calc(100% - 48px);
    width: 100%;
    padding: 24px 24px 50px;
    display: inline-grid;
    row-gap: 24px;
    align-items: center;

    svg {
      --primary-btn-disabled-icon-color: #a19f9d;
    }

    @include media(medium, large, extraLarge, extraExtraLarge) {
      // width: calc(100% - 144px);
      padding: 25px 72px;
      grid-template-columns: repeat(var(--qt-footer-column-count, 1), 1fr);
      row-gap: 0;
      column-gap: 24px;

      a {
        // min-width: 150px;
        place-self: start;

        &:nth-child(2) {
          place-self: end;
        }
      }
    }
    @include media(extraLarge) {
      // width: calc(100% - 192px);
      padding: 25px 96px;
    }
    @include media(extraExtraLarge) {
      // width: 100%;
      max-width: 1312px;
      padding: 25px 0;
    }
  }
}

.quiz-footer-content-btn-primary {
  a {
    color: var(--quiz-primary-btn-text-color, var(--primary-btn-text-color));
    background-color: var(
      --quiz-primary-btn-bgcolor,
      var(--primary-btn-bgcolor)
    );
    border: solid 3px
      var(--quiz-primary-btn-border-color, var(--primary-btn-border-color));

    &:hover {
      color: var(
        --quiz-primary-btn-hover-text-color,
        var(--primary-btn-hover-text-color)
      );
      background-color: var(
        --quiz-primary-btn-hover-bgcolor,
        var(--primary-btn-hover-bgcolor)
      );
      border: solid 3px
        var(
          --quiz-primary-btn-hover-border-color,
          var(--primary-btn-hover-border-color)
        );

      svg {
        fill: var(
          --quiz-primary-btn-hover-text-color,
          var(--primary-btn-hover-text-color)
        );
      }
    }
    &:active {
      color: var(
        --quiz-primary-btn-active-text-color,
        var(--primary-btn-active-text-color)
      );
      background-color: var(
        --quiz-primary-btn-active-bgcolor,
        var(--primary-btn-active-bgcolor)
      );
      border: solid 3px
        var(
          --quiz-primary-btn-active-border-color,
          var(--primary-btn-active-border-color)
        );

      svg {
        fill: var(
          --quiz-primary-btn-active-text-color,
          var(--primary-btn-active-text-color)
        );
      }
    }
    svg {
      fill: var(--quiz-primary-btn-text-color, var(--primary-btn-text-color));
    }
  }
}

.quiz-footer-content-btn-secondary {
  a {
    color: var(
      --quiz-secondary-btn-text-color,
      var(--secondary-btn-text-color)
    );
    background-color: var(
      --quiz-secondary-btn-bgcolor,
      var(--secondary-btn-bgcolor)
    );
    border: solid 3px
      var(--quiz-secondary-btn-border-color, var(--secondary-btn-border-color));

    &:hover {
      color: var(
        --quiz-secondary-btn-hover-text-color,
        var(--secondary-btn-hover-text-color)
      );
      background-color: var(
        --quiz-secondary-btn-hover-bgcolor,
        var(--secondary-btn-hover-bgcolor)
      );
      border: solid 3px
        var(
          --quiz-secondary-btn-hover-border-color,
          var(--secondary-btn-hover-border-color)
        );

      svg {
        fill: var(
          --quiz-secondary-btn-hover-text-color,
          var(--secondary-btn-hover-text-color)
        );
      }
    }
    &:active {
      color: var(
        --quiz-secondary-btn-active-text-color,
        var(--secondary-btn-active-text-color)
      );
      background-color: var(
        --quiz-secondary-btn-active-bgcolor,
        var(--secondary-btn-active-bgcolor)
      );
      border: solid 3px
        var(
          --quiz-secondary-btn-active-border-color,
          var(--secondary-btn-active-border-color)
        );

      svg {
        fill: var(
          --quiz-secondary-btn-active-text-color,
          var(--secondary-btn-active-text-color)
        );
      }
    }
    svg {
      fill: var(
        --quiz-secondary-btn-text-color,
        var(--secondary-btn-text-color)
      );
    }
  }
}

.quiz-footer-content-btn-tertiary {
  a {
    color: var(--modal-tertiary-btn-text-color, var(--tertiary-btn-text-color));

    &:hover {
      color: var(
        --modal-tertiary-btn-hover-text-color,
        var(--tertiary-btn-hover-text-color)
      );
      svg {
        fill: var(
          --modal-tertiary-btn-hover-text-color,
          var(--tertiary-btn-hover-text-color)
        );
      }
    }
    &:active {
      color: var(
        --modal-tertiary-btn-active-text-color,
        var(--tertiary-btn-active-text-color)
      );
      background-color: var(
        --modal-tertiary-btn-active-bgcolor,
        var(--tertiary-btn-active-bgcolor)
      );
      svg {
        fill: var(
          --modal-tertiary-btn-active-text-color,
          var(--tertiary-btn-active-text-color)
        );
      }
    }
    svg {
      fill: var(
        --modal-tertiary-btn-text-color,
        var(--tertiary-btn-text-color)
      );
    }
  }
}

.btn-disabled {
  a {
    border: 2px solid #f5f5f6;
    background-color: #f5f5f6;
    color: #4b4c53;
  }
}
