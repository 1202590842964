@import 'style/mixins/_responsive.scss';
@import 'style/variables';

.app-layout--v1 {
  .webinar-card-container {
    padding: 0;
  
    .webinar-tags__list__detail {
      display: flex;
      align-items: center;
      justify-content: center;
  
      svg {
        margin-right: 10px;
      }
    }
    .webinar-card {
      width: 100%;
  
      &__image {
        width: 100%;
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
  
        img {
          width: 100%;
          height: 300px;
        }
      }
  
      &__details {
        padding: 20px;
        height: 255px;
        
        h2 {
          font-size: 24px;
          font-weight: 700;
          line-height: 29px;
          letter-spacing: 0px;
          margin-top: 0;
        }
  
        .RichText {
          margin-bottom: 20px;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0px;
          max-width: 340px;
        }
  
        .webinar-tags {
          margin-bottom: 40px;
          padding: 0;
  
        }
  
        a{
          width: 290px;
        }
      }
    }
  }
}
.app-layout--v2 {
  .webinar-card-container {
    padding: 0;
    box-shadow: 0px 2px 4px rgba(52, 58, 64, 0.25);
    border-radius: 5px;
    max-width: 450px;
    margin: auto;
  
    &:hover {
      box-shadow: -5px 5px 0px 0px $kk-primary, 0px 2px 4px rgba(52, 58, 64, 0.25);
    }
  
  
    .webinar-tags__list__detail {
      display: flex;
      align-items: center;
      justify-content: center;
  
      svg {
        margin-right: 10px;
      }
    }
    .webinar-card {
      width: 100%;
  
      &__image {
        width: 100%;
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
  
        img {
          width: 100%;
          height: 300px;
        }
      }
  
      &__details {
        padding: 20px;
        // height: 255px;
        
        h2 {
          font-size: 24px;
          font-weight: 700;
          line-height: 29px;
          letter-spacing: 0px;
          margin-top: 0;
        }
  
        .RichText {
          margin-bottom: 20px;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0px;
          max-width: 340px;
        }
  
        .webinar-tags {
          margin-bottom: 40px;
          padding: 0;
  
        }
  
        a{
          width: 290px;
        }
      }
    }
  }
}
