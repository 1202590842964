@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.content_item--form {
  margin-top: 30px;

  .formName {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 1px;
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 25px;
  }

  .form-component-wrapped {
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    max-width: 583px;
    margin-bottom: 0;
  }

  .form-labels {
    .mandatory {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      color: #ea5504;
      text-transform: capitalize;
    }

    .form-bottom-label {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0px;
      text-align: left;
      margin-bottom: 40px;
    }
  }
  .formbuilder-sign-up__success-modal {
    position: absolute;
    z-index: 1;
    border-top: 1.03846px solid #c4c4c4;
    border-bottom: 1.03846px solid #c4c4c4;
    width: 100vw;
    left: 0;
    background: white;
    bottom: -120px;
  
    @media (max-width: 400px) {
      bottom: -235px;
    }
  
    @include screen-width-medium {
      bottom: -120px;
    }
  
    @include screen-width-large {
      bottom: -90px;
    }
  
    @include screen-width-xl {
      bottom: -90px;
    }
  
    .success-modal__content {
      display: flex;
      align-items: center;
      padding: 20px;
      margin: auto;
  
      @media (max-width: 400px) {
        flex-direction: column;
      }
  
      @include screen-width-medium {
        padding: 20px;
      }
  
      @include screen-width-large {
        padding: 20px calc(5% + 20px);
      }
  
      @include screen-width-xl {
        max-width: 1600px;
        padding: 20px 80px;
      }
  
      svg {
        min-width: 40px;
        min-height: 40px;
  
        @media (max-width: 400px) {
          margin-bottom: 15px;
        }
      }
  
      p {
        width: auto;
        margin: 0 20px;
  
        @media (max-width: 400px) {
          margin: 0;
        }
      }
  
      button {
        min-width: 80px;
        border: none;
        background-color: #ea570d;
        color: #ffffff;
        text-transform: uppercase;
        border-radius: 30px;
        padding: 12px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 1px;
  
        @media (max-width: 400px) {
          margin-top: 15px;
        }
      }
    }
  }
  .formbuilder-sign-up__success-modal {
    bottom: auto;
    width: 100%;
  }

  .form-field-radio-eapc {
    .radio-button-section {
      .input-radio-section {
        flex-direction: column;

        .options {
          justify-content: flex-start;
          width: fit-content;
          margin-bottom: 14px;
          cursor: pointer;

          .radio-option-label {
            margin-right: 0;
          }
        }

        .options:last-child {
          margin-bottom: 0;
        }
      }
    }
    .field-meta-data {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
    }
  }

  .form-field-button {
    margin-bottom: 20px;
    button {
      max-width: 262px;
    }
  }
}
