@import 'style/mixins/_responsive.scss';
@import 'style/variables';

.videotrackcontainer {
  font-size: 0;
  position: relative;
  margin: 0;

  video {
    width: 75%;
    height: auto;
    display: inline-block;
    background-color: #faf6f4;
  }
}
.videotrackcontainer figcaption {
  position: absolute;
  right: 0;
  top: 0;
  background: #333333;
  width: 25%;
  font-size: 0.8rem;
  color: #ffffff99;
  height: 96.5%;
  overflow-y: auto;
}
.videotrackcontainer figcaption ol {
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 0;
  line-height: normal;
}
.videotrackcontainer figcaption ol li a {
  display: block;
  padding: 0.7rem 1rem;
  border-bottom: 0.5px solid #000;
  transition: 0.3s;
}

.current {
  background: #ffffff;
  opacity: 0.6;
  color: #000;
}

.videotrackcontainer figcaption ol li a:not(.current):hover,
.videotrackcontainer figcaption ol li a:not(.current):focus {
  background: #666;
  color: #000;
  cursor: pointer;
}
.videotrackcontainer figcaption::-webkit-scrollbar {
  width: 13px;
  height: 100%;
}
.videotrackcontainer figcaption::-webkit-scrollbar-track {
  background-color: #000;
}
.videotrackcontainer figcaption::-webkit-scrollbar-thumb:vertical {
  background: #666;
  width: 13px;
  height: 20px;
}

.videotrackcontainer {
  ul {
    margin-left: 17px;
    height: 0px;
    position: relative;
    transition: visibility 2s, opacity 2s linear;

    @include screen-width-desktop {
      width: 70%;
    }
    @include screen-width-mobile {
      width: 85%;
    }

    li {
      background-color: #000000;
      bottom: 0;
      left: 0;
      height: 6px;
      position: absolute;
      width: 8px;
      cursor: pointer;
      @include screen-width-desktop {
        margin-bottom: 19px;
      }
      @include screen-width-mobile {
        margin-bottom: 28px;
        display: none;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .videotrackcontainer video,
  .videotrackcontainer figcaption {
    display: block;
    width: 100%;
  }
  .videotrackcontainer video {
    border-bottom: 10px solid #111;
  }
  .videotrackcontainer figcaption {
    position: static;
    height: auto;
    max-height: 200px;
  }
}
