@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/modal.scss';
@import 'assets/mixins.scss';

.audio-download-modal {
  .modal-dialog-centered {
   
    @media screen and (max-width: 768px){
      align-items: end;
      margin: 0;
      padding: 0;
      height: 100%;
      min-height: 100%;
    }
  }
  .modal-dialog {
  max-width: 540px;
  max-height: 300px;
  }
  .modal {
    &-content {
      padding: 25px;
    }
    &-header {
      padding: 0;
      border-bottom: none;
      .modal-title {
        font-weight: 700;
        font-size: 25px;
      }
      .close {
        display: none;
      }
    }


    &-body {
      hr {
        margin: 0;
        margin-top: 22px;
        background-color: $kk-primary;
        border-color: $kk-primary;
      }
      padding: 0;
      &__section {
        padding: 28px 0 32px 0;
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        text-align: left;

        .video {
          margin: 0;
        }
      }
    }

    &-footer {
        justify-content: unset;
        border: 0;
        padding: 0;
        gap: 12px;
        padding-top: 12px;
        
        a {
            margin: 0;
            border-radius: 30px;
            height: 40px;
            text-transform: uppercase;
            font-weight: 700;
            @media screen and (max-width: 768px){ 
              width: 100%;
              margin-bottom: 6px;

            }

        }
        a:nth-of-type(2) {
          border: 1px solid #979797;
          color: #979797;
        }
        a:nth-of-type(2):hover {
          background-color: #F3F2F1;
        }
        @include screen-width-mobile  {
           flex-direction: column;
           a {
            width: 100%;
           }
          }
    }
  }
}
