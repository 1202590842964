@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_titleWithIcon.scss';
@import 'assets/mixins.scss';
.app-layout--v1 {
    .TitleCard {
        overflow: hidden;
        flex-direction: column;
        height: 340px;
    
        .CardImage {
            width: 100%;
            flex: 1 1 20px;
            background-position: center top;
        }
    
        .CardContent {
            width: 100%;
            flex: 1 1 0px;
            padding: 20px;
            justify-content: flex-start;
    
            .CardHeading {
                margin: 0;
                font-size: 25px;
                color: #ffffff;
                font-family: $myriad-reg;
            }
        }
    
        &--center {
            margin: 0px auto;
        }
    }
    
    @media only screen and (min-width: 1365px) and (max-width: 1380px) {
        .TitleCard {
            .CardContent {
                .CardHeading {
                    font-size: 24px !important;
                }
            }
        }
    }
}
.app-layout--v2 {
    .TitleCard {
        overflow: hidden;
        flex-direction: column;
        min-height: 340px;
    
        .CardImage {
            width: 100%;
            flex: 1 1 0;
            background-position: center top;
        }
    
        .CardContent {
            width: 100%;
            flex: 1 1 0px;
            padding: 20px;
            justify-content: flex-start;
    
            .CardHeading {
                margin: 0;
                margin-top: 15px;
                font-size: 25px;
                font-family: $hindMadurai-reg;
            }
        }
    
        &--center {
            margin: 0px auto;
        }
    }
    
    @media only screen and (min-width: 1365px) and (max-width: 1380px) {
        .TitleCard {
            .CardContent {
                .CardHeading {
                    font-size: 24px !important;
                }
            }
        }
    }
}