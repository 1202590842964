$prefix:'-moz-', '-webkit-', '-o-', '-ms-', '';

// Usage:   @include transition(width, height 0.3s ease-in-out);
// Output:  -webkit-transition(width 0.2s, height 0.3s ease-in-out);
//          transition(width 0.2s, height 0.3s ease-in-out);
//
// Pass in any number of transitions
@mixin transition($transitions...) {
  $unfoldedTransitions: ();
  @each $transition in $transitions {
    $unfoldedTransitions: append($unfoldedTransitions, unfoldTransition($transition), comma);
  }
  @each $p in $prefix {
    #{$p}transition: $unfoldedTransitions;
  }
}

@function unfoldTransition ($transition) {
  // Default values
  $property: all;
  $duration: .5s;
  $easing: null; // Browser default is ease, which is what we want
  $delay: null; // Browser default is 0, which is what we want
  $defaultProperties: ($property, $duration, $easing, $delay);

  // Grab transition properties if they exist
  $unfoldedTransition: ();
  @for $i from 1 through length($defaultProperties) {
    $p: null;
    @if $i <= length($transition) {
      $p: nth($transition, $i)
    } @else {
      $p: nth($defaultProperties, $i)
    }
    $unfoldedTransition: append($unfoldedTransition, $p);
  }

  @return $unfoldedTransition;
}

/*
  Responsive Sizing
*/

$extra-small: 320px;
$small: 545px;
$medium: 832px;
$large: 1088px;
$extra-large: 1272px;
$extra-extra-large: 1504px;
$menu-extra-large: 1310px;

@mixin extraSmall {
  @media (min-width: 0) and (max-width: #{$small - 1px}) {
    @content;
  }
}
@mixin small {
  @media (min-width: #{$small}) and (max-width: #{$medium - 1px}) {
    @content;
  }
}
@mixin medium {
  @media (min-width: #{$medium}) and (max-width: #{$large - 1px}) {
    @content;
  }
}
@mixin large {
  @media (min-width: #{$large}) and (max-width: #{$extra-large - 1px}) {
    @content;
  }
}
@mixin extraLarge {
  @media (min-width: #{$extra-large}) and (max-width: #{$extra-extra-large - 1px}) {
    @content;
  }
}
@mixin extraExtraLarge {
  @media (min-width: #{$extra-extra-large}) {
    @content;
  }
}
@mixin menuExtraLarge {
  @media (min-width: #{$menu-extra-large}) and (max-width: #{$extra-extra-large - 1px}) {
    @content;
  }
}

@mixin media($keys...) {
  @each $key in $keys {
    @if ($key == extraSmall) {
      @include extraSmall {
        @content;
      }
    } @else if ($key == small) {
      @include small {
        @content;
      }
    } @else if ($key == medium) {
      @include medium {
        @content;
      }
    } @else if ($key == large) {
      @include large {
        @content;
      }
    } @else if ($key == extraLarge) {
      @include extraLarge {
        @content;
      }
    } @else if ($key == extraExtraLarge) {
      @include extraExtraLarge {
        @content;
      }
    } @else if ($key == menuExtraLarge) {
      @include menuExtraLarge {
        @content;
      }
    }
  }
}