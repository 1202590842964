@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import '../../assets/mixins.scss';

.content {
  &__outer:first-of-type {
    .content__divider {
      display: none;
    }
    .content__inner {
      padding: 40px 20px 0;
    }
    .content__inner--no-padding {
      padding: 40px 0 0 0;
    }
  }

  &__outer {
    &--has-title {
      margin-bottom: 20px;
    }
  }

  &__divider {
    &--has-title {
      display: block;
      height: 5px;
      width: 100%;
      margin: 20px 0;
    }
  }

  &__inner {
    padding: 20px 20px;

    &--no-padding {
      padding: 0;
    }

    p:first-of-type {
      margin-top: 0;
    }

    .pdf-preview {
      padding-bottom: 1px; // activates the margin 30px built into the pdf styles
      .embedded-pdf-comonent {
        width: 100%;
        canvas {
          width: 100% !important;
          max-width: 918px;
          max-height: 1175px;
        }
      }
    }
  }

  &__title:first-of-type {
    padding-top: 0;
  }

  &__title:first-of-type h2,
  &__title:first-of-type p {
    font-size: inherit;
    margin: 0 auto 0.83rem;
  }

  &__title {
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    color: $body-text-color;
    padding-top: 20px;
  }

  &__media-container {
    display: block;
    @include spans-columns(12);
    padding: 20px 0;
    padding-left: 0 !important;
    padding-right: 0 !important;

    .video {
      padding: 0;
      margin: 0;
      width: 100%;
      border-radius: 30px;
      overflow: hidden;
      line-height: 0;

      video {
        width: 100%;
        margin: 0;
      }
    }
  }

  &__figure {
    padding: 0;
    margin: 0;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    line-height: 0;

    img {
      width: 100%;
      margin: 0;
    }
  }

  @include desktopOnly {
    &__title {
      font-size: 25px;
      line-height: 30px;
    }
  }
}
