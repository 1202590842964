@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.circle-call-out__itemWrapper {
    text-align: center;
    margin: 0;

    @include screen-width-small {
        @include spans-columns(12);
        margin-bottom: 15px;
    }
    .circle-call-out__itemContent {
        position: relative;
        width: 380px;
        height: 380px;
        background-color: #ffffff;
        border-radius: 50%;
        box-shadow: 0px 3px 6px #9A3F26;
        display: block;
        margin: 0 auto;
        @media only screen and (min-width: 1025px) and (max-width: 1200px) { 
            width: 300px;
            height: 300px;
        }
        @media only screen and (min-width: 1201px) and (max-width: 1400px) { 
            width: 350px;
            height: 350px;
        }
        @include screen-width-small {
            width: 300px;
            height: 300px;
        }
        > .circle-call-out__content {
            color: #3D3F5B;
            font-size: 30px;
            font-weight: normal;
            margin: 0;
            position: absolute;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            padding: 12px;
            @media only screen and (min-width: 1025px) and (max-width: 1200px) { 
                font-size: 21px;
            }
            @media only screen and (min-width: 1201px) and (max-width: 1400px) { 
                font-size: 26px;
            }
            @include screen-width-small {
                font-size: 21px;
            }
            span {
                color: #E07A5F;
            }
        }
        .circle-call-out__btn {
            border: 2px solid #3D3F5B;
            border-radius: 50px;
            text-transform: uppercase;
            font-size: 15px;
            color: #3D3F5B;
            padding: 6px 28px;
            display: inline-block;
            position: absolute;
            bottom: 8%;
            left: auto;
            right: auto;
            transform: translate(-50%, -50%);
            @media only screen and (min-width: 1025px) and (max-width: 1200px) { 
                font-size: 12px;
            }
            @media only screen and (min-width: 1201px) and (max-width: 1400px) { 
                font-size: 12px;
            }
            @include screen-width-small {
                font-size: 12px;
            }
            cursor: pointer;
            .RichText {
                * {
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }
}

.lozenge-call-out__btn {
    width: 100%;
    height: 78px;
    text-align: center;
    background-color: #e07a5f;
    border-radius: 50px;
    color: #fff;
    font-size: 20px;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    margin: 60px 0;
    box-shadow: 0 3px 6px #00000029;
}

.onOffCallOut__state {
    position: relative;
    margin-top: 60px;
    text-align: center;
    padding:10px 5px;
    border: 1px solid #FFFFFF;
    border-radius: 15px;
    @include screen-width-medium {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    @media only screen and (min-width: 1025px) and (max-width: 1199px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &.floatRightCircle {
        .deco {
            @media only screen and (min-width: 1200px) {
                right: 5px;
            }
        }
    }

    &.floatLeftCircle {
        .deco {
            @media only screen and (min-width: 1200px) {
                left: 5px;
            }
        }
    }

    @media only screen and (min-width: 1200px) {
        display: flex;
        padding:5px 5px;
        align-items: center;
        justify-content: space-between;
        border-radius: 50px;
    }

    .state {
        width: 20%;
        padding-bottom:10px;
        font-size: 32px;
        font-weight: bold;
        text-transform: uppercase;
        .RichText {
            * {
                margin: 0;
            }
        }

        @media only screen and (min-width: 1200px) {
            padding-top: 4px;
            padding-left: 75px;
        }
        @include screen-width-small {
            width: 100%;
            text-align: center;
        }
        @include screen-width-medium {
            width: 30%;
            text-align: center;
        }
        @media only screen and (min-width: 1025px) and (min-width: 1199px) {
            width: 30%;
            text-align: center;
        }
    }

    .desc {
        width: 80%;
        font-size: 20px;
        text-align: left;
        .RichText {
            * {
                padding: 0;
                margin: 0;
            }
        }
        @media only screen and (min-width: 1200px) {
            margin-left: 60px;
            margin-right: 40px;
        }
        @include screen-width-small {
            width: 100%;
            text-align: center;
        }
        @include screen-width-medium {
            width: 70%;
            text-align: left;
        }
        @media only screen and (min-width: 1025px) and (max-width: 1199px) {
            width: 70%;
            text-align: left;
        }
    }

    .deco {
        @media only screen and (min-width: 1200px) {
            position: absolute;
            -webkit-transform: translateY(0%);
            transform: translateY(0%);
            width: 50px;
            height: 50px;
            background-color: #FFFFFF;
            border-radius:50%;
        }
    }
}

.onOffCallOut__state-highlight {
    width: 100%;
    margin: 30px 0;
    height: 90px;
    padding-top: 12px;
    font-size: 20px;
    text-align: center;
    background-color: #E07A5F;
    border:1px solid #FFFFFF;
    border-radius: 15px;

    @media only screen and (min-width: 768px) {
        display: flex;
        height: 60px;
        padding-top: 0;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
    }

    &.accordion-bottom {
        margin: 10px 0 20px 0;
        border:none;
        color: #FFFFFF;
        /*position: absolute;
        width: 101%;
        top: -30px;
        left: -5px;*/
    }

    &.accordion-overlay {
        position: absolute;
        top: -38px;
        left: 0px;
    }
}

.call-out__circle {
    position: relative;
    width: 380px;
    height: 380px;
    background-color: #3D3F5B;
    border-radius: 50%;
    display: block;
    margin: 0 auto;    
    // padding: 20px;
    text-align: center;
    @media only screen and (min-width: 1025px) and (max-width: 1200px) { 
        width: 300px;
        height: 300px;
    }
    @media only screen and (min-width: 1201px) and (max-width: 1400px) { 
        width: 350px;
        height: 350px;
    }
    @include screen-width-small {
        width: 300px;
        height: 300px;
    }
    > span {
        position: absolute;
        top: 3%;
        left: 38%;
        @include screen-width-small {
            top: 5%;
            left: 34%;
        }
        @include screen-width-medium {
            top: 5%;
            left: 34%;
        }
        > img {
            width: 100px;
            height: auto;
        }
    }
    > .call-out-circle__title {
        color: #ffffff;
        font-size: 30px;
        font-weight: normal;
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        padding: 16px;
        @media only screen and (min-width: 1025px) and (max-width: 1200px) { 
            font-size: 21px;
        }
        @media only screen and (min-width: 1201px) and (max-width: 1400px) { 
            font-size: 26px;
        }
        @include screen-width-small {
            font-size: 21px;
        }
        span {
            font-family: Arial;
        }
    }
    > .call-out-circle__subTitle {
        font-size: 18px;
        color: #ffffff;
        font-weight: bold;
        line-height: 26px;
        transform: translateY(320px);
        -ms-transform: translateY(320px);
        -o-transform: translateY(320px);
        -moz-transform: translateY(320px);
        -webkit-transform: translateY(320px);
        text-align: center;
        @include screen-width-small {
            font-size: 14px;
            transform: translateY(198px);
            -ms-transform: translateY(198px);
            -o-transform: translateY(198px);
            -moz-transform: translateY(198px);
            -webkit-transform: translateY(198px);
        }
        @include screen-width-medium {
            font-size: 16px;
            transform: translateY(235px);
            -ms-transform: translateY(235px);
            -o-transform: translateY(235px);
            -moz-transform: translateY(235px);
            -webkit-transform: translateY(235px);
        }
        > a {
            color: #ffffff;
            text-decoration: none;
            cursor: pointer;
        }
    }
}
#calloutSm{
   
    flex-direction:column;

    .state{
        margin-left: -146px;
        padding-left:0px;
        width: unset;
        font-size: 22px;
        @media only screen and (max-width:768px){
            width: 100%;
            text-align: center;
        }
    }
    .deco{
        right: 20px;
        top: 15px;
    }
    .desc{
         @media only screen and (max-width:768px){
            width: 100%;
            text-align: center;
        }
    }
    .call-out-circle__subTitle{
        position: absolute;
        top: -150px;
        left: 50px;
    }
    .call-out-circle__title{
        top: 42%;
        left: 30px;
    }
}
#calloutFourColumn{
    flex-direction:column;
    .state{
        margin-left: 0px;
        margin-right:0px;
        padding-left:0px;
        width: unset;
        font-size: 16px;
        width:152px;
        
        @media only screen and (max-width:768px){
            margin-top:26px;
            font-size: 26px;
            width: 100%;
            text-align: center
        }
    }
    .desc{
        font-size: 16px;
        margin-left: 0px;
        margin-right:0px;
        width:152px;
        text-align: center;
        @media only screen and (max-width:768px){
            width: 100%;
        }
       
    }
    .deco{
        right: 20px;
        top: 11px;
        width: 40px;
        height: 40px; 
    }
    .call-out__circle{
        width: 271px;
        height: 271px;
    }
    .call-out-circle__subTitle{
        position: absolute;
        top: -150px;
        left: 50px;
    }
    .call-out-circle__title{
        top: 42%;
        left: 30px;
    }
}
#calloutThreeColumn{
    flex-direction:column;
    .state{
        margin-left: 0px;
        margin-right:0px;
        width: unset;
        font-size: 16px;
        padding-left: 0px;
    }
    .desc{
        margin-left: 0px;
        margin-right:0px;
        font-size: 16px;
        width:unset;
        @media only screen and (max-width:768px){
            width: 100%;
            text-align: center
        }
    }
    .deco{
        right: 12px;
        top: 6px;
    }
    .call-out-circle__subTitle{
        position: absolute;
        top: -150px;
        left: 50px;
    }
    .call-out-circle__title{
        top: 42%;
        @media only screen and (max-width:768px){
            word-break: break-word;
            width: 297px;
        }
    }
}
