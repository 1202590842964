@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';



.generic-page-component {
  .generic-page {
    @include grid-row();
    .column-left {
      @include spans-columns(3);
      @include spans-columns-small(4);
    }
    .column-right {
      @include spans-columns(3);
      @include spans-columns-small(4);
    }
    @include screen-width-mobile {
      flex-direction: column;
      .column-left {
        @include spans-columns(12);
      }
      .column-right {
        @include spans-columns(12);
      }
      article {
        @include spans-columns(12);
      }
    }
  }
  article {
    @include spans-columns(6);
    @include spans-columns-small(4);
    padding-top: 0px;
    padding-bottom: 0px;
    h2 {
      margin-top: 0;
      font-size: 20px;
      line-height: 35px;
      font-family: $subtitle-font;
      font-weight: 600;
    }

    table {
      width: 100% !important; // rich text editor writes styles inline - not ideal but have to use !important on these styles
      th {
        background-color: lightgray;
        text-align: left;
        padding: 0 5px !important;
      }
      td {
        padding: 0 5px !important;
        vertical-align: top;
      }

      @include screen-width-small {
        line-height: 16px;
        font-size: 7px;
      }

      @include screen-width-large {
        font-size: 12px;
      }
    }
  }
}


