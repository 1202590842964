@import 'style/variables.scss';
.references-small-section{
    border-radius: 10px 0px 0px 10px;
    width: 46px;
    height: 140px;
    position: relative;
    left: 0px;
    &.reference-blue{
        background-color: #3D3F5B;
    }
    
    &.reference-orange{
        background-color: #F2CC8F;
    }
}

.references-small-container{
    margin-top:54px;
    display: flex;
}
.references-big-container{
    margin-top: 32px;
    display: flex;
    position: fixed;
    top: 50px;
    right: 0;
    z-index: 999;
    height: 100%;
}
.reference-caption{
    font-size: 18px;
    text-transform: uppercase;
    align-self: center;
    font-family: $hindMadurai-sb;
    position: absolute;
    bottom: 60px;
    left: 0;
    margin-left: -30px;
    transform: rotate(270deg);  
    cursor: pointer;
    letter-spacing: 0.8px;
    &.text--blue{
        color: #3D3F5B;
    }
    &.text--white{
        color: #fff;
    }
}
.close-btn-image{
    width:20px;
    height:20px;
    margin-top:8px;
    margin-left:12px;
    cursor: pointer;
}
.references-block{
    height: 92%;
    width: 315px;
    margin-right: 0px;
    background-color: #F2CC8F;
    overflow-y: auto; 
    margin-top:43px;
    @media only screen and (min-width:1024px) {
        width: 754px;
        margin-right: 127px;
    }
}
.references-wrapper{
    height: 100%;
    width: 322px;
    background-color: #F2CC8F;
    @media only screen and (min-width:1024px){
        width: 865px;
    }
    ol{
        font-family: $hindMadurai-sb;
        padding-right: 5px;
        @media only screen and (min-width:1024px){
            padding-right: 158px;
        }
        p{
            font-family: $hindMadurai-reg;
            padding-left: 5px;
        }
    }
}
#block{
    &::-webkit-scrollbar {
        width: 10px;
        margin-right: 15px;
      }
      
      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgb(255, 253, 253); 
        border-radius: 10px;
        background-color: #ffff;
      }
       
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #3D3F5B; 
        border-radius: 10px;
        border:1px;
      }
      & {
        scrollbar-face-color: #3D3F5B;
        scrollbar-track-color: #ffff;
      }
}
.overlay-reference{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 98;
    height: 100%;
    width: 100%;
    background-color: rgba(61, 63, 91, 0.57);
}
.pointer{
    cursor:pointer;
}
