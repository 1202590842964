@import "style/mixins/_responsive.scss";
.video {
  height: 100%;
  width: 100%;
  position: relative;
  cursor: pointer;
  transform: translateZ(0);
  
  .video__poster {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: white;
    z-index: 2;

    .video__poster__img {
      height: 100%;
      width: auto;
      margin: auto;
      @media (max-width: 501px) {
        height: auto;
        margin-bottom: auto!important;
      }      
    }

    .video__poster__play {
      position: absolute;
      top: calc(50% - 24px);
      opacity: 0.7;
    }
  }
}

.video:hover {
  .video__poster {
    .video__poster__play {
      opacity: 0.5;
    }
  }
}

.video--chapters {
  border-radius: 0!important;

  video {
    @media (max-width: 500px) {
      width: 100%!important;
    }
  }

  .video__poster {
    width: 75%;
    height: calc(100% - 10px);
  }
}