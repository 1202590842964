@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.app-layout--v1 {
  .my-hub-bookmarks {
    padding-left: 24px;
    min-height: 800px;
    @include screen-width-small {
      min-height: 0;
      padding: 0 21px;
    }
  }
  
  .my-hub-bookmarks__title {
    font-family: $myriad-light;
    color: #ea5504;
    margin: 24px 0;
    font-weight: 300;
    font-size: 40px;
    line-height: 48px;
  
    @include screen-width-small {
      margin-top: 40px;
      font-size: 25px;
      font-weight: 300;
      line-height: 30px;
      letter-spacing: 0px;
    }
  }
  
  .my-hub-bookmarks__desc {
    font-family: $myriad-reg;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  
    @include screen-width-small {
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0px;
    }
  }
  
}
.app-layout--v2 {
  .my-hub-bookmarks {
    padding-left: 24px;
     min-height: 800px;
    @include screen-width-small {
      padding: 0 21px;
      min-height: 0;
    }
  }
  
  .my-hub-bookmarks__title {
    // font-family: $myriad-light;
    font-family: $hindMadurai-sb;
    color: #ea5504;
    margin: 24px 0;
    font-weight: 300;
    font-size: 40px;
    line-height: 48px;
  
    @include screen-width-small {
      margin-top: 40px;
      font-size: 25px;
      font-weight: 300;
      line-height: 30px;
      letter-spacing: 0px;
    }
  }
  
  .my-hub-bookmarks__desc {
    font-family: $hindMadurai-reg;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  
    @include screen-width-small {
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0px;
    }
  }

}



/* Bookmark Container */
.bookmark-container {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 10px 0;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
}

/* Breadcrumb Styling */
.breadcrumb {
  font-size: 14px;
  color: #F9F4F2;
  padding: 0 32px;
}

/* Title Styling */
.bookmark-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

/* Description Styling */
.bookmark-description {
  font-size: 16px;
  flex: 8;
  color: #555;
  margin: 10px 0;
}

.bookmark-content {
  padding: 16px 32px;
  background-color: white;
}

.pagination-icon-button {
  background: none;
  outline: none;
  color: #ea5504;
  border: none;
  margin: 0;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  svg {
    width: 16px;
    height: 16px;
  }
}

.pagination_controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-bottom: 15px;
}

.pagination-number-button {
  background: none;
  outline: none;
  border: none;
  color: black;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}

.current-page {
  color: white;
  background-color: #e05500;
  border-radius: 15px;

}

.pagination-icon-button:disabled,.pagination-icon-button[disabled] {
  color: #979797 !important;
  cursor: not-allowed;
}

.bookmark-description-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 90px
}

@include screen-width-small {
  .bookmark-description-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 0
  }

  .bookmark-buttons {
    width: 100%;
    justify-content: space-between;
  }
}

/* Buttons Container */
.bookmark-buttons {
  display: flex;
  flex: 2;
  gap: 10px;
}

/* Visit Button */
.visit-button {
  background-color: #ea5504;
  color: #fff;
  border: none;
  height: 34px;
  width: 103px;
  border-radius: 50px;
  padding: 8px 36px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.visit-button:hover {
  background-color: #e05500;
}

/* Delete Button */
.delete-button {
  color: #333;
  border: none;
  padding: 2px;
  font-size: 14px;
  cursor: pointer;
  background: none;
}


/* App Container */
.app-container {
  padding: 20px;
}


