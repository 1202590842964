@import 'style/reset.scss';
@import 'style/variables.scss';
@import './assets/mixins.scss';

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  color: $body-text-color;
  // base font size is 16px
  font-size: $body-font-size;

  // Controlled by : redux/ui/slice.js
  &.Body--ScrollLock {
    overflow: hidden;
  }
}

// all buttons should have a pointer cursor by default
button {
  cursor: pointer;
  &:disabled {
    cursor: default;
  }
}

// Some focusable items have rounded edges. The problem with this is their outline is square. As such, this className overwrites that and uses the
// style defined here:
// https://www.figma.com/file/sseXCDDCoBI1Sge6080EpI/WF_KK?node-id=133%3A771
.custom-focus {
  outline: none;
  // &:focus {
  //   box-shadow: 0px 0px 0px 2px rgba(0,0,0,1);
  // }
}

// Same as above, except uses the important marker. It does this due to shadow already being applied and nested in some places
.custom-focus-forced {
  outline: none !important;
  &:focus {
    box-shadow: 0px 0px 0px 2px rgba(0,0,0,1) !important;
  }
}

.no-top-margin {
  margin-top: 0px;
}

.standard-margin-bottom {
  margin-bottom: 30px;
}

.center-align-children {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ie-11-button-fix {
  // max-height: 44px; is needed for ie11. It could probably be applied less globally but
  // it's hard to check because ie11 gets cors issues in local dev. This will likely cause
  // issues to be honest, but it's better to address those issues locally as this global
  // rule makes ie11 significantly better.
  max-height: 44px; // ie11 fix
}
