@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_videoFigure.scss';
@import '../../assets/mixins.scss';

.brand-landing-page {
  &__logo {
    @include container-rules();    
    margin: 0 auto;
    display: flex;
    max-width: 1600px;    
  }

  &__header {
    p a {
      color: inherit;
    }
  }

  &__content-section {
    padding-top: 40px;
  }

  &__content-section-inner {
    display: flex;
    flex-direction: column;

    @include desktopOnly {      
      flex-direction: row;
    }
  }

  &__subnavigation {
    display: none;
    .content-page-promo-subnav {
      padding: 0;
    }
    @include desktopOnly {
      display: block;
      @include spans-columns(3);
      padding-left: 0 !important;
    }
  }

  &__content {
    @include spans-columns(12);
    padding: 0;

    @include desktopOnly {
      display: block;
      @include spans-columns(9);
      padding-right: 0 !important;
    }

    .Related_Content_Section {
      padding: 40px 0;
    }
  }

  &__references {
    padding-bottom: 40px;
  }

  &__related-content {
    margin-bottom: 40px;
  }

  // expert video selector (element has been created in Acoustic for this but its not used anywhere yet)
  &__video-selector {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__video-selector-h3 {
    font-family: $subtitle-font;
    font-size: 25px;
    line-height: 28px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 30px;
  }
  .footer-subcontent-section  {
    margin-bottom: 40px;
  }
}