.timeIcon-section{
    display: flex;
    justify-items: center;
    align-items: center;
    @media (max-width: 1024px) { 
        margin-right: 10px;
    }
}
.time-icon{
    width:24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.text-time{
    padding-top: 4px;
    padding-left: 8px;
}

.desktop {
    @media (max-width: 600px) {
        display: none;
    }
}