@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.content-gate {
  position: absolute;
  bottom: 0;
  z-index: 1003;
  width: 100%;
  padding: 24px 0;
  background-color: #fff;

  &__overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc(100% - 80px);
    z-index: 1003;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    svg#svgBlur {
      width: 0;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }

    img.blur {
      width: 100%;
      filter: url(#blur);
    }
  }

  &__title {
    font-family: $hindMadurai-sb;
    color: $kk-primary;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    margin: 0 0 16px;
    padding: 0 8px;
  }

  &__text {
    color: $body-text-color;
    font-size: 18px;
    line-height: 20px;
    font-weight: normal;
    margin: 0 0 10px;
    padding: 0 8px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
  }

  &__cta {
    &-wrapper {
      width: 100%;
      margin: 16px 0 0;
      max-width: 310px;
      display: flex;
      flex-direction: column;
      padding: 0 8px;

      &>.chevron-button-component {
        text-align: left;
        margin-bottom: 8px;
        width: 100%;
        height: 44px;
      }
    }

    &-smallprint {
      font-size: 10px;
      line-height: 12px;
      font-family: $hindMadurai-reg;
      margin: 0;
      padding: 0 5px;
    }

  }


}

@include screen-width-desktop {
  .content-gate {
    padding: 40px 0;

    &__title {
      font-size: 60px;
      font-weight: 400;
      line-height: 72px;
      margin: 0 0 24px;
      padding: 0;
    }

    &__text {
      margin: 0 0 44px;
      padding: 0;
    }

    &__footer {
      flex-direction: row;
    }

    &__cta {
      &-wrapper {
        margin: 0;
        padding: 0;
        max-width: 395px;

        &:first-of-type {
          margin-right: 24px;
        }
      }

      &-smallprint {
        font-size: 12px;
        line-height: 15px;
        padding: 0;
      }

    }
  }
}