@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_videoFigure.scss';
@import '../../assets/mixins.scss';

.header {
    
    &__outer {
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: $kk-primary-light;

        @include screen-width-small() {
            flex-direction: column;
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

    &__inner {
        margin-top: 15px;
        @include grid-row();

        @include screen-width-small {
            flex-direction: column;
        }
    }

    &__column {
        @include spans-columns(6);
        @include spans-columns-small(4);
        // Rich text can return any number of different elements so :first-child is not a solution for "all elements should have Npx
        // between them". However if we give all elements a margin-top of 20px and then just hoist up the .column by 20px, then the
        // extra spacing from the first element is canceled out.
        $space-between-text: 20px;

        p {
            // p from rich text
            font-size: 20px;
        }

        
    }

    &__title {
        margin: 0;
        font-size: 40px;
        line-height: 48px;
        color: $kk-primary;
        margin-bottom: 30px;
        font-weight: normal;
        @include tabletMin {
            font-size: 60px;
            line-height: 72px;
        }

        h1, h2, h3 {
            font-size: inherit;
            margin: 0 0 5px;
        }

        p {
            line-height: initial;
            padding-bottom: 30px;
        }
    }

    &__subtitle {
        margin-top: 0;
        font-size: 35px;
        color: $kk-primary;
    }

    &__text {
        font-size: 20px;
        line-height: 25px;
        color: $body-text-color;

        a {
            &:visited {
                color: white;
            }
        }
    }

    &__media-container {
        width: 100%;
        padding-top: 20px;

        .video {
            padding: 0;
            margin: 0;
            width: 100%;
            border-radius: 30px;
            overflow: hidden;
            line-height: 0;

            video {
                width: 100%;
                margin: 0;
            }
        }

        @include desktopOnly {
            width: 80%;
            float: right;
        }
    }

    &__figure {
        padding: 0;
        margin: 0;
        width: 100%;
        border-radius: 30px;
        overflow: hidden;
        line-height: 0;

        img {
            width: 100%;
            margin: 0;
        }
    }
}