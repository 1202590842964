@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.images-panel-pdf {
  margin-top: 40px;
  margin-bottom: 60px;
  @media (min-width: 0) and (max-width: 740px) {
    margin-bottom: 40px;
  }

  &__title {
    h4 {
      margin: 0;
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0px;
      text-align: left;
      margin-bottom: 3px;

      @media (min-width: 0) and (max-width: 740px) {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
      }
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    @media (min-width: 0) and (max-width: 740px) {
      flex-direction: column;
    }

    &__card {
      width: calc(50% - 10px);
      @media (min-width: 0) and (max-width: 740px) {
        width: 100%;
      }
      img {
        width: 100%;
        margin-top: 20px;
        border-radius: 15px;
      }

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        margin: 0;
        margin-top: 10px;
      }

      a {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
  }
}
