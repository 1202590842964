@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.imageWithBullets{
    @media only screen and (min-width:1024px) {
    display: flex;
    }
}

.hide {
   opacity:0;
}
.show {
    opacity:100;
}
.btn__click-more-image{
    width: 600px;
    height: 78px;
    text-align: center;
    background-color: #e07a5f;
    border-radius: 50px;
    color: #fff;
    font-size: 20px;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    margin: 60px 0;
    box-shadow: 0 3px 6px #00000029;
    @include screen-width-small {
        width: 100%;
        font-size: 16px;
        margin: 20px 0;
    }

}
.imageGirl{
    @media only screen and (max-width:768px) {
      background-image : none !important;
    }
}