@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.mdt-summary-section {
    background-color: #F4F1DE;
    padding-bottom: 8%;

    .container {
        .qof {
            margin-bottom: 80px;
            margin-top: 55px;
            @include screen-width-small {
                margin-top: 35px;
                margin-bottom: 40px;
            }
        }
        .mdt-summary-section__inner {
            padding: 65px 0 30px;
            .mdt-summary-section__title {
                // font: normal normal normal 30px/36px Prata-Regular;
                font-weight: normal;
                margin-top: 0;
                color: #3D3F5B;
                &::after {
                    content: '';
                    width: 50px;
                    height: 1px;
                    background-color: #3D3F5B;
                    display: block;
                    margin: 60px 0;
                }
            }
        }
        > .row {
            @include grid-row;
            margin-bottom: 20px;
            @include screen-width-small {
                flex-direction: column;
            }
            @include screen-width-medium {
                flex-direction: column;
            }
            .mdt-summary-section-content__itemWrapper {
                @include spans-columns(4);
                border-radius: 20px;
                @include screen-width-small {
                    @include spans-columns(12);
                    margin-bottom: 16px;
                }
                @include screen-width-medium {
                    @include spans-columns(12);
                    margin-bottom: 16px;
                }
                &.expand {
                    @include spans-columns(8); 
                    @include screen-width-small {
                        @include spans-columns(12);
                        margin-bottom: 16px;
                    }
                    @include screen-width-medium {
                        @include spans-columns(12);
                        margin-bottom: 16px;
                    }
                }
                &.primary-blue {
                    .mdt-summary-section-content__item {
                        background-color: #3D3F5B;
                    }
                }
                &.primary-orange {
                    .mdt-summary-section-content__item {
                        background-color: #E07A5F;
                    }
                }
                &.primary-yellow {
                    .mdt-summary-section-content__item {
                        background-color: #F2CC8F;
                    }
                }
                .mdt-summary-section-content__item {
                    border-radius: 20px;
                    padding: 52px 27px;
                    color: #ffffff;
                    > .row {
                        @include grid-row;
                        margin-bottom: 20px;
                        @include screen-width-small {
                            flex-direction: column;
                        }
                        .mdt-summary-section-content-item__subItem {
                            display: flex;
                            @include spans-columns(12);
                            border-radius: 20px;
                            @include screen-width-small {
                                @include spans-columns(12);
                                margin-bottom: 16px;
                            }
                            p {
                                font-size: 20px;
                                color: #ffffff;
                                line-height: 30px;
                                margin: 0;
                                display: inline-block;
                                @include screen-width-small {
                                    br {
                                        display: none;
                                    }
                                }
                                @include screen-width-medium {
                                    br {
                                        display: none;
                                    }
                                }
                            }
                            &::before {
                                content: "•";
                                color: #ffffff;
                                font-size: 50px;
                                margin: -18px 20px 0;
                                @include screen-width-small {
                                    margin: -18px 10px 0 0;
                                }
                            }
                            &.expand {
                                @include spans-columns(6); 
                            }
                            &.bullet {
                                &::before {
                                    content: "•" !important;
                                    color: #ffffff;
                                    font-size: 50px;
                                    margin: -18px 20px 0;
                                    @include screen-width-small {
                                        margin: -18px 10px 0 0;
                                    }
                                }
                            }
                        }
                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.mdt-summary-footer {
    padding-top: 30px;
    background-color: #5D6164;

    p {
        color: #FFFFFF;
    }
}