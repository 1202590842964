@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import '../../assets/mixins.scss';

.SideNavigation {
    display: none;
    width: 100%;

    @include desktopOnly {
        display: block;
    }

    &_Item {
        display: block;
        font-size: 16px;
        color: #333333;
        text-decoration: none;
        margin-bottom: 20px;
        line-height: 19px;

        &--level1 {
            font-size: 20px;
            line-height: 24px;
            font-weight: 400;
        }

        &--level2 {
            display: block;
            font-weight: 400;
            padding-left: 40px;
        }

        &--level3 {
            display: block;
            padding-left: 60px;
        }

        &--active {
            color: $kk-primary;
        }
    }


    &--external {
        display: flex;
        align-items: center;
        
        svg {
            margin-left: 12px;
        }
    }

}