@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/modal.scss';
@import 'assets/mixins.scss';

.media-modal {
  .modal {
    &-content {
      padding: 25px;
      border-radius: 30px;
    }
    &-header {
      padding: 0;
      border-bottom: none;
      height: 25px;

      .close {
        font-family: $hindMadurai-reg;
        font-size: 50px;
        line-height: 1;
        color: $kk-primary;
        border: none;
        background-color: white;
        padding: 0;
        margin: -1rem 0rem -1rem auto;
        .sr-only {
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0,0,0,0);
          white-space: nowrap;
          border: 0;
        }
      }
    }

    &-body {
      padding: 0;
      &__section {
        padding: 25px 0 0;
        margin: 0;

        @include desktopOnly {
          padding: 35px;
        }
        .video {
          margin: 0
        }
      }
    }
  }
}
