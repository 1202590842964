@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.my-hub-form {
  .my-hub-divider1 {
    width: 100%;
    border: 1px dashed rgba(234, 85, 4, 0.5);
    margin-bottom: 40px;
    @include screen-width-small {
      margin-bottom: 30px;
    }
  }

  .withdraw-consent-component {
    margin-bottom: 30px;
  }
}
