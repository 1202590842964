@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.onOffCallOut__state {
    position: relative;
    margin-top: 30px;
    text-align: center;
    padding:10px 5px;
    border: 1px solid #FFFFFF;
    border-radius: 15px;
    @include screen-width-medium {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    @media only screen and (min-width: 1025px) and (max-width: 1199px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &.floatRightCircle {
        .deco {
            @media only screen and (min-width: 1200px) {
                right: 5px;
            }
        }
    }

    &.floatLeftCircle {
        .deco {
            @media only screen and (min-width: 1200px) {
                left: 5px;
            }
        }
    }

    @media only screen and (min-width: 1200px) {
        display: flex;
        padding:5px 5px;
        align-items: center;
        justify-content: space-between;
        border-radius: 50px;
    }

    .state {
        width: 20%;
        padding-bottom:10px;
        font-size: 32px;
        font-weight: bold;
        text-transform: uppercase;

        @media only screen and (min-width: 1200px) {
            padding-top: 4px;
            padding-left: 75px;
        }
        @include screen-width-small {
            width: 100%;
            text-align: center;
        }
        @include screen-width-medium {
            width: 30%;
            text-align: center;
        }
        @media only screen and (min-width: 1025px) and (min-width: 1199px) {
            width: 30%;
            text-align: center;
        }
    }

    .desc {
        width: 80%;
        font-size: 20px;
        text-align: left;
        .RichText {
            * {
                padding: 0;
                margin: 0;
            }
        }
        @media only screen and (min-width: 1200px) {
            margin-left: 60px;
            margin-right: 40px;
        }
        @include screen-width-small {
            width: 100%;
            text-align: center;
        }
        @include screen-width-medium {
            width: 70%;
            text-align: left;
        }
        @media only screen and (min-width: 1025px) and (max-width: 1199px) {
            width: 70%;
            text-align: left;
        }
    }

    .deco {
        @media only screen and (min-width: 1200px) {
            position: absolute;
            -webkit-transform: translateY(0%);
            transform: translateY(0%);
            width: 50px;
            height: 50px;
            background-color: #FFFFFF;
            border-radius:50%;
        }
    }
}

.onOffCallOut__state-highlight {
    width: 100%;
    margin: 30px 0;
    height: 90px;
    padding-top: 12px;
    font-size: 20px;
    text-align: center;
    background-color: #E07A5F;
    border:1px solid #FFFFFF;
    border-radius: 15px;

    @media only screen and (min-width: 768px) {
        display: flex;
        height: 60px;
        padding-top: 0;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
    }

    &.accordion-bottom {
        margin: 10px 0 20px 0;
        border:none;
        color: #FFFFFF;
        /*position: absolute;
        width: 101%;
        top: -30px;
        left: -5px;*/
    }

    &.accordion-overlay {
        position: absolute;
        top: -38px;
        left: 0px;
    }
}