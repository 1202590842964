.colum_section{
    display: flex;
    flex-direction: row;
    margin-bottom:20px;
    @media only screen and (max-width: 1024px) { 
        flex-direction: column;
    }

    &.colum_section--hp{
        .twoColumn_section-first:first-child{
            margin-left: 0;
        }
    
        .twoColumn_section-first:last-child{
            margin-right: 0;
        }
    
        .twoColumn_section-second{
            margin-right: 0;
        }
    
        .threeColumn_section-first{
            margin-left: 0;
        }
    
        .threeColumn_section-third{
            margin-right: 0;
        }
    }
}
.twoColumn_section-first{
    @media only screen and (min-width: 768px) { 
        width:50%;
        margin: 16px;
    }
    margin:16px 0;
}
.twoColumn_section-second{
    @media only screen and (min-width: 768px) { 
        width:50%;
        margin: 16px;
    }
    margin:16px 0;
}

.threeColumn_section-first {
    width:33.3%;
    margin: 16px;
    @media only screen and (max-width: 1024px) { 
        width:100%;
        margin:16px 0;
    }
}

.threeColumn_section-second {
    width:33.3%;
    margin: 16px;
    @media only screen and (max-width: 1024px) { 
        width:100%;
        margin:16px 0;
    }
}

.threeColumn_section-third {
    width:33.3%;
    margin: 16px;
    @media only screen and (max-width: 1024px) { 
        width:100%;
        margin:16px 0;
    }
}

.one-column-left-sm{
    @media only screen and (min-width: 768px) { 
        width:40%;
        margin: 16px;
    }
    margin:16px 0;
}
.one-column-right-wide{
    @media only screen and (min-width: 768px) { 
        width:70%;
        margin: 16px;
    }
    margin-bottom:16px 0;
    img{
        display: block;
        margin:0 auto;
    }
}
.one-column-right-sm{
    @media only screen and (min-width: 768px) { 
        width:40%;
        margin: 16px 0 16px 16px;
    }
    margin:16px;
}
.one-column-left-wide{
    @media only screen and (min-width: 768px) { 
        width:70%;
        margin-right:15px;
        margin: 16px 16px 16px 0;
    }
    img{
        display: block;
        margin:0 auto;
    }
    margin:16px 0;
}

.colum_section.fusion.colum_section--two-column.colum_section--hp.colum_section--columnwise-mobile {
    flex-direction: column !important;
}

.column_section--two-fusion{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
        width: 49%;
        display: flex;
    }
}

.app-layout--v2 {
    @media (max-width: 923px) {
  
        .colum_section--columnwise-mobile {
            flex-direction: row;
        
            .twoColumn_section-first {
                margin-right: 10px!important;
            }
    
            .Card {
                box-shadow: none;
            }
            .CardImage {
                border-radius: 5px;
            }
            .CardHeading {
                font-size: 16px;
                font-weight: 600;
                line-height: 30px;
                margin-top: 0!important;
            }
            .CardContent {
                padding: 10px 10px 0 0;
    
                .CardLink {
                    margin-top: 10px;
                    width: 100%;
                    font-size: 16px!important;
                    font-weight: 600!important;
                    line-height: 20px!important;
    
                    svg {
                        width: 10px;
                        height: 19px!important;
                    }
                }
            }
        }
    }
}