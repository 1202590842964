@import '../../../../assets/kanso/styles/global.scss';

.callOutQuote {
  font-family: var(--base-font-family);
  font-size: var(--cq-font-size, var(--base-font-size));
  font-weight: var(--cq-font-weight, var(--base-font-weight));
  line-height: var(--cq-line-weight, var(--base-line-height));
  color: var(--cq-text-color, var(--base-font-color));
  margin: 0;
  
  svg {
    fill: var(--cq-quote-image-color, #C3C4CA);
    width: 56px;

    @include media(medium,large) {
      width: 72px;
    }
    @include media(extraLarge,extraExtraLarge) {
      svg {
        width: 80px;
      }
    }
  }

  hr {
    margin: 4px 0 11px;
    background-color: var(--cq-divider-color, #C3C4CA);

    @include media(medium,large,extraLarge,extraExtraLarge) {
      margin: 19px 0 11px;
    }
  }

  blockquote {
    font-size: var(--cq-quote-font-size, 24px);
    font-weight: var(--cq-quote-font-weight, 600);
    line-height:  var(--cq-quote-line-height, 38.4px);
    color: var(--cq-quote-text-color, #EA5724);
    margin: 0 0 28px;
  }
}