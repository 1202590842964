@import 'style/variables.scss';
@import '../../assets/mixins.scss';

.Search_Results {
    .title-section-inner {
        p {
            font-size: 20px;
            font-weight: 400;
            line-height: 48px;
            margin: 0px 0 0;
            font-family: $title-font-v2;
    
            @include tabletMin {
                font-size: 40px;
                margin-top: 20px;
            }
    
            .emphasisedText { 
                display: inline-block;
                margin-left: 7px;
                font-weight: 600;
                text-transform: capitalize;
                font-family: $hindMadurai-reg;
            }
        }
    }
}


.Results-Text {
    margin: 20px 0 30px;
}

.No-Results {
    font-size: 60px;
    font-weight: 400;
    line-height: 48px;
    margin: 50px 0;
    font-family: $title-font-v2;
    color: #EA5504;
}

.Pagination {
    display: flex;

    nav {
        margin: 0 auto 20px; 

        .MuiPaginationItem-textPrimary.Mui-selected {
            background-color: #EA5504;
        }
        .MuiPaginationItem-icon {
            color: #EA5504;
        }
    }

}

