@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';


.collapsible-details.references {
  margin-bottom: 30px;
  button {
    border-radius: 5px;
    background-color: $button-grey;
    color: white;
    font-family: $section-title-font;
  }
  .contents {
    background-color: #F0EFEF;
    padding: 30px 50px;
    ol {
      margin: 0;
      padding: 0px;
      li {
        margin-bottom: 16px;
        padding: 0 0 0 15px;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
