@import 'style/variables.scss';

.form-component {
  .mandatory-key {
    margin: 0px 0px 30px 0px;
    font-family: $hindMadurai-sb;
    font-size: 16px;
    line-height: 14px;
    /* identical to box height */
    color: #EA5504;
  }
}



