@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_titleWithIcon.scss';


section[role="main"].article-page-component {
  .article-content-list-container {
    background-color: $kk-secondary;
    border-radius: 5px;
    overflow: hidden;
    // contents list dissapears on mobile
    @include screen-width-mobile {
      display: none;
    }
    .article-content-list-title {
      @include title-with-icon;
      font-family: $subtitle-font;
      padding: 16px 20px;
      font-size: 16px;
      font-weight: 600;
      border-bottom: 1px solid white;
    }
    .article-content-list {
      margin: 0;
      padding: 0;
      li {
        padding: 12px 20px;
        border-bottom: solid 1px white;
        a {
          color: #777;
        }
      }
    }
  }
}

