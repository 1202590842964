.tool-tip__wrapper {
    position: relative;
    width: 100%;
    .tool-tip__close {
        float: right;
        cursor: pointer;
        position: absolute;
        right: -25px;
        top: 5px;
        img {
            width: 28px;
            height: 28px;
        }
    }
    .tool-tip__content {
        vertical-align: middle;
        font-size: 16px;
        color: #3D3F5B;
        line-height: 24px;
        margin-right: 16px;
        &::-webkit-scrollbar {
            width: 10px;
            margin-right: 15px;
        }
        &.activeScrollBar {
            margin: 15px 10px 15px 0;
            height: 165px;
            overflow-y: scroll;
            /* Track */
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px rgb(255, 253, 253); 
                border-radius: 20px;
                background-color: #ffff;
                border: 2px solid #3D3F5B;
            }
            
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #3D3F5B; 
                border-radius: 20px;
                border:1px;
            }
            & {
                scrollbar-face-color: #3D3F5B;
                scrollbar-track-color: #ffff;
            }
        }
        p {
            margin: 25px 16px 16px 0;
        }
        .tool-tip__content-cta {
            width: 50%;
            margin-bottom: 16px;
            button {
                margin-top: 10px;
                display: inline-block;
                width: 100%;
                padding: 6px 10px;
                font-size: 14px;
                color: #3D3F5B;
                text-align: center;
                text-decoration: none;
                background-color: transparent;
                border:1px solid #3D3F5B; 
                border-radius: 25px;
                cursor: pointer;
                transition: opacity 0.5s ease-in-out;
                opacity: 1;
    
                &:hover {
                    opacity: 0.6;
                } 
    
                span {
                    display: block;
                }
            }
        }
    }
}
.callout_Bullet_Image{
    margin-bottom:40px;
}