@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.accordion-item {
    overflow: hidden;
    transition: max-height 0.6s cubic-bezier(1, 0, 1, 0);
    -ms-transform: max-height 0.6s cubic-bezier(1, 0, 1, 0);
    -moz-transform: max-height 0.6s cubic-bezier(1, 0, 1, 0);
    -webkit-transform: max-height 0.6s cubic-bezier(1, 0, 1, 0);
    -o-transform: max-height 0.6s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
    margin-bottom: 26px;
}
  
.accordion-item {
    &.collapsed {
        max-height: 0;
        transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);
        -moz-transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);
        -webkit-transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);
        -o-transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);
        -ms-transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);
    }
}
  
.accordion-title {
    min-height: 77px;
    font-weight: normal;
    font-size: 25px;
    cursor: pointer;
    color: #ffffff;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    background-color: #E07A5F;
    border-radius: 5px;
    overflow: hidden;
    transition: all 1s ease;
    -moz-transition: all 1s ease;
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    @include screen-width-small {
        min-height: 100px;
        padding: 0 15px;
        font-size: 16px;
    }
    @include screen-width-medium {
        font-size: 24px;
    }
    .accordion-title-inner_wrapper {
        display: flex;
        min-height: 77px;
        align-items: center;
        width: 96%;
        border-right: 2px solid #ffffff;
        @include screen-width-small {
            width: 88%;
            min-height: 100px;
        }
        @include screen-width-medium {
            width: 93%;
        }
    }
    .accordion-icon__wrapper {
        display: flex;
        align-items: center;
    }
    &.open {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease; 
        img {
            transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
        }
    }
    img {
        width: 25px;
        height: 25px;
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
    }
}
  
.accordion-content {
    padding: 40px 30px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-top: -10px;
    border: 5px solid #E07A5F;
    .survey-title {
        color: #3D3F5B;
        margin-bottom: 40px;
    }

    &.no-bottom-margin {
        margin-bottom: 0;
    }
    
    @include screen-width-small {
        padding: 15px;
    }
    .accordion-content__title {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 24px;
    }
    .accordion-content__desc {
        font-weight: normal;
        font-size: 16px;
        margin-bottom: 40px;
    }
    .bar-chart__wrapper {
        .bar-chart__item {
            display: flex;
            background-color: #FFFFFF3C;
            flex-direction: column;
            > p {
                text-align: center;
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 16px;
                margin-top: 32x;
            }
            > img {
                width: 60%;
                height: auto;
                margin: 20px auto 40px;
                @include screen-width-small {
                    width: 100%
                }
            }
            &:first-child {
                > img {
                    margin: 60px auto 40px;
                    @include screen-width-small {
                        margin: 20px auto 40px;
                    }
                }
            }
        }
    }
    .row {
        @include grid-row;
        @include screen-width-small {
            flex-direction: column;
        }
        .accordion-content-percentage__item {
            @include spans-columns(4);
            display: flex;
            @include screen-width-small {
                @include spans-columns(12);
                margin-bottom: 15px;
            }
            .arrow-down-shape {
                width: 218px;
                height: 218px;
                color: #3D3F5B;
                display: flex;
                justify-content: center;
                font-size: 76px;
                img {
                    width: 100%;
                }
                span {
                    margin-top: 38px;
                }
            }
            .arrow-down-shape__description {
                width: 115px;
                font-size: 16px;
                font-weight: normal;
                line-height: 25px;
                margin-top: -10px;
                span {
                    font-size: 12px;
                }
            }
        }

    }
    .accordion-content-outcomes__wrapper {
        padding: 40px 30px;
        margin-top: 16px;
        background-color: #F2CC8F;
        border-radius: 20px;
        color: #3D3F5B;
        font-weight: normal;
        font-size: 20px;
        p {
            margin-top: 0;
            margin-bottom: 12px;
        }
        ul {
            list-style-type: square;
            margin-bottom: 0;
            margin-top: 0;
            padding-left: 20px;
            li {
                line-height: 30px;
            }
        }
    }
    .accordion-content-footer-text__wrapper {
        margin-top: 50px;
        @include screen-width-small {
            margin-top: 30px;
        }
    }
    .data-percentage__wrapper {
        display: flex;
        justify-content: center;
        flex-direction: row;
        @include screen-width-small {
            flex-direction: column;
        }
        .data-percentage__item {
            width: 300px;
            display: flex;
            flex-direction: column;
            .data-percentage-img__wrapper {
                width: 100%;
                min-height: 180px;
                > img {
                    width: 173px;
                    height: 173px;
                    margin: 0 auto;
                    display: block;
                    @include screen-width-medium {
                        width: 150px;
                    }
                }
            }
            p {
                font-size: 20px;
                color: #3D3F5B;
                font-weight: normal;
                text-align: center;
            }
        }
    }
    .audio_wrapper {
        min-height: 1700px;
        > * {
            display: none;
            @include screen-width-small {
                display: block;
            }
            @include screen-width-medium {
                display: block;
            }
        }
        @include screen-width-small {
            min-height: auto;
            display: block;
        }
        @include screen-width-medium {
            min-height: auto;
            display: block;
        }
        .text-card__wrapper {
            background-color: #3D3F5B;
            border-radius: 20px;
            margin-bottom: 20px;
            > h4 {
                color: #ffffff;
            }
        }
    }
}

.accordion-wrapper.theme-2  {
    .accordion-title {
        background-color: #F4F1DE;
        color: #3D3F5B;
        .accordion-title-inner_wrapper {
            border-right: 2px solid #3D3F5B;
        }
    }
    .accordion-item {
        .accordion-content {
            border: 5px solid #F4F1DE;
            h3 {
                font-size: 20px;
                line-height: 30px;
                margin: 0 0 40px;
            }
            .accordion-content__footerText {
                p {
                    font-size: 15px;
                    line-height: 30px;
                    font-weight: normal;
                    margin-bottom: 0;
                    margin-top: 40px;
                }
            }
        }
    }
}
.accordion-chart {
    display: grid;
    grid-template-columns: auto auto;
    margin-bottom: 15px;
    margin-left: -30px;
    margin-right: -30px;
    @media only screen and (max-width: 640px) {
        grid-template-columns: auto;
        margin-left: -15px;
        margin-right: -15px;
    }
}
