@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_videoFigure.scss';

// styles shared by both desktop and mobile version
@mixin video-button-list {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    margin-bottom: 5px;
    button {
      border: none;
      text-align: left;
      width: 100%;
      display: flex;
      align-items: center;
      .name-description {
        p {
          margin: 0;
          &.name {
            font-size: 20px;
            font-family: $subtitle-font;
          }
          &.description {
            font-size: 12px;
          }
        }
      }
      .chevron-container {
        margin-left: auto;
        margin-right: 12px;
        color: $button-grey;
      }
    }
  }
}

.video-selector {
  @include grid-row();
  .selection-controls {
    @include video-button-list();
    @include spans-columns(4);
    li {
      button {
        background-color: $background-grey;
        border-radius: 5px;
        padding: 5px;
        .avatar {
          width: 60px;
          height: 60px;
          border-radius: 5px;
          overflow: hidden;
          margin-right: 15px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
            filter: saturate(0%);
          }
        }
        .chevron-container {
          svg {
            height: 23px;
            width: 12px;
          }
        }
        &.selected {
          .avatar {
            img {
              filter: none;
            }
          }
        }
      }
    }
  }
  .video-container {
    @include spans-columns(8);
    .video {
      @include video-figure();
      box-shadow: $card-box-shadow;
    }
  }
}

.video-selector-mobile {
  .videos {
    @include video-button-list();
    li {
      background-color: $background-grey;
      border-radius: 5px;
      button {
        background-color: transparent;
        padding: 20px 20px 14px;
        .chevron-container {
          svg {
            width: 23px;
            height: 12px;
          }
        }
      }
      .video-container {
        padding: 0 20px 20px;
        .video {
          @include video-figure();
        }
      }
    }
  }
}