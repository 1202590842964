@import 'style/variables.scss';
@import '../../assets/mixins.scss';


.SearchResult {
    &_Holder {
        display: flex;
        flex-direction: row;
        padding: 20px;
        margin: 20px 0;
        border: solid 1px #979797;
    }

    &_Icon {
        padding-right: 15px;
        svg {
            color: #EA5504;
        }
    }

    &_Content {
        width: 85%;

        &_Heading {
            margin: 0 0 10px;
            padding: 0;
        }

        &_Link {
            color:#EA5504;
        }

        &_Text {
            span {
                text-transform: capitalize;
                display: inline-flex;
                background-color: #F9F4F2;
                padding: 5px;
                border-radius: 10px;
                font-family: $hindMadurai-sb;
                font-size: 16px;
            }
        }
    }
}