@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

$container-width: 100% !important;
.img-responsive {
    @media only screen and (max-width: 768px) { 
        width: 100%;
        height: auto;
    }
}

.infographics-diagram__wrapper {
    position: relative;
    color: #3D3F5B;
    overflow: hidden;

    .mdt-main-wrapper {
        margin: auto;
        @media only screen and (min-width: 1200px) { 
            width: $container-width;
        }

        .custom-popup {
            padding: 30px 30px 20px;
            background-color: white;
            border-radius: 15px;
            max-width: 500px;
            width: 100%;
            position: absolute;
            top: 50%;;
            z-index: 999;
            transform: translate(0, -50%);
            max-height: 80%;
            height: 290px;

            @include screen-width-mobile {
                left: calc(50% - 125px) !important;
                transform: none;
                top: 30px;
                width: 250px;
            }

            &__close {
                position: absolute;
                top: 6px;
                right: 6px;
                .close {
                    height: 25px;
                    width: 25px;
                    cursor: pointer;
                }
            }

            &__detail {
                height: 100%;
                overflow-y: auto;
                .title {
                    font-weight: 900;
                    margin-top: 0;
                    font-size: 20px;
                }

                p {
                    text-align: left;
                    font-size: 18px;
                    color: #3D3F5B;
                }
            }
        }
    }

    h2 {
        font-size: 30px;
        font-weight: normal;
        
        span {
            display: inline;

            @media only screen and (min-width: 1200px) { 
                display: block;
            }
        }

        &::after {
            content: '';
            display: block;
            margin-top: 36px;
            width: 50px;
            height: 1px;
            background-color: #3D3F5B;
        }
    }

    .subtitle {
        font-size: 27px;
        font-weight: normal;

        sup {
            font-size: 18px;
        }
    }

    .info-container {

        @media only screen and (min-width: 1200px) { 
            margin: 60px 0 30px 0;
        }
    }

    .captiontext {
        padding: 25px 0 25px 0;
        font-size: 15px;
    }
}

.infographics-diagram__infographics {
    position: relative;
    border-radius: 20px;
    // justify-content: center;
    // @media only screen and (min-width: 1200px) { 
    //     display: flex;
    // }
    

    .mdt__infographics-image-container1 {
        // text-align: center;
        color: #3D3F5B;
        display: flex;
        justify-content: center;

        &.image {
            &--left {
                justify-content: left;
            }
            &--right {
                justify-content: right;
            }
        }

        @media (min-width: 320px) and (max-width: 1024px){
            flex-direction: column;
            // text-align: left;
        }
        // @media only screen and (min-width: 1200px) { 
        //     // text-align:left;
        // }
        
        // img {
        //     @media only screen and (max-width: 1024px) { 
        //         width: 100%;
        //         height: auto;
        //     }
        // }

        .hide {
            width: 0;
            height: 0;
        }

        .caption-container1 {
            position: relative;
            display: flex;
            justify-content: center;
            // margin: auto;
            @media (min-width: 320px) and (max-width: 768px){
                width: 100%;
            }
            .caption-container-wrap {
                // display: flex;
                // // align-items: center;
                // justify-content: center;
                // width: 100%;
                // @media (min-width: 699px) and (max-width: 800px){
                //     height: 514px;
                // }
                // @media (min-width: 599px) and (max-width: 700px){
                //     height: 481px;
                // }
                // @media (min-width: 499px) and (max-width: 600px){
                //     height: 343px;
                // }
                // @media (min-width: 399px) and (max-width: 500px){
                //     height: 242px;
                // }
                // @media (min-width: 299px) and (max-width: 400px){
                //     height: 274px;
                // }
                .caption-spacer {
                    @media (max-width: 800px){
                        height: 750px;
                        width: 500px;
                    }
                    @media (min-width: 299px) and (max-width: 500px){
                        width: 1500px;
                    }
                }
                div {
                    // @include screen-width-mobile {
                        // transform: scale(0.6);
                        transform-origin: top left;
                    // }
                    // @media (min-width: 699px) and (max-width: 800px){
                    //     transform: scale(0.7);
                    // }
                    // @media (min-width: 599px) and (max-width: 700px){
                    //     transform: scale(0.6);
                    // }
                    // @media (min-width: 499px) and (max-width: 600px){
                    //     transform: scale(0.5);
                    // }
                    // @media (min-width: 399px) and (max-width: 500px){
                    //     transform: scale(0.4);
                    // }
                    // @media (min-width: 299px) and (max-width: 400px){
                    //     transform: scale(0.4);
                    // }
                }
            }

            > span {
                position: absolute;
                display: block;
                width: 54px;
                height: 54px;
                line-height: 54px;
                font-size: 35px;
                color: #FFFFFF;
                text-align: center;
                background-color: #E07A5F;
                border-radius: 50%;
                transition: opacity 0.5s ease-in-out;
                opacity: 1;
                cursor: pointer;
    
                &:hover {
                    opacity: 0.8;
                }
            }

            .timepopups {
                top: 20%;
                left: 10%;
                right: 10%;

                @media only screen and (max-width: 640px) { 
                    left: -3%;
                    width: 100%;

                    .timepopups__scroll {
                        width: 90%;
                    }
                }
            }
            .brain-pathology-year-img {
                position: absolute;
                left: -80px;
                top: 0;
                height: auto;
                @media only screen and (max-width: 640px) {
                    left: -22px;
                    top: -165px;
                    transform: scale(0.5);
                }
                @media  (min-width: 641px) and (max-width: 840px) {
                    left: -13px;
                    top: -20px;
                    transform: scale(0.75);
                }
            }
        }
    }

    .mdt__infographics-selections {
        padding: 30px 0 30px 35px;

        @media only screen and (max-width: 1024px) { 
            padding: 40px 0 30px 50px; 
        }

        @media only screen and (max-width: 500px) { 
            padding: 40px 0 30px 0; 
        }

        .checkbox-container__shape {
            width: 70px;
            height: 60px;
            margin-right: 20px;
            display: flex;
            flex:none;
            align-items: center;
            justify-content: center;

            .container-input {
                margin-top: -4px;
                padding-left: 19px;
                .checkmark {
                    &::after {
                        content: url('../../../../../assets/images/infographics-assets/tick-icon.svg');
                        top: -6px;
                        left: -3px;
                        background-color: transparent;
                    }
                }
            }

            &.bg-orange-1 {
                background-color: #EFBCAF;
            }

            &.bg-orange-2 {
                background-color: #E9A28F;
            }

            &.bg-orange-3 {
                background-color: #E45F4E;
            }

            &.bg-orange-4 {
                background-color: #E07A5F;
            }

            &.bg-blue {
                background-color: #3D3F5B;
            }

            &.bg-yellow {
                background-color: #F2CC8F;
            }

        }
    }
}


/* info text*/
#normal-text{
    @media only screen and (max-width:1024px){
        padding-bottom:65px;
    }
    p{
        font-family: $hindMadurai-reg;
    }
 }
 #bold-text{
    p{
     font-family: $hindMadurai-sb;
     }
 }
 .checkbox-container {
     display:flex;
 }
 .main-text{
     font-weight:700;
     .RichText {
         * {
             margin: 0;
             padding: 0;
         }
     }
 }
 .sub-text{
     display:block;
     padding-bottom:22px;
     .RichText {
        * {
            margin: 0;
            padding: 0;
        }
    }
 }
 .checkbox-text-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 60px;
    vertical-align: middle;
    text-align: left;
    // @media only screen and (min-width:1024px){
    //  margin-top:10px;
    // }
 }
 .full-screen{
    img{
        z-index: 0 !important;
    }
 }
 .timepopups__content-cta {
    text-align: center;
}

.btn-popups {
    display: inline-block;
    padding: 8px 15px;
    border: 2px solid #3D3F5B;
    border-radius: 20px;
    background-color: #FFFFFF;
    font-size: 16px;
    color: #3D3F5B !important;
    cursor: pointer;
    transition: opacity 0.5s ease-in-out;
    opacity: 1;

    &:hover {
        opacity: 0.6;
    }

    p {
        margin: 0;
        padding: 0 !important;
    }
}
sup {
    width:25px;
    height:25px;
    cursor: pointer;
    pointer-events:auto
}
.left{
    left: calc(50% - 250px);
}
.sm-left{
    left:0;
}









