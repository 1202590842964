@import 'style/mixins/_responsive.scss';
@import 'style/variables.scss';

.PersonalizedMessage {
  border: 1px solid $kk-primary;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 5;

  &_Content {
    max-width: 1440px;
    margin: 0 auto;
    padding: 10px 1em;
    display: flex;

    @include screen-width-small {
        flex-direction: column;
    }

    p {
      margin: 0;
      line-height: 25px;
      font-size: 18px;
    }

    .Guest {
      margin-left: auto;
      margin-top: auto;
      margin-bottom: auto;

      .UserStatus_CTA {
        color: $kk-primary;
        border: 2px $kk-primary solid;

        @include screen-width-small {
            margin-top: 10px;
        }
      }
    }
  }
}
