@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.app-layout--v1 {
  .paywall {
    width: 100%;
    padding: 24px;
  
    &__overlay {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: calc(100% - 135px);
      z-index: 1003;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      @media screen and (min-width: 768px) and (max-width: 935px){
        height: calc(100% - 180px);
      }
      @media screen and (min-width: 936px){
        height: calc(100% - 129px);
      }
      @media screen and (max-width: 350px){
        height: calc(100% - 135px);
      }
      
    }
  
    &__title {
      font-family: $myriad-reg;
      color: $kk-primary;
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
      margin: 0 0 16px;
      padding: 0 8px;
    }
  
    &__text {
      color: $body-text-color;
      font-size: 18px;
      line-height: 20px;
      font-weight: normal;
      margin: 0 0 10px;
      padding: 0 8px;
    }
  
    &__footer {
      display: flex;
      flex-direction: column;
    }
  
    &__cta {
      &-wrapper {
        width: 100%;
        margin: 16px 0 0;
        max-width: 310px;
        display: flex;
        flex-direction: column;
        padding: 0 8px;
  
        &>.chevron-button-component {
          text-align: left;
          margin-bottom: 8px;
          width: 100%;
          height: 44px;
        }
      }
  
      &-smallprint {
        font-size: 10px;
        line-height: 12px;
        font-family: $myriad-reg;
        margin: 0;
        padding: 0 5px;
      }
  
    }
  }
  @include screen-width-desktop {
    .paywall {
      padding: 40px;
      max-width: 1176px;
      &__title {
        font-size: 60px;
        font-weight: 400;
        line-height: 72px;
        margin: 0 0 24px;
        padding: 0;
      }
  
      &__text {
        margin: 0 0 44px;
        padding: 0;
      }
  
      &__footer {
        flex-direction: row;
      }
  
      &__cta {
        &-wrapper {
          margin: 0;
          padding: 0;
          max-width: 395px;
  
          &:first-of-type {
            margin-right: 24px;
          }
        }
  
        &-smallprint {
          font-size: 12px;
          line-height: 15px;
          padding: 0;
        }
  
      }
    }
  }
}
.app-layout--v2 {
  .paywall {
    width: 100%;
    padding: 24px;
  
    &__overlay {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: calc(100% - 135px);
      z-index: 1003;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      @media screen and (min-width: 768px) and (max-width: 935px){
        height: calc(100% - 180px);
      }
      @media screen and (min-width: 936px){
        height: calc(100% - 129px);
      }
      @media screen and (max-width: 350px){
        height: calc(100% - 135px);
      }
      
    }
  
    &__title {
      // font-family: $myriad-reg;
      font-family: $hindMadurai-sb;
      color: $kk-primary;
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
      margin: 0 0 16px;
      padding: 0 8px;
    }
  
    &__text {
      color: $body-text-color;
      font-size: 18px;
      line-height: 20px;
      font-weight: normal;
      margin: 0 0 10px;
      padding: 0 8px;
    }
  
    &__footer {
      display: flex;
      flex-direction: column;
    }
  
    &__cta {
      &-wrapper {
        width: 100%;
        margin: 16px 0 0;
        max-width: 310px;
        display: flex;
        flex-direction: column;
        padding: 0 8px;
  
        &>.chevron-button-component {
          text-align: left;
          margin-bottom: 8px;
          width: 100%;
          height: 44px;
        }
      }
  
      &-smallprint {
        font-size: 10px;
        line-height: 12px;
        font-family: $hindMadurai-reg;
        margin: 0;
        padding: 0 5px;
      }
  
    }
  }
  @include screen-width-desktop {
    .paywall {
      padding: 40px;
      max-width: 1176px;
      &__title {
        font-size: 60px;
        font-weight: 400;
        line-height: 72px;
        margin: 0 0 24px;
        padding: 0;
      }
  
      &__text {
        margin: 0 0 44px;
        padding: 0;
      }
  
      &__footer {
        flex-direction: row;
      }
  
      &__cta {
        &-wrapper {
          margin: 0;
          padding: 0;
          max-width: 395px;
  
          &:first-of-type {
            margin-right: 24px;
          }
        }
  
        &-smallprint {
          font-size: 12px;
          line-height: 15px;
          padding: 0;
        }
  
      }
    }
  }
}