/* The container */
.container-input {
	display: block;
	position: relative;
	padding-top: 2px;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 18px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.container-input input {
	position: absolute;
	opacity: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border:2px solid #3D3F5B;
	background-color: #FFFFFF;
}

.checkmark.notvalid {
	border: 2px solid red;
}

.radiobtn {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #F9F8EE;
	border:2px solid #40425D;
	border-radius: 50%;
	//box-shadow: 1px 4px 5px #210D4C;
	transition: 0.3s ease-in-out;
}

.radiobtn:after {
	content: "";
	position: absolute;
	display: none;
}

.radiobtn.notvalid {
	border: 4px solid red;
}

.container-input .radiobtn:after {
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
	border-radius: 50%;
	background: #E07A5F;

	/*left: 15px;
	top: 8px;
	width: 8px;
	height: 18px;
	border: solid #FFFFFF;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);*/
}

.container-input input:checked ~ .radiobtn {
	//border:2px solid #26B34B;
	background-color: #F9F8EE;
}

/* On mouse-over, add a grey background color */
.container-input:hover input ~ .checkmark,  .container-input:hover input ~ .radiobtn{
	//background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
/*.container-ipnut input:checked ~ .checkmark {
	background-color: #2196F3;
}*/

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container-input input:checked ~ .checkmark:after, .container-input input:checked ~ .radiobtn:after {
	display: block;
}

/* Style the checkmark/indicator */
.container-input .checkmark:after {
	/*left: 10px;
	top: 2px;
	width: 10px;
	height: 20px;
	border: solid #068209;
	border-width: 0 5px 5px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);*/

	content: '';
	top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
	background: #E07A5F;
}