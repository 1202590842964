@import 'style/variables.scss';
.text-card-logo{
    width:254px;
}
.logo-card-section{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
   
}
#textCardMoodle{

    flex-direction: column;
    @media only screen and (min-width:640px){
        flex-direction: row;
    }

    .bottom-section{
        flex-direction: row;
    }
    .learnMore-button-section{
        @media only screen and (min-width:640px){
            display: flex;
        }
       
    }
    .svg-course{
        padding-left:6px;
    }
    .text-card-logo{
      margin:unset;
    }
    .learn-more{
        padding-bottom: 10px;
        @media only screen and(min-width:640px){
            width: unset;
            padding-right: 100px;
        }
       
    } 
}
.logo-container{
    @media only screen and (min-width:640px){
        display:flex;
    }
}
#column-left{
    @media only screen and (max-width:1024px){
        width:0px;
    }
}
#moodleList{
    // .title-section-inner {
    //     padding-top:40px;
    // }
    .column-left{
        flex:none;
        max-width: 25%;
        word-break: break-word;
    }
    // .title-section-title{
    //     padding-bottom: 40px;
    // }
    .description{
        p{
            font-size:25px;
            word-break: break-word;
            line-height: 100%;
            font-family: $hindMadurai-reg;
            color: inherit;
            margin: 0 0 20px;
            font-weight: 400;
        }
      
    }
    .learn-more-text{
        text-transform: capitalize;
    }
    .text-card-title{
        @media only screen and(max-width:768px) {
            font-size:20px;
        }
    }
    .text-card{
        &:hover {
            .learn-more {
              text-decoration: none;
            }
        } 
    }
    .learnText{
        &:hover{
            text-decoration: underline; 
        }
    }
    .accessText{
        &:hover{
            text-decoration: underline; 
            color:#EA5504;
            cursor: pointer;
        }
    }
    .title-section-category{
        color:black;
    }
    .main-title-section.has-category .title-section-inner .title{
        width:100%;
    }
}

