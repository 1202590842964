@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.CustomisableImageCard {
    &.Card {
        align-items: inherit;
        padding: 24px;
        width: fit-content;
        max-width: 100%;

        .CardImage {
            margin-right: 24px;
            min-width: 192px;
            max-height: 192px;
            width: 192px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 8px;
            }

            @include screen-width-small {
                margin: 0 0 24px;
                max-width: 100%;
                max-height: 100%;
                height: auto;
                width: 100%;
            }
        }
        .CardContent {
            flex-basis: unset;

            .CardHeading {
                font-size: 25px;
                line-height: 40px;
                margin-bottom: 16px;
            }
            .CardDescription {
                line-height: 25.6px;
            }
            p {
                margin: 0;  
            }
        }

        @include screen-width-small {
            flex-direction: column;
        }
    }
}
.CustomisableImageCard--right {
    &.Card {
        flex-direction: row-reverse;

        .CardImage {
            margin: 0 0 0 24px;

            @include screen-width-small {
                margin: 0 0 24px 0;
            }
        }

        @include screen-width-small {
            flex-direction: column;
        }
    }
}