@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.module-section-page {
  a {
    color: inherit;
    text-decoration: none;
  }
  h2 {
    color: #333333;
    font-family: $subtitle-font;
    font-weight: 600;
    font-size: 25px;
    margin: 0 0 26px;
  }
  .category-divider {
    $category-dashed-border: 1px dashed $kk-primary;
    color: $kk-primary;
    display: flex;
    align-items: baseline;
    span {
      padding-right: 10px;
    }
    .line {
      display: block;
      border-bottom: $category-dashed-border;
      width: 100%;
    }
    @include screen-width-small {
      padding-bottom: 8px;
      border-bottom: $category-dashed-border;
      .line {
        display: none;
      }
    }
  }
}