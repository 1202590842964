.full-width-banner {
  display: block;
  margin: 40px 0;

  &__link {
    display: block;
    text-decoration: none;
  }

  &__image{
    display: block;
    max-width:100%;
    margin:0 auto;
  }

}