@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_linkPanelWithIntro.scss';

.app-layout--v1 {
  .homepage-collapsible-section {
    margin-top: 20px;
    margin-bottom: 40px;
    border-radius: 35px;
    background-color: white;
    .section-header {
      background: none;
      border: none;
      width: 100%;
      height: 75px;
      display: flex;
      align-items: center;
      .icon {
        width: 60px;
        height: 60px;
        flex-shrink: 0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          height: 30px;
          color: white;
        }
      }
      .title {
        font-size: 20px;
        margin-left: 30px;
        margin-right: 20px;
        text-align: left;
        @include screen-width-small {
          font-size: 16px;
          margin-left: 10px;
          
        }
      }
      .chevron {
        color: $kk-primary;
        margin-left: auto;
        margin-right: 30px;
        svg {
          height: 12px;
          width: 20px;
        }
      }
      &.open {
        .icon {
          background-color: $kk-primary;
        }
        .chevron {
          color: $kk-primary;
        }
      }
      &.closed {
        .icon {
          background-color: $button-grey;
        }
        .chevron {
          color: $button-grey;
        }
      }
    }
    .contents {
      @include link-panel-with-intro();
      padding: 40px 60px 20px;
      .links {
        li {
          button {
            color: $kk-primary;
          }
        }
      }
    }
  }
}
.app-layout--v2 {
  .homepage-collapsible-section {
    margin-top: 20px;
    margin-bottom: 20px;
    
    .section-header--re-skin {
      margin-top: 30px;
      margin-bottom: 20px;
      background-color: #FEF6F2;
    }
  
    .section-header {
      border: none;
      width: 100%;
      height: 75px;
      display: flex;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 20px;
      background-color: #FEF6F2;
  
      .icon {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        svg {
          height: 25px;
          color: white;
        }
        img {
          height: 25px;
          color: white;
        }
      }
      .title {
        font-size: 30px;
        font-weight: 600;
        line-height: 41px;
        letter-spacing: 0px;
        color: #EA5504;
        margin-left: 10px;
        font-family: $hindMadurai-sb;
        
        @include screen-width-small {
          font-size: 16px;
          margin-left: 10px;
          
        }
      }
      .chevron {
        color: $kk-primary;
        margin-left: auto;
        margin-right: 30px;
        svg {
          height: 12px;
          width: 20px;
        }
      }
      &.open {
        .icon {
          background-color: $kk-primary;
        }
        .chevron {
          color: $kk-primary;
        }
      }
      &.closed {
        .icon {
          background-color: $button-grey;
        }
        .chevron {
          color: $button-grey;
        }
      }
    }
    .contents {
      @include link-panel-with-intro();
      padding: 30px 75px 0 65px;
      background: #FFFFFF;
      border: 1px solid #EA5504;
      border-radius: 5px;
  
      @include screen-width-small {
        padding: 20px;
      }
  
      .title-description {
        h2 {
          font-size: 30px;
          font-weight: 700;
          line-height: 28px;
          @include screen-width-small {
            font-size: 25px;
          }
        }
  
        p {
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: left;
        }
      }
  
      .links {
        li {
          button {
            color: $kk-primary;
            font-family: $hindMadurai-sb;
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            letter-spacing: 1px;
          }
        }
      }
  
      p {
          font-family: $hindMadurai-reg;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0px;
          text-align: left;
      }
  
    }
  }
}