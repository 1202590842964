@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.tab-form-component {
  .form-tabs-component {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    .form-tab {
      display: block;
      width: 100%;
      height: 60px;
      background-color: #F5AA82;
      border-radius: 0px;
      border: 0px;
      margin: 0px 15px;
      box-shadow: inset 0px -35px 15px -30px rgba(35, 50, 59, 0.2);

      &-active {
        background-color: #FFFFFF;
        box-shadow: none;
      }

      &:first-child {
        border-radius: 30px 0px 0px 0px;
        margin: 0px 2px 0px 0px;
      }

      &:last-child {
        border-radius: 0px 30px 0px 0px;
        margin: 0px 0px 0px 2px;
      }

      p {
        display: block;
        width: 100%;
        font-family: $hindMadurai-reg;
        font-size: 25px;
        line-height: 29px;
        text-align: center;
        color: #333333;
        margin: 0px;
      }
    }
  }


  .tab-form-inner {
    background-color: #FFFFFF;
    overflow: hidden;
    padding-top: 20px;
    .form-p {
      display: block;
      width: 100%;
      font-family: $hindMadurai-reg;
      font-size: 16px;
      line-height: 21px;
      text-align: left;
      color: #5D6164;
      padding: 0px 30px 12px 30px;
      margin: 0px;
    }

    hr {
      height: 1px;
      background-color: #EA5504;
      margin: 0px 30px;
      padding: 0px;
      border: 0px;
    }
  }

}

.tab-form-component--new-fields {
  .form-component {
    background-color: #FAF6F4;
  }

  .form-tabs-component {
    .custom-focus-forced {
      background-color: rgba(250, 246, 244, 0.5);
    }

    .form-tab-active {
      background-color: #FAF6F4;
      opacity: 1;
    }
  }
}