@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_titleWithIcon.scss';
@import 'src/style/mixins/_videoFigure.scss';

.anchors-container {
  position: sticky;
  top: 20px;
  z-index: 2;
  &--sticky {
    top: 20px;

    .anchors__title {
      color: $kk-primary; 
      
      p {
        margin: 0;
      }
    }
  }
}

.anchors {
    background-color: $kk-secondary;
    border-radius: 5px;
    overflow: hidden;
    
    @include screen-width-mobile {
      // display: none;
      z-index: 1000;
      margin: 20px 22px 20px 22px;
      // position: relative;
      top: 0;
    }

    &__title {
      @include title-with-icon;
      font-family: $subtitle-font;
      padding: 20px;
      font-size: 16px;
      font-weight: 600;
      position: relative;
      border-bottom: 1px solid white;

      svg {
        min-width: 15px;
      }
      
      svg:last-child {
        position: absolute;
        right: 15px;
      }
    }
    &__list {
      margin: 0;
      padding: 0;
      li {
        padding: 10px 20px;
        border-bottom: solid 1px white;
        a {
          color: #777;
          cursor: pointer;
        }
        &.selected {
          color: #ec6820;
          display: flex;
          align-items: center;

          svg {
            min-width: 6px;
            max-width: 6px;
            margin-right: 8px;
          }
          a {
            display: inline-block;
            color: $kk-primary; 
          }
        }
      }
      .RichText p {
        margin: 0;
      }

      &--collapsed {
        display: none;
      }
    }

  }

