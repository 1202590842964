@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.module-section-card-container {
    @include grid-row();
    flex-flow: wrap;
    @include screen-width-mobile {
        flex-direction: column;
    }
    .module-section-card-item {
        @include spans-columns(4);
        @include spans-columns-small(4);
        text-decoration:none;
        .module-section-card {
            height: 410px;
            position: relative;
            margin-bottom: 30px;
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
            color: #ffffff;
            padding: 24px;
            @include screen-width-mobile {
                height: 300px;
            }
            .section-card-header {
                z-index: 3;
                width: 100%;
                display: flex;
                font-size: 26px;
                margin: 0;
                .title {
                    font-size: 26px;
                    margin-left: 16px;
                    text-align: left;
                    font-weight: 600;
                    word-break: break-word;
                    margin-top: 6px;
                    @include screen-width-small {
                      margin-left: 10px;
                      
                    }
                }
                .icon {
                    width: 40px;
                    height: 40px;
                    flex-shrink: 0;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #EA5504;
                    svg {
                      height: 18px;
                      color: white;
                    }
                }
            }
            .read-more {
                z-index: 3;
                a {
                    color: #ffffff;
                    text-decoration: none;
                    text-transform: uppercase;
                    display: flex;
                    height: 18px;
                    align-items: center;
                }
                svg {
                    margin-left: 24px;
                }
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
            }
            
        }
       
    }
    .custom-focus-therapy:hover {
        box-shadow: 0px;
        .module-section-card {
            &::after {
                background-color: rgba(234, 87, 13, 0.8);
                z-index:2;
            }
        }
       
    }
}