.info-container {
    margin-bottom: 25px;
    @media only screen and (min-width: 1200px) { 
        margin: 60px 0 30px 0;
    }

    &.active {
        position: relative;
        opacity: 1;
        cursor: pointer;
        transition: opacity 0.5s ease-in-out;

        &:hover {
           opacity: 0.8;
        }

        &::before {
            content: '';
            display: block;
            width: 22px;
            height: 22px;
            background: url('../../../../assets/images/infographics-assets/add-icon.svg') no-repeat;
            position: absolute;
            top: 20px;
            right: 10px;
        }
    }
}