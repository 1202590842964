@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.icon-text__item {
    text-align: center;
    padding: 15px;
    @include screen-width-small {
        margin-bottom: 15px;
    }
    .icon-text-item__imgWapper {
        width: 100%;
        min-height: 110px;
        img {
            margin: 0 auto;
        }
    }
    > .icon-text-item__text {
        font-weight: bold;
        font-size: 21px;
    }
}

.icon-text-popup__item {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    @include screen-width-small {
        margin-bottom: 16px;
    }
    .icon-text-popup__imgWrapper {
        width: 132px;
        height: 132px;
        display: flex;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
    }
    .label {
        color: #ffffff;
        font-size: 20px;
        font-weight: bold;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #3D3F5B;
        border-radius: 50px;
        margin: 20px auto;
        padding: 12px 18px;
        cursor: pointer;
        > .RichText {
            * {
                margin: 0;
                padding: 0;
            }
        }
    }

    .timepopups {
        top: -38%;
        left: 10%;

        @media only screen and (max-width: 640px) { 
            top: -38%;
            left: -26px;
        }
    }
}