@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'assets/mixins.scss';
@import 'src/style/mixins/_videoFigure.scss';



.app-layout {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // overflow-x: hidden;

  .global {
    &_layout {
      .brand_logo_section {
        padding: 15px 0;
      }
    }
  }

  .container {
    @include container-rules();

    &_inner {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include screen-width-mobile {
        flex-direction: column;
      }
    }
  }

  .content {
    &_wrapper {
      margin: 40px 0;

      &--columns-1 {}

      &--columns-2 {
        &-center {
          width: 100%;

          @include desktopOnly {
            margin: 40px 0 40px 40px;
            width: auto;
            flex: 8;

            &--stretch {
              flex: 10;
            }
          }
        }

        &-left {
          flex: 2;
        }

        &-right {
          flex: 2;

          &--shrink {
            flex: 0;
          }
        }

        &-right,
        &-left {
          display: none;

          @include desktopOnly {
            display: block;
          }
        }
      }

      &--columns-3 {
        &-center {
          width: 100%;

          @include screen-width-mobile {
            display: block;
            margin: 0;
            flex: none;
          }

          @include desktopOnly {
            margin: 40px;
            width: auto;
            flex: 7;
          }
        }

        &-left {
          flex: 2;
        }

        &-right {
          flex: 3;
        }

        &-right,
        &-left {
          display: none;

          @include screen-width-mobile {
            display: block;
            margin: 0;
            flex: none;
          }

          @include desktopOnly {
            display: block;
          }
        }
      }
    }

    &_item {
      margin-bottom: 40px;

      figure {
        &.video {
          @include video-figure();
        }
      }
    }
  }
}
.app-layout--v1 {
  font-family: "Myriad Pro Regular";
}
.app-layout--v2 {
  font-family: "Hind Madurai Regular";

  .content {
    &_item {
      margin-bottom: 40px;

      figure {
        &.video {
          border-radius: inherit;
        }
      }
    }
  }
}
.body--v1 {
  font-family: "Myriad Pro Regular";
}
.body--v2 {
  font-family: "Hind Madurai Regular";
}
.content-gate {
  @include zoomOut {
    bottom: 50% !important;
  }
}