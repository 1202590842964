@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

#mdt-section-members {
    // color:#333;
    .mdt-section-members__title {
        font-size: 30px;
        font-weight: normal;
        color: #E07A5F;
        padding: 95px 0 0;
        @include screen-width-small {
            padding: 48px 0 0;
        }
       
    }
    .mdt-section-members-content__wrapper {
        padding: 30px 20px;
        word-break:break-word;
        // background-color: #F7E0BC;
        border-radius: 20px;

        .row {
            @include grid-row;
            @include screen-width-small {
                flex-direction: column;
            }
            .mdt-section-members-content__item {
                @include spans-columns(4);
                @include screen-width-small {
                    @include spans-columns(12);
                    margin-bottom: 16px;
                }
                display: flex;
                p {
                    font-size: 20px;
                    color: #3D3F5B;
                    line-height: 30px;
                    margin: 0;
                }
            }
            .mdt-section-members-content__item.expand {
                @include spans-columns(8);
                @include screen-width-small {
                    @include spans-columns(12);
                }
            }
            &:first-child {
                margin-bottom: 40px;
                @include screen-width-small {
                    margin-bottom: 0;
                }
            }
        }
        @media only screen and (min-width:768px){
            display:flex;
            flex-direction:row;
        }
    }
    //for the title with reference
    .mdt-main-section__title{
        &::after {
            content: '';
            width: 50px;
            height: 1px;
            background-color: #E07A5F;
            display: block;
            margin: 60px 0 20px;
        }
    }
}
//To overide existing styling

.inColumn{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.one-column{
    display: flex;
    flex-direction: column !important;
}
.twoWidth{
    width:50% !important;
    @include screen-width-small{
       width: 100% !important;
    }
}
.containerTwoColumn{
    // width: 64%; //66.66667%
    @include screen-width-small {
        width: 100% !important;
    }
    @media only screen and (max-width: 768px) { 
        width: 100% !important;
    }

}
.containerOneColumn{
    // width: 33.333% !important;
    @include screen-width-small {
        width: 100% !important;
    }
    @media only screen and (max-width: 768px) { 
        width: 100% !important;
    }
}#calloutBullets{
    li{
        list-style: none;
        font-size: 20px;
        line-height: 30px;
        margin: 0;
        &::before {
            content: "•";
            // color: #000000;
            font-size: 50px;
            margin: -6px 20px 0;
            @include screen-width-small {
                margin: -6px 10px 0 0;
            }
        }
    }
    
}
.mdt-section-members-content__item{
    font-size: 20px;
    line-height: 30px;
    margin: 0;
    padding-bottom:5px;
    padding-left:25px;
    padding-right:25px;
    flex:1;
}
