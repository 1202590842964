@import '../../../../assets/kanso/styles/global.scss';

.audio {
  font-family: var(--base-font-family);
  font-size: var(--audio-font-size, var(--base-font-size));
  font-weight: var(--audio-font-weight, var(--base-font-weight));
  line-height: var(--audio-line-height, var(--base-line-height));
  color: var(--audio-text-color, var(--base-font-color));
}

.audio .audio-container {
  display: inline-grid;
  width: -webkit-fill-available;
  background-color: var(--audio-bgcolor, #f5f5f6);
  border-radius: var(--audio-border-radius, 8px);
  padding: 16px;
  margin: 0;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  justify-items: center;
  align-items: start;

  @include media(medium, large, extraLarge, extraExtraLarge) {
    grid-template-columns: 2.5fr 5.5fr;
    padding: 24px;
    grid-gap: 24px;
  }
  @include media(large) {
    grid-template-columns: 2fr 6fr;
  }
  @include media(extraLarge) {
    grid-template-columns: 2.5fr 9.5fr;
  }
  @include media(extraExtraLarge) {
    grid-template-columns: 2fr 10fr;
  }
}

.audio .audio-container.audio-container-list {
  border: none;
}

.audio .audio-container.audio-container-compact {
  grid-template-columns: 1fr;
}

.audio .audio-container .audio-image {
  position: relative;
  width: 100%;
  height: auto;
  --img-border-radius: var(--audio-image-border-radius, 8px);
  // --tertiary-btn-icon-color: var(--audio-image-icon-color, #fff);
  @include transition(background-color);
  img {
    aspect-ratio: var(--audio-image-aspect-ratio, 1/1);
    vertical-align: text-top;
  }
  .playIcon {
    position: absolute;
    top: 0;
    left: 0;
    z-index: auto;
    display: inline-block;
    width: 100%;
    height: 100%;
    a {
      display: inline-block;
      background-color: rgba(var(--audio-image-bgcolor, 0, 0, 0), var(--audio-image-opacity, 0.5));
      border-radius: var(--audio-image-border-radius, 8px);
      padding: 50%;
      svg {
        position: absolute;
        transform: translate(-10px, -10px);
        z-index: 10;
        margin: 0;
        padding: 0;
      }
      &:hover {
        background-color: rgba(var(--audio-image-hover-bgcolor, 0, 0, 0), var(--audio-image-hover-opacity, 0.3));
        // --tertiary-btn-hover-icon-color: var(--audio-image-icon-hover-color, #fff);
      }
      &:active {
        background-color: rgba(var(--audio-image-active-bgcolor, 0, 0, 0), var(--audio-image-active-opacity, 0.5));
        --tertiary-btn-active-icon-color: var(--audio-image-icon-active-color, #fff);
      }
    }
  }
}

.audio .audio-container .audio-player-container {
  display: flex;
  justify-content: space-between;

  input[type='range'] {
    appearance: none;
    -webkit-appearance: none;
    vertical-align: middle;
    // background-color: rgba(98, 103, 161, 0.4);
    // background-image: linear-gradient(rgba(98, 103, 161, 0.6), rgba(98, 103, 161, 0.6));
    background-color: rgba(221, 221, 221, 0.5);
    background-image: linear-gradient(rgba(221, 221, 221, 0.9), rgba(221, 221, 221, 0.9));
    background-repeat: no-repeat;
    height: 3px;
    border-radius: calc(var(--plyr-range-thumb-height, 13px) * 2);

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      background-color: var(--audio-plyr-control-color-hover, #ea5724);
      height: 12px;
      width: 12px;
      border: 0;
      border-radius: 50%;
    }

    &::-moz-range-thumb {
      appearance: none;
      background-color: var(--audio-plyr-control-color-hover, #ea5724);
      height: 12px;
      width: 12px;
      border: 0;
      border-radius: 50%;
    }
  }
}

.audio .audio-container .audio-player-container .audio-time {
  font-size: 13px;
  color: var(--audio-plyr-time-color, #4b4c53);
  font-weight: var(--plyr-font-weight-regular, 400);
  margin: 0 12px;

  @include media(extraSmall, small) {
    margin: 0 5px;
    span {
      display: none;
    }
  }
}

.audio .audio-container .audio-player-container {
  a {
    background-color: transparent;
  }
  svg {
    vertical-align: middle;
    width: 17px;
    height: 17px;
    fill: var(--audio-plyr-control-color, #333333);
  }
}

.audio .audio-container .audio-player-container .audio-progress-container {
  flex-grow: 1;

  input[type='range'] {
    width: 95%;

    @include media(extraSmall) {
      // width: 100px;
    }
  }
}

.audio .audio-container .audio-player-container .audio-volume {
  svg {
    margin: 0 10px;
  }
  input[type='range'] {
    width: 80px;

    @include media(extraSmall, small) {
      width: 50px;
    }
  }
}

.audio .audio-container .audio-player {
  width: 100%;
}

.audio .audio-container .plyrContainer {
  width: 100%;
  background-color: transparent;
}

.audio .audio-container .audio-label {
  margin: 0 0 2px;
  font-size: var(--audio-label-font-size, 14px);
  font-weight: var(--audio-label-font-weight, 300);
}

.audio .audio-container .headline {
  font-size: var(--audio-headline-font-size, 24px);
  font-weight: var(--audio-headline-font-weight, 600);
  line-height: var(--audio-headline-line-height, 38px);
  margin: 0 0 16px 0;
}

.audio .audio-transcript {
  margin: 12px 0 0;
  --tertiary-btn-font-size: var(--audio-transcript-font-size, 16px);
  --tertiary-btn-font-weight: var(--audio-transcript-font-weight, 700);
  --tertiary-btn-line-height: var(--audio-transcript-line-height, 19.2px);
  // --tertiary-btn-text-color: var(--audio-transcript-text-color, #6267a1);
  // --tertiary-btn-icon-color: var(--audio-transcript-icon-color, #6267a1);
  --tertiary-btn-text-decoration: var(--audio-transcript-text-decoration, none);
  --tertiary-btn-bgcolor: var(--audio-transcript-bgcolor, transparent);
  // --tertiary-btn-hover-text-color: var(--audio-transcript-hover-text-color, #6267a1);
  // --tertiary-btn-hover-icon-color: var(--audio-transcript-hover-icon-color, #6267a1);
  --tertiary-btn-hover-text-decoration: var(--audio-transcript-hover-text-decoration, underline);
  --tertiary-btn-hover-bgcolor: var(--audio-transcript-hover-bgcolor, transparent);
  // --tertiary-btn-active-text-color: var(--audio-transcript-active-text-color, #6267a1);
  --tertiary-btn-active-icon-color: var(--audio-transcript-active-icon-color, #6267a1);
  --tertiary-btn-active-text-decoration: var(--audio-transcript-active-text-decoration, underline);
  --tertiary-btn-active-bgcolor: var(--audio-transcript-active-bgcolor, #f5f5f6);
  --tertiary-btn-focus-text-color: var(--audio-transcript-focus-text-color, #4b4c53);
  --tertiary-btn-focus-icon-color: var(--audio-transcript-focus-icon-color, #4b4c53);
  --tertiary-btn-focus-text-decoration: var(--audio-transcript-focus-text-decoration, none);
  --tertiary-btn-focus-bgcolor: var(--audio-transcript-focus-bgcolor, #ffdd00);
  --tertiary-btn-disabled-text-color: var(--audio-transcript-disabled-text-color, #4b4c53);
  --tertiary-btn-disabled-icon-color: var(--audio-transcript-disabled-icon-color, #4b4c53);
  --tertiary-btn-disabled-text-decoration: var(--audio-transcript-disabled-text-decoration, none);
  --tertiary-btn-disabled-bgcolor: var(--audio-transcript-disabled-bgcolor, transparent);
}

.audio {
  [class*='plyr--audio'] {
    margin: 0 0 16px;
    [class*='plyr__controls'] {
      padding: 0;
      --plyr-color-main: var(--audio-plyr-bgcolor, #f5f5f6);
      --plyr-control-spacing: 8px;
      --plyr-control-radius: 0;
      --plyr-audio-controls-background: var(--audio-plyr-bgcolor, #f5f5f6);
      --plyr-audio-control-color: var(--audio-plyr-control-color, #6267a1);
      --plyr-audio-control-color-hover: var(--audio-plyr-control-color-hover, #6267a1);
      --plyr-audio-control-background-hover: transparent;
      --plyr-range-thumb-background: var(--audio-plyr-control-color-hover, #6267a1);
      --plyr-range-fill-background: rgba(98, 103, 161, 0.6);
      --plyr-audio-progress-buffered-background: rgba(98, 103, 161, 0.2);
      --plyr-audio-range-track-background: rgba(98, 103, 161, 0.4);
      --plyr-range-thumb-height: 12px;
      --plyr-range-track-height: 2px;
      --plyr-control-icon-size: 12px;
      .plyr__controls__item:first-of-type {
        padding-left: 0;
      }
      [class*='plyr__time'] {
        color: var(--audio-plyr-time-color, #4b4c53);
      }
    }
  }
}
