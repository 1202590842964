@import 'style/mixins/_responsive.scss';
@import 'style/variables';

.full-width-banner {
  // height:207px;
  // @media only screen and (min-width:768px){
  //     height: 512px;
  // }
  img {
    @media only screen and (min-width: 1024px) {
      height: 100%;
    }
  }
}

.webinar-banner {
  position: relative;

  &__container {
    height: 207px;
    position: relative;

    @media (min-width: $MIN_WIDTH_MEDIUM) {
      height: 512px;
    }
    .set-opacity {
      opacity: 0.7;
    }

    &__image {
      height: 100%;

      img {
        height: 207px;
        float: right;
        margin-right: -20%;

        @media (min-width: $MIN_WIDTH_MEDIUM) {
          height: 512px;
          margin-right: -50%;
        }

        @include screen-width-medium {
          margin-right: -25%;
        }

        @include screen-width-large {
          margin-right: 0;
        }

        @include screen-width-xl {
          width: 953px;
          margin-right: 0;
        }
      }
    }
  }

  &__text {
    position: absolute;
    top: 74px;
    width: 236px;
    left: 21px;

    @media (min-width: $MIN_WIDTH_MEDIUM) {
      top: 18%;
      width: 400px;
      left: 10%;
    }

    @include screen-width-large {
      top: 18%;
      width: 678px;
      left: 10%;
    }

    @include screen-width-xl {
      top: 18%;
      width: 678px;
      left: 10%;
    }

    h4 {
      font-family: $body-font;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 1px;
      text-align: left;
      margin: 0;
      text-transform: uppercase;

      @media (min-width: $MIN_WIDTH_MEDIUM) {
        font-size: 40px;
        line-height: 72px;
      }

      @include screen-width-large {
        font-size: 60px;
        line-height: 72px;
      }

      @include screen-width-xl {
        font-size: 60px;
        line-height: 72px;
      }
    }
  }
}
