.form-switch {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.form-switch--full-width {
  justify-content: space-between;
}

.form-switch__label {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-right: 24px;
}

[dir='rtl'] .form-switch__label {
  margin-right: 0;
  margin-left: 16px;
}

.form-switch__input {
  display: table;
  border-radius: 60px;
  position: relative;

  input {
    --input-background: #888995;
    &:checked {
      --input-background: #ea5504;
      & + svg {
        --default-s: 0;
        --default-x: 8px;
        --dot-s: 1;
        --dot-x: 0px;
      }
    }
    & + svg {
      --input-background: #888995;
      --default-s: 1;
      --default-x: 0px;
      --dot-s: 0;
      --dot-x: -8px;
    }
  }

  .default {
    transform-origin: 15px 15px;
    transform: translateX(var(--default-x)) scale(var(--default-s))
      translateZ(0);
  }

  .dot {
    transform-origin: 26px 15px;
    transform: translateX(var(--dot-x)) scale(var(--dot-s)) translateZ(0);
  }

  input {
    appearance: none;
    outline: none;
    border: none;
    background: var(--input-background, none);
    display: block;
    cursor: pointer;
    margin: 0;
    padding: 0;
    border-radius: inherit;
    width: 44px;
    height: 24px;
  }

  svg {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
    fill: #ffffff;
    transform: scale(1.01) translateZ(0);
  }
}

.form-switch__input:hover {
  input:checked {
    background: #ea5504;
  }
}

.form-switch--disabled {
  input {
    background: #ea5504;
    cursor: default;
  }

  .form-switch__input:hover {
    input:checked {
      background: #ea5504;
    }
  }
}
