.info-container {
    padding: 10px 30px;
    background-color: #F2CC8F;
    border-radius: 20px;

    p {
       font-size: 22px;
       color: #3D3F5B; 
       line-height: 33px;
    }
}