@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

/*
  FN: 16/10/2020
  At the time this was written it was literally extracted from the home page and componentised. The current styles are
  ver much so geared towards the home page so be careful if editing/refactor if needed.
*/



$home-page-title-width: 65%;
.app-layout--v1 {
  .page-hero {
    padding-top: 10px;
    h1{
      max-width: $home-page-title-width;
      font-family: $title-font;
      font-weight: 300;
      font-size: 60px;
      line-height: 100%;
      color: $kk-primary;
      @include screen-width-small {
        font-size: 40px;
      }
    }
    p {
      max-width: $home-page-title-width;
      min-width: 330px;
      font-size: 20px;
    }
  
    blockquote {
      max-width: $home-page-title-width;
      min-width: 330px;
      font-size: 20px;
      left: 0;
  
      @include screen-width-large {
        left: -15px;
        max-width: calc(65% + 40px);
      }
  
      @include screen-width-xl{
        left: -15px;
        max-width: calc(65% + 40px);
      }
  
      p {
        min-width: 0;
      }
    }
  }
}
.app-layout--v2 {
  .page-hero {
    padding-top: 10px;
    
    @media (min-width: 768px) {
      max-width: $home-page-title-width;
    }
    @include screen-width-large {
      max-width: $home-page-title-width;
    }
    @include screen-width-xl{
      max-width: $home-page-title-width;
    }
  
    h1{
      font-family: $title-font-v2;
      font-weight: 300;
      font-size: 60px;
      line-height: 80px;
      margin-bottom: 30px;
      margin-top: 64px;
      color: #333;
      @include screen-width-small {
        margin-top: 25px;
        line-height: 40px;
        margin-bottom: 30px;
        font-size: 40px;
      }
    }
    a {
      color: #EA5504;
    }
    p {
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
    }
  
    blockquote {
      min-width: 330px;
      font-size: 20px;
      left: 0;
  
      @include screen-width-large {
        left: -15px;
      }
  
      @include screen-width-xl{
        left: -15px;
      }
  
      p {
        min-width: 0;
      }
    }
  }
}