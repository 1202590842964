@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
.anonymous-form {
  .form-component {
    margin-bottom: 0;
    .form-field {
      label {
        margin-bottom: 8px;
      }

      .input-section {
        margin-bottom: 20px;
      }
    }
    input {
      background-color: white;
    }

    button {
      width: 245px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0.699999988079071px;
      text-align: left;
    }

    .consent-label {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0px;
      text-align: left;
      margin-top: 0;
      margin-bottom: 16px;
    }

    .anonymous-consent {
      .RichText {
        p {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0px;
          text-align: left;
        }

        a {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0px;
          text-align: left;
          color: inherit;
        }
        margin-bottom: 25px;
      }
    }
  }
  &__title {
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 15px;

    @include screen-width-small {
      margin-bottom: 20px;
    }
  }

  &__mandatory {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 10px;
    color: #ea5504;
  }
  // .field-radio-buttons{
  //   padding-bottom:25px;
  // }
}
