/*
  KANSO default theme design tokens for approved KANSO library Components only. OLD
*/

:root {

  //Global Footer
  --gf-font-size: 14px;
  --gf-font-weight: 300;
  --gf-line-height: 22.4px;
  --gf-text-color: #4B4C53;
  --gf-div-bgcolor: #C3C4CA;
  --gf-div-margin: 25px 0;
  --gf-div-height: 1px;
  --gf-scroll-box-shadow: 0px 7.48582px 30px rgba(50, 50, 50, 0.2);
  --gf-scroll-circle-color: #4B4C53; 
  --gf-scroll-arrow-color: #fff;
  --gf-country-link-font-size: 16px;
  --gf-country-link-font-weight: 700;
  --gf-country-link-line-height: 22.4px;
  --gf-country-link-text-color: #2F345A;
  --gf-country-link-icon-color: #2F345A;
  --gf-country-link-text-decoration: none;
  --gf-country-link-bgcolor: transparent;
  --gf-country-link-hover-text-color: #6267A1;
  --gf-country-link-hover-icon-color: #6267A1;
  --gf-country-link-hover-text-decoration: underline;
  --gf-country-link-hover-bgcolor: transparent;
  --gf-country-link-active-text-color: #6267A1;
  --gf-country-link-active-icon-color: #6267A1;
  --gf-country-link-active-text-decoration: underline;
  --gf-country-link-active-bgcolor: transparent;
  --gf-country-link-focus-text-color: #4B4C53;
  --gf-country-link-focus-icon-color: #4B4C53;
  --gf-country-link-focus-text-decoration: none;
  --gf-country-link-focus-bgcolor: #FFDD00;
  --gf-country-link-disabled-text-color: #4B4C53;
  --gf-country-link-disabled-icon-color: #4B4C53;
  --gf-country-link-disabled-text-decoration: none;
  --gf-country-link-disabled-bgcolor: transparent;  
  --gf-menu-link-font-size: 14px;
  --gf-menu-link-font-weight: 300;
  --gf-menu-link-line-height: 22.4px;
  --gf-menu-link-text-color: #4B4C53;
  --gf-menu-link-icon-color: #4B4C53;
  --gf-menu-link-text-decoration: none;
  --gf-menu-link-bgcolor: transparent;
  --gf-menu-link-hover-text-color: #4B4C53;
  --gf-menu-link-hover-icon-color: #4B4C53;
  --gf-menu-link-hover-text-decoration: underline;
  --gf-menu-link-hover-bgcolor: transparent;
  --gf-menu-link-active-text-color: #4B4C53;
  --gf-menu-link-active-icon-color: #4B4C53;
  --gf-menu-link-active-text-decoration: underline;
  --gf-menu-link-active-bgcolor: transparent;
  --gf-menu-link-focus-text-color: #4B4C53;
  --gf-menu-link-focus-icon-color: #4B4C53;
  --gf-menu-link-focus-text-decoration: none;
  --gf-menu-link-focus-bgcolor: #FFDD00;
  --gf-menu-link-disabled-text-color: #4B4C53;
  --gf-menu-link-disabled-icon-color: #4B4C53;
  --gf-menu-link-disabled-text-decoration: none;
  --gf-menu-link-disabled-bgcolor: transparent;
  
  //Global Navigation
  --gn-background-color: #2F345A;
  --gn-menu-desktop-font-size: 16px;
  --gn-menu-desktop-font-weight: 400;
  --gn-menu-desktop-line-height: 22.4px;
  --gn-menu-desktop-text-color: #fff;
  --gn-menu-desktop-hover-text-color: #FABDA8;
  --gn-menu-desktop-active-text-color: #FABDA8;
  --gn-menu-desktop-active-bgcolor: transparent;
  --gn-menu-desktop-selected-bgcolor: #2F345A;
  --gn-menu-desktop-selected-text-color: #FABDA8;
  --gn-menu-desktop-selected-hover-text-color: #FABDA8;
  --gn-menu-desktop-selected-active-text-color: #FABDA8;
  --gn-menu-desktop-selected-active-bgcolor: transparent;
  --gn-menu-desktop-subnav-bgcolor: #4B4C53;
  --gn-menu-desktop-subnav-font-size: 14px;
  --gn-menu-desktop-subnav-font-weight: 400;
  --gn-menu-desktop-subnav-line-height: 22.6px;
  --gn-menu-desktop-subnav-text-color: #fff;
  --gn-menu-desktop-subnav-hover-text-color: #fff;
  --gn-menu-desktop-subnav-active-text-color: #fff;
  --gn-menu-desktop-subnav-active-bgcolor: transparent;
  --gn-menu-desktop-fixed-bgcolor: #2F345A;
  --gn-menu-hamburger-icon-color: #fff;
  --gn-menu-hamburger-icon-color-hover: #fff;
  --gn-menu-slideout-bgcolor: 75, 76, 83;
  --gn-menu-slideout-menu-bgcolor: #2F345A;
  --gn-menu-slideout-icon-close-font-size: 24px;
  --gn-menu-slideout-icon-close-font-weight: 200;
  --gn-menu-slideout-icon-close-color: #ffffff;
  --gn-menu-slideout-active-bgcolor: transparent;
  --gn-menu-slideout-font-size: 16px;
  --gn-menu-slideout-font-weight: 700;
  --gn-menu-slideout-line-height: 19.2px;
  --gn-menu-slideout-text-color: #ffffff;
  --gn-menu-slideout-hover-text-color: #FABDA8;
  --gn-menu-slideout-active-text-color: #FABDA8;
  --gn-menu-slideout-active-bgcolor: transparent;
  --gn-menu-slideout-subnav-font-size: 14px;
  --gn-menu-slideout-subnav-font-weight: 600;
  --gn-menu-slideout-subnav-line-height: 19.2px;
  --gn-menu-slideout-subnav-text-color: #ffffff;
  --gn-menu-slideout-subnav-hover-text-color: #4B4C53;
  --gn-menu-slideout-subnav-active-text-color: #4B4C53;
  --gn-menu-slideout-subnav-active-bgcolor: transparent;
  
  //Modal
  --modal-font-size: 16px;
  --modal-font-weight: 200;
  --modal-line-height: 25.6px;
  --modal-text-color: #4b4c53;
  --modal-bgcolor: rgba(0,0,0,0.4);
  --modal-content-width: 84%;
  --modal-content-width-medium: 80%;
  --modal-content-width-large: 70%;
  --modal-content-width-extraLarge: 60%;
  --modal-content-width-extraExtraLarge: 60%;
  --modal-content-height: 90.6%;
  --modal-content-padding: 3%;
  --modal-content-margin: 0 0 20px;
  --modal-content-bgcolor: #fff;
  --modal-content-border-radius: 8px;
  --modal-content-headline-font-size: 28px;
  --modal-content-headline-font-weight: 400;
  --modal-content-headline-line-height: 33.6px;
  --modal-content-blur-bgcolor: none;
  --modal-scroll-color: #C3C4CA;
  --modal-scroll-radius: 20px;
  --modal-primary-btn-font-weight: 700;  
  --modal-primary-btn-line-height: 19.2px;
  --modal-primary-btn-text-color: #fff;
  --modal-primary-btn-bgcolor: #ea5724;
  --modal-primary-btn-border-color: #ea5724;
  --modal-primary-btn-hover-text-color: #fff;
  --modal-primary-btn-hover-bgcolor: #ff7648;
  --modal-primary-btn-hover-border-color: #ff7648;
  --modal-primary-btn-active-text-color: #fff;
  --modal-primary-btn-active-bgcolor: transparent;
  --modal-primary-btn-active-border-color: #ea5724;
  --modal-secondary-btn-font-weight: 700;  
  --modal-secondary-btn-line-height: 19.2px;
  --modal-secondary-btn-text-color: #ea5724;
  --modal-secondary-btn-bgcolor: transparent;
  --modal-secondary-btn-border-color: #ea5724;
  --modal-secondary-btn-hover-text-color: #fff;
  --modal-secondary-btn-hover-bgcolor: #ff7648;
  --modal-secondary-btn-hover-border-color: #ff7648;
  --modal-secondary-btn-active-text-color: #fff;
  --modal-secondary-btn-active-bgcolor: #ea5724;
  --modal-secondary-btn-active-border-color: #ea5724;
  --modal-tertiary-btn-font-weight: 700;  
  --modal-tertiary-btn-line-height: 19.2px;
  --modal-tertiary-btn-text-color: #ea5724;
  --modal-tertiary-btn-hover-text-color: #ea5724;
  --modal-tertiary-btn-hover-bgcolor: transparent;
  --modal-tertiary-btn-active-text-color: #ea5724;
  --modal-tertiary-btn-active-bgcolor: transparent;

  //Toastbar
  --toast-font-size: 16px;
  --toast-font-weight: 200;
  --toast-line-height: 25.6px;
  --toast-text-color: #2F345A;
  --toast-bgcolor: #F5F5F6;
  --toast-link-font-size: 16px;
  --toast-link-font-weight: 700;
  --toast-link-line-height: 22.4px;
  --toast-link-text-color: #2F345A;
  --toast-link-icon-color: #2F345A;
  --toast-link-text-decoration: none;
  --toast-link-bgcolor: transparent;
  --toast-link-hover-text-color: #f62900;
  --toast-link-hover-icon-color: #f62900;
  --toast-link-hover-text-decoration: underline;
  --toast-link-hover-bgcolor: transparent;
  --toast-link-active-text-color: #2F345A;
  --toast-link-active-icon-color: #2F345A;
  --toast-link-active-text-decoration: underline;
  --toast-link-active-bgcolor: transparent;
  --toast-link-focus-text-color: #F5F5F6;
  --toast-link-focus-icon-color: #F5F5F6;
  --toast-link-focus-text-decoration: none;
  --toast-link-focus-bgcolor: #FFDD00;
  --toast-link-disabled-text-color: #F5F5F6;
  --toast-link-disabled-icon-color: #F5F5F6;
  --toast-link-disabled-text-decoration: none;
  --toast-link-disabled-bgcolor: transparent;

  //Alert
  --alert-font-size: 16px;
  --alert-font-weight: 600;
  --alert-line-height: 25.6px;
  --alert-text-color: #fff;
  --alert-width: auto;
  --alert-bgcolor: #ea5724;
  --alert-border: none;
  --alert-border-radius: 8px;
  --alert-position-y: 20px;
  
  //Hero Header
  --hh-font-size: 24px;
  --hh-font-weight: 200;
  --hh-line-height: 24px;
  --hh-text-color: #333;
  --hh-bgcolor: transparent;
  --hh-bgvideo-opacity: 0.7;
  --hh-breadcrumb-font-size: 16px;
  --hh-breadcrumb-font-weight: 700;
  --hh-breadcrumb-line-height: 22.4px;
  --hh-breadcrumb-text-color: #fff;
  --hh-breadcrumb-icon-color: #fff;
  --hh-breadcrumb-text-decoration: none;
  --hh-breadcrumb-bgcolor: transparent;
  --hh-breadcrumb-hover-text-color: #F5F5F6;
  --hh-breadcrumb-hover-icon-color: #F5F5F6;
  --hh-breadcrumb-hover-text-decoration: underline;
  --hh-breadcrumb-hover-bgcolor: transparent;
  --hh-breadcrumb-active-text-color: #F5F5F6;
  --hh-breadcrumb-active-icon-color: #F5F5F6;
  --hh-breadcrumb-active-text-decoration: underline;
  --hh-breadcrumb-active-bgcolor: transparent;
  --hh-breadcrumb-focus-text-color: #F5F5F6;
  --hh-breadcrumb-focus-icon-color: #F5F5F6;
  --hh-breadcrumb-focus-text-decoration: none;
  --hh-breadcrumb-focus-bgcolor: #FFDD00;
  --hh-breadcrumb-disabled-text-color: #F5F5F6;
  --hh-breadcrumb-disabled-icon-color: #F5F5F6;
  --hh-breadcrumb-disabled-text-decoration: none;
  --hh-breadcrumb-disabled-bgcolor: transparent;
  --hh-title-font-size: var(--base-h5-size);
  --hh-title-font-weight: var(--base-h5-weight);
  --hh-title-line-height: var(--base-h5-line-height);
  --hh-headline-font-size: var(--base-h3-size);
  --hh-headline-font-weight: var(--base-h3-weight);
  --hh-headline-line-height: var(--base-h3-line-height);
  --hh-headline-font-size-lg: var(--base-h2-size);
  --hh-headline-font-weight-lg: var(--base-h2-weight);
  --hh-headline-line-height-lg: var(--base-h2-line-height);
  --hh-description-font-size: 24px;
  --hh-description-font-weight: 200;
  --hh-description-line-height: 38.4px;
  --hh-primary-btn-font-weight: 700;
  --hh-primary-btn-line-height: 19.2px;
  --hh-primary-btn-text-color: #fff;
  --hh-primary-btn-icon-color: #fff;
  --hh-primary-btn-bgcolor: #ea5724;
  --hh-primary-btn-border-color: #ea5724;
  --hh-primary-btn-hover-text-color: #fff;
  --hh-primary-btn-hover-icon-color: #fff;
  --hh-primary-btn-hover-bgcolor: #ea5724;
  --hh-primary-btn-hover-border-color: #ea5724;
  --hh-primary-btn-active-text-color: #fff;
  --hh-primary-btn-active-icon-color: #fff;
  --hh-primary-btn-active-bgcolor: #ea5724;
  --hh-primary-btn-active-border-color: #ea5724;
  --hh-secondary-btn-font-weight: 700;
  --hh-secondary-btn-line-height: 19.2px;
  --hh-secondary-btn-text-color: #ea5724;
  --hh-secondary-btn-icon-color: #ea5724;
  --hh-secondary-btn-bgcolor: transparent;
  --hh-secondary-btn-border-color: #ea5724;
  --hh-secondary-btn-hover-text-color: #fff;
  --hh-secondary-btn-hover-icon-color: #fff;
  --hh-secondary-btn-hover-bgcolor: #ea5724;
  --hh-secondary-btn-hover-border-color: #ea5724;
  --hh-secondary-btn-active-text-color: #ea5724;
  --hh-secondary-btn-active-icon-color: #ea5724;
  --hh-secondary-btn-active-bgcolor: #F5F5F6;
  --hh-secondary-btn-active-border-color: #F5F5F6;
  --hh-tertiary-btn-font-weight: 700;
  --hh-tertiary-btn-line-height: 19.2px;
  --hh-tertiary-btn-text-color: #EA5724;
  --hh-tertiary-btn-icon-color: #EA5724;
  --hh-tertiary-btn-hover-text-color: #EA5724;
  --hh-tertiary-btn-hover-icon-color: #EA5724;
  --hh-tertiary-btn-hover-bgcolor: transparent;
  --hh-tertiary-btn-active-text-color: #6267A1;
  --hh-tertiary-btn-active-bgcolor: #F5F5F6;
  --hh-side-image-text-color: #fff;
  --hh-side-image-bgcolor: transparent;
  --hh-side-image-border-radius: 0;
  --hh-side-image-link-text-color: #2F345A;
  --hh-side-image-link-icon-color: #2F345A;
  --hh-side-image-link-decoration: none;
  --hh-side-image-link-bgcolor: transparent;
  --hh-side-image-link-hover-text-color: #2F345A;
  --hh-side-image-link-hover-icon-color: #2F345A;
  --hh-side-image-link-hover-decoration: underline;
  --hh-side-image-link-hover-bgcolor: transparent;
  --hh-side-image-link-active-text-color: #2F345A;
  --hh-side-image-link-active-icon-color: #2F345A;
  --hh-side-image-link-active-decoration: underline;
  --hh-side-image-link-active-bgcolor: transparent;
  --hh-side-image-link-disabled-text-color: #2F345A;
  --hh-side-image-link-disabled-icon-color: #2F345A;
  --hh-side-image-link-disabled-decoration: none;
  --hh-side-image-link-disabled-bgcolor: transparent;
  --hh-side-video-text-color: #2F345A;
  --hh-side-video-border-radius: 8px;
  --hh-side-video-aspect-ratio: 16/9;
  --hh-side-video-link-text-color: #2F345A;
  --hh-side-video-link-icon-color: #2F345A;
  --hh-side-video-link-decoration: none;
  --hh-side-video-link-bgcolor: transparent;
  --hh-side-video-link-hover-text-color: #2F345A;
  --hh-side-video-link-hover-icon-color: #2F345A;
  --hh-side-video-link-hover-decoration: underline;
  --hh-side-video-link-hover-bgcolor: transparent;
  --hh-side-video-link-active-text-color: #2F345A;
  --hh-side-video-link-active-icon-color: #2F345A;
  --hh-side-video-link-active-decoration: underline;
  --hh-side-video-link-active-bgcolor: transparent;
  --hh-side-video-link-disabled-text-color: #2F345A;
  --hh-side-video-link-disabled-icon-color: #2F345A;
  --hh-side-video-link-disabled-decoration: none;
  --hh-side-video-link-disabled-bgcolor: transparent;
  
  //Accordion
  --acc-text-color: #4B4C53;
  --acc-headline-font-size: 28px;
  --acc-headline-font-weight: 400;
  --acc-headline-line-height: 33.6px;
  --acc-item-font-size: 16px;
  --acc-item-font-weight: 700;
  --acc-item-line-height: 19.2px;
  --acc-item-box-shadow: none;
  --acc-item-text-color: #fff;
  --acc-item-icon-color: #fff;
  --acc-item-bgcolor: #EA5724;
  --acc-item-border-color: #EA5724;
  --acc-item-hover-box-shadow: none;
  --acc-item-hover-text-color: #fff;
  --acc-item-hover-icon-color: #fff;
  --acc-item-hover-bgcolor: #EA5724;
  --acc-item-hover-border-color: #EA5724;
  --acc-item-active-box-shadow: none;
  --acc-item-active-text-color: #fff;
  --acc-item-active-icon-color: #fff;
  --acc-item-active-bgcolor: #EA5724;
  --acc-item-active-border-color: #EA5724;
  --acc-item-focus-box-shadow: none;
  --acc-item-focus-text-color: #fff;
  --acc-item-focus-icon-color: #fff;
  --acc-item-focus-bgcolor: #EA5724;
  --acc-item-focus-border-color: #EA5724;
  --acc-item-disabled-box-shadow: none;
  --acc-item-disabled-text-color: #F5F5F6;
  --acc-item-disabled-icon-color: #F5F5F6;
  --acc-item-disabled-bgcolor: #4B4C53;
  --acc-item-disabled-border-color: #4B4C53;
  --acc-item-closed-font-size: 16px;
  --acc-item-closed-font-weight: 700;
  --acc-item-closed-line-height: 19.2px;
  --acc-item-closed-box-shadow: none;
  --acc-item-closed-text-color: #4B4C53;
  --acc-item-closed-icon-color: #4B4C53;
  --acc-item-closed-bgcolor: #F5F5F6;
  --acc-item-closed-border-color: #F5F5F6;
  --acc-item-closed-hover-box-shadow: none;
  --acc-item-closed-hover-text-color: #fff;
  --acc-item-closed-hover-icon-color: #fff;
  --acc-item-closed-hover-bgcolor: #EA5724;
  --acc-item-closed-hover-border-color: #EA5724;
  --acc-item-closed-active-box-shadow: none;
  --acc-item-closed-active-text-color: #fff;
  --acc-item-closed-active-icon-color: #fff;
  --acc-item-closed-active-bgcolor: #EA5724;
  --acc-item-closed-active-border-color: #EA5724;
  --acc-item-closed-focus-box-shadow: none;
  --acc-item-closed-focus-text-color: #fff;
  --acc-item-closed-focus-icon-color: #fff;
  --acc-item-closed-focus-bgcolor: #EA5724;
  --acc-item-closed-focus-border-color: #EA5724;
  --acc-item-closed-disabled-box-shadow: none;
  --acc-item-closed-disabled-text-color: #F5F5F6;
  --acc-item-closed-disabled-icon-color: #F5F5F6;
  --acc-item-closed-disabled-bgcolor: #4B4C53;
  --acc-item-closed-disabled-border-color: #4B4C53;

  //Tabs
  --tab-text-color: #4B4C53;
  --tab-headline-font-size: 28px;
  --tab-headline-font-weight: 400;
  --tab-headline-line-height: 33.6px;
  --tab-list-btn-font-size: 16px;
  --tab-list-btn-font-weight: 700;
  --tab-list-btn-line-height: 19.2px;
  --tab-list-btn-box-shadow: none;
  --tab-list-btn-text-color: #4B4C53;
  --tab-list-btn-icon-color: #4B4C53;
  --tab-list-btn-bgcolor: transparent;
  --tab-list-btn-border-color: none;
  --tab-list-btn-hover-box-shadow: none;
  --tab-list-btn-hover-text-color: #6267A1;
  --tab-list-btn-hover-icon-color: #6267A1;
  --tab-list-btn-hover-bgcolor: transparent;
  --tab-list-btn-hover-border-color: none;
  --tab-list-btn-active-box-shadow: none;
  --tab-list-btn-active-text-color: #EA5724;
  --tab-list-btn-active-icon-color: #EA5724;
  --tab-list-btn-active-bgcolor: transparent;
  --tab-list-btn-active-border-color: none;
  --tab-list-btn-focus-box-shadow: none;
  --tab-list-btn-focus-text-color: #6267A1;
  --tab-list-btn-focus-icon-color: #6267A1;
  --tab-list-btn-focus-bgcolor: transparent;
  --tab-list-btn-focus-border-color: none;
  --tab-list-btn-disabled-box-shadow: none;
  --tab-list-btn-disabled-text-color: #6267A1;
  --tab-list-btn-disabled-icon-color: #6267A1;
  --tab-list-btn-disabled-bgcolor: transparent;
  --tab-list-btn-disabled-border-color: none;
  --tab-list-btn-selected-border-bottom: #C3C4CA;
  --tab-list-btn-selected-hover-border-bottom: #EA5724;
  --tab-list-btn-selected-active-border-bottom: #EA5724;
  --tab-list-btn-selected-font-size: 16px;
  --tab-list-btn-selected-font-weight: 700;
  --tab-list-btn-selected-line-height: 19.2px;
  --tab-list-btn-selected-box-shadow: none;
  --tab-list-btn-selected-text-color: #EA5724;
  --tab-list-btn-selected-icon-color: #EA5724;
  --tab-list-btn-selected-bgcolor: transparent;
  --tab-list-btn-selected-border-color: none;
  --tab-list-btn-selected-hover-box-shadow: none;
  --tab-list-btn-selected-hover-text-color: #EA5724;
  --tab-list-btn-selected-hover-icon-color: #EA5724;
  --tab-list-btn-selected-hover-bgcolor: transparent;
  --tab-list-btn-selected-hover-border-color: none;
  --tab-list-btn-selected-active-box-shadow: none;
  --tab-list-btn-selected-active-text-color: #EA5724;
  --tab-list-btn-selected-active-icon-color: #EA5724;
  --tab-list-btn-selected-active-bgcolor: transparent;
  --tab-list-btn-selected-active-border-color: none;
  --tab-list-btn-selected-focus-box-shadow: none;
  --tab-list-btn-selected-focus-text-color: #6267A1;
  --tab-list-btn-selected-focus-icon-color: #6267A1;
  --tab-list-btn-selected-focus-bgcolor: transparent;
  --tab-list-btn-selected-focus-border-color: none;
  --tab-list-btn-selected-disabled-box-shadow: none;
  --tab-list-btn-selected-disabled-text-color: #6267A1;
  --tab-list-btn-selected-disabled-icon-color: #6267A1;
  --tab-list-btn-selected-disabled-bgcolor: transparent;
  --tab-list-btn-selected-disabled-border-color: none;
  --tab-list-btn-active-selected-border-bottom: #EA5724;
  --tab-list-btn-active-selected-hover-border-bottom: #EA5724;
  --tab-list-btn-active-selected-active-border-bottom: #EA5724;

  //Content Card
  --cc-bgcolor: transparent;
  --cc-border-radius: 8px;
  --cc-dropshadow: 0 7px 10px rgba(50,50,50,.2);
  --cc-item-font-size: 16px;
  --cc-item-font-weight: 200;
  --cc-item-line-height: 25.6px;
  --cc-item-text-color: #333;
  --cc-item-border-radius: 8px;
  --cc-item-bgcolor: #fff;
  --cc-item-label-font-size: 16px;
  --cc-item-label-font-weight: 400;
  --cc-item-label-line-height: 25.6px;
  --cc-item-headline-font-size: 22px;
  --cc-item-headline-font-weight: 500;
  --cc-item-headline-line-height: 33.6px;
  --cc-item-subheadline-font-size: 24px;
  --cc-item-subheadline-font-weight: 400;
  --cc-item-subheadline-line-height: 38.4px;
  --cc-item-1col-image-left-border-radius: 8px 8px 0 0;
  --cc-item-2col-image-left-border-radius: 8px 0 0 8px;
  --cc-item-2col-image-right-border-radius:  0 8px 8px 0;
  --cc-item-2col-image-top-border-radius:  8px 8px 0 0;
  --cc-item-primary-btn-font-weight: 700;
  --cc-item-primary-btn-line-height: 19.2px;
  --cc-item-primary-btn-text-color: #fff;
  --cc-item-primary-btn-icon-color: #fff;
  --cc-item-primary-btn-bgcolor: #ea5724;
  --cc-item-primary-btn-border-color: #ea5724;
  --cc-item-primary-btn-hover-text-color: #fff;
  --cc-item-primary-btn-hover-icon-color: #fff;
  --cc-item-primary-btn-hover-bgcolor: #fa7b51;
  --cc-item-primary-btn-hover-border-color: #fa7b51;
  --cc-item-primary-btn-active-text-color: #fff;
  --cc-item-primary-btn-active-icon-color: #fff;
  --cc-item-primary-btn-active-bgcolor: #ea5724;
  --cc-item-primary-btn-active-border-color: #ea5724;
  --cc-item-secondary-btn-font-weight: 700;
  --cc-item-secondary-btn-line-height: 19.2px;
  --cc-item-secondary-btn-text-color: #ea5724;
  --cc-item-secondary-btn-icon-color: #ea5724;
  --cc-item-secondary-btn-bgcolor: transparent;
  --cc-item-secondary-btn-border-color: #ea5724;
  --cc-item-secondary-btn-hover-text-color: #fff;
  --cc-item-secondary-btn-hover-icon-color: #fff;
  --cc-item-secondary-btn-hover-bgcolor: #ea5724;
  --cc-item-secondary-btn-hover-border-color: #ea5724;
  --cc-item-secondary-btn-active-text-color: #fff;
  --cc-item-secondary-btn-active-icon-color: #fff;
  --cc-item-secondary-btn-active-bgcolor: #ea5724;
  --cc-item-secondary-btn-active-border-color: #ea5724;
  --cc-item-tertiary-btn-font-weight: 700;
  --cc-item-tertiary-btn-line-height: 19.2px;
  --cc-item-tertiary-btn-text-color: #ea5724;
  --cc-item-tertiary-btn-icon-color: #ea5724;
  --cc-item-tertiary-btn-hover-text-color: #ea5724;
  --cc-item-tertiary-btn-hover-bgcolor: transparent;
  --cc-item-tertiary-btn-active-text-color: #ea5724;
  --cc-item-tertiary-btn-active-bgcolor: transparent;
  
  //Content Card Tile
  --cctile-bgcolor: transparent;
  --cctile-border-radius: 8px;
  --cctile-dropshadow: none;
  --cctile-item-font-size: 16px;
  --cctile-item-font-weight: 200;
  --cctile-item-line-height: 25.6px;
  --cctile-item-text-color: #4B4C53;
  --cctile-item-border-radius: 8px;
  --cctile-item-bgcolor: #ffffff;
  --cctile-item-label-font-size: 16px;
  --cctile-item-label-font-weight: 400;
  --cctile-item-label-line-height: 25.6px;
  --cctile-item-headline-font-size: 28px;
  --cctile-item-headline-font-weight: 600;
  --cctile-item-headline-line-height: 33.6px;
  --cctile-item-subheadline-font-size: 24px;
  --cctile-item-subheadline-font-weight: 400;
  --cctile-item-subheadline-line-height: 38.4px;
  --cctile-item-border-radius: 8px;
  --cctile-item-primary-btn-font-weight: 700;
  --cctile-item-primary-btn-line-height: 19.2px;
  --cctile-item-primary-btn-text-color: #fff;
  --cctile-item-primary-btn-icon-color: #fff;
  --cctile-item-primary-btn-bgcolor: #ea5724;
  --cctile-item-primary-btn-border-color: #ea5724;
  --cctile-item-primary-btn-hover-text-color: #fff;
  --cctile-item-primary-btn-hover-icon-color: #fff;
  --cctile-item-primary-btn-hover-bgcolor: #ea5724;
  --cctile-item-primary-btn-hover-border-color: #ea5724;
  --cctile-item-primary-btn-active-text-color: #fff;
  --cctile-item-primary-btn-active-icon-color: #fff;
  --cctile-item-primary-btn-active-bgcolor: #ea5724;
  --cctile-item-primary-btn-active-border-color: #ea5724;
  --cctile-item-secondary-btn-font-weight: 700;
  --cctile-item-secondary-btn-line-height: 19.2px;
  --cctile-item-secondary-btn-text-color: #ea5724;
  --cctile-item-secondary-btn-icon-color: #ea5724;
  --cctile-item-secondary-btn-bgcolor: transparent;
  --cctile-item-secondary-btn-border-color: #ea5724;
  --cctile-item-secondary-btn-hover-text-color: #fff;
  --cctile-item-secondary-btn-hover-icon-color: #fff;
  --cctile-item-secondary-btn-hover-bgcolor: #ea5724;
  --cctile-item-secondary-btn-hover-border-color: #ea5724;
  --cctile-item-secondary-btn-active-text-color: #fff;
  --cctile-item-secondary-btn-active-icon-color: #fff;
  --cctile-item-secondary-btn-active-bgcolor: #ea5724;
  --cctile-item-secondary-btn-active-border-color: #ea5724;
  --cctile-item-tertiary-btn-font-weight: 700;
  --cctile-item-tertiary-btn-line-height: 19.2px;
  --cctile-item-tertiary-btn-text-color: #ea5724;
  --cctile-item-tertiary-btn-icon-color: #ea5724;
  --cctile-item-tertiary-btn-hover-text-color: #ea5724;
  --cctile-item-tertiary-btn-hover-icon-color: #ea5724;
  --cctile-item-tertiary-btn-hover-bgcolor: transparent;
  --cctile-item-tertiary-btn-active-text-color: #ea5724;
  --cctile-item-tertiary-btn-active-icon-color: #ea5724;
  --cctile-item-tertiary-btn-active-bgcolor: transparent;

  //Carousel
  --carousel-headline-font-size: 28px;
  --carousel-headline-font-weight: 400;
  --carousel-headline-line-height: 33.6px;
  --carousel-slide-height: auto;
  --carousel-btn-prevnext-middle-padding: 10px;
  --carousel-btn-prevnext-middle-text-color: #fff;
  --carousel-btn-prevnext-middle-bgcolor: #000;
  --carousel-btn-hover-prevnext-middle-text-color: #000;
  --carousel-btn-hover-prevnext-middle-bgcolor: #fff;
  
   //Callout Quote
   --cq-font-size: 16px;
   --cq-font-weight: 600;
   --cq-line-height: 26.5px;
   --cq-text-color: #4B4C53;
   --cq-quote-image-color: #C3C4CA;
   --cq-divider-color: #C3C4CA;
   --cq-quote-font-size: 24px;
   --cq-quote-font-weight: 600;
   --cq-quote-line-height: 38.4px;
   --cq-quote-text-color: #EA5724;
 
   //PanelList
   --pl-font-size: 16px;
   --pl-font-weight: 200;
   --pl-line-height: 25.6px;
   --pl-text-color: #4b4c53;
   --pl-bgcolor: #F5F5F6;
   --pl-padding: 32px 24px;
   --pl-border-radius: 0 8px 8px 0;
   --pl-box-shadow: 0px 7.48582px 30px rgba(50, 50, 50, 0.2);
   --pl-heading-font-size: 28px;
   --pl-heading-font-weight: 400;
   --pl-heading-line-height: 33.6px;
   --pl-compliance-font-size: 16px;
   --pl-compliance-font-weight: 400;
   --pl-list-item-border-color: #6267a1;
   --pl-list-item-ol-padding: 16px 0 16px 40px;
   --pl-list-item-ul-padding: 16px 0 16px 16px;
   --pl-list-tertiary-btn-font-size: 16px;
   --pl-list-tertiary-btn-text-color: #6267a1;
   --pl-list-tertiary-btn-icon-color: #6267a1;
   --pl-list-tertiary-btn-hover-text-color: #6267a1;
   --pl-list-tertiary-btn-hover-icon-color: #6267a1;
   --pl-list-tertiary-btn-hover-bgcolor: transparent;
   --pl-list-tertiary-btn-active-text-color: #6267a1;
   --pl-list-tertiary-btn-active-icon-color: #6267a1;
   --pl-list-tertiary-btn-active-bgcolor: #f5f5f6;
  
  //Person
  --person-font-size: 16px;
  --person-font-weight: 700;
  --person-line-height: 25.6px;
  --person-text-color: #4b4c53;
  --person-role-font-size: 14px;
  --person-role-font-weight: 300;
  --person-role-line-height: 22.4px;
}