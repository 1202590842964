@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.banner-with-background {
  &__container {
    width: 100%;
    background-size: cover;
    border-radius: 30px;
    &.mobile {
      display: none;
    }

    img {
      border-radius: 30px;
      width: 100%;
    }

    @media (min-width: 0px) and (max-width: 500px) {
      &.desktop {
        display: none;
      }
      &.mobile {
        display: block;
      }
    }
  }
}
