@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

section[role="main"] {
  overflow-x: hidden;

  &.anchorsFix{
    overflow-x: clip;
  }

  .center-aligned-loader {
    margin-top: 10vh;
    margin-bottom: 10vh;
  }

  .content-section {
    padding-top: 20px;
  }

  &#blurredContentWrapper {
    position: relative;
    @supports (filter: url(#blur)) {
      filter: url(#blur);
      // if filter is supported remove IE11 fix
      &::after {
        display: none;
        content: none;
      }
    }
    
    // IE11 fix
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, .8);
    }
   
  }
}