@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.app-layout--v1 {
  .my-hub {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 16px;
    padding: 0 1em;
    @include screen-width-small {
      padding: 0;
    }
  
    &__title {
      background-color: #f9f4f2;
      padding: 38px 19px;
  
      @include screen-width-small {
        padding: 20px 40px;
        height: 108px;
      }
      h1 {
        font-family: $title-font;
        margin: 0;
        font-style: normal;
        font-weight: 300;
        font-size: 60px;
        line-height: 100%;
        color: #ea5504;
  
        @include screen-width-small {
          font-size: 40px;
          font-style: normal;
          font-weight: 300;
          line-height: 40px;
          letter-spacing: 0px;
          text-align: left;
          margin-top: 14px;
        }
      }
    }
  
    &__tabs {
      min-height: 500px;
      margin-top: 16px;
      display: flex;
      height: 100%;
  
      @include screen-width-small {
        flex-direction: column;
      }
      &__left {
        width: 282px;
        background: #faf6f4;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
  
        @include screen-width-small {
          width: 100%;
          height: 175px;
          align-items: flex-start;
          padding: 20px;
        }
  
        &__anchors {
          margin-top: 20px;
          width: 100%;
  
          @include screen-width-small {
            &__tabs {
              min-width: 137px;
              margin-right: 12px;
            }
            min-height: 60px;
            display: flex;
            flex-direction: row;
            overflow-x: scroll;
          }
        }
  
        &__details {
          display: flex;
          flex-direction: column;
          align-items: center;
  
          @include screen-width-small {
            flex-direction: row;
          }
  
          img {
            margin-top: 38px;
            width: 132px;
            height: 132px;
  
            @include screen-width-small {
              width: 60px;
              height: 60px;
              margin-top: 0;
              margin-right: 14px;
            }
          }
  
          &__texts {
            &__name {
              margin-top: 25px;
              max-width: 230px;
              margin-bottom: 16px;
              font-family: $myriad-bold;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 20px;
              letter-spacing: 0px;
              text-align: center;
              overflow: hidden;
  
              @include screen-width-small {
                text-align: left;
                margin-top: 0;
                font-size: 16px;
                font-weight: 700;
                line-height: 16px;
              }
            }
  
            &__detail {
              max-width: 230px;
              margin-top: 12px;
              font-family: $body-font;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 0px;
              text-align: center;
  
              @include screen-width-small {
                text-align: left;
                margin-top: 12px;
              }
  
              &.desktop {
                @include screen-width-small {
                  display: none;
                }
              }
            }
          }
        }
      }
      &__right {
        width: 80%;
        @include screen-width-small {
          width: 100%;
        }
  
        @include screen-width-medium {
          width: 100%;
        }
      }
    }
  }
}
.app-layout--v2 {
  .my-hub {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 16px;
    padding: 0 1em;
    @include screen-width-small {
      padding: 0;
    }
  
    &__title {
      background-color: #f9f4f2;
      padding: 38px 19px;
  
      @include screen-width-small {
        padding: 20px 40px;
        height: 108px;
      }
      h1 {
        font-family: $title-font-v2;
        margin: 0;
        font-style: normal;
        font-weight: 300;
        font-size: 60px;
        line-height: 100%;
        color: #ea5504;
  
        @include screen-width-small {
          font-size: 40px;
          font-style: normal;
          font-weight: 300;
          line-height: 40px;
          letter-spacing: 0px;
          text-align: left;
          margin-top: 14px;
        }
      }
    }
  
    &__tabs {
      min-height: 500px;
      margin-top: 16px;
      display: flex;
      height: 100%;
  
      @include screen-width-small {
        flex-direction: column;
      }
      &__left {
        width: 282px;
        background: #faf6f4;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
  
        @include screen-width-small {
          width: 100%;
          height: 175px;
          align-items: flex-start;
          padding: 20px;
        }
  
        &__anchors {
          margin-top: 20px;
          width: 100%;
  
          @include screen-width-small {
            &__tabs {
              min-width: 137px;
              margin-right: 12px;
            }
            min-height: 60px;
            display: flex;
            flex-direction: row;
            overflow-x: scroll;
          }
        }
  
        &__details {
          display: flex;
          flex-direction: column;
          align-items: center;
  
          @include screen-width-small {
            flex-direction: row;
          }
  
          img {
            margin-top: 38px;
            width: 132px;
            height: 132px;
  
            @include screen-width-small {
              width: 60px;
              height: 60px;
              margin-top: 0;
              margin-right: 14px;
            }
          }
  
          &__texts {
            &__name {
              margin-top: 25px;
              max-width: 230px;
              margin-bottom: 16px;
              font-family: $hindMadurai-sb;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 20px;
              letter-spacing: 0px;
              text-align: center;
              overflow: hidden;
  
              @include screen-width-small {
                text-align: left;
                margin-top: 0;
                font-size: 16px;
                font-weight: 700;
                line-height: 16px;
              }
            }
  
            &__detail {
              max-width: 230px;
              margin-top: 12px;
              font-family: $body-font;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 0px;
              text-align: center;
  
              @include screen-width-small {
                text-align: left;
                margin-top: 12px;
              }
  
              &.desktop {
                @include screen-width-small {
                  display: none;
                }
              }
            }
          }
        }
      }
      &__right {
        width: 80%;
        @include screen-width-small {
          width: 100%;
        }
  
        @include screen-width-medium {
          width: 100%;
        }
      }
    }
  }
}
