@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.verify-notification {
    background-color: #FAF6F4;

    .container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        .close-container {
            margin-left: auto;
        }
    }
}