@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.bar-chart__wrapper {
    width:100%;
    // margin-bottom: 16px;
    @include screen-width-small {
        flex-direction: column;
        margin-left: 0px;
        margin-right: 0px;
        width:100%;
    }
    .bar-chart__item {
        display: flex;
        flex-direction: column;
        @include screen-width-small {
            @include spans-columns(12);
        }
        p {
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 16px;
            margin-top: 32x;
            font-size: 16px;
            font-weight: 700;
        }
        > img {
            width:60%;
            height: auto;
            margin: 20px auto 40px;
            @include screen-width-small {
                width: 100%
            }
        }
        &:first-child {
            > img {
                margin: 60px auto 40px;
                @include screen-width-small {
                    margin: 20px auto 40px;
                }
            }
        }
    }
}