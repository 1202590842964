@import 'style/mixins/_responsive.scss';
@import '../../../../../assets/mixins.scss';

.header-logos {
    display: flex;
    .Logo {
        display: flex;
        align-self: center;
        margin-right: 8px;
        width: 206px;

        @media (max-width: 767px) {
            width: 162px;
            &.logo-events {
                width: 168px;
            }
        }
    
        // If browser supports css grid use
        @supports (display: grid) {
            display: grid;
            grid-area: logo;
            
            img {
                width: 100%;
            }
        }

        &.events {
            width: auto;
        }
    }
}