$header-height : 80px;


.app-layout--v1 {
    .MobileNavigation {
        background-color: #FFFFFF;
        display: none;
        position: fixed;
        top: $header-height;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1004;

        .SideNavigation {
            display: block;
            overflow-y: scroll;
            height: calc(100% - 260px);

            .SideNavigation_Item {
                padding: 15px 20px;
                margin: 0;
                
                &--active {
                    color: unset;
                }

                &--level1 {
                    font-size: 16px ;
                }
            }
        }
    
        .Main {
            list-style-type: none;
            margin: 0;
            padding: 0;
            height: calc(100% - 140px);
            overflow-y: scroll;
    
            li {
                display: grid;
                grid-template-columns: 1fr 69px;
                align-items: center;
                height: 60px;
                border-bottom: 1px #ebebeb solid;
    
                &:first-child {
                    color: #979797;
                }
            }
        }
    
        .NavInfo {
            display: grid;
            grid-template-columns: 1fr;
            padding: 0 20px;
            
            .ParentLink {
                display: none;
                height: 100%;
                align-content: center;
            }
    
            .ParentName {
                display: grid;
                grid-column: 1 / 3;
                color: #a4a4a4;
                height: 100%;
                align-content: center;
                height: 60px;
            }
    
            &.NavInfo_Sub {
                grid-template-columns: 100px 1fr;
                align-content: center;
    
                .ParentName {
                    grid-column:unset;
                    text-align: right;
                }
    
                .ParentLink {
                    display: inline-block;
                    line-height: 60px;
                    height: 60px;
                    grid-template-columns: 20px 1fr;
                    cursor: pointer;
    
                    i {
                        border: solid #f94b22;
                        border-width: 0 2px 2px 0; // Thickness
                        display: inline-block;
                        padding: 5px; // Size
                        max-width: 5px;
                        transform: rotate(135deg);
                    }
                }
            }
        }
    
    
        .NavLink {
            cursor: pointer;
            display: grid;
            padding-left: 20px;
            height: 100%;
            line-height: 100%;
            align-content: center;
            color: #4c4c4c;
            text-decoration: none;
        }
    
        .ChildLink {
            cursor: pointer;
            display: grid;
            border-left: 1px #ebebeb solid;
            height: 100%;
            line-height: 100%;
            align-content: center;
            justify-content: center;
    
            i {
                border: solid #f94b22;
                border-width: 0 2px 2px 0; // Thickness
                display: inline-block;
                padding: 5px; // Size
                max-width: 5px;
                transform: rotate(-45deg);
            }
        }
    
        .ContactLink {
            &_Wrapper {
                position: relative;
                margin-left: 0;
            }
    
            &_Link {
                background-color: #FFFFFF;
                border-top: 1px #ebebeb solid;
                bottom: 70px;
                left: 0;
                padding: 20px 10px 10px 20px;
                position: fixed;
                width: 100%;
                
                a {
                    border: solid 1px #EA570D;
                    border-radius: 20px;
                    padding: 6px 15px;
                    color: #EA570D;
                    text-decoration: none;
                }
    
            }
        }
    }
    .MobileNavigation--contact-us {
        .Main {
            height: calc(100% - 260px);
        }
    }
}

.app-layout--v2 {
    .MobileNavigation {
        background-color: #FFFFFF;
        display: none;
        position: fixed;
        top: $header-height;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1004;
    
        .Main {
            list-style-type: none;
            margin: 0;
            padding: 0;
            height: calc(100% - 200px);
            overflow-y: scroll;
    
            li {
                display: grid;
                grid-template-columns: 1fr 69px;
                align-items: center;
                height: 60px;
                border-bottom: 1px #ebebeb solid;
    
                &:first-child {
                    color: #979797;
                }
            }
        }
    
        .Main--subnav {
            .NavLink {
                padding-left: 40px;
            }
        }
    
        .NavInfo {
            display: grid;
            grid-template-columns: 1fr;
            padding: 0 20px;
            
            .ParentLink {
                display: none;
                height: 100%;
                align-content: center;
            }
    
            .ParentLink--subnav {
                color: #333;
                text-decoration: none;
                cursor: pointer;
                font-size: 20px;
                font-weight: 500;
                line-height: 60px;
                letter-spacing: 0px;
                margin: 0;
            }
    
            .ParentName {
                display: grid;
                grid-column: 1 / 3;
                color: #a4a4a4;
                height: 100%;
                align-content: center;
            }
    
            &.NavInfo_Sub {
                grid-template-columns: 100px 1fr;
                align-content: center;
    
                .ParentName {
                    grid-column:unset;
                    text-align: right;
                }
    
                .ParentLink {
                    display: inline-block;
                    line-height: 60px;
                    height: 60px;
                    grid-template-columns: 20px 1fr;
                    font-weight: 600;
                    color: #EA570D;
                    cursor: pointer;
    
                    i {
                        border: solid #f94b22;
                        border-width: 0 2px 2px 0; // Thickness
                        display: inline-block;
                        padding: 5px; // Size
                        max-width: 5px;
                        transform: rotate(135deg);
                        margin-right: 10px;
                    }
                }
            }
    
            &.NavInfo_Sub--subnav {
                grid-template-columns: none;
            }
        }
    
    
        .NavLink {
            cursor: pointer;
            display: grid;
            padding-left: 20px;
            height: 100%;
            line-height: 100%;
            align-content: center;
            color: #4c4c4c;
            text-decoration: none;
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 0px;
        }
    
        .ChildLink {
            cursor: pointer;
            display: grid;
            border-left: 1px #ebebeb solid;
            height: 100%;
            line-height: 100%;
            align-content: center;
            justify-content: center;
    
            i {
                border: solid #f94b22;
                border-width: 0 2px 2px 0; // Thickness
                display: inline-block;
                padding: 5px; // Size
                max-width: 5px;
                transform: rotate(-45deg);
            }
        }
    
        .ContactLink {
            &_Wrapper {
                position: relative;
                margin-left: 0;
            }
    
            &_Link {
                background-color: #FFFFFF;
                border-top: 1px #ebebeb solid;
                bottom: 70px;
                left: 0;
                padding: 20px 10px 10px 20px;
                position: fixed;
                width: 100%;
                
                a {
                    border: solid 2px #EA570D;
                    border-radius: 20px;
                    padding: 7px 20px;
                    color: #EA570D;
                    text-decoration: none;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19px;
                    letter-spacing: 0px;
    
                    svg {
                        margin-left: 5px;
                    }
                }
    
            }
        }
    }
    .MobileNavigation--contact-us {
        .Main {
            height: calc(100% - 260px);
        }
    }
}

