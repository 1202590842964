@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import '../../../assets/mixins.scss';

.calculator-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid #979797;

  .calculator-header {
    display: flex;
    flex-direction: column;
    gap: 20px;
    p,
    h4 {
      margin: 0;
      font-weight: 600;
      line-height: 20px;
    }
    h4 {
      font-size: 24px;
      line-height: 26px;
      color: $kk-primary;
    }
  }
  .calculator-option-list {
    width: calc(100% + 16px);
    display: flex;
    flex-wrap: wrap;

    .calculator-option-container {
      width: 50%;

      label {
        margin: 8px;

        p {
          font-size: 18px;
          line-height: 160%;
        }
      }
      // .calculator-option {
      //   display: flex;
      //   padding: 16px;
      //   border-radius: 8px;
      //   background: #faf6f4;
      //   margin: 8px;
      //   cursor: pointer;

      //   .radio-btn {
      //     width: 65px;
      //     @include screen-width-small {
      //       width: 85px;
      //     }
      //     @media (max-width: 280px) {
      //       width: 150px;
      //     }
      //   }

      //   input[type="radio"] {
      //     display: none;
      //   }

      //   label {
      //     cursor: pointer;
      //   }

      //   p {
      //     margin: 0;
      //   }
      // }

      @include screen-width-small {
        width: 100%;
      }
    }
  }
  .calculator-footer {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #979797;
    .calculator-btn {
      gap: 8px;
      height: 40px;

      &.chevron-button-component-disabled {
        background: #f5f5f6;
        color: #a19f9d;
        pointer-events: none;
      }
      .chevron-button-svg-container--hidden {
        display: none;
      }
    }
  }
}
