@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.content-gate {
  position: absolute;
  bottom: 0;
  z-index: 1003;
  width: 100%;
  padding: 24px 0;
  background-color: #fff;

  &__overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc(100% - 80px);
    z-index: 1003;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    svg#svgBlur {
      width: 0;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }

    img.blur {
      width: 100%;
      filter: url(#blur);
    }
  }

  &__title {
    font-family: $myriad-reg;
    color: $kk-primary;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    margin: 0 0 16px;
    padding: 0 8px;
  }

  &__text {
    color: $body-text-color;
    font-size: 18px;
    line-height: 20px;
    font-weight: normal;
    margin: 0 0 10px;
    padding: 0 8px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
  }

  &__cta {
    &-wrapper {
      width: 100%;
      margin: 16px 0 0;
      max-width: 310px;
      display: flex;
      flex-direction: column;
      padding: 0 8px;

      &>.chevron-button-component {
        text-align: left;
        margin-bottom: 8px;
        width: 100%;
        height: 44px;
      }
    }

    &-smallprint {
      font-size: 10px;
      line-height: 12px;
      font-family: $myriad-reg;
      margin: 0;
      padding: 0 5px;
    }

  }


}

@include screen-width-desktop {
  .content-gate {
    padding: 40px 0;

    &__title {
      font-size: 60px;
      font-weight: 400;
      line-height: 72px;
      margin: 0 0 24px;
      padding: 0;
    }

    &__text {
      margin: 0 0 44px;
      padding: 0;
    }

    &__footer {
      flex-direction: row;
    }

    &__cta {
      &-wrapper {
        margin: 0;
        padding: 0;
        max-width: 395px;

        &:first-of-type {
          margin-right: 24px;
        }
      }

      &-smallprint {
        font-size: 12px;
        line-height: 15px;
        padding: 0;
      }

    }
  }
}

.content-gate--limited-access {
  padding: 40px 0 60px 0;

  .content-gate__title {
    h1,h2,h3,h4,h5,h6,p {
      margin-bottom: 0;
      margin-top: 60px;
      font-weight: 400;
    }
  }

  .content-gate__text {
    ul {
      li {
        color: #EA5504;
        margin-bottom: 4px;
      }
    }
  }

  .content-gate__footer {
    margin-top: 80px;

    .content-gate__cta-wrapper {
      width: 245px;
    }
  }
}


.content-gate--tc {
  @media (max-width: 1300px)  {
    max-height: calc(100vh - 80px);
    overflow-y: scroll;
  }

  @media (max-height: 780px)  {
    max-height: calc(100vh - 80px);
    overflow-y: scroll;
  }
}

.content-gate--toastbar-present {
  max-height: calc(100vh - 174px);
  overflow-y: scroll;

  @media (max-width: 1024px)  {
    @media (max-height: 830px)  {
      max-height: calc(100vh - 174px);
      overflow-y: scroll;
    }
  }

  @media (max-width: 615px)  {
    max-height: calc(100vh - 196px);
    overflow-y: scroll;
  }

  @media (max-width: 350px)  {
    max-height: calc(100vh - 216px);
    overflow-y: scroll;
  }
}

.app-layout--v1 {
  .content-gate {
    &__title {
      font-family: $myriad-reg;
    }
    &__cta { 
      &-smallprint {
        font-family: $myriad-reg;
      }
    }
  }  
}
.app-layout--v2 {
  .content-gate {
    &__title {
      font-family: $hindMadurai-sb;
    }
    &__cta { 
      &-smallprint {
        font-family: $hindMadurai-reg;
      }
    }
  }  
}