@import 'style/variables.scss';

.form-redesign {
  .input-section {
    input {
      border-radius: 4px;
    }
    input:focus {
      border:1.5px solid #EA5504;
    }
    .error  {
      color:#FF0000;
      font-size: 12px;
      font-family: "Myriad Pro Regular";
    }
    .hide-password {
      svg{
        margin-top: 20px;
      }
    }
  }
  .form-inline-error-text {
    float: unset;
  }
  
}

.form-field-input {
  display: flex;
  flex-direction: column;
  .field-meta-data {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  label {
    display: inline;
    font-family: $hindMadurai-reg;
    font-size: 12px;
    line-height: 14px;
    color: #333333;
    margin-bottom: 4px;
  };
  
  input {
    display: block;
    width: 100%;
    padding: 12px 20px;
    font-size: 16px;
    line-height: 19px;
    color: $body-text-color;
    font-family: $hindMadurai-sb;
    background-color: $kk-secondary;
    border: 1px solid #C4C4C4;
    border-radius: 30px;

    &::placeholder {
      font-family: $hindMadurai-reg;
      color: #616161;
    }

    .form-component-wrapped & {
      background-color: #FFF;
    }
  }
  &-read-only {
    input {
      background-color: transparent;
      border: 0px;
      padding: 6px 0;
      border-radius: 0;
    }
  }
  .error{
    color:#EA5504;
    line-height:0px;
    padding-top: 5px;
    &.capslock{
      margin-top: -20px;
      margin-bottom: 10px;
      color:red;
    }
  }
  .input-section{
    margin-bottom: 30px;

    div{
      text-align: right;
      padding-right: 30px;

      svg{
        position: absolute;
        margin-top: 15px;
        cursor: pointer;
      }
    }
  }
}
.required-input{
  border: 1.5px solid #FF0000 !important;
};





