@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';


.app-layout--v1 {
  .homepage-card {
    padding: 30px !important;
    
    &-header {
      display: flex;
      align-items: top;
      width: 100%;
      .homepage-card-title {
        margin: 0 10px 0 0;
        font-size: 20px; // Reduced from 24px - titles in German were too big
        font-family: $subtitle-font;
        max-width: 80%;
        word-break: break-word;
      }
      .homepage-card-icon {
        margin-left: auto;
      }
      margin-bottom: 30px;
    }
  }
}

.app-layout--v2 {
  .homepage-card {
    padding: 30px !important;
  
    @include screen-width-small {
      padding: 20px !important;
    }
  
    // &:hover {
    //   border-bottom: 5px solid;
    //   border-left: 5px solid;
    //   border-color: $kk-primary;
    // }
  
    &-header {
      display: flex;
      align-items: center;
      width: 100%;
      // margin-bottom: 30px;
      // font-family: $subtitle-font;
  
      .homepage-card-icon {
        width: 23px;
        height: 23px;
  
        img {
          width: inherit;
          height: inherit;
        }
      }
  
      .homepage-card-category {
        margin: 0 0 0 10px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        max-width: 85%;
        word-break: break-word;
        text-transform: uppercase;
      }
    }
  
    .homepage-card-title {
      margin: 20px 0 42px;
      // height: 30%;
  
      h2 {
        margin: 0;
        font-weight: 600;
        font-size: 25px; // Reduced from 24px - titles in German were too big
        word-break: break-word;
        line-height: 27px;
      }
    }
  
    .RichText {
      margin-top: auto;
      margin-bottom: 30px;
    }
  }
}
