@import '../../../../../assets/mixins.scss';
@import 'style/mixins/_responsive.scss';



.app-layout--v1 {
	.CountrySelector {
		color: #EA5504;
		display: flex;
		align-items: center;

		
	
			&_Wrapper {
					position: relative;
	
			margin-left: auto;
	
					@supports (display: grid) {
							margin-left: 0;
					}
			}
		img{
			max-width: 24px;
		}
	
		&.Mobile {
			background-color: #FFFFFF;
			border-top: 1px #ebebeb solid;
			bottom: 0;
			height: 60px;
			left: 0;
			padding: 20px 10px;
			padding-left: 20px;
			position: fixed;
			width: 100%;
		}
	
		&.Desktop {
			cursor: pointer;
			@include tabletMax{
				display: none;
			}
		}
	
		&.icon-up::after {
			content: "\25B2";
			font-size: 10px;
			margin-left: .5em;
		}
		
		&.icon-down::after {
			content: "\25BC";
			font-size: 10px;
			margin-left: .5em;
		}
	}
	
	// Overlay
	.CountryPopup {
			background: rgba(0,0,0,0.5);
		cursor: pointer;
		display: none;
		height: 100%;
		left: 0;
		padding: 0 1em;
		position: fixed;
		width: 100%;
		z-index: 1005;
	
			@include tabletMax {
					top: 80px;
					padding:0;
					background: transparent;
					margin-bottom: 100px;
					height: auto;
			}
	
		&_Container {
			display: flex;
			margin: 0 auto;
			max-width: 1440px;
			@include tabletMax {
				height: calc(100vh - 140px);
				overflow-y: auto;
				background-color: #fff;
			}
		}
	
		&_Content {
			background-color: #FFFFFF;
			cursor: auto;
			display: inline-flex;
			flex-direction: column;
			margin: 5px 0 0 auto;
			padding: 20px;
			width: fit-content;
	
					@include tabletMax {
							width: 100%;
							margin: 0 auto;
					}
					@include tabletMax {
						display: block;

					}
		}
	
		&_Header {
			display: flex;
			flex-direction: column;
			font-size: 18px;
	
					@include tabletMax {
							overflow: scroll;
							max-height: 75vh;
					}
	
			& > div {
				display: flex;
				align-items: center;
	
				& > p {
					margin: 0;
				}
	
				&:last-child {
					margin-bottom: 1em;
				}
	
				&.CountryPopup_Heading {
					p {
						font-size: 18px;
						padding-bottom: 5px;
						border-bottom: 2px solid #333333;
					}
	
					margin-bottom: 10px;
				}
	
				&.CountryPopup_SubHeading {
					p {
						font-size: 14px;
						margin-right: 40px;
					}
	
					margin-bottom: 20px;
				}
			}
		}
	
		&_Close {
			position: relative;
			cursor: pointer;
			height: 17px;
			width: 17px;
			margin-left: auto;

			@include tabletMax {
				display: none;
			}
	
			span {
				background: #EA5504;
				border-radius: 9px;
				display: block;
				height: 2px;
				opacity: 1;
				position: absolute;
				top: 7px;
				width: 100%;
	
				&:nth-child(1) {
					-moz-transform: rotate(45deg);
					-o-transform: rotate(45deg);
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
				}
	
				&:nth-child(2) {
					-moz-transform: rotate(-45deg);
					-o-transform: rotate(-45deg);
					-webkit-transform: rotate(-45deg);
					transform: rotate(-45deg);
				}
			}
		}
	
		&_List {
	
			h3 {
				margin-top: -2px;
				padding-top: 0;
			}
		}
	
		&_Region {
			display: flex;
			flex-direction: column;
			margin-bottom: 1em;
	
			@include desktopOnly{
				flex-direction: row;
			}
	
			h3 {
				font-weight: bold;
				margin: 0;
				padding: 0;
				margin-bottom: .5em;
			}
		}
		&_RegionList:first-child {
			@media (max-width: 1024px) {
				h3 {
					margin-top: 0;
				}
			}
		}
		&_RegionList {
			display: flex;
			flex-direction: column;
			
			& > *:not(:last-child) {
				margin-bottom: .5em;
			}
	
			@include tabletMin {
				margin-right: 30px;

				h3 {
					margin-top: 10px;
				}
			}
			@media (max-width: 1024px) {
				h3 {
					margin-top: 10px;
				}
			}
		}
	
		&_Link {
			color: #313131;
			display: flex;
			align-items: center;
	
			img {
				margin-right: .5em;
				max-width: 24px;
				max-height: 24px;
			}
		}
	
		&_Current {
			color: #EA5504;
			text-decoration: underline;
			display: flex;
			align-items: center;
	
			img {
				margin-right: .5em;
				max-width: 24px;
				max-height: 24px;
			}
		}
	
		&--open {
			display: block;
		}
	}
}
.app-layout--v2 {
	.CountrySelector {
		color: #EA5504;
		display: none;
	
		@include tabletMin {
			display: block;
		}
	
		&_Wrapper {
			position: relative;
			margin-left: auto;
	
			@supports (display: grid) {
					margin-left: 0;
			}
		}
		p {
			display: none;
			// margin: 0;
		}
	
		img{
			max-width: 24px;
			max-height: 24px;
			height: 24px;
			@include screen-width-small {
				height: auto;
				max-height: 24px;
      }
		}
	
		&.Mobile {
			background-color: #FFFFFF;
			border-top: 1px #ebebeb solid;
			bottom: 0;
			height: 60px;
			left: 0;
			padding: 20px 10px;
			padding-left: 20px;
			position: fixed;
			display: flex;
			align-items: center;
			width: 100%;
			p {
				display: block;
				margin: 0;
				margin-left: 5px;
			}
			svg {
				fill: #EA5504;
				margin-left: 10px;
			}
			img {
				width: 30px;
			}
		}
	
		&.Desktop {
			cursor: pointer;
			padding: 27px 0;
			margin-left: 56px;
			@media (max-width: 1050px) {
				margin-left: 20px;
			}
			svg {
				margin-left: 5px;
			}
			@include tabletMin {
				display: flex;
				align-items: center;
				justify-content: end;
				min-width: 50px;
			}
			@include tabletMax {
				align-items: center;
				justify-content: end;
				margin-left: 10px;
				min-width: 50px;
			}
		}
	
		&.icon-up {
			svg {
				transform: rotate(180deg);
			}
		}
		
		// &.icon-down::after {
		// 	content: "\25BC";
		// 	font-size: 10px;    
		// 	margin-top: auto;
		// 	margin-left: .5em;
		// 	position: relative;
		// 	bottom: 7px;
		// 	color: #333;
		// 	@media (max-width: 767px) {
		// 		bottom: 4px;
		// 		color: #EA570D;
		// 	}
		// }
		img{
			@include tabletMin {
				border-radius: 50%;
			}
		}
	}
	
	// Overlay
	.CountryPopup {
			background: rgba(0,0,0,0.5);
		cursor: pointer;
		display: none;
		height: 100%;
		left: 0;
		padding: 0 1em;
		position: fixed;
		width: 100%;
		z-index: 1005;
	
			@include tabletMax {
					top: 80px;
					padding:0;
					background: transparent;
					margin-bottom: 100px;
					height: auto;
			}
	
		&_Container {
			display: flex;
			margin: 0 auto;
			max-width: 1440px;
		}
	
		&_Content {
			background-color: #FFFFFF;
			cursor: auto;
			display: inline-flex;
			flex-direction: column;
			margin: 5px 0 0 auto;
			padding: 20px;
			width: fit-content;
	
					@include tabletMax {
							width: 100%;
							margin: 0 auto;
					}
		}
	
		&_Header {
			display: flex;
			flex-direction: column;
			font-size: 18px;
	
					@include tabletMax {
							overflow: scroll;
							max-height: 75vh;
					}
	
			& > div {
				display: flex;
				align-items: center;
	
				& > p {
					margin: 0;
				}
	
				&:last-child {
					margin-bottom: 1em;
				}
	
				&.CountryPopup_Heading {
					p {
						font-size: 18px;
						padding-bottom: 5px;
						border-bottom: 2px solid #333333;
					}
	
					margin-bottom: 10px;
				}
	
				&.CountryPopup_SubHeading {
					p {
						font-size: 14px;
						margin-right: 40px;
					}
	
					margin-bottom: 20px;
				}
			}
		}
	
		&_Close {
			position: relative;
			cursor: pointer;
			height: 17px;
			width: 17px;
			margin-left: auto;
	
	
			span {
				background: #EA5504;
				border-radius: 9px;
				display: block;
				height: 2px;
				opacity: 1;
				position: absolute;
				top: 7px;
				width: 100%;
	
				&:nth-child(1) {
					-moz-transform: rotate(45deg);
					-o-transform: rotate(45deg);
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
				}
	
				&:nth-child(2) {
					-moz-transform: rotate(-45deg);
					-o-transform: rotate(-45deg);
					-webkit-transform: rotate(-45deg);
					transform: rotate(-45deg);
				}
			}
		}
	
		&_List {
	
			h3 {
				margin-top: -2px;
				padding-top: 0;
			}
		}
	
		&_Region {
			display: flex;
			flex-direction: column;
			margin-bottom: 1em;
	
			@include tabletMin {
				flex-direction: row;
			}
	
			h3 {
				font-weight: bold;
				margin: 0;
				padding: 0;
				margin-bottom: .5em;
			}
		}
	
		&_RegionList {
			display: flex;
			flex-direction: column;
			
			& > *:not(:last-child) {
				margin-bottom: .5em;
			}
	
			@include tabletMin {
				margin-right: 30px;
			}
		}
	
		&_Link {
			color: #313131;
			display: flex;
			align-items: center;
	
			img {
				margin-right: .5em;
				max-width: 24px;
				max-height: 24px;
			}
		}
	
		&_Current {
			color: #EA5504;
			text-decoration: underline;
			display: flex;
			align-items: center;
	
			img {
				margin-right: .5em;
				max-width: 24px;
				max-height: 24px;
			}
		}
	
		&--open {
			display: block;
		}
	}
	.CountrySelector_Wrapper--disabled {
		.CountrySelector {
			cursor: auto;
		}
	}
}

.CountrySelector--global {
	display: flex!important;
	align-items: center;
	justify-content: start!important;
	min-width: auto;
	margin-left: 0!important;
	p {
		display: block!important;
		margin: 16px 0!important;
	}

	img {
		border-radius: 0!important;
	}
}

.app-layout--v1, .app-layout--v2 {
	.CountrySelector--global {
		img {
			max-height: 24px;
		}
	}
}

.CountryPopup--open {
	.CountryPopup_Close {
		display: block;
	}
}