/*
   KANSO default theme design tokens for approved KANSO library Patterns only.
*/

:root {

  //Page Section
  --ps-bgcolor: #fff;
  --ps-text-color: #4B4C53;
  --ps-bgimage-radius: 0;

}