@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
.app-layout--v1 {
    .webinar-card {
        &.module-section-card-container {
            @include grid-row();
            flex-flow: wrap;
            @include screen-width-mobile {
                flex-direction: column;
            }
            margin-bottom: 20px;
            .module-section-card-item {
                @include spans-columns(6);
                @include spans-columns-small(4);
                text-decoration:none;
        
                .module-section-card {
                    height: 410px;
                    position: relative;
                    margin-bottom: 30px;
                    flex-wrap: wrap;
                    align-content: space-between;
                    color: #ffffff;
                    max-width: 480px;
                    
                    @include screen-width-mobile {
                        max-width: 100%;
                        padding-bottom:150px;
                    }
                    .card-details-container{
                        padding: 20px 25px 43px 20px;
                        @include screen-width-small{
                            padding: 20px;
                        }   
        
                        .webinar-time-section {
                            align-items: center;
                            height: 29px;
                        }
                        .title-card {
                            margin-top: 0;
                            margin-bottom: 10px;
                        }
        
                        .body-text {
                            height: 61px;
                            p {
                                @media (min-width: $MIN_WIDTH_LARGE) {
                                    height: 67px;
                                }
                            }
                        }
                        .read-more {
                            margin-top: 20px;
                        }
                    }
                    .section-card-header {
                        z-index: 3;
                        width: 100%;
                        display: flex;
                        font-size: 26px;
                        margin: 0;
                        .title {
                            font-size: 26px;
                            margin-left: 16px;
                            text-align: left;
                            font-weight: 600;
                            word-break: break-word;
                            margin-top: 6px;
                            @include screen-width-small {
                              margin-left: 10px;
                              
                            }
                        }
                        .icon {
                            width: 40px;
                            height: 40px;
                            flex-shrink: 0;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #EA5504;
                            svg {
                              height: 18px;
                              color: white;
                            }
                        }
                    }
                    .title{
                        flex-direction: column;
                        color:#333;
                    }
                    .title-card{
                        font-family: 'Myriad Pro Bold';
                        font-weight: 600;
                        font-size: 25px;
                        line-height: 30px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1; /* number of lines to show */
                        -webkit-box-orient: vertical;
                    }
                    .read-more {
                        z-index: 3;
                        a {
                            color: #ffffff;
                            text-decoration: none;
                            text-transform: uppercase;
                            display: flex;
                            height: 18px;
                            align-items: center;
                        }
                        svg {
                            margin-left: 24px;
                        }
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 0;
                        height: 0;
                        background-color: rgba(0, 0, 0, 0);
                    }
                    
                }
               
            }
            .custom-focus-therapy:hover {
                box-shadow: 0px;
                .module-section-card {
                    &::after {
                        background-color: rgba(234, 87, 13, 0.8);
                        z-index:2;
                    }
                }
               
            }
        }
    }
}
.app-layout--v2 {
    .webinar-card {
        &.module-section-card-container {
            @include grid-row();
            flex-flow: wrap;
            @include screen-width-mobile {
                flex-direction: column;
            }
            margin-bottom: 20px;
            .module-section-card-item {
                @include spans-columns(6);
                @include spans-columns-small(4);
                text-decoration:none;
        
                .module-section-card {
                    height: 410px;
                    position: relative;
                    margin-bottom: 30px;
                    flex-wrap: wrap;
                    align-content: space-between;
                    color: #ffffff;
                    max-width: 480px;
                    box-shadow: 0px 2px 4px rgba(52, 58, 64, 0.25);
                    border-radius: 5px;
                    &:hover {
                        box-shadow: -5px 5px 0px 0px $kk-primary, 0px 2px 4px rgba(52, 58, 64, 0.25);
                    }
                    
                    @include screen-width-mobile {
                        max-width: 100%;
                        padding-bottom:150px;
                    }
                    .card-details-container{
                        padding: 20px 25px 43px 20px;
                        @include screen-width-small{
                            padding: 20px;
                        }   
        
                        .webinar-time-section {
                            align-items: center;
                            height: 29px;
                        }
                        .title-card {
                            margin-top: 0;
                            margin-bottom: 10px;
                        }
        
                        .body-text {
                            height: 61px;
                            p {
                                @media (min-width: $MIN_WIDTH_LARGE) {
                                    height: 67px;
                                }
                            }
                        }
                        .read-more {
                            margin-top: 20px;
                        }
                    }
                    .section-card-header {
                        z-index: 3;
                        width: 100%;
                        display: flex;
                        font-size: 26px;
                        margin: 0;
                        .title {
                            font-size: 26px;
                            margin-left: 16px;
                            text-align: left;
                            font-weight: 600;
                            word-break: break-word;
                            margin-top: 6px;
                            @include screen-width-small {
                              margin-left: 10px;
                              
                            }
                        }
                        .icon {
                            width: 40px;
                            height: 40px;
                            flex-shrink: 0;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #EA5504;
                            svg {
                              height: 18px;
                              color: white;
                            }
                        }
                    }
                    .title{
                        flex-direction: column;
                        color:#333;
                    }
                    .title-card{
                        font-family: $hindMadurai-sb;
                        font-weight: 600;
                        font-size: 25px;
                        line-height: 30px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1; /* number of lines to show */
                        -webkit-box-orient: vertical;
                    }
                    .read-more {
                        z-index: 3;
                        a {
                            color: #ffffff;
                            text-decoration: none;
                            text-transform: uppercase;
                            display: flex;
                            height: 18px;
                            align-items: center;
                        }
                        svg {
                            margin-left: 24px;
                        }
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 0;
                        height: 0;
                        background-color: rgba(0, 0, 0, 0);
                    }
                    
                }
               
            }
            .custom-focus-therapy:hover {
                box-shadow: 0px;
                .module-section-card {
                    &::after {
                        background-color: rgba(234, 87, 13, 0.8);
                        z-index:2;
                    }
                }
               
            }
        }
    }
}
