@import 'style/mixins/_responsive.scss';
@import '../../../../../assets/mixins.scss';
@import 'style/variables.scss';

.notice-bar {
  background-color: #e5e5e5;
  padding: 20px 87px;

  &-content {
    display: flex;
    gap: 23px;

    .logo {
      height: 40px;
      width: 140px;
      flex-shrink: 0;

      img {
        width: 100%;
      }

      @include screen-width-small {
        margin: 15px 0;
      }
    }

    .notice-expandable-section {
      .more-information {
        margin-top: 15px;
      }

      .more-information-button {
        border: none;
        background: none;
        // width: 130px;
        justify-content: space-between;
        display: flex;
        align-items: center;
        margin-top: 22px;
        float: right;
        gap: 20px;
        color: $kk-primary;
        text-transform: uppercase;
      }

      p {
        margin: 0;
      }

      @include screen-width-small {
        padding: 20px 2px;
      }
    }

    @include screen-width-small {
      flex-direction: column;
      gap: 0;
    }
  }

  @include screen-width-small {
    padding: 0 22px;
  }
}
