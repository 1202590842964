@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';


.story-selector-component, .story-selector-accordion-component, .video-selector, .video-selector-mobile {
  .story-selector-body-container {
    .story-selector-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #F9F4F2;
      border-radius: 5px;
      // padding: <- We set this lower down to be exclusive to it's parent
      margin-bottom: 10px; // 10px for when it stacks. Note, it's parents only have 20px (not 30px) margin bottom because of this
      .meta-tag {
        color: #EA5504;
      }
      .story-body-title {
        font-family: $hindMadurai-reg;
        font-size: 30px;
        margin: 0px; // not even bottom margin
        text-align: left;
      }
      .meta-tag-category {
        font-family: $hindMadurai-reg;
        font-size: 16px;
        margin: 0px 0px 12px 0px;
      }
      .meta-tag-date {
        font-size: 12px;
        margin: 0px 0px 11px 0px;
      }
      .story-body-text {
        font-family: $hindMadurai-reg;
        font-size: 16px;
        color: #333333;
        margin-bottom: 0px 0px 24px 0px;
      }
      .learn-more {
        display: inline-flex;
        color: $kk-primary;
        font-family: $subtitle-font;
        justify-content: space-between;
        padding: 0px;
        border: 0px;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
        .learn-more-text {
          font-family: $subtitle-font;
          text-decoration: none;
          margin: 0px 12px 0px 0px;
        }
        .svg-container {
          svg {
            display: block;
            height: 16px;
          }
        }
      }
    }
  }
}

.story-selector-component, .video-selector {
  .story-selector-body-container {
    @include spans-columns(8);
    .story-selector-body {
      padding: 30px;
    }
  }
}

.video-selector, .video-selector-mobile {
  .story-selector-body-container {
    .story-selector-body {
      background: inherit;
    }
  }
}

.story-selector-accordion-component, .video-selector-mobile {
  .story-selector-body-container {
    @include spans-columns(12);
    .story-selector-body {
      padding: 14px 20px;
    }
  }
  .story-selector-accordion-head-button {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
    align-items: center; // for some reason stretch wasn't working?
    padding: 0px;
    border: 0px;
    .story-body-title {
      display: block;
      margin-right: 12px;
      font-size: 20px;
    }
    .svg-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #EA5504;
      svg {
        width: 24px;
        display: block;
      }
    }
  }
}