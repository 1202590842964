@import '../../../../assets/kanso/styles/global.scss';

.imageList {
  div ul {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 0px;
    justify-content: center;
    margin-top: -15px;
    margin-bottom: -15px;
    li {
      padding: 0;
    }
    @include media(medium, large, extraLarge, extraExtraLarge) {
      li:first-of-type {
        justify-self: right;
      }
      li:last-of-type {
        justify-self: left;
      }
    }
  }
}