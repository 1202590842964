.iframe {
    &-preview {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #5d6164;
        border-radius: 32px;
        max-width:331px;
        height:190px;
        margin: 0 auto;
        
        @media (min-width: 992px) {
            max-width: 784px;
            height:479px;
            width:784px;
        }
        .iframe-preview--success {
            text-align: center;
            padding: 20px;
            flex: 1;
            height: 100%;
            
        }
        .iframe-image-sucess {
            position: relative;
            display: flex;
            align-items: center;
            overflow: hidden;
            height: 100%;
            
            &:after {
                content: '';
                position: absolute;
                background: rgba(0, 0, 0, 0.1);
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
        }
       
    }

    &-grey {
        background-color: #5d6164;
        color: #fff;
        padding: 5px 20px;
    }

    &-button {
        color: #fff;
        padding: 15px 10px;
        border-radius: 20px;
        background-color: #A42A2E;
        border: 1px solid #A42A2E;
        display: block;
        margin: 25px auto;
    }
    &-preview--error{
        text-align: center;
    }
}
.tooltiptext {
    visibility: hidden;
    width: 120px;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    right: -18px;
    padding-top: 2px;
}

.button-frame:hover .tooltiptext {
    visibility: visible;
}
.imagebutton{
    padding-left: 14px;
}
.button-placement{
    @media only  screen and (min-width:992px){
        width:784px;
    }
}
.iframe-button-color{
    background-color:#EA570D;
    border:1px solid #EA570D;
    color: #fff;
    padding: 15px 10px;
    border-radius: 20px;
    display: block;
    margin: 25px auto;
}
.edetailerModal{
    padding-bottom: 0px;
}
.pdfPreview{
    height:100%;
}
.pdfShow{
    overflow-y: scroll;
    height: 100%;
}
#pdf{
    .embedded-pdf-controls{
        display: block;
        bottom:100px;
        left:45%;
    }
    span{
        display: inline;
    }
    button{
        display: inline;
    }
}
