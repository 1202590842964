@import '../../../../../assets/mixins.scss';

$header-height : 80px;
.app-layout--v1 {
    .DesktopNavigation {
        display: none;
        margin-left: 1em;
        margin-right: auto;
    
        // If browser supports css grid use
        @supports (display: grid) {
            grid-area: mainNav;
            margin-right: none;
        }
    
        @include desktopOnly {
            display: block;
        }
    
        .Main {
            display: flex;
            padding: 0;
            margin: 0;
            list-style-type: none;
            height: $header-height;
    
            // If browser supports css grid use
            @supports (display: grid) {
                display: grid;
                grid-auto-columns: max-content;
                grid-auto-flow: column;
                column-gap: 1em;
            }
    
            li {
                display: flex;
                align-items: center;
                height: $header-height;
                margin: 0 1.5em;
                color: #FFFFFF;
    
                // If browser supports css grid use
                @supports (display: grid) {
                    display: grid;
                    margin: 0;
                }
    
                a {
                    display: flex;
                    align-items: center;
                    color: #FFFFFF;
                    text-decoration: none;
                    height: calc(100% - 1px);
                    border-top: 4px transparent solid;
                    border-bottom: 4px transparent solid;
    
                    // If browser supports css grid use
                    @supports (display: grid) {
                        display: grid;
                        grid-auto-flow: column;
                        column-gap: .2em;
                    }
    
                    &.active,
                    &:hover {
                        border-bottom: 4px #FFFFFF solid;
                    }
                }
    
                .hasChild::after {
                    font-size: 10px;
                    content: '▼';
                    margin: 4px 0 0 4px;
    
                    // If browser supports css grid use
                    @supports (display: grid) {
                        margin: 0;
                    }
                }
            }
        }
    }
}
.app-layout--v2 {
    .DesktopNavigation .Main li .active {
        padding: 0 20px;
        background-color: #FEF6F2;
    }
    
    .DesktopNavigation {
        display: none;
        margin-left: 6em;
        margin-right: auto;
        @media (max-width: 1050px) {
            margin-left: 0;
        }
        // If browser supports css grid use
        @supports (display: grid) {
            grid-area: mainNav;
            margin-right: none;
        }
    
        @include tabletMin {
            display: block;
        }
    
        .Main {
            display: flex;
            padding: 0;
            margin: 0;
            list-style-type: none;
            height: $header-height;
    
            // If browser supports css grid use
            @supports (display: grid) {
                display: grid;
                grid-auto-columns: max-content;
                grid-auto-flow: column;
                column-gap: 50px;
            }
    
            li {
                display: flex;
                align-items: center;
                height: $header-height;
                margin: 0 1.5em;
                color: #FFFFFF;
    
                // If browser supports css grid use
                @supports (display: grid) {
                    display: grid;
                    margin: 0;
                }
    
                a {
                    display: flex;
                    align-items: center;
                    color: #1C364E;
                    text-decoration: none;
                    height: calc(100% - 1px);
                    border-top: 4px transparent solid;
                    border-bottom: 4px transparent solid;
    
                    // If browser supports css grid use
                    @supports (display: grid) {
                        display: grid;
                        grid-auto-flow: column;
                        column-gap: .2em;
                    }
                    
                }
                
                .hasChild.active {
                    svg {
                        display: none;
                    }
                }
                .hasChild.active::before{
                    content:"";
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #EA5504;
                    margin-right: 4px;
                }
                
    
            }
        }
    }
}
