.overlay-modal {
    position: fixed;
    top: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background-color: rgba(2, 2, 2, 0.548);
    line-height: 1.5em;

    &.hide-overlay {
        display: none;
    }
}

body.no-scroll {
    overflow: hidden;
}

