@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_titleWithIcon.scss';
@import '../../../../../assets/mixins.scss';

.CardHeading {
    display: flex;
    align-items: flex-start;
    font-family: $subtitle-font;
    margin: 0px 0px 30px 0px;
    max-width: 700px;
    text-decoration: none;
    flex-shrink: 0;
    font-family: $hindMadurai-sb;
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0px;


    p {
        margin: 0;
        display: block;
        margin-right: auto;
        font-family: $hindMadurai-sb;
        font-size: 20px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0px;
    }

    img {
        margin-left: 40px;
        flex-shrink: 0;
    }
}