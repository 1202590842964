@import '../../../../assets/kanso/styles/global.scss';

.personList {
  div ul {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 24px;
    margin-bottom: -20px;
    li {
      padding: 0;
    }
  }
}