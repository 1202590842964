@import '../../../../assets/mixins.scss';


.timer{
    margin: auto;
    padding: 20px;

    @include desktopOnly {
        padding: 0;
    }
}

.countdown{
    margin-left: 5px;
    font-weight: bold;
}

.show-counter{
    color: #ea5504;
    display: inline-flex;
}