@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.login-page-component {
  flex-grow: 1; // for those ridoculously tall screens
  background-repeat: no-repeat;
  background-size: 250px 218px;
  background-position: 100% -36px;
  background-color: #fff;
  background-image: url('../../../../assets/images/login_background_new.png');
  padding-top:40px;

  .login-page-container {
    @include container-rules();

    .page-content {
      @include grid-row();

      @include screen-width-mobile {
        flex-direction: column-reverse;
      }

      .login-page-form {
        .role-dropdown-select {
          margin-bottom: 30px;
        }

        .default-dropdown-select {
          margin-bottom: 30px;
        }

        @include spans-columns-small(4);
        @include offset-columns-small(0);
        @include spans-columns(6);
        @include offset-columns(1);
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

        @include screen-width-mobile {
          @include spans-columns(12);
          @include offset-columns(0);
        }
      }
    }
  }

  .withdraw-consent-component {
    margin-top: 0;
    a {
      color: inherit;
    }
  }
}

.withdraw-consent-alt-component {
  font-family: $hindMadurai-reg;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
  margin-top: -10px;
  margin-bottom: 30px;

  a {
    color: inherit;
  }
}

@include screen-width-desktop {
  .login-page-component {
    background-size: 499px 437px;
    background-position: 102% -80px;
    padding-top:50px;

    .login-page-container {
      .page-content {
        .login-page-form {
          padding-top: 10px;
        }
      }
    }
  }
}