.eapc-thank-you-page {
  .text-and-image-panel__columns__left {
    .RichText {
      height: auto;
    }
  }

  .text-and-image-panel__columns__right__link {
    a {
      margin-top: 30px;
    }
  }
}