@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.form-redesign {
  .role-dropdown-select { 
      select {
          border-radius: 4px !important;
          background-image: url('https://content-eu-1.content-cms.com/8d91cb19-ea2d-486c-88f7-f3578ba5c4d7/dxdam/0b/0b3ea2b2-1730-4774-82a2-f45feebe19e6/Path.png') !important;
      }
      select:focus {
          border:1.5px solid #EA5504 !important;
          outline: none;
      }
  }
  .form-inline-error-text {
      float: unset;
    }
}
.required-input{
  border: 1.5px solid #FF0000 !important;
};

.form-field-role-dropdown {
  .field-meta-data {
    display: flex;
    justify-content: space-between;
    label {
      display: inline;
      font-family: $hindMadurai-reg;
      font-size: 12px;
      line-height: 14px;
      color: #333333;
    }

    .form-inline-error-text {
      display: inline;
      font-family: $hindMadurai-reg;
      font-size: 12px;
      line-height: 14px;
      text-align: left;
      color: #ff0000;
      margin: 0px;
      padding: 0px;
    }
  }

  .role-dropdown-select {
    position: relative;
    width: 100%;

    .role-dropdown {
      width: 100%;
      font-size: 16px;
      line-height: 19px;
      border: 1px solid #c4c4c4;
      border-radius: 30px;
      padding: 12px 20px;
      cursor: pointer;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      background-image: none;
      -ms-word-break: normal;
      word-break: normal;
      -webkit-appearance: none;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAHCAYAAAAxrNxjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACGSURBVHgBjY6xDcIwFET/t6ew3DACsis6KOlgA0aATViBhay/Aa7s0GUB25wbhKIkyhVfp9PTv2Pv/ZuIdrSi1tpD4dAWqVrrCXBcYV4hhKcSkai1viIYZyAppdy70f2klAZjzIeZL3+7ItrOeDT8wK6cs1hrGfYIaAR06G2Lg5xztz00zb+J1ztro5jh1AAAAABJRU5ErkJggg==');
      background-repeat: no-repeat;
      background-position: right;
      background-position-x: calc(100% - 20px);
    }
  }
}

.form-field-role-dropdown-profile {
  margin-bottom: 30px;

  select {
    background-color: #faf6f4;
  }
}

.form-field-role-dropdown-anonymous {
  margin-bottom: 20px;
}
