.sectionHeader {
    .sectionHeader__title {
        font-size: 40px;
        position: relative;
        width: 97%;
        padding: 0;
        &::after {
            content: '';
            display: block;
            margin: 20px 0;
            width: 50px;
            height: 1px;
            background-color: #ffffff;
        }
    }
    .sectionHeader__subTitle {
        font-size: 27px;
        margin: 16px 0;
    }
}