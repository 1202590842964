@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import "style/modal.scss";

.cookie-banner-modal {
  .modal {
    &-content {
      padding: 30px;
      border-radius: 30px;
    }
    &-header {
      padding: 0;
      border-bottom: 1px solid $kk-primary;
    }
    &-title__main {
      font-size: 25px;
      line-height: 29px;
      margin: 0 0 30px;
    }
    &-title__subtitle {
      margin-bottom: 20px;
      margin-top: 0;
      font-size: 16px;
      line-height: 16px;
      color: $footer-grey;
    }
    &-body {
      padding:0;
      &__section {
        padding: 0;
        margin: 20px 0 0;

        &-title {
          color: $body-text-color;
          font-size: 16px;
          line-height: 16px;
          font-weight: bold;
          margin: 0 0 10px;
        }

        &-text {
          color: $footer-grey;
          font-size: 16px;
          line-height: 19px;
          font-weight: normal;
          margin: 0 0 10px;
        }

        &-controls {
          color: $kk-primary;
          font-size: 16px;
          line-height: 14px;
          font-weight: normal;
          margin: 0;
        }
      }
    }
    &-footer{
      border-top: none;
      justify-content: flex-start;
      padding: 30px 0 0;

      & > button {
        text-transform: uppercase;
        min-width: 130px;
        margin: 0 10px 0 0;

        &.blank {
          color: #333;
        }

      }
    }
  }
}

@include screen-width-desktop {
  .cookie-banner-modal {
    .modal {
      &-footer{
        & > button {
          min-width: 160px;
          margin-right: 20px;
        }
      }
    }
  }
}
