@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_videoFigure.scss';
@import '../../../assets/mixins.scss';

.app-layout--v1, .app-layout--v2 {
    .container_country {
        width: auto;
        height: auto;
    
        @include desktopOnly {
            display: flex;
            flex-wrap: wrap;
        }
    
        .CountrySelector {
            margin: 0;
            color: #333;
            cursor: default !important;
            @include desktopOnly {
                width: 25%;
            }
    
            img {
                width: 33px;
                height: 33px;
                max-width: 33px;
                margin-right: 10px;
            }
        }
    
        .Desktop {
            padding: 0 !important;
            @include desktopOnly {
                padding: 20px 0 !important;
            }
            
        }
    
        .active {
            color: #ea5504;
        }
    
        &.collapsed{
            display: none!important;
        }
    
        a{
            display: flex;
            align-items: center;
            color: #333;
            text-decoration: none;
            p {
                font-size: 16px;
                font-family: $hindMadurai-sb;
                font-weight: 600;
                line-height: 22px;
                letter-spacing: 0em;
                @include screen-width-mobile {
                    font-weight: 500;
                }
            }
            @include screen-width-mobile {
                width: 100%;
            }
        }
    
        a:hover {
            text-decoration: underline;
            color: #ea5504;
        }
    }
}