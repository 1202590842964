@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';


.app-layout--v1 {
  .main-title-section {
    background-color: $kk-secondary;
    .title-section-inner {
      padding-top: 50px;
      padding-bottom: 20px;
      
      .title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .title-section-title {
          color: $kk-primary;
          font-size: 60px;
          margin: 0;
          line-height: 100%;
          font-weight: normal;
          font-family: $title-font;
          .RichText {
            p {
              margin: 0;
            }
          }
        }
        .title-section-category {
          font-size: 25px;
          font-family: $myriad-reg;
          color: $body-text-color;
          margin: 0 0 20px;
          line-height: 100%;
          font-weight: normal;
          text-transform: uppercase;
        }
        img {
          margin-bottom: 16px;
          max-width: 225px;
        }
      }
      .description {
        p {
          word-break: break-word;
          font-size: 20px;
          line-height: 25px;
        }
      }
      @include screen-width-small {
        padding-top: 27px;
        flex-direction: column;
        .title {
          .title-section-title {
            font-size: 36px;
          }
        }
        .description {
          p {
            padding-left: 0;
          }
        }
      }
    }
    &.has-category {
      .title-section-inner {
        .title {
          @include screen-width-mobile {
            @include spans-columns-small(4);
            @include spans-columns(5);
          }
          @include screen-width-desktop {
            @include spans-columns(4);
          }
        }
        .description {
          @include screen-width-mobile {
            @include spans-columns-small(4);
            @include spans-columns(7);
          }
          @include screen-width-desktop {
            @include spans-columns(8);
          }
        }
      }
    }
    &--promo {
      .title-section-inner {
        @include screen-width-desktop{
          display: flex;
        }
        .title {
          @include screen-width-mobile {
            @include spans-columns-small(4);
            @include spans-columns(5);
          }
          @include screen-width-desktop {
            @include spans-columns(4);
          }
          .title-section-title,
          .title-section-category {
            color: white;
          }
          // On --promo content we want to place the category below the title and make it a little bigger
          .title-section-title {
            order: 1;
            margin-bottom: 10px;
          }
          .title-section-category {
            order: 2;
            font-size: 35px;
            font-family: $title-font;
            margin-bottom: 0px;
            text-transform: none;
          }
        }
        .description {
          @include screen-width-mobile {
            @include spans-columns-small(4);
            @include spans-columns(7);
          }
          @include screen-width-desktop {
            @include spans-columns(8);
          }
          // this isn't perfect as we might want <a> to be a different color than other tags here. However they don't use
          // a tags here at the moment. It's a problem for another day.
          * {
            color: white;
          }
        }
      }
    }
  }
  
  .main-title-section {
    .title-section-inner.use-header-alternative {
      display: flex;
      .description {
        margin: 0;
        padding-left: 10%;
      }
    }
  }
}
.app-layout--v2 {
  .main-title-section {
    background-color: $kk-primary-light;
    .title-section-inner {
      padding-top: 57px;
      padding-bottom: 29px;
  
      .title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .title-section-title {
          color: $kk-primary;
          font-size: 60px;
          margin: 0;
          line-height: 100%;
          font-weight: 600;
          font-family: $hindMadurai-reg;
  
          .RichText {
            p {
              margin: 0;
            }
          }
        }
        .title-section-category {
          font-size: 25px;
          color: $body-text-color;
          margin: 0 0 20px;
          line-height: 100%;
          font-weight: normal;
          text-transform: uppercase;
        }
        img {
          margin-bottom: 16px;
          max-width: 225px;
        }
      }
      .title-description {
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        padding: 11px 0;
  
        p {
          word-break: break-word;
          // margin: 0;
        }
      }
      @include screen-width-small {
        padding-top: 24px;
        padding-bottom: 11px;
        flex-direction: column;
        .title {
          .title-section-title {
            font-size: 40px;
          }
        }
        .title-description {
          font-size: 16px;
          padding: 18px 0;
          // p {
            // padding-left: 0;
            // margin: 0;
          // }
        }
      }
    }
    &.has-category {
      .title-section-inner {
        .title {
          @include screen-width-mobile {
            @include spans-columns-small(4);
            @include spans-columns(5);
          }
          @include screen-width-desktop {
            @include spans-columns(4);
          }
        }
        .description {
          @include screen-width-mobile {
            @include spans-columns-small(4);
            @include spans-columns(7);
          }
          @include screen-width-desktop {
            @include spans-columns(8);
          }
        }
      }
    }
    &--promo {
      .title-section-inner {
        @include screen-width-desktop{
          display: flex;
        }
        .title {
          @include screen-width-mobile {
            @include spans-columns-small(4);
            @include spans-columns(5);
          }
          @include screen-width-desktop {
            @include spans-columns(4);
          }
          .title-section-title,
          .title-section-category {
            color: white;
          }
          // On --promo content we want to place the category below the title and make it a little bigger
          .title-section-title {
            order: 1;
            margin-bottom: 10px;
          }
          .title-section-category {
            order: 2;
            font-size: 35px;
            font-family: $title-font-v2;
            margin-bottom: 0px;
            text-transform: none;
          }
        }
        .title-description {
          @include screen-width-mobile {
            @include spans-columns-small(4);
            @include spans-columns(7);
          }
          @include screen-width-desktop {
            @include spans-columns(8);
          }
          // this isn't perfect as we might want <a> to be a different color than other tags here. However they don't use
          // a tags here at the moment. It's a problem for another day.
          * {
            font-family: $title-font-v2;
            color: white;
          }
        }
      }
    }
  }
  
  .main-title-section {
    .title-section-inner.use-header-alternative {
      display: flex;
      .description {
        margin: 0;
        padding-left: 10%;
      }
    }
  }
  .main-title-section {
    &--promo {
      .title {
        .title-section-category {
          order: 2;
          font-size: 35px;
          font-family: $title-font-v2;
          margin-bottom: 0px;
          text-transform: none;
        }
      }
    }
  }
}