@import './mixins.scss';

@mixin grid() {
    display: flex;
    flex-wrap: wrap;

    @supports(grid-area: auto) {
        display: grid;
    }
}

@mixin gridAuto($items-per-row-sm: 1, $items-per-row-md: 2, $items-per-row-lg: 3, $gap-sm: 10px, $gap-md: 15px, $gap-lg: 20px) {
    margin-left: -#{$gap-sm};

    >* {
        margin-bottom: #{$gap-sm};
        margin-left: #{$gap-sm};

        width: calc((99%/ #{$items-per-row-sm}) - #{$gap-sm});
        flex: 0 0 calc((99% / #{$items-per-row-sm}) - #{$gap-sm});
    }


    @include tabletMin {
        margin-left: -#{$gap-md};

        >* {
            margin-bottom: #{$gap-md};
            margin-left: #{$gap-md};

            width: calc((99%/ #{$items-per-row-md}) - #{$gap-md});
            flex: 0 0 calc((99% / #{$items-per-row-md}) - #{$gap-md});
        }
    }


    @include desktopOnly {
        margin-left: -#{$gap-lg};

        >* {
            margin-bottom: #{$gap-lg};
            margin-left: #{$gap-lg};

            width: calc((99%/ #{$items-per-row-lg}) - #{$gap-lg});
            flex: 0 0 calc((99% / #{$items-per-row-lg}) - #{$gap-lg});
        }
    }


    @supports(grid-area: auto) {
        grid-gap: #{$gap-sm} #{$gap-sm};
        grid-template-columns: repeat(1, minmax(auto, 1fr));

        @include tabletMin {
            grid-gap: #{$gap-md} #{$gap-md};
            grid-template-columns: repeat(#{$items-per-row-md}, minmax(auto, 1fr));
        }

        @include desktopOnly {
            grid-gap: #{$gap-lg} #{$gap-lg};
            grid-template-columns: repeat(#{$items-per-row-lg}, minmax(auto, 1fr));
        }

        margin-left: 0;

        >* {
            width: auto;
            margin-left: 0;
            margin-bottom: 0;
        }
    }

}