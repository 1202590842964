@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_titleWithIcon.scss';
@import 'src/style/mixins/_videoFigure.scss';

section[role="main"].article-page-component {
  p.date {
    color: $kk-primary;
  }
  .article-page {
    @include grid-row();
    .column-left {
      @include spans-columns(3);
      @include spans-columns-small(4);
      .main-page-subnavsection {
        .content-page-promo-subnav {
          padding: 18px 50px 0 0;
        }
        
      }
    }
    .column-right {
      @include spans-columns(3);
      @include spans-columns-small(4);
    }
    @include screen-width-mobile {
      flex-direction: column;
      .column-left {
        @include spans-columns(12);
      }
      .column-right {
        @include spans-columns(12);
      }
      article {
        @include spans-columns(12);
      }
    }
  }
  article {
    @include spans-columns(6);
    @include spans-columns-small(4);
    padding-top: 0px;
    padding-bottom: 0px;
    figure.video {
      @include video-figure();
      margin: 0px 0px 40px;
      box-shadow: $card-box-shadow;
      video {
        width: 100%;
      }
    }
  }
  .related-content-section {
    background-color: $kk-secondary;
    padding-top: 20px;
    padding-bottom: 50px;
    .related-content-header {
      font-size: 25px;
      font-family: $subtitle-font;
    }
    .related-content-list {
      list-style: none;
      margin: 0;
      padding: 0;
      flex-wrap: wrap;
      @include grid-row();
      li {
        @include spans-columns(3);
        @include spans-columns-small(4);
        margin-bottom: 20px;
        .text-card {
          background-color: white;
          height: 100%;
        }
      }
      @include screen-width-medium {
        li {
          @include spans-columns(6)
        }
      }
    }
  }
  .references-section {
    padding-top: 40px;
    padding-bottom: 10px;
  }
}