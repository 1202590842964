@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';


.login-page-text {
  @include spans-columns-small(4);
  @include spans-columns(5);
  @include screen-width-mobile {
    @include spans-columns(12);
  }
  margin-bottom: 20px;
  
  .RichText  {

    p {
      display: block;
      width: 100%;
      font-family: $hindMadurai-reg;
      font-size: 16px;
      line-height: 19px;
      margin: 0px 0px 30px 0px;
    }

    ul {
      margin: 0px 0px 30px 0px;
      padding-left: 19px;
      list-style-type: square;
      li::marker, li:before {
        color: $kk-primary;
        font-size: 20px;
      }
      li {
        a {
          color: inherit;
        }
      }
    }
  }
}
