@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/modal.scss';

.star-rating-view {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 100px;
  border-top: 1px solid $button-grey;
  border-bottom: 1px solid $button-grey;
  margin-bottom: 40px;
  margin-top: 10px;

  .rating-text {
    font-weight: 600;
    font-size: 25px;
    margin: 0 20px;
    text-align: center;

    @include screen-width-small {
      margin: 0 0 18px 0;
      font-size: 11px;
    }
  }

  .star-rating-content {
    display: flex;
    align-items: center;

    span {
      margin: 0 8.5px;

      @include screen-width-small {
        margin: 0 10px;
        width: 24px !important;
        height: 24px !important;
      }
    }

    svg {
      @include screen-width-small {
        width: 24px;
        height: 24px;
      }
    }
  }

  @include screen-width-small {
    flex-direction: column;
    padding: 20px;
  }

  &--hide {
    display: none;
  }
}

.disable-rating {
  pointer-events: none;
}
