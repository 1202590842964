@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_titleWithIcon.scss';
@import '../../assets/mixins.scss';



.content-page {
  .content-page-main-section {
    display: flex;
    flex-direction: row;
    max-width: 1600px;
    margin: 0 auto;

    .content-page-promo-content-subnav-section {
      @include desktopOnly {
        width: 45%;
      }
      
      .content-page-promo-subnav {
        @include desktopOnly {
          padding: 40px 5px 40px 30px;
        }

        @include desktopOnlyLarge {
          padding: 40px 0 40px 70px;
        }

        &-link {
          &-level2 {
            padding-left: 35px;
            font-weight: normal;
          }
  
          &--active {
            color: #EA570D;
          }
  
        }
      }
    }

    
    .content-section {

      @include desktopOnly {
        margin-left: 40px;
      }

      .content-page-article {
        @include spans-columns(10);
        @include spans-columns-small(4);
        // width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }

    

  }

  
  .content-promo-intro-text {
    margin-bottom: 30px;
  }
  .outer-container {
    background-color: $background-grey;
    // 30px padding-top needed to spread the background above. 30px not needed on the bottom though as the contents
    // give the 30px on the bottom
    padding: 30px 0px 0px 0px;
    .container {
      .carousel-card-slide {
        height: 100%; // needed to stop the image from single card carsould from being too big for the view
      }
    }
  }
}