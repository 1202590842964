@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.key-speaker {
  padding: 0 20px 40px 0;
  max-width: 382px;

  @media (max-width: 783px) {
    padding: 0 20px 30px 0;
  }

  @include screen-width-xl {
    max-width: 460px;
    width: 460px;
  }

  &__image {
    width: 100%;

    img {
      width: 100%;
      border-radius: 15px;
    }
  }

  &__text {
    width: 100%;
    min-height: 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin-top: 5px;

    @media (max-width: 783px) {
      margin-top: 15px;
    }
  }

  @media (max-width: 783px) {
    max-width: 100%;
    width: 100%;
    &__image {
      width: 100%;

      img {
        width: 100%;
      }
    }

    &__text {
      width: 100%;
    }
  }
}
