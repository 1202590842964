@import 'assets/kanso/styles/global.scss';

.form-select__field {
    position: relative;
    display: flex;
    flex-direction: column;
}

.form-select__label {
    // font-family: $base-font-family;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: #4b4c53;
    line-height: 26px;
    letter-spacing: 0;
    margin-bottom: 4px;
}

.form-select__field--error {

    .form-select__label {
        // color: $input-error;
    }
}

.form-select__field__error-icon {
    position: absolute;
    bottom: 19px;
    right: -30px;
}

[dir="rtl"] .form-select__field__error-icon {
    left: -30px;
    right: auto;
}

.form-select__field--disabled {

    .form-select__label {
        color: #c51d55;
    }
}

