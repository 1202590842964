@import "../../../../assets/kanso/styles/global.scss";

.editorialText {
  font-family: var(--base-font-family);
  font-size: var(--et-font-size, var(--base-font-size));
  font-weight: var(--et-font-weight, var(--base-font-weight));
  line-height: var(--et-line-height, var(--base-line-height));
  color: var(--et-text-color, var(--base-font-color));
  text-align: var(--et-text-align, left);
  padding: 0;
  margin: 0;
}

.editorialText .editorialText-caption {
  font-size: var(--et-caption-font-size, 16px);
  font-weight: var(--et-caption-font-weight, 600);
  line-height: var(--et-caption-line-height, 25.6px);
  margin: 0 0 24px;
}

.editorialText .headline {
  font-size: var(--et-headline-font-size, 28px);
  font-weight:  var(--et-headline-font-weight, 400);
  line-height:  var(--et-headline-line-height, 33.6px);
  margin:  var(--et-headline-margin, 0 0 24px 0);
}

.editorialText .editorialText-content {
  margin: 0 0 24px;
  column-count: var(--et-text-column-count, 1);

  &:last-child {
    margin: 0;
  }
  p {
    margin: 0 0 24px;

    &:last-child {
      margin: 0;
    }
  }
  ol {
    margin-top: 0;
    display: inline-block;
  }
  ul {
    margin-top: 0;
    display: inline-block;
  }
  a {
    text-decoration: none;
  }
  sup {
    font-size: var(--et-sup-font-size, 12px);
    font-weight: var(--et-sup-font-weight, 700);
    padding: 0 3px 0 0;

    a {
      font-size: var(--et-sup-font-size, 12px);
    }
  }
  sub {
    font-size: var(--et-sub-font-size, 12px);
    font-weight: var(--et-sub-font-weight, 700);
    padding: 0 3px 0 0;

    a {
      font-size: var(--et-sub-font-size, 12px);
    }
  }
  mark {
    font-weight: var(--et-mark-font-weight, 600);
    color: var(--et-mark-text-color, #000);
    background-color: var(--et-mark-bgcolor, #ffff00);
    padding: 0 3px;
  }
}

.editorialText .editorialText-btn {
  --primary-btn-font-weight: var(--et-primary-btn-font-weight);
  --primary-btn-line-height: var(--et-primary-btn-line-height);
  --primary-btn-text-color: var(--et-primary-btn-text-color);
  // --primary-btn-bgcolor: var(--et-primary-btn-bgcolor);
  // --primary-btn-border-color: var(--et-primary-btn-border-color);
  --primary-btn-hover-text-color: var(--et-primary-btn-hover-text-color);
  // --primary-btn-hover-bgcolor: var(--et-primary-btn-hover-bgcolor);
  // --primary-btn-hover-border-color: var(--et-primary-btn-hover-border-color);
  --primary-btn-active-text-color: var(--et-primary-btn-active-text-color);
  // --primary-btn-active-bgcolor: var(--et-primary-btn-active-bgcolor);
  // --primary-btn-active-border-color: var(--et-primary-btn-active-border-color);  
  --secondary-btn-font-weight: var(--et-secondary-btn-font-weight);
  --secondary-btn-line-height: var(--et-secondary-btn-line-height);
  // --secondary-btn-text-color: var(--et-secondary-btn-text-color);
  --secondary-btn-bgcolor: var(--et-secondary-btn-bgcolor);
  // --secondary-btn-border-color: var(--et-secondary-btn-border-color);
  --secondary-btn-hover-text-color: var(--et-secondary-btn-hover-text-color);
  // --secondary-btn-hover-bgcolor: var(--et-secondary-btn-hover-bgcolor);
  // --secondary-btn-hover-border-color: var(--et-secondary-btn-hover-border-color);
  --secondary-btn-active-text-color: var(--et-secondary-btn-active-text-color);
  // --secondary-btn-active-bgcolor: var(--et-secondary-btn-active-bgcolor);
  // --secondary-btn-active-border-color: var(--et-secondary-btn-active-border-color);  
  --tertiary-btn-font-weight: var(--et-tertiary-btn-font-weight);
  --tertiary-btn-line-height: var(--et-tertiary-btn-line-height);
  // --tertiary-btn-text-color: var(--et-tertiary-btn-text-color);
  // --tertiary-btn-hover-text-color: var(--et-tertiary-btn-hover-text-color);
  --tertiary-btn-hover-bgcolor: var(--et-tertiary-btn-hover-bgcolor);
  // --tertiary-btn-active-text-color: var(--et-tertiary-btnactive-text-color);
  --tertiary-btn-active-bgcolor: var(--et-tertiary-btn-active-bgcolor);
  
  a {
    margin: 0 0 16px;    
    @include media (medium, large, extraLarge, extraExtraLarge) {
      margin: 0 16px 0 0;
      [dir=rtl] & {
        margin: 0 0 0 16px;
      }
    }
  }
}

@media print {
  .editorialText {
    display: block;
    text-align: left;
    margin: 0;
    padding: 0;
    break-before: avoid !important;
    break-after: avoid !important;
    break-inside: avoid !important;
    page-break-before: avoid !important;
    page-break-after: avoid !important;
    page-break-inside: avoid !important;
    .editorialText-btn {
      display: none;
    }
    .headline {
      font-size: 18px;
      line-height: 1.7rem;
      margin: 0;
      padding-bottom: 0.05in !important;
    }
    .editorialText-content {
      display: block !important;
    }
    .editorialText-content ul, 
    .editorialText-content ol {
      display: block !important;
      margin: 0 !important;
      padding: 0 !important;
      li {
        break-before: auto;
        margin: 0 !important;
        padding-bottom: 0.15in !important;
        display: block !important;
      }
    }
  }
}