@import 'style/variables.scss';
@import 'style/mixins/_titleWithIcon.scss';


.authors-section-container {
  background-color: $kk-secondary;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;

  h4 {
    @include title-with-icon;
    font-family: $subtitle-font;
    padding: 20px;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid white;
  }

  .authors-section-body {
    padding: 20px;
    margin: 0;
    font-size: 16px;
    line-height: 18px;

    p:first-child {
      margin-top: 0;
    }
  }
}