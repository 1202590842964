@import 'style/mixins/_responsive.scss';
@import '../../../../../assets/mixins.scss';
@import 'style/variables.scss';


.app-layout--v1 {
  .toastbar {
    font-family: "Myriad Pro Regular";
    font-size: normal;
    font-weight: 400;
    background-color: #f9f4f2;
    color: #000;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  
    .toastbar-content {
      width: 1440px;
      padding: 0 1em;
  
      @include desktopOnly {
        display: flex;
      }
    }
    .RichText{
      width: 100%;
      @include desktopOnly {
        width: 75%;
      }
    }
  
    .RichText p {
      font-family: "Myriad Pro Regular";
      font-size: 16px;
      font-weight: 400;
      margin-top: 16px;
      line-height: 20px;
  
      @include tabletMax {
        margin-bottom: 0;
      }
    }
  
    .toastbar-icon {
      display: inline-flex;
      padding-left: 10px;
      position: relative;
      top: 2px;
    }
    .toastbar-link {
      margin-block: auto;
      margin-left: auto;
    }
    .adverse-link {
      text-decoration: none;
      font-family: "Myriad Pro Regular";
      font-weight: 600;
      line-height: 60px;
      font-size: 16px;
      color: #ea5504;
    }
  }
}
.app-layout--v2 {
  .toastbar {
    font-family: $hindMadurai-reg;
    display: flex;
    width: 100%;
    position: relative;
  
    @include desktopOnly {
      min-height: 52px;
      height: 100%;
    }
  
    .toastbar-bg {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      display: none;
  
      @include desktopOnly {
        display: flex;
        flex-direction: row;
      }
  
      .toastbar-content-bg {
        background-color: #5d6164;
        @include desktopOnly {
          height: 100%;
          width: 80%;
        }
      }
  
      .toastbar-link-bg {
        background-color: #ea5504;
        @include desktopOnly {
          height: 100%;
          width: 20%;
        }

        @media (min-width: 2100px) {
          height: 100%;
          width: 35%;
        }
        @media (min-width: 3400px) {
          height: 100%;
          width: 40%;
        }
        @media (min-width: 4000px) {
          height: 100%;
          width: 45%;
        }
        @media (min-width: 4800px) {
          height: 100%;
          width: 50%;
        }
        @media (min-width: 6000px) {
          height: 100%;
          width: 55%;
        }
        @media (min-width: 6000px) {
          height: 100%;
          width: 60%;
        }
      }
    }
  
    .toastbar-content {
      z-index: 12;
      width: 1440px;
      margin: auto;
      height: 100%;
      display: flex;
      align-items: center;
      
      @include desktopOnly {
        padding: 0 1em;
        height: 100%;
        min-height: 52px;
      }
    }
  
    .toastbar-content-wrap {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;
  
      .RichText {
        min-height: 52px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #5d6164;
        padding: 14px 1em;
        a {
          color: #fff;
        }
        @include desktopOnly {
          align-items: center;
          width: 80%;
          padding: 0;
          flex-direction: row;
        }
      }
  
      @include desktopOnly {
        min-height: 52px;
        flex-direction: row;
      }
    }
  
    .toastbar-link {
      min-height: 52px;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      background-color: #ea5504;
      padding: 0 1em;
  
      @include desktopOnly {
        width: auto;
        padding: 0;
        padding-left: 50px;
        flex-direction: row;
      }
    }
  
    .adverse-link {
      text-decoration: none;
      font-family: $hindMadurai-reg;
      font-weight: 600;
      font-size: 16px;
      color: #fff;
      display: flex;
      align-items: center;
    }
  
    .toastbar-icon {
      margin-left: 13px;
    }
  
    .RichText p {
      font-family: $hindMadurai-reg;
      margin: 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #fff;
    }
  }
}