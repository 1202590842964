@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.two-column-panel-container {
  padding: 20px;
  margin-bottom: 20px;

  @media (min-width: $MIN_WIDTH_MEDIUM) {
    margin-bottom: 30px;
  }

  &__wrap {
    @media (min-width: $MIN_WIDTH_MEDIUM) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__left {
      @media (min-width: $MIN_WIDTH_MEDIUM) {
        width: calc(50% - 10px);
      }
    }

    &__right {
      @media (min-width: $MIN_WIDTH_MEDIUM) {
        width: calc(50% - 10px);
      }

      .learn-more {
        display: flex;
        align-items: center;
        align-content: center;
        width: 100%;
        margin-top: auto;
        text-decoration: none;
        color: $kk-primary;

        .svg-container {
          height: 15px;
        }

        p {
          margin: 0;
          color: $kk-primary;
          text-transform: uppercase;
          font-family: $hindMadurai-reg;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: 1px;
          text-align: left;
          margin-right: 16px;

          @media (min-width: $MIN_WIDTH_MEDIUM) {
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 1px;
            text-align: left;
          }
        }
      }
    }
  }

  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0 0 13.61px;
    font-family: $hindMadurai-reg;
    font-style: normal;
    color: $body-text-color;

    @media (min-width: $MIN_WIDTH_MEDIUM) {
      margin: 0 0 20px;
    }
  }

  h4 {
    font-family: $hindMadurai-reg;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    text-align: left;
  }

  h5 {
    font-family: $hindMadurai-reg;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }

  p {
    font-family: $hindMadurai-reg;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
  }
}
