@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.form-inline-error-text {
  display: inline;
  font-family: $body-font;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #FF0000;
  margin: 0px;
  padding: 0px;
}

.form-general-error-text {
  font-family: $body-font;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #FF0000;
  margin: 10px 0px 0px;
  padding: 0px;
}

