@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.form-error-text {
  display: block;
  width: 100%;
  font-family: $body-font;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #FF0000;
  margin: 0px 0px 10px;
  padding: 0px;
}