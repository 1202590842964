.form-field-dropdown-webinar {
  margin-bottom: 20px;

  .field-meta-data {
    margin-bottom: 4px;

    label {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0px;
      text-align: left;
    }
  }

  .webinar-dropdown-select {
    position: relative;
    width: 100%;
    z-index: 5;

    select::-ms-expand {
      display: none;
    }

    .custom-dropdown-select {
      width: 100%;
      font-size: 16px;
      line-height: 19px;
      border: 1px solid #c4c4c4;
      border-radius: 30px;
      padding: 12px 20px;
      cursor: pointer;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      background-image: none;
      -ms-word-break: normal;
      word-break: normal;
      -webkit-appearance: none;
    }

    svg {
      position: absolute;
      top: 18px;
      right: 17px;
      cursor: pointer;
      z-index: 2;
    }
  }
  .dropdown-open {
    svg {
        transform: rotate(180deg);
    }
  }
}
