
.taskbar{
    background: #F9F4F2;
    // padding-bottom:13%;
    position: relative;
    height: 30px;
    // @media only screen and (min-width:992px){
        // padding-bottom:6%;
    // }
    .button-frame {
        height: 100%;
        display: flex;
        align-items: center;
        .close-button-container{
            position:absolute;
            right:0;
            margin:2%;

            svg {
                width: 12px;
                height: 12px;
            }
        }
    }
}
#imagebutton{
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: inline;
    margin-bottom:0px;
}
#overlay{
    .edetailer-modal{
        height: 100%;
    }
   
}

.overlay-iframe {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    z-index: 999;
    top: 0;
}

#edetailerModal{
    padding-bottom: 0px;
    margin-top: 5vh;
    overflow: hidden;
}
.tooltiptext-linkNotAvailable{
    visibility: hidden;
    color: #ffff;
    text-align: center;
    border-radius: 3px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    padding-left: 10px;
    background-color: black;
    font-size: 15px;
    padding-right: 10px;
    margin-left: 9px;
}
a:hover .tooltiptextShowMessage {
    visibility: visible;
}
.cursor-default{
    cursor: default;
}


