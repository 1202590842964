@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_videoFigure.scss';
@import '../../assets/mixins.scss';

.main-page-promo {
  &-hidden {
    display: none;
  }

  &-logo {
    padding: 0 0 0 0px;
    margin: 0 auto;
    display: flex;
    max-width: 1600px;

    &-holder {
      margin: 0;
      padding: 20px;
      
      @include desktopOnly {
        padding: 10px 95px;
      }

      img {
        width: 100%;
      }
    }
  }

  &-header {
    padding-top: 40px;
    padding-bottom: 40px;
    @include grid-row();

    @include screen-width-small() {
      flex-direction: column;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .header-text {
      @include spans-columns(5);
      @include spans-columns-small(4);
      color: white;

      strong {
        font-family: $subtitle-font;
      }

      p {
        // p from rich text
        margin-top: 0;
        font-size: 20px;

        a {
          color: inherit;
        }
      }
    }

    .media-container {
      @include spans-columns(6);
      @include spans-columns-small(4);
      @include offset-columns(1);
      @include offset-columns-small(0);

      figure {
        @include video-figure();

        img {
          width: 100%;
        }
      }
    }
  }

  &-subheader-section {
    background-color: $background-grey;
    min-height: 200px;
  }

  &-subheader-section.footer-subheader-section {
    margin-bottom: 30px;
  }

  &-subheader {
    padding-top: 40px;
    padding-bottom: 40px;
    @include grid-row();

    @include screen-width-small {
      padding-top: 27px;
      padding-bottom: 27px;
      flex-direction: column;
    }

    .column {
      @include spans-columns(6);
      @include spans-columns-small(4);
      // Rich text can return any number of different elements so :first-child is not a solution for "all elements should have Npx
      // between them". However if we give all elements a margin-top of 20px and then just hoist up the .column by 20px, then the
      // extra spacing from the first element is canceled out.
      $space-between-text: 20px;
      margin-top: -$space-between-text;

      p {
        // p from rich text
        font-size: 20px;
      }

      * {
        margin: $space-between-text 0px 0px 0px;
      }
    }

  }

  h3 {

    &.promo-treatment-h3,
    &.promo-video-selector-h3 {
      font-family: $subtitle-font;
      font-size: 25px;
      line-height: 28px;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 30px;
    }
  }

  &-content-keyinfo {
    padding: 40px 0 0 0;
    @include spans-columns(12);

    @include desktopOnly {
      @include spans-columns(9);
    }
  }

  &-content-section {
    @include desktopOnly {
      display: flex;
      flex-direction: row;
      max-width: 1600px;
      margin: 0 auto;

    }
  }


  .main-page-promo-content-subnav-section {
    display: none;

    @include desktopOnly {
      display: block;
    }

    .content-page-promo-subnav {
      width: 85%;
      
      @include desktopOnly {
        padding: 40px 5px 40px 40px;
      }

      @include desktopOnlyLarge {
        padding: 40px 0 40px 85px;
      }

    }

    &-video-selector {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &-treatment-section {
      padding: 20px 20px 10px;
      background-color: #F6F6F5;

      @include desktopOnly {
        padding: 40px 20px 10px;
        background-color: white;
      }

      h3 {
        margin: 0 0 10px;
      }

    }

    &-references {
      padding-bottom: 40px;
    }
  }

  &-related-content {
    margin-bottom: 40px;
  }
}