@import "../../../../assets/kanso/styles/global.scss";

.pageSectionWrapper {
  margin: 0;
  padding: 0;
  width: 100%;
}

.pageSectionWrapper .pageSection {
  // width: calc(100% - 130px);
  padding: 32px 65px 32px 65px;
  background-color: var(--ps-bgcolor, #fff);   
  color: var(--ps-text-color, #4B4C53);
  position: relative;
  display: grid;
  grid-template-columns: 12fr;

  @include media (extraSmall, small){
// width: calc(100% - 40px);
padding: 32px 20px 32px 20px;
  }
}

.pageSectionWrapper .pageSection .pageSection-background {
  position: absolute;
  z-index: -1;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;    
  --img-height:  100%;

  img {
    width: 100%;
    height: 100%;
    opacity: 1;
    object-fit: cover;
    object-position: 50%;
    border-radius: var(--ps-bgimage-radius, 0);

    @include media(medium, large, extraLarge, extraExtraLarge) {
      object-fit: fill;
    }
  }
}

@media print {
  .pageSectionWrapper .pageSection {
    display: block !important;
    width: 100% !important;
    padding: 0 !important;
    padding-bottom: 0.15in !important;
    .pageSection-background {
      display: none;
    }
  }
}