@import "style/variables.scss";
@import "style/mixins/_responsive.scss";
@import "style/mixins/_titleWithIcon.scss";
@import "../../assets/mixins.scss";

$all-col: 12;

$left-col-phone: 4;
$left-col-tab: 4;
$left-col-desk: 3;

$card-col-phone: 4;
$card-col-tab: 4;
$card-col-desk: 3;

// Because theres 12 columns in a row and left meny takes up n columns, we need to transform the remaining columns
// into the remaining grid. ie, a 12 column grid can be split into 3 groups of 4 columns. The first group is taken
// up by the left menu. Ideally at this point we should be able to say "Uuse 4 columns and content wrap" to fill the
// last columns. But that doesn't work because the grid system uses % and it is unaware of the 4 columns already taken up.
// As such, we need to adjust the ammount of columns to 6 columns. ie, 50% of the remaining 8 columns
$applied-col-phone: $card-col-phone; // same as the menu cus of display column
$applied-col-tab: ($number_columns * $left-col-tab) /
  ($number_columns - $card-col-tab);
$applied-col-desk: ($number_columns * $left-col-desk) /
  ($number_columns - $card-col-desk);

.app-layout--v1 {
  section[role='main'].list-content {
    .list-content-page {
      @include grid-row();
      @include screen-width-small {
        flex-direction: column;
      }
      .column-left {
        .main-page-subnavsection {
          width: auto;
  
          .content-page-promo-subnav {
            width: 100%;
            padding: 40px 0 40px 0px;
          }
        }
  
        @include screen-width-mobile {
          @include spans-columns-dynamic-small($left-col-phone);
          @include spans-columns-dynamic($left-col-tab);
        }
        @include screen-width-desktop {
          @include spans-columns-dynamic($left-col-desk);
        }
        .list-page-content-menu {
          @include screen-width-small {
            width: 100vw;
            display: block;
            margin-left: calc(50% - 50vw);
          }
        }
      }
      .main-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 15px;
  
        @include desktopOnly {
          margin: 0 100px;
        }
  
        .module-subcategory-filter {
          list-style-type: none;
          margin: 0;
          padding-left: 1em;
          overflow: hidden;
  
          & .subcategory-item {
            float: left;
            padding: 0px 5px 0px 5px;
  
            & .subcategory-button {
              background: none;
              border: none;
              color: $body-text-color;
              cursor: pointer;
            }
  
            & .selected {
              text-decoration: underline;
              color: $kk-primary;
            }
  
            & .subcategory-button:hover {
              text-decoration: underline;
            }
          }
        }
  
        .cards-list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          @include screen-width-small {
            flex-direction: column;
          }
          @include screen-width-desktop {
          }
          .list-content-page-card-wrapper {
            margin-bottom: 30px; // each item should have 30px bottom
            @include spans-columns($all-col);
            .text-card {
              height: 100%; // make sure the cards have the same height within a row
              .category {
                svg {
                  width: 27px;
                  height: 28px;
                }
  
                .categoryText {
                  font-size: 14px;
                  padding-top: 5px;
                  color: #ea5504;
                  font-family: $myriad-sb;
                }
              }
  
              .card-section {
                .image-section {
                  img {
                    width: 100%;
                  }
                }
              }
            }
          }
          .no-content {
            padding-left: 15px;
          }
        }
      }
    }
  }
}
.app-layout--v2 {
  section[role='main'].list-content {
    .content-section {
      background-repeat: no-repeat;
      background-position-x: center;
      background-size: contain;
    }
    .list-content-page {
      @include grid-row();
      @include screen-width-small {
        flex-direction: column;
      }
      .column-left {
        .main-page-subnavsection {
          width: auto;
  
          .content-page-promo-subnav {
            width: 100%;
            padding: 40px 0 40px 0px;
          }
        }
  
        @include screen-width-mobile {
          @include spans-columns-dynamic-small($left-col-phone);
          @include spans-columns-dynamic($left-col-tab);
        }
        @include screen-width-desktop {
          @include spans-columns-dynamic($left-col-desk);
        }
        .list-page-content-menu {
          @include screen-width-small {
            width: 100vw;
            display: block;
            margin-left: calc(50% - 50vw);
          }
        }
      }
      .main-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 15px;
  
        @include desktopOnly {
          margin: 0 100px;
        }
  
        .module-subcategory-filter {
          list-style-type: none;
          margin: 0;
          padding-left: 1em;
          overflow: hidden;
  
          & .subcategory-item {
            float: left;
            padding: 0px 5px 0px 5px;
  
            & .subcategory-button {
              background: none;
              border: none;
              color: $body-text-color;
              cursor: pointer;
            }
  
            & .selected {
              text-decoration: underline;
              color: $kk-primary;
            }
  
            & .subcategory-button:hover {
              text-decoration: underline;
            }
          }
        }
  
        .cards-list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          @include screen-width-small {
            flex-direction: column;
          }
          @include screen-width-desktop {
          }
          .list-content-page-card-wrapper {
            margin-bottom: 30px; // each item should have 30px bottom
            @include spans-columns($all-col);
            .text-card {
              height: 100%; // make sure the cards have the same height within a row
              .category {
                svg {
                  width: 27px;
                  height: 28px;
                }
  
                .categoryText {
                  font-size: 14px;
                  padding-top: 5px;
                  color: #333;
                  font-family: $hindMadurai-sb;
                }
              }
  
              .card-section {
                .image-section {
                  img {
                    width: 100%;
                  }
                }
              }
            }
          }
          .no-content {
            padding-left: 15px;
          }
        }
      }
    }
  }
}
