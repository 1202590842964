@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_titleWithIcon.scss';


$left-col-phone: 4;
$left-col-tab: 4;
$left-col-desk: 3;

$card-col-phone: 4;
$card-col-tab: 8;
$card-col-desk: 3;

// Because theres 12 columns in a row and left meny takes up n columns, we need to transform the remaining columns
// into the remaining grid. ie, a 12 column grid can be split into 3 groups of 4 columns. The first group is taken
// up by the left menu. Ideally at this point we should be able to say "Uuse 4 columns and content wrap" to fill the
// last columns. But that doesn't work because the grid system uses % and it is unaware of the 4 columns already taken up.
// As such, we need to adjust the ammount of columns to 6 columns. ie, 50% of the remaining 8 columns
$applied-col-phone: $card-col-phone; // same as the menu cus of display column
$applied-col-tab: ($number_columns * $left-col-tab) / ($number_columns - $card-col-tab);
$applied-col-desk: ($number_columns * $left-col-desk) / ($number_columns - $card-col-desk);


.app-layout--v1 {
  section[role="main"].available-page-template {
    .category-row {
      @include grid-row();
      @include screen-width-small {
        flex-direction: column;
      }
      .category-row-left {
        @include screen-width-mobile {
          @include spans-columns-small($left-col-phone);
          @include spans-columns($left-col-tab);
        }
        @include screen-width-desktop {
          @include spans-columns($left-col-desk);
        }
        h3 {
          font-family: $body-font;
          font-size: 18px;
          line-height: 21px;
          color: #EA5506;
          padding: 8px 0px;
          margin: 0px;
          border-top: 1px dashed rgba(234, 85, 6, 0.5);
          @include screen-width-small {
            border-top: 0px;
            border-bottom: 1px dashed rgba(234, 85, 6, 0.5);
            margin-bottom: 30px;
          }
        }
      }
      .category-row-right {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        @include screen-width-small {
          flex-direction: column;
        }
        .list-content-page-card-wrapper {
          margin-bottom: 30px; // each item should have 30px bottom
          @include screen-width-mobile {
            @include spans-columns-small($applied-col-phone);
            @include spans-columns($applied-col-tab);
          }
          @include screen-width-desktop {
            @include spans-columns($applied-col-desk);
          }
        }
      }
    }
  }
}
.app-layout--v2 {
  section[role="main"].available-page-template {
    @include screen-width-mobile {
      background: url(../../assets/images/Background-image-phone.png);
    }
    
    .category-row {
  
      .category-row-left {
        display: flex;
        @include screen-width-mobile {
          padding-left: 0;
        }
        .categorie-title{
          width: auto;
          margin-right: 17px;
          @include screen-width-mobile {
            margin-right: 0;
            width: 100%;
          }
          h3 {
            font-family: $body-font;
            font-size: 18px;
            line-height: 21px;
            color: #EA5506;
            padding: 8px 0px;
            margin: 0px;
            width: max-content;
            @include screen-width-small {
              border-top: 0px;
              border-bottom: 1px dashed rgba(234, 85, 6, 0.5);
              margin-bottom: 30px;
              width: auto;
            }
            @include screen-width-medium {
              border-top: 0px;
              border-bottom: 1px dashed rgba(234, 85, 6, 0.5);
              margin-bottom: 30px;
              width: auto;
            }
          }
        }
  
        .categorie-span{
          width: 100%;
        
          h3{
            border-top: 1px dashed rgba(0, 0, 0, 0.5);
          }
          @include screen-width-mobile {
            display: none;
          }
        }
        
      }
      .category-row-right {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        @include screen-width-small {
          flex-direction: column;
        }
        .list-content-page-card-wrapper {
          margin-bottom: 30px; // each item should have 30px bottom
          @include screen-width-desktop {
            @include spans-columns($applied-col-desk);
          }
        }
  
        .list-content-page-card-wrapper:first-child {
          @include screen-width-desktop {
            padding-left: 0;
          }
        }
        .list-content-page-card-wrapper:nth-child(3) {
          @include screen-width-desktop {
            padding-right: 0;
          }
        }
        .list-content-page-card-wrapper:nth-child(4) {
          @include screen-width-desktop {
            padding-left: 0;
          }
        }
        .list-content-page-card-wrapper:nth-child(6) {
          @include screen-width-desktop {
            padding-right: 0;
          }
        }
      }
    }
  }
}

