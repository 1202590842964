@import 'style/variables.scss';
@import '../../assets/mixins.scss';

.link-panel {
    display: flex;
    flex-direction: column;

    h3 {
        font-family: $subtitle-font;
        font-size: 25px;
        line-height: 28px;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 30px;
    }

    .category {
        margin-top: 0;
    }

    &-links {
        list-style: none;
        display: flex;
        flex-wrap: wrap;

        padding: 0;
        margin-top: 0px;
        margin-bottom: 0px;

        li {
            padding-bottom: 20px;

            @include desktopOnly {
                min-height: 160px;
                flex: 45%;
                width: 100%;
                padding: 0 20px 0 0;
            }

            .link-panel-title-link {
                background: none;
                border: none;
                padding: 0;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                width: 100%;
                font-size: 20px;
                font-family: $subtitle-font;
                text-align: left;
                text-decoration: none;

                .chevron {
                    margin-left: auto;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }  
}

.link-panel-links, .links {
    a, button {
      .RichText {
        p {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }