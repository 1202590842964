@import '../../../../../assets/mixins.scss';
@import 'style/variables.scss';

// User Auth : Login - User Info
.app-layout--v1 {
    .form-redesign {
        a{
            border-radius: 4px;
        }
    }
    .UserStatus {
        cursor: pointer;
        color: #FFFFFF;
        align-self: center;
        justify-self: end;
    
        @supports (display: grid) {
            grid-area: userAuth;
        }
    
        // Guest
        .Guest {}
    
        // Authenticated
        .Authenticated {
            cursor: pointer;
            user-select: none;
    
            @include tabletMin {
                display: flex;
                align-items: center;
                margin-right: 15px;
    
                @supports (display: grid) {
                    display: grid;
                    grid-template-columns: 1fr 55px;
                    column-gap: .5em;
                    margin-right: 0px;
                }
            }
        }
    
        &_CTA {
            cursor: pointer;
            display: block;
            color: #FFFFFF;
            text-decoration: none;
            border: 2px #FFFFFF solid;
            border-radius: 30px;
            line-height: 20px;
            padding: 2px 15px;
            font-size: 14px;
        }
    
        &_Name {
            display: none;
            font-family: $myriad-sb;
    
            @include tabletMin {
                display: block;
                margin-right: 8px;
                
                @supports (display: grid) {
                    margin-right: 0px;
                }
            }
        }
    
        &_Icon {
            position: relative;
            padding: 5px;
            width: 40px;
    
            svg {
                margin-top: 4px;
                max-height: 27px;
                color: #FFFFFF;
            }
    
            &--open {
                background-color: #FFFFFF;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
    
                svg {
                    color: #EA5504;
                }
            }
    
            @include tabletMin {
                &::after {
                    font-size: 10px;
                    content: '▼';
                    position: absolute;
                    right: -15px;
                    top: calc(50% - 5px);
                }
            }
        }
    
        &_Menu {
            display: none;
            position: absolute;
            right: 0;
            background-color: #FFFFFF;
            overflow: hidden;
            color: #000;
            top: 44px;
            min-width: 150px;
            list-style-type: none;
            margin: 0;
            padding: 0;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            box-shadow: 0 1mm 0.35rem rgba(0, 0, 0, 0.3);
    
            @supports (filter: drop-shadow(0 2mm 0.25rem rgba(0, 0, 0, 0.3))) {
                filter: drop-shadow(0 2mm 0.25rem rgba(0, 0, 0, 0.3));
                box-shadow: unset;
            }
    
            @supports (display: grid) {
                top: 45px;
            }
    
            li {
                display: flex;
                align-items: center;
                height: 40px;
                border-top: 1px #ebebeb solid;
                font-size: 16px;
    
                @supports (display: grid) {
                    display: grid;
                }
    
                a {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    width: 100%;
                    color: #000;
                    text-decoration: none;
                    padding: 0 10px;
    
                    @supports (display: grid) {
                        display: grid;
                        grid-template-columns: 25px 1fr;
                    }
                }
    
                &:hover {
                    background-color: #EA5504;
    
                    a {
                        color: #FFFFFF;
    
                        .UserStatus_Menu_Icon.Grey {
                            display: none;
                        }
    
                        .UserStatus_Menu_Icon.White {
                            display: block;
                        }
                    }
                }
            }
    
            &_Icon {
                display: inline-block;
                width: 18px;
                height: 18px;
                background-size: cover;
                margin-right: 8px;
    
                @supports (display: grid) {
                    margin-right: 0px;
                }
    
                &.White {
                    display: none;
                }
            }
    
            &--open {
                display: block;
                z-index: 1020;
            }
        }
    }
}
.app-layout--v2 {
    .form-redesign {
        a{
            border-radius: 4px;
        }
    }
    .UserStatus {
        cursor: pointer;
        color: #FFFFFF;
        align-self: center;
        justify-self: end;
        @include tabletMax {
            margin-left: auto;
          }
        @supports (display: grid) {
            grid-area: userAuth;
        }
        // Guest
        .Guest {}
    
        // Authenticated
        .Authenticated {
            cursor: pointer;
            user-select: none;
    
            @include tabletMin {
                display: flex;
                align-items: center;
                margin-right: 15px;
    
                @supports (display: grid) {
                    display: grid;
                    grid-template-columns: 1fr 55px;
                    column-gap: .5em;
                    margin-right: 0px;
                }
            }
        }
    
        &_CTA {
            cursor: pointer;
            display: block;
            color: #333;
            text-decoration: none;
            border: 2px #333 solid;
            border-radius: 30px;
            line-height: 20px;
            padding: 2px 15px;
            font-size: 14px;
        }
    
        &_Name {
            display: none;
            font-family: $hindMadurai-sb;
            color: #333333;
    
            @include tabletMin {
                display: block;
                margin-right: 8px;
                
                @supports (display: grid) {
                    margin-right: 0px;
                }
            }
        }
    
        &_Icon {
            position: relative;
            width: 40px;
    
            svg {
                margin-top: 4px;
                max-height: 27px;
                color: #333333;
            }
    
            &--open {
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
    
                svg {
                    color: #333333;
                }
            }
            .UserStatus_down-arrow {
                display: none;
                @include tabletMin {
                    display: block;
                    position: absolute;
                    right: -5px;
                    top: calc(50% - 7px);
                }
            }
        }
    
        &_Menu {
            display: none;
            position: absolute;
            right: 0;
            background-color: #FFFFFF;
            overflow: hidden;
            color: #000;
            top: 44px;
            min-width: 150px;
            list-style-type: none;
            margin: 0;
            padding: 0;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            box-shadow: 0 1mm 0.35rem rgba(0, 0, 0, 0.3);
    
            @supports (filter: drop-shadow(0 2mm 0.25rem rgba(0, 0, 0, 0.3))) {
                filter: drop-shadow(0 2mm 0.25rem rgba(0, 0, 0, 0.3));
                box-shadow: unset;
            }
    
            @supports (display: grid) {
                top: 45px;
            }
    
            li {
                display: flex;
                align-items: center;
                height: 40px;
                border-top: 1px #ebebeb solid;
                font-size: 16px;
    
                @supports (display: grid) {
                    display: grid;
                }
    
                a {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    width: 100%;
                    color: #000;
                    text-decoration: none;
                    padding: 0 10px;
    
                    @supports (display: grid) {
                        display: grid;
                        grid-template-columns: 25px 1fr;
                    }
                }
    
                &:hover {
                    background-color: #EA5504;
    
                    a {
                        color: #FFFFFF;
    
                        .UserStatus_Menu_Icon.Grey {
                            display: none;
                        }
    
                        .UserStatus_Menu_Icon.White {
                            display: block;
                        }
                    }
                }
            }
    
            &_Icon {
                display: inline-block;
                width: 18px;
                height: 18px;
                background-size: cover;
                margin-right: 8px;
    
                @supports (display: grid) {
                    margin-right: 0px;
                }
    
                &.White {
                    display: none;
                }
            }
    
            &--open {
                display: block;
                z-index: 1020;
            }
        }
    }

}
