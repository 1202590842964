/*
  KANSO default theme design tokens for approved KANSO library Atoms only.
*/

:root {

  //Buttons & Links
  --primary-btn-font-size: 16px;
  --primary-btn-font-weight: 700;
  --primary-btn-line-height: 19.2px;
  --primary-btn-box-shadow: 0px 6px 24px -4px rgba(98, 103, 161, 0.7);
  --primary-btn-text-color: #fff;
  --primary-btn-icon-color: #fff;
  --primary-btn-bgcolor: #EA5724;
  --primary-btn-border-radius: 8px;
  --primary-btn-border-color: #EA5724;
  --primary-btn-hover-box-shadow: 0px 6px 14px -4px rgba(98, 103, 161, 0.6);
  --primary-btn-hover-text-color: #fff;
  --primary-btn-hover-icon-color: #fff;
  --primary-btn-hover-bgcolor: #fa7b51;
  --primary-btn-hover-border-color: #fa7b51;
  --primary-btn-active-box-shadow: 0px 6px 14px -4px rgba(98, 103, 161, 0.6);
  --primary-btn-active-text-color: #6267A1;
  --primary-btn-active-icon-color: #6267A1;
  --primary-btn-active-bgcolor: #fff;
  --primary-btn-active-border-color: #fff;
  --primary-btn-focus-box-shadow: none;
  --primary-btn-focus-text-color: #4B4C53;
  --primary-btn-focus-icon-color: #4B4C53;
  --primary-btn-focus-bgcolor: #FFDD00;
  --primary-btn-focus-border-color: #FFDD00;
  --primary-btn-disabled-box-shadow: none;
  --primary-btn-disabled-text-color: #4B4C53;
  --primary-btn-disabled-icon-color: #4B4C53;
  --primary-btn-disabled-bgcolor: #F5F5F6;
  --primary-btn-disabled-border-color: #F5F5F6;
  --secondary-btn-font-size: 16px;
  --secondary-btn-font-weight: 700;
  --secondary-btn-line-height: 19.2px;
  --secondary-btn-box-shadow: none;
  --secondary-btn-text-color: #EA5724;
  --secondary-btn-icon-color: #EA5724;
  --secondary-btn-bgcolor: transparent;
  --secondary-btn-border-color: #EA5724;
  --secondary-btn-border-radius: 8px;
  --secondary-btn-hover-box-shadow: none;
  --secondary-btn-hover-text-color: #fff;
  --secondary-btn-hover-icon-color: #fff;
  --secondary-btn-hover-bgcolor: #fa7b51;
  --secondary-btn-hover-border-color: #fa7b51;
  --secondary-btn-active-box-shadow: none;
  --secondary-btn-active-text-color: #6267A1;
  --secondary-btn-active-icon-color: #6267A1;
  --secondary-btn-active-bgcolor: #F5F5F6;
  --secondary-btn-active-border-color: #F5F5F6;
  --secondary-btn-focus-box-shadow: none;
  --secondary-btn-focus-text-color: #4B4C53;
  --secondary-btn-focus-icon-color: #4B4C53;
  --secondary-btn-focus-bgcolor: #FFDD00;
  --secondary-btn-focus-border-color: #FFDD00;
  --secondary-btn-disabled-box-shadow: none;
  --secondary-btn-disabled-text-color: #4B4C53;
  --secondary-btn-disabled-icon-color: #4B4C53;
  --secondary-btn-disabled-bgcolor: transparent;
  --secondary-btn-disabled-border-color: #4B4C53;
  --tertiary-btn-font-size: 16px;
  --tertiary-btn-font-weight: 700;
  --tertiary-btn-line-height: 19.2px;
  --tertiary-btn-width: unset;
  --tertiary-btn-padding: 0;
  --tertiary-btn-text-color: #EA5724;
  --tertiary-btn-icon-color: #EA5724;
  --tertiary-btn-text-decoration: none;
  --tertiary-btn-bgcolor: transparent;
  --tertiary-btn-border-radius: 0;
  --tertiary-btn-hover-text-color: #EA5724;
  --tertiary-btn-hover-icon-color: #EA5724;
  --tertiary-btn-hover-text-decoration: underline;
  --tertiary-btn-hover-bgcolor: transparent;
  --tertiary-btn-active-text-color: #EA5724;
  --tertiary-btn-active-icon-color: #EA5724;
  --tertiary-btn-active-text-decoration: underline;
  --tertiary-btn-active-bgcolor: #F5F5F6;
  --tertiary-btn-focus-text-color: #4B4C53;
  --tertiary-btn-focus-icon-color: #4B4C53;
  --tertiary-btn-focus-text-decoration: none;
  --tertiary-btn-focus-bgcolor: #FFDD00;
  --tertiary-btn-disabled-text-color: #4B4C53;
  --tertiary-btn-disabled-icon-color: #4B4C53;
  --tertiary-btn-disabled-text-decoration: none;
  --tertiary-btn-disabled-bgcolor: transparent;

  //Audio
  --audio-font-size: 16px;
  --audio-font-weight: 200;
  --audio-line-height: 25.6px;
  --audio-text-color: #333;
  --audio-bgcolor: #f5f5f6;
  --audio-border-radius: 8px;
  --audio-image-bgcolor: 0,0,0;
  --audio-image-opacity: 0.5;
  --audio-image-hover-bgcolor: 0,0,0;
  --audio-image-hover-opacity: 0.3;
  --audio-image-active-bgcolor: 0,0,0;
  --audio-image-active-opacity: 0.3;
  --audio-image-aspect-ratio: 1/1;  
  --audio-image-icon-color: #fff;  
  --audio-image-icon-hover-color: #fff;  
  --audio-image-icon-active-color: #fff;  
  --audio-headline-font-size: 24px;
  --audio-headline-font-weight: 600;
  --audio-headline-line-height: 38px;
  --audio-label-font-size: 14px;
  --audio-label-font-weight: 300;
  --audio-plyr-bgcolor: #f5f5f6;
  --audio-plyr-control-color: #333333;
  // --audio-plyr-control-color-hover: #6267A1;
  --audio-plyr-control-color-hover: #ea5724;
  --audio-plyr-time-color: #4B4C53;
  --audio-transcript-font-size: 16px;
  --audio-transcript-font-weight: 700;
  --audio-transcript-line-height: 19.2px;
  --audio-transcript-text-color: #EA5724;
  --audio-transcript-icon-color: #EA5724;
  --audio-transcript-text-decoration: none;
  --audio-transcript-bgcolor: transparent;
  --audio-transcript-hover-text-color: #EA5724;
  --audio-transcript-hover-icon-color: #EA5724;
  --audio-transcript-hover-text-decoration: underline;
  --audio-transcript-hover-bgcolor: transparent;
  --audio-transcript-active-text-color: #EA5724;
  --audio-transcript-active-icon-color: #EA5724;
  --audio-transcript-active-text-decoration: underline;
  --audio-transcript-active-bgcolor: #F5F5F6;
  --audio-transcript-disabled-text-color: #4B4C53;
  --audio-transcript-disabled-icon-color: #4B4C53;
  --audio-transcript-disabled-text-decoration: none;
  --audio-transcript-disabled-bgcolor: transparent;

  //Video
  --video-font-size: 16px;
  --video-font-weight: 200;
  --video-line-height: 25.6px;
  --video-text-color: #333;
  --video-headline-font-size: 24px;
  --video-headline-font-weight: 400;
  --video-headline-line-height: 38px;
  --video-poster-border-radius: 8px;
  --video-poster-aspect-ratio: 16/9;
  --video-plyr-text-color: #000;
  --video-plyr-controls-bgcolor: none;
  --video-plyr-controls-spacing: 8px;
  --video-plyr-controls-border-radius: 0;
  --video-plyr-controls-text-color: #fff;
  --video-plyr-controls-hover-text-color: #fff;
  --video-plyr-controls-hover-bgcolor: transparent;
  --video-plyr-controls-range-thumb-bgcolor: #fff;
  --video-plyr-controls-range-bgcolor: rgba(255, 255, 255, 0.6);
  --video-plyr-progress-bgcolor: rgba(255, 255, 255, 0.2);
  --video-plyr-controls-range-track-bgcolor: rgba(255, 255, 255, 0.4);
  --video-plyr-controls-range-thumb-height: 12px;
  --video-plyr-controls-range-track-height: 2px;
  --video-plyr-controls-icon-height: 12px;
  --video-plyr-controls-time-text-color: #fff;
  --video-caption-font-size: 16px;
  --video-caption-font-weight: 300;
  --video-caption-line-height: 22.4px;
  --video-transcript-font-size: 16px;
  --video-transcript-font-weight: 700;
  --video-transcript-line-height: 19.2px;
  --video-transcript-text-color: #EA5724;
  --video-transcript-icon-color: #EA5724;
  --video-transcript-text-decoration: none;
  --video-transcript-bgcolor: transparent;
  --video-transcript-hover-text-color: #EA5724;
  --video-transcript-hover-icon-color: #EA5724;
  --video-transcript-hover-text-decoration: underline;
  --video-transcript-hover-bgcolor: transparent;
  --video-transcript-active-text-color: #EA5724;
  --video-transcript-active-icon-color: #EA5724;
  --video-transcript-active-text-decoration: underline;
  --video-transcript-active-bgcolor: #F5F5F6;
  --video-transcript-disabled-text-color: #4B4C53;
  --video-transcript-disabled-icon-color: #4B4C53;
  --video-transcript-disabled-text-decoration: none;
  --video-transcript-disabled-bgcolor: transparent;

  //Image
  --img-font-size: 16px;
  --img-font-weight: 200;
  --img-line-height: 25.6px;
  --img-text-color: #4B4C53;
  --img-bgcolor: transparent;
  --img-width: 100%;
  --img-max-width: 100%;
  --img-height: auto;
  --img-border-radius: 8px;
  --img-aspect-ratio: auto;
  --img-opacity: 1;
  --img-headline-font-size: 24px;
  --img-headline-font-weight: 600;
  --img-headline-line-height: 38px;
  --img-caption-font-size: 16px;
  --img-caption-font-weight: 600;
  --img-caption-line-height: 22.4px;  
  --img-download-font-size: 16px;
  --img-download-font-weight: 700;
  --img-download-line-height: 19.2px;
  --img-download-text-color: #6267A1;
  --img-download-icon-color: #6267A1;
  --img-download-text-decoration: none;
  --img-download-bgcolor: transparent;
  --img-download-hover-text-color: #6267A1;
  --img-download-hover-icon-color: #6267A1;
  --img-download-hover-text-decoration: underline;
  --img-download-hover-bgcolor: transparent;
  --img-download-active-text-color: #6267A1;
  --img-download-active-icon-color: #6267A1;
  --img-download-active-text-decoration: underline;
  --img-download-active-bgcolor: #F5F5F6;
  --img-download-disabled-text-color: #4B4C53;
  --img-download-disabled-icon-color: #4B4C53;
  --img-download-disabled-text-decoration: none;
  --img-download-disabled-bgcolor: transparent;

  //Divider
  --div-bgcolor: #4B4C53;
  --div-margin: 32px 0;
  --div-height: 1px;
  --div-duration: 1.2s;

  //Section Title
  --st-font-size: 24px;
  --st-font-weight: 200;
  --st-line-height: 38.4px;
  --st-text-color: #4B4C53;
  --st-headline-font-size: var(--base-h2-size);
  --st-headline-font-weight: var(--base-h2-weight);
  --st-headline-line-height: var(--base-h2-line-height);

  //Section Footer
  --sf-text-color: #4B4C53;
  --sf-font-size: 14px;
  --sf-font-weight: 300;
  --sf-line-height: 22.4px;

  //Editorial Text
  --et-font-size: 16px;
  --et-font-weight: 200;
  --et-line-height: 25.6px;
  --et-text-color: #333;
  --et-text-align: left;
  --et-caption-font-size: 16px;
  --et-caption-font-weight: 600;
  --et-caption-line-height: 25.6px;
  --et-headline-font-size: 28px;
  --et-headline-font-weight: 600;
  --et-headline-line-height: 33.6px;
  --et-headline-margin: 0 0 24px 0;
  --et-sup-font-size: 12px;
  --et-sup-font-weight: 700;
  --et-sub-font-size: 12px;
  --et-sub-font-weight: 700;
  --et-mark-font-weight:600;
  --et-mark-text-color: #000;
  --et-mark-bgcolor: #ffff00;
  --et-primary-btn-font-weight: var(--primary-btn-font-weight);
  --et-primary-btn-line-height: var(--primary-btn-line-height);
  --et-primary-btn-bgcolor: var(--primary-btn-bgcolor);
  --et-primary-btn-text-color: var(--primary-btn-text-color);
  --et-primary-btn-border-color: var(--primary-btn-border-color);
  --et-primary-btn-hover-bgcolor: var(--primary-btn-hover-bgcolor);
  --et-primary-btn-hover-text-color: var(--primary-btn-hover-text-color);
  --et-primary-btn-hover-border-color: var(--primary-btn-hover-border-color);
  --et-primary-btn-active-bgcolor: var(--primary-btn-active-bgcolor);
  --et-primary-btn-active-text-color: var(--primary-btn-active-text-color);
  --et-primary-btn-active-border-color: var(--primary-btn-active-border-color);
  --et-secondary-btn-font-weight: var(--secondary-btn-font-weight);
  --et-secondary-btn-line-height: var(--secondary-btn-line-height);
  --et-secondary-btn-bgcolor: var(--secondary-btn-bgcolor);
  --et-secondary-btn-text-color: var(--secondary-btn-text-color);
  --et-secondary-btn-border-color: var(--secondary-btn-border-color);
  --et-secondary-btn-hover-bgcolor: var(--secondary-btn-hover-bgcolor);
  --et-secondary-btn-hover-text-color: var(--secondary-btn-hover-text-color);
  --et-secondary-btn-hover-border-color: var(--secondary-btn-hover-border-color);
  --et-secondary-btn-active-bgcolor: var(--secondary-btn-active-bgcolor);
  --et-secondary-btn-active-text-color: var(--secondary-btn-active-text-color);
  --et-secondary-btn-active-border-color: var(--secondary-btn-active-border-color);
  --et-tertiary-btn-font-weight: var(--tertiary-btn-font-weight);
  --et-tertiary-btn-line-height: var(--tertiary-btn-line-height);
  --et-tertiary-btn-text-color: var(--tertiary-btn-text-color);
  --et-tertiary-btn-hover-bgcolor: var(--tertiary-btn-hover-bgcolor);
  --et-tertiary-btn-hover-text-color: var(--tertiary-btn-hover-text-color);
  --et-tertiary-btn-active-bgcolor: var(--tertiary-btn-active-bgcolor);
  --et-tertiary-btn-active-text-color: var(--tertiary-btn-active-text-color);
        
  //List
  --list-font-size: 16px;
  --list-font-weight: 200;
  --list-line-height: 25.6px;
  --list-text-color: #4b4c53;
  --list-margin: 0 0 24px;
  --list-ul-item-padding: 16px 0 8px 16px;
  --list-ol-item-padding: 16px 0 8px 40px;
  --list-item-border-color: transparent;
  --list-item-before-content-position: 10px;
  --list-item-before-font-size: 16px;
  --list-item-tertiary-btn-font-size: 16px;
  --list-item-tertiary-btn-text-color: #6267a1;
  --list-item-tertiary-btn-icon-color: #6267a1;
  --list-item-tertiary-btn-hover-text-color: #6267a1;
  --list-item-tertiary-btn-hover-icon-color: #6267a1;
  --list-item-tertiary-btn-hover-bgcolor: transparent;
  --list-item-tertiary-btn-active-text-color: #6267a1;
  --list-item-tertiary-btn-active-icon-color: #6267a1;
  --list-item-tertiary-btn-active-bgcolor: transparent;

  //Radio
  --radio-font-size: 16px;
  --radio-font-weight: 600;
  --radio-text-color: #4B4C53;
  --radio-bgcolor:#f5f5f6;
  --radio-border-color:#f5f5f6;
  --radio-border-radius: 8px;
  --radio-description-font-size: 14px;
  --radio-description-font-weight: 300;
  --radio-chkmark-bgcolor: transparent;
  --radio-chkmark-border-radius: 16px;
  --radio-chkmark-border-color: #ea5724;
  --radio-chkmark-chked-bgcolor: #ea5724;
  --radio-chkmark-chked-color: #fff;
  --radio-chkmark-chked-border-color: #f5f5f6;
  --radio-disabled: #888995;
  --radio-chk-disabled: #888995;

  //Slider
  --slider-font-size: 16px;
  --slider-font-weight: 600;
  --slider-text-color: #4B4C53;
  --slider-bgcolor: #C3C4CA;
  --slider-fill: #ea5724;
  --slider-border-radius: 8px;
  --slider-value-label-color: #ea5724;
  --slider-thumb-bgcolor: #fff;
  --slider-thumb-focus: #ea5724;
  --slider-text-font-size: 12px;
  --slider-text-font-weight: 400;
  --slider-disabled: #888995;
  --slider-error-color: #b00020;
}