@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_videoFigure.scss';
@import '../../assets/mixins.scss';
@import 'assets/mixins.scss';

.global_landing_page {
    .header {
        font-size: 20px;
        background: #F9F4F2;
        line-height: 20px;
        padding-top: 57px;
        padding-bottom: 40px;

        .header-container {
            @include container-rules();
        }

        h1{
            font-family: $hindMadurai-sb;
            font-size: 60px;
            color: $kk-primary;
            line-height: 60px;
            margin-top: 0;
            margin-bottom: 11px;
            font-weight: 600;
        }

        p {
            font-family: $hindMadurai-reg;
        }

        a {
            color: #ea5504 !important;
        }

        @include screen-width-mobile {
            padding-top: 24px;
            padding-bottom: 12px;
            h1 {
                font-size: 40px;
                font-weight: 600;
                line-height: 40px;
                letter-spacing: 0px;
            }

            p {
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
    .container {
        margin-bottom: 40px;

        @include screen-width-mobile {
            margin-bottom: 20px;
        }
    }
}