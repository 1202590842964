$tablet-width: 768px;
$desktop-width-sm: 1024px;
$desktop-width-lg: 1366px;
$zoom-out: 2000px;

@mixin tabletOnly {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width-sm - 1px}) {
    @content;
  }
}

@mixin tabletMin {
    @media (min-width: #{$tablet-width}) {
      @content;
    }
}

@mixin tabletMax {
  @media (max-width: #{$desktop-width-sm - 1px}) {
    @content;
  }
}

@mixin desktopOnly {
  @media (min-width: #{$desktop-width-sm}) {
    @content;
  }
}

@mixin desktopOnlyLarge {
  @media (min-width: #{$desktop-width-lg}) {
    @content;
  }
}
@mixin zoomOut {
  @media (min-width: #{$zoom-out}) {
    @content;
  }
}

