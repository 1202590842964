@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.app-layout--v1 {
  .my-hub-webinars {
    padding-left: 24px;
    @include screen-width-small {
      padding: 0 21px;
    }
  }
  
  .my-hub-webinars__title {
    font-family: $myriad-light;
    color: #ea5504;
    margin: 24px 0;
    font-weight: 300;
    font-size: 40px;
    line-height: 48px;
  
    @include screen-width-small {
      margin-top: 40px;
      font-size: 25px;
      font-weight: 300;
      line-height: 30px;
      letter-spacing: 0px;
    }
  }
  
  .my-hub-webinars__desc {
    font-family: $myriad-reg;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    margin-bottom: 10px;
  
    @include screen-width-small {
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0px;
    }
  }
  
  .webinar-labels {
    display: flex;
  }
  
  .webinar-card-count {
    margin-right: 32px;
    font-size: 16px;
    font-weight: 400;
    font-family: $myriad-reg;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0px;
    width: auto;
    padding-bottom: 8px;
    cursor: pointer;
    @include screen-width-small {
      margin-right: 10px;
    }
  }

  .webinar-card-count--active {
    font-family: $myriad-bold;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0px;
    width: auto;
    border-bottom: 2px solid #333333;
    padding-bottom: 8px;
  }
  
  .webinar-cards__list {
    display: none;
    margin-top: 20px;
    min-height: 800px;
  
    @include screen-width-small {
      min-height: auto;
    }
    
    &.active {
      display: block;
    }
  }
  
  .webinar-cards-item-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 639px) {
      width: calc(100% + 42px);
      margin-left: -21px;
    }
  }
  
  .webinar-cards-item-list__card {
    width: calc(50% - 10px);
    margin-bottom: 20px;
  
    @media (max-width: 900px) {
      width: 100%;
    }
  }
  
}
.app-layout--v2 {
  .my-hub-webinars {
    padding-left: 24px;
    @include screen-width-small {
      padding: 0 21px;
    }
  }
  
  .my-hub-webinars__title {
    // font-family: $myriad-light;
    font-family: $hindMadurai-sb;
    color: #ea5504;
    margin: 24px 0;
    font-weight: 300;
    font-size: 40px;
    line-height: 48px;
  
    @include screen-width-small {
      margin-top: 40px;
      font-size: 25px;
      font-weight: 300;
      line-height: 30px;
      letter-spacing: 0px;
    }
  }
  
  .my-hub-webinars__desc {
    font-family: $hindMadurai-reg;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    margin-bottom: 10px;
  
    @include screen-width-small {
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0px;
    }
  }
  
  .webinar-labels {
    display: flex;
  }
  
  .webinar-card-count {
    margin-right: 32px;
    font-size: 16px;
    font-weight: 400;
    font-family: $hindMadurai-reg;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0px;
    width: auto;
    padding-bottom: 8px;
    cursor: pointer;
    @include screen-width-small {
      margin-right: 10px;
    }
  }

  .webinar-card-count--active {
    font-family: $hindMadurai-sb;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0px;
    width: auto;
    border-bottom: 2px solid #333333;
    padding-bottom: 8px;
  }
  
  .webinar-cards__list {
    display: none;
    margin-top: 20px;
    min-height: 800px;
  
    @include screen-width-small {
      min-height: auto;
    }
    
    &.active {
      display: block;
    }
  }
  
  .webinar-cards-item-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 639px) {
      width: calc(100% + 42px);
      margin-left: -21px;
    }
  }
  
  .webinar-cards-item-list__card {
    width: calc(50% - 10px);
    margin-bottom: 20px;
  
    @media (max-width: 900px) {
      width: 100%;
    }
  }
}

