@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

article {
    .RichText {
      font-size: 16px;
      line-height: 20px;
      h2 {
        font-size: 20px;
        font-family: $subtitle-font;
      }
      h3 {
        font-size: 16px;
        font-family: $bold-font;
      }
      ul, ol {
        padding-left: 16px;
        line-height: 27px;
      }
      a {
        color: $body-text-color;
      }
    }
  }

  .RichText {
    a {

      &:visited {
        color: blue;
      }

    }

    blockquote {
      background-color: $kk-secondary;
      position: relative;
      margin: 0;
      padding: 20px 20px 20px 24px;
      width: calc(100% + 40px);
      left: -20px;
      margin-bottom: 20px;
  
      @media (min-width: $MIN_WIDTH_MEDIUM) {
        margin-bottom: 30px;
      }

      @include screen-width-large {
        width: calc(100% + 40px);
        left: -20px;
      }

      @include screen-width-xl{
        width: calc(100% + 40px);
        left: -20px;
      }

      h4 {
        font-size: 24px;
        margin: 0 0 10px;
        letter-spacing: 0px;
        font-family: $hindMadurai-reg;
        font-style: normal;
        font-size: 20px;
        line-height: 24px;

        @media (min-width: $MIN_WIDTH_MEDIUM) {
          margin: 0 0 20px;
          font-family: $hindMadurai-reg;
          font-weight: 600;
          font-size: 25px;
          line-height: 28px;
        }
      }

      p {
        margin: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        max-width: 100%;
        font-family: $hindMadurai-reg;
        font-style: italic;
        font-weight: normal;
        font-size: 16px;
        line-height: 14px;

        @media (min-width: $MIN_WIDTH_MEDIUM) {
          font-size: 16px;
          line-height: 20px;
        }

      }
    }
    
    blockquote:before {
      position: absolute;
      content: '';
      width: 3px;
      left: 10px;
      height: calc(100% - 40px);
      background-color: $kk-primary;
      
      @media (min-width: $MIN_WIDTH_MEDIUM) {
        width: 4px;
      }
    }
  }