@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.cookie-confirmation-dialogue {
  padding: 30px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.15);
  z-index: 1005;

  p {
    line-height: 20px;
    margin: 0;
    a {
      color: $body-text-color;
    }
  }
  .controls {
    margin-top: 24px;
    display: flex;
    justify-content: flex-start;
    button {
      text-transform: uppercase;
      min-width: 160px;
      &:first-child {
        margin-right: 20px;
      }
      @include screen-width-small {
        min-width: inherit;
        width: 100%;
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}
