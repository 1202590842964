@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.completed-course-card {
  position: relative;
  width: 100%;
  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  padding: 20px;
  .delete-btn {
    position: absolute;
    cursor: pointer;
    right: 27px;
    top: 24px;

    @media (max-width: 950px) {
      right: 17px;
      top: auto;
      bottom: 26px;
    }

    @media (min-width: 950px) and (max-width: 1150px) {
      right: 20px;
      top: 24px;
    }

    svg {
      width: 30px;
      color: #ea5504;
    }
  }

  .delete-btn-tooltip {
    position: absolute;
    right: 0;
    top: -70px;
    width: 200px;
    z-index: 5;
    background-color: #faf6f4;
    padding: 10px;
    border-radius: 15px;
    display: none;
    max-height: 74px;

    &.active {
      @media (max-width: 825px) {
        display: none;
      }
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      display: block;
    }
  }

  @media (max-width: 950px) {
    margin-bottom: 16px;
    padding: 16px 10px 22px 16px;
    flex-direction: column;
  }

  &__title {
    width: 25%;
    margin-right: 15px;

    @media (max-width: 950px) {
      width: 85%;
      justify-content: flex-start;
      margin-bottom: 25px;
    }

    h3 {
      margin: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      font-family: $hindMadurai-sb;
      line-height: 19px;
    }
  }

  &__dates {
    display: flex;
    width: 48%;

    @media (max-width: 950px) {
      width: 100%;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    &__registered {
      display: flex;
      flex-direction: column;
      margin-right: 10%;
      margin-left: 5%;

      @media (max-width: 950px) {
        margin: 0;
      }

      .label {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        font-family: $hindMadurai-sb;
        margin-bottom: 2px;
      }

      .date {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
      }
    }

    &__end {
      display: flex;
      flex-direction: column;

      @media (max-width: 825px) {
        margin: 0;
      }

      .label {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 2px;
        line-height: 19px;
        font-family: $hindMadurai-sb;
      }

      .date {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
      }
    }

    &__start {
      display: flex;
      flex-direction: column;
      margin-right: 10%;

      @media (max-width: 950px) {
        margin: 0;
      }

      .label {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 2px;
        line-height: 19px;
        font-family: $hindMadurai-sb;
      }

      .date {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  &__continue {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 7.5%;

    @media (max-width: 950px) {
      margin-right: 0;
      justify-content: flex-start;
      width: 100%;
    }
    @media (min-width: 950px) and (max-width: 1150px) {
      margin-left: 15px;
      margin-right: 2%;
    }

    &__btn {
      float: right;
      margin-right: 5%;

      @media (max-width: 950px) {
        margin-right: 0;
      }

      a {
        display: flex;
        justify-content: center;
        background: #ea5504;
        border-radius: 30px;
        border: none;
        color: #ffffff;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        padding: 8px 20px;
        min-width: 106.12px;
        text-decoration: none;
      }
    }
  }
}
