@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.icon-link-grid__item {
    width: 100%;
    display: flex;
    margin-bottom: 50px;
    @include screen-width-small {
        @include spans-columns(12);
        flex-direction: column;
    }
    .main-icon {
      width: 80px;
      height: 80px;
      @include screen-width-small {
          margin: 0 auto;
      }
      @include screen-width-medium {
        max-width: 60px;
        max-height: 60px;
      }
    }
    .icon-link-grid-item__contentWrapper {
        margin-left: 30px;
        display: flex;
        @include screen-width-small {
            margin-left: 0;
            text-align: center;
            display: block;
        }
        .arrow {
            margin-top: 33px;
            margin-right: 10px;
            width: 23px;
            height: 12px;
            @include screen-width-small {
                display: none;
            }
            @include screen-width-medium {
                max-height: 12px;
                min-width: 23px;
            }
        }
        .icon-link-grid-item__content {
            @include screen-width-small {
                margin-left: 0;
                text-align: center;
            }  
            .icon-link-grid-item__title {
                .RichText * {
                    font-weight: bold;
                    font-size: 21px !important;
                    margin-block-start: 1.33em;
                    margin-block-end: 1.33em;
                }
            }
            .icon-link-grid-item__button {
                font-weight: normal;
                font-size: 16px;
                border: 2px solid #FFFFFF;
                border-radius: 20px;
                display: inline-block;
                padding: 6px 15px;
                text-transform: uppercase;
                cursor: pointer;
                @include screen-width-medium {
                    font-size: 14px;
                }
            }
        }
    }
}