// Responsiveness mixins for Rapp-coma
// The breakpoints are defined as in the figm designs
// The grid system uses basic sass mixins to apply a grid
// based on the approach within bootstrap
// https://getbootstrap.com/docs/4.0/layout/grid/


// Breakpoints as defined in RAPP-KKLife designs
// the sizes (using the naming in the design) are small, medium, large and xl
// anything less than 640px is considered small width
// NB: these need to be up to date with the values in appConfig javascript
$MIN_WIDTH_MEDIUM: 640px;
$MIN_WIDTH_LARGE: 1024px;
$MIN_WIDTH_XL: 1366px;

// mixins for media queries for each breakpoint.
// we use @content to allow arbitrary nesting of sass instructions within the mixin
// see: http://www.sass-lang.com/documentation/at-rules/mixin#content-blocks
@mixin screen-width-small {
  @media (min-width: 0) and (max-width: #{$MIN_WIDTH_MEDIUM - 1px}) {
    @content;
  }
}

@mixin screen-width-medium {
  @media (min-width: $MIN_WIDTH_MEDIUM) and (max-width: #{$MIN_WIDTH_LARGE - 1px}) {
    @content;
  }
}

@mixin screen-width-large {
  @media (min-width: $MIN_WIDTH_LARGE) and (max-width: #{$MIN_WIDTH_XL - 1px}) {
    @content;
  }
}

@mixin screen-width-xl {
  @media (min-width: $MIN_WIDTH_XL) {
    @content;
  }
}

// convenience mixins for styles that apply to desktop or mobile
// sizes small and medium are for mobile
// and large and xl are for desktop

// mixin for both desktop sizes. These are large and xl
@mixin screen-width-desktop {
  @media (min-width: $MIN_WIDTH_LARGE) {
    @content;
  }
}

// mixin for mobile sizes. These are small and medium
@mixin screen-width-mobile {
  @media (min-width: 0) and (max-width: #{$MIN_WIDTH_LARGE - 1px}) {
    @content;
  }
}

// Very basic grid system queries, based on bootstraps approach to a grid system.
$number_columns: 12; // default 12 columns on medium large and xl
$number_columns_small: 4;

// gutter width defaults to 20px on all screen sizes except for xl
$gutter_width: 20px;
$gutter_width_xl: 30px;

$half_gutter: $gutter_width / 2;
$half_gutter_xl: $gutter_width_xl / 2;

// column widths are in percentage, with columns stretching with screen size
$column_width: 100% / $number_columns;
$column_width_small: 100% / $number_columns_small;

// Mixin for a component representing a row within the grid
// the row has a negative left and right margin of half a gutter to allow the row
// items to have a gutter spacing either side
@mixin grid-row {
  display: flex;
  flex-direction: row;
  $negative_margin: (-1 * $half_gutter);
  margin-left: $negative_margin;
  margin-right: $negative_margin;
  width: calc(100% + #{$gutter_width});
  @include screen-width-xl {
    $xl_negative_margin: -1 * $half_gutter_xl;
    margin-left: $xl_negative_margin;
    margin-right: $xl_negative_margin;
    width: calc(100% + #{$gutter_width_xl});
  }
}

// Mixin for adding a gutter
// the gutter is implemented as internal padding with half the gutter left and right
// the gutter at the edges is dealt with by a negative margin on the row container
@mixin with-grid-gutter {
  padding-left: $half_gutter;
  padding-right: $half_gutter;
  @include screen-width-xl {
    padding-left: $half_gutter_xl;
    padding-right: $half_gutter_xl;
  }
}

// Mixin for a row item spanning n columns
// the grid gutter is added with padding and the width
// is defined by the number of columns
// because the item has padding for the gutter, any content padding for the content of the row item
// should be applied to a child element
@mixin spans-columns($num_cols) {
  width: $num_cols * $column_width;
  @include with-grid-gutter();
}

// Seperate version of the mixin for small screen widths
// as the number of columns is different
@mixin spans-columns-small($num_cols) {
  @include screen-width-mobile {
    width: $num_cols * $column_width_small;
    @include with-grid-gutter();
  }
}

@mixin spans-columns-dynamic($num_cols) {
  max-width: $num_cols * $column_width;
  @include with-grid-gutter();
}

// Seperate version of the mixin for small screen widths
// as the number of columns is different
@mixin spans-columns-dynamic-small($num_cols) {
  @include screen-width-mobile {
    max-width: $num_cols * $column_width_small;
    @include with-grid-gutter();
  }
}

// mixins to offset be N columns to the left
// margin left should be $num_cols width
@mixin offset-columns($num_cols) {
  margin-left: $num_cols * $column_width;
}

@mixin offset-columns-small($num_cols) {
  @include screen-width-mobile {
    margin-left: $num_cols * $column_width_small;
  }
}


@mixin container-rules() {
  box-sizing: border-box;
  max-width: $container-max-width;
  margin: 0 auto;
  padding: 0 $container-mobile-padding;
  @include screen-width-large {
    padding: 0 $container-large-padding;
  }
  @include screen-width-xl {
    padding: 0 $container-xl-padding;
  }
  // at screen widths larger than container-max-width, the padding should go back to fixed
  @media (min-width: #{$container-max-width}) {
    padding: 0 87px;
  }
}