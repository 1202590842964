@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.form-redesign {
  textarea {
    border-radius: 4px !important;
  }
  textarea:focus {
    border:1.5px solid #EA5504;
  } 
  .input-section {
    textarea:focus-visible {
      outline: none;
    }
  
  }
  

.form-inline-error-text {
  float: unset;
} 
}

.form-field-textarea {
  width: 100%;

  .field-meta-data {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 4px;
  }
  .input-section {
    margin-bottom: 30px;
  }
  textarea {
    resize: none;
    width: 100%;
    height: 160px;
    padding: 12px 20px;
    font-size: 16px;
    line-height: 19px;
    color: #333;
    background-color: #fff;
    border: 1px solid #c4c4c4;
    border-radius: 30px;

    @include screen-width-small {
      height: 260px;
    }

    &::-webkit-scrollbar {
      width: 22px;
      border-radius: 14px;
      background-clip: padding-box;
      border: 1px solid transparent;
    }
    &::-webkit-scrollbar-thumb {
      width: 22px;
      border-radius: 18px;
      background-clip: padding-box;
      border: 7px solid transparent;
    }
    
    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 0 10px #C3C4CA;
    }

    &:focus-visible {
      outline: 2px solid #6267a1;
    }
  }

  .error {
    color: #ff0000;
    line-height: 0px;
    padding-top: 5px;
    margin: 0;
  }

  .helptexts {
    display: flex;

    .helptext-label {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      margin-top: 0;
    }

    .helptext-counts {
      margin-top: 0;
      margin-left: auto;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
    }
  }
}
