.audio-section {
  width: 100%;
  min-height: 73px;
  background: #f9f4f2;
  border-radius: 30px;
  padding: 24px;
  display: flex;
  margin-bottom: 40px;

  &.redesign {
    border-radius: 8px;
  }

  .rhap_container {
    background: transparent;
    box-shadow: inherit;

    &.rmPadding {
      padding-left: 0;
      padding-right: 0;
    }

    .rhap_volume-container {
      flex: none;
    }

    .rhap_progress-filled {
      background: transparent;
    }

    .rhap_volume-bar-area,
    .rhap_controls-section {
      display: none;
    }

    .rhap_progress-indicator {
      display: none;
      background: #ea5504;
      width: 12px;
      height: 12px;
      top: -5.5px;
    }

    .rhap_main-controls {
      margin-right: 8px;
    }

    .rhap_progress-bar {
        // background-color: #979797;
        border-radius: 8px;
        height: 2px;
    }

    .rhap_time {
        font-size: 14px;
        font-weight: 600;

        &.rhap_current-time {
            margin-right: 3px;
        }

        &.rhap_total-time {
            margin-left: 3px;
        }
    }

    button {
      display: contents;
    }
  }

  h2 {
    font-style: normal;
    font-size: 25px;
    line-height: 160%;
    margin: auto;

    p {
      margin-top: 0;
    }

    strong {
        font-weight: 600;
    }
  }

  p {
    margin-bottom: 0;
    line-height: 160%;
  }

  img {
    width: 275px;
    max-width: 100%;
    height: 192px;
    border-radius: 8px;
    margin-right: 24px;
  }

  .audio-content {
    width: inherit;
  }

  .audio-player-wrapper {
    display: flex;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;

    img {
      width: 100%;
      margin: 0;
    }
  }
}
