@import 'style/variables.scss';
.modal-references{
    margin-top:40px;
}
#modalDetail{
    .Related_Content_Section{
        padding: 5%;
    }
    .title-section-title{
        font-size:40px;
        @media only screen and (min-width:768px){
            font-size:60px;
        }
    }
    .title-section-category{
        text-transform: capitalize;
    }
    // .title-section-inner{
    //     padding-top:40px;
    // }
    .content_wrapper--columns-2-left{
        flex:none;
        max-width: 25%;
        word-break: break-word;
    }
    .accessCourse{
        @media only screen and (max-width:765px){
            padding:0 4%;
        }
        margin-top: 50px;
        padding: 0 6%;
        
        a{
            color:#EA5504;
            text-decoration: none;
            font-family: $hindMadurai-reg;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 19px;
            letter-spacing: 1px;
            text-align: left;
        }
        &:hover{
            text-decoration: underline;
        }
    }
    .learn-more-text{
        display: inline;
        text-transform: uppercase;
        font-family:$hindMadurai-sb;
    }
    .svg-course{
        display: inline;
        padding-left:50px;
        vertical-align: sub;
    }
    .brand_logo_section{
        width:254px;
        img{
            width:100%;
            height: 100%;
        }
    }
    .title-section-category{
        color:black;
    }
    .main-title-section.has-category .title-section-inner .title{
        width:100%;
    }

}