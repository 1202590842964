@import "../../../../assets/kanso/styles/global.scss";

.carousel {
  font-family: inherit;
  color: var(--carousel-text-color, var(--base-font-color));
  padding: 0 0 32px;
  margin: 0;
  max-width: 100vw;
  width: inherit;

  .headline {
    font-size: var(--carousel-headline-font-size, 28px);
    font-weight: var(--carousel-headline-font-weight, 400);
    line-height: var(--carousel-headline-line-height, 33.6px);
    margin: 0 0 24px;
  }

  .slider-container {
    position: relative;
    overflow: hidden;

    .carousel-items {
      display: flex;
      margin-bottom: 40px;

      .slider-item {
        flex: 0 0 100%;
        height: fit-content;
      }
    }

    .prev,
    .next {
      position: absolute;
      top: calc(50% - 40px);

      a {
        padding: var(--carousel-btn-prevnext-middle-padding, 10px);
        // --tertiary-btn-text-color: var(
        //   --carousel-btn-prevnext-middle-text-color,
        //   #fff
        // );
        --tertiary-btn-bgcolor: transparent;
        // --tertiary-btn-hover-text-color: var(
        //   --carousel-btn-hover-prevnext-middle-text-color,
        //   #000
        // );
        --tertiary-btn-hover-bgcolor: var(
          --carousel-btn-hover-prevnext-middle-bgcolor,
          #fff
        );
        // --tertiary-btn-active-text-color: var(
        //   --carousel-btn-active-prevnext-middle-text-color,
        //   #000
        // );
        --tertiary-btn-active-bgcolor: transparent;
        --tertiary-btn-disabled-text-color: var(
          --carousel-btn-disabled-prevnext-middle-text-color,
          #ccc
        );
        --tertiary-btn-disabled-bgcolor: transparent;

        svg {
          width: 16px;
        }
      }

      @include media(extraSmall, small) {
        top: auto;
        left: auto;
        right: auto;
      }
    }

    .next {
      right: 0;
    }

    .prev {
      left: 0;
    }

    .navigation {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      width: fit-content;
      margin: 0 auto;
      gap: 10px;
      position: absolute;
      bottom: 5%;
      left: 50%;
      transform: translate(-50%, 50%);

      .dot {
        font-size: 25px;
        line-height: 20px;
        cursor: pointer;
        border: 0;
        outline: none;
        display: block;
        color: #c3c4ca;
        text-align: center;
        text-decoration: none;
        margin-top: 2px;

        &-active {
          color: #6267a1;
          content: url("../../../../assets/kanso/images/carousel_active.png");
        }
      }

      .next-bottom, .prev-bottom {
        svg {
          // margin: 0;
          padding-top: 4px;
          width: 16px;
          height: 16px;
        }
      }

      li {
        display: flex;
        align-items: center;
      }
    }
  }

  &.carousel-height {
    [class$="slick-list"] {
      height: var(--carousel-slide-height, auto) !important;
    }
  }

  .carouselContent {
    border: none;
    width: 95%;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }

  [class^="slick-slider"] {
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  [class$="slick-list"] {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;

    &:focus {
      outline: none;
    }
  }

  [class$="slick-slider"] .slick-track,
  [class$="slick-slider"] .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  [class$="slick-track"] {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
      display: table;
      content: "";
    }

    &:after {
      clear: both;
    }
  }

  [class$="slick-loading"] .slick-list {
    background: #fff url("../../../../assets/kanso/images/ajax-loader.gif") center center
      no-repeat;
  }

  [class$="slick-loading"] .slick-track {
    visibility: hidden;
  }

  [class$="slick-loading"] .slick-slide {
    visibility: hidden;
  }

  [dir="rtl"][class$="slick-slide"] {
    float: right;
  }

  [class$="slick-hidden"] {
    display: none;
  }

  [class~="slick-slide"] {
    display: none;
    float: left;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    height: 100%;
    min-height: 1px;
  }

  [class$="slick-initialized"] {
    [class~="slick-slide"] {
      display: block;
    }
  }

  [class$="slick-dots"] {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;

    [class$="slick-active"] {
      padding: 0 5px 0 0;

      button {
        height: 30px;
        padding: 0 5px 0 0;

        &:before {
          color: #6267a1;
          content: url("../../../../assets/kanso/images/carousel_active.png");
        }
      }
    }

    [class$="prev-bottom"],
    [class$="next-bottom"] {
      transform: translateY(8px);
      margin: 0 8px 0 0;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    [class$="next-bottom"] {
      margin: 0 0 0 8px;
      svg {
        width: 17px;
        height: 17px;
      }
    }

    [class$="prev-bottom"] a,
    [class$="next-bottom"] a {
      padding: var(--carousel-btn-prevnext-bottom-padding, 0);
      // --tertiary-btn-text-color: var(
      //   --carousel-btn-prevnext-bottom-text-color,
      //   #757ab5
      // );
      --tertiary-btn-bgcolor: var(
        --carousel-btn-prevnext-bottom-bgcolor,
        transparent
      );
      // --tertiary-btn-hover-text-color: var(
      //   --carousel-btn-hover-prevnext-bottom-text-color,
      //   #757ab5
      // );
      --tertiary-btn-hover-bgcolor: var(
        --carousel-btn-hover-prevnext-bottom-bgcolor,
        transparent
      );
      // --tertiary-btn-active-text-color: var(
      //   --carousel-btn-active-prevnext-bottom-text-color,
      //   #757ab5
      // );
      --tertiary-btn-active-bgcolor: var(
        --carousel-btn-active-prevnext-bottom-bgcolor,
        transparent
      );
      --tertiary-btn-disabled-text-color: var(
        --carousel-btn-disabled-prevnext-bottom-text-color,
        #ccc
      );
      --tertiary-btn-disabled-bgcolor: var(
        --carousel-btn-disabled-prevnext-bottom-bgcolor,
        transparent
      );
    }

    [class$="prev-middle"],
    [class$="next-middle"] {
      position: absolute;
      top: calc((var(--carousel-slide-height) / 2) - 12px);
    }

    [class$="prev-middle"] {
      left: 0;
    }

    [class$="next-middle"] {
      right: 0;
    }

    [class$="prev-middle"] a,
    [class$="next-middle"] a {
      padding: var(--carousel-btn-prevnext-middle-padding, 10px);
      // --tertiary-btn-text-color: var(
      //   --carousel-btn-prevnext-middle-text-color,
      //   #fff
      // );
      --tertiary-btn-bgcolor: var(--carousel-btn-prevnext-middle-bgcolor, #000);
      // --tertiary-btn-hover-text-color: var(
      //   --carousel-btn-hover-prevnext-middle-text-color,
      //   #000
      // );
      --tertiary-btn-hover-bgcolor: var(
        --carousel-btn-hover-prevnext-middle-bgcolor,
        #fff
      );
      // --tertiary-btn-active-text-color: var(
      //   --carousel-btn-active-prevnext-middle-text-color,
      //   #000
      // );
      --tertiary-btn-active-bgcolor: var(
        --carousel-btn-active-prevnext-middle-bgcolor,
        #fff
      );
      --tertiary-btn-disabled-text-color: var(
        --carousel-btn-disabled-prevnext-middle-text-color,
        #ccc
      );
      --tertiary-btn-disabled-bgcolor: var(
        --carousel-btn-disabled-prevnext-middle-bgcolor,
        #000
      );
    }

    li {
      position: relative;
      display: inline-block;
      padding: 0;
      margin: 0;
      cursor: pointer;

      button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 20px;
        height: 20px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;

        &::before {
          font-size: 25px;
          line-height: 20px;
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          content: "•";
          text-align: center;
          color: #c3c4ca;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }
  }

  @include media(extraSmall, small) {
    [class$="slick-dots"] {
      position: sticky;
      bottom: 0;
      padding: 8px 0 12px;
      background-color: #fff;

      [class$="prev-middle"],
      [class$="next-middle"] {
        position: unset;
      }

      [class$="prev-middle"],
      [class$="next-middle"] {
        transform: translateY(8px);
        margin: 0 8px 0 0;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      [class$="next-middle"] {
        margin: 0 0 0 8px;
        svg {
          width: 17px;
          height: 17px;
        }
      }

      [class$="prev-middle"] a,
      [class$="next-middle"] a {
        padding: var(--carousel-btn-prevnext-bottom-padding, 0);
        // --tertiary-btn-text-color: var(
        //   --carousel-btn-prevnext-bottom-text-color,
        //   #000
        // );
        --tertiary-btn-bgcolor: var(
          --carousel-btn-prevnext-bottom-bgcolor,
          transparent
        );
        // --tertiary-btn-hover-text-color: var(
        //   --carousel-btn-hover-prevnext-bottom-text-color,
        //   #000
        // );
        --tertiary-btn-hover-bgcolor: var(
          --carousel-btn-hover-prevnext-bottom-bgcolor,
          transparent
        );
        // --tertiary-btn-active-text-color: var(
        //   --carousel-btn-active-prevnext-bottom-text-color,
        //   #000
        // );
        --tertiary-btn-active-bgcolor: var(
          --carousel-btn-active-prevnext-bottom-bgcolor,
          transparent
        );
        --tertiary-btn-disabled-text-color: var(
          --carousel-btn-disabled-prevnext-bottom-text-color,
          #ccc
        );
        --tertiary-btn-disabled-bgcolor: var(
          --carousel-btn-disabled-prevnext-bottom-bgcolor,
          transparent
        );
      }
    }
  }
}
