.AdverseEvents{
    margin-top: 50px;
    margin-bottom: 50px;
    border: 2px solid #979797;
    padding: 0 15px;

    a{
        color: #333 !important;
    }
}

.AdverseEvents--remove-bottom-spacing {
    margin-bottom: 0;
}