.login-branding {
  display: flex;
  justify-content: flex-start;
  margin: 30px 0px 70px 0px;
  a {
    display: block;
    img {
      display: block;
      height: 26px;
    }
  }
}