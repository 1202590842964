@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_titleWithIcon.scss';
@import 'src/style/mixins/_videoFigure.scss';




.article-section-component {
  
  .article-section-title {
    margin: 0px 0px 16px 0px;
    font-size: 20px;
    line-height: 25px;
    font-family: $subtitle-font;
    font-weight: 600;
  }
  p {
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 30px;
  }
  section {
    padding-bottom: 16px;
  }
  img {
    max-width: 100%;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

