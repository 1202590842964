.CardImage {
    &--bg-image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        flex-shrink: 0;
    }

    &--icon-image {}

    &--brand-logo {
        margin-bottom: 10px;
        
        img {
            width: 100%;
            max-width: 140px;
        }
    }
}