@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.app-layout--v1 {
  .my-hub-settings {
    width: 100%;
    padding: 24px 120px 40px 120px;
    margin: auto;
    @include screen-width-small {
      padding: 0 1em 40px 1em;
    }
    @include screen-width-medium {
      padding: 24px 60px 40px 60px;
    }
  
    @include screen-width-large {
      padding: 24px 60px 40px 60px;
    }
    
  
    .content-title {
      h2 {
        font-family: $title-font;
        color: #ea5504;
        font-size: 40px;
        font-style: normal;
        font-weight: 300;
        line-height: 40px;
        letter-spacing: 0px;
        text-align: left;
        margin-bottom: 40px;
      }
    }
  
    .account-details {
      margin-top: 0;
    }
  
    .my-hub-divider {
      width: 100%;
      border: 1px dashed rgba(234, 85, 4, 0.5);
      margin-bottom: 29px;
    }
  
    .form-component {
      margin-bottom: 0;
    }
  }
}
.app-layout--v2 {
  .my-hub-settings {
    width: 100%;
    padding: 24px 120px 40px 120px;
    margin: auto;
    @include screen-width-small {
      padding: 0 1em 40px 1em;
    }
    @include screen-width-medium {
      padding: 24px 60px 40px 60px;
    }
  
    @include screen-width-large {
      padding: 24px 60px 40px 60px;
    }
    
  
    .content-title {
      h2 {
        font-family: $title-font-v2;
        color: #ea5504;
        font-size: 40px;
        font-style: normal;
        font-weight: 300;
        line-height: 40px;
        letter-spacing: 0px;
        text-align: left;
        margin-bottom: 40px;
      }
    }
  
    .account-details {
      margin-top: 0;
    }
  
    .my-hub-divider {
      width: 100%;
      border: 1px dashed rgba(234, 85, 4, 0.5);
      margin-bottom: 29px;
    }
  
    .form-component {
      margin-bottom: 0;
    }
  }
}
