@import 'style/reset.scss';
@import 'style/variables.scss';

.embedded-pdf-comonent {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  .embedded-pdf-container {
    display: block;
    position: relative;
    border: 4px solid #000000;

    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }
  .embedded-pdf-controls {
    display: none;
    width: 100%;
    justify-content: center;
    position: absolute;
    bottom: 4px;
    button {
      display: block;
      padding: 0 4px;
      background: transparent;
      border: 0px;
      &:hover {
        background-color: #F3F2F1;
      }
    }
    span {
      display: block;
      margin: 0px 4px;
    }
  }
  &:hover {
    .embedded-pdf-controls {
      display: flex;
    }
  }
}