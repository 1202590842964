@import '../../../../../assets/mixins.scss';

.app-layout--v1 {
    .MobileToggle {
        grid-area: mobileToggle;
        align-self: center;
        justify-self: end;
        width: 30px;
        height: 27px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
    
        @include desktopOnly {
            display: none;
        }
    
        span {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            background: #FFFFFF;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
    
            &:nth-child(1) {
                top: 0px;
            }
    
            &:nth-child(2), &:nth-child(3) {
                top: 13px;
            }
    
            &:nth-child(4) {
                top: 25px;
            }
        }
    
        &--open span {
    
            &:nth-child(1) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
    
            &:nth-child(2) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }
    
            &:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
    
            &:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
        }
    }
}
.app-layout--v2 {
    .MobileToggle {
        grid-area: mobileToggle;
        align-self: center;
        justify-self: end;
        margin-left: 12px;
        width: 30px;
        height: 27px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
    
        @include tabletMin {
            display: none;
        }
    
        span {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            background: #333333;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
    
            &:nth-child(1) {
                top: 0px;
            }
    
            &:nth-child(2), &:nth-child(3) {
                top: 13px;
            }
    
            &:nth-child(4) {
                top: 25px;
            }
        }
    
        &--open span {
    
            &:nth-child(1) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
    
            &:nth-child(2) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }
    
            &:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
    
            &:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
        }
    }
}