.customPopup {
    position: relative;
    background: #ffffff;
    text-transform: none;
    color: #3D3F5B;
    display: block;
    margin-bottom: 15px;
    opacity: 0;
    padding: 20px;
    pointer-events: none;
    position: absolute;
    width: 550px;
    border-radius: 20px;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -ms-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
    -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    z-index: 999;
    &::after {
        border-left: solid transparent 20px;
        border-right: solid transparent 20px;
        border-top: solid #ffffff 20px;
        top: -20px;
        content: " ";
        height: 0;
        left: 50%;
        margin-left: -13px;
        position: absolute;
        width: 0;
        transform: rotate(180deg);
    }
    &::before {
        bottom: -20px;
        content: " ";
        display: block;
        height: 20px;
        left: 0;
        position: absolute;
        width: 100%;
    }
    &.show {
        opacity: 1;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px);
    }
    &.hide {
        opacity: 0;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px);
    }
    .customPopup__closeBtn {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 5px;
        top: 5px;
        pointer-events: auto;
        cursor: pointer;
    }
    sup {
        width:25px;
        height:25px;
        cursor: pointer;
        pointer-events:auto
    }
}