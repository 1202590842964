@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';


$standard-item-margin-bottom: 30px;

.app-layout--v1 {
  .promo-banner-component {
    position: relative;
    padding: 40px 30px;
    margin-bottom: 30px;
    z-index: 0;
  
    &-background {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: -1; // behind content
      background-color: #F8F8FA;
      border-radius: 35px;
  
      &-bg-darker {
        background-color: #faf6f4;
      }
  
      &-bg-white {
        background-color: #ffffff;
      }
  
      &-shadow {
        -webkit-box-shadow: 2px 13px 13px -14px rgba(0, 0, 0, 0.35);
        box-shadow: 2px 13px 13px -14px rgba(0, 0, 0, 0.35);
      }
    }
  
    .inner-content {
      width: 33%;
  
      @include screen-width-mobile {
        width: 80%;
      }
  
      @include screen-width-small {
        width: 100%;
      }
    }
  
    h3 {
      margin: 0px 0px $standard-item-margin-bottom 0px;
      font-size: 20px;
      /*
        // FN: 20/11/2020
        // Client complained about the font size being 24px. But the rule that makes it looks too deliberate. There's not
        // much time to check with the client so I'm just gonna comment it out for now. If they complain it's not 24px
        // in the near future, well, you know what to do champ!
        font-size: 24px;
        @include screen-width-large {
          font-size: 20px;
        }
      */
  
    }
  
    .promo-banner-text {
      margin-bottom: 30px;
  
      @include screen-width-medium {
        margin-bottom: 80px;
      }
    }
  
    p {
      margin: 0px 0px $standard-item-margin-bottom 0px;
      font-size: 20px;
    }
    .chevron-button-text {
      font-size: 16px;
    }
    .promo-button {
      display: flex; // to make the button full width
      height: 2.55em; // to fix IE11 layout
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      p {
        margin: 0;
      }
    }
  
    *:last-child {
      margin-bottom: 0px;
    }
  }
}
.app-layout--v2 {
  .promo-banner-component {
    position: relative;
    padding: 30px;
    margin-bottom: 30px;
    z-index: 0;
  
    &-background {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: -1; // behind content
      background-color: #F8F8FA;
      border-radius: 5px;
  
      &-bg-darker {
        background-color: #faf6f4;
      }
  
      &-bg-white {
        background-color: #ffffff;
      }
  
      &-shadow {
        -webkit-box-shadow: 2px 13px 13px -14px rgba(0, 0, 0, 0.35);
        box-shadow: 2px 13px 13px -14px rgba(0, 0, 0, 0.35);
      }
    }
  
    .inner-content {
      width: 33%;
  
      @include screen-width-mobile {
        width: 80%;
      }
  
      @include screen-width-small {
        width: 100%;
      }
    }
  
    h3 {
      margin: 0px 0px $standard-item-margin-bottom 0px;
      font-size: 20px;
      /*
        // FN: 20/11/2020
        // Client complained about the font size being 24px. But the rule that makes it looks too deliberate. There's not
        // much time to check with the client so I'm just gonna comment it out for now. If they complain it's not 24px
        // in the near future, well, you know what to do champ!
        font-size: 24px;
        @include screen-width-large {
          font-size: 20px;
        }
      */
  
    }
  
    .promo-banner-text {
      margin-bottom: 18px;
      min-height: 194px;
  
      @include screen-width-medium {
        margin-bottom: 80px;
      }
    }
  
    p {
      margin: 0px 0px $standard-item-margin-bottom 0px;
      font-size: 20px;
    }
    
    .chevron-button-text {
      font-size: 16px;
    }

    .promo-button {
      display: flex; // to make the button full width
      height: 2.55em; // to fix IE11 layout
      flex-direction: row;
      justify-content: space-between;

      p {
        margin: 0;
      }
    }
  
    *:last-child {
      margin-bottom: 0px;
    }
  }
  .promo-banner-component {
    position: relative;
    padding: 30px;
    margin-bottom: 30px;
    z-index: 0;
  
    &-background {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: -1; // behind content
      background-color: #fff;
      box-shadow: 0px 2px 4px rgba(52, 58, 64, 0.25);
      border-radius: 5px;
    }
  }
}