@import "style/variables.scss";
@import "style/mixins/_responsive.scss";
@import "style/mixins/_titleWithIcon.scss";

.article-content-list-container {
  background-color: $kk-secondary;
  border-radius: 5px;
  overflow: hidden;
  // contents list dissapears on mobile
  @include screen-width-mobile {
    // display: none;
    z-index: 1000;
    margin: 20px 0 20px 0;
    // position: relative;
    top: 0;
  }
  .article-content-list-title {
    @include title-with-icon;
    font-family: $subtitle-font;
    padding: 16px 20px;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid white;

    svg:last-child {
      position: absolute;
      right: 15px;
    }
  }
  .article-content-list {
    margin: 0;
    padding: 0;
    li {
      padding: 12px 20px;
      border-bottom: solid 1px white;
      a {
        color: #777;
        cursor: pointer;
      }
      &.selected {
        display: flex;
        color: #ec6820;
        align-items: center;

        svg {
          min-width: 6px;
          max-width: 6px;
          margin-right: 8px;
        }
        
        a {
          display: inline-block;
          color: $kk-primary; 
        }
      }
    }

    &--collapsed {
      display: none;
    }
  }

  &--chapters-nav {
    .article-content-list {
      li {
        padding: 5px 20px 5px 12px;
        border-bottom: solid 1px white;
        border-left: solid 8px transparent;

        &.article-content-list--current {
          border-left-color: $kk-primary;
          font-weight: bold;
          a,
          span {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.article-content-list-container-wrapper {
  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: 2;
  top: 20px;
  &--sticky {
    top: 0;

    .article-content-list-title {
      color: $kk-primary;
    }
  }
}
