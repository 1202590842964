@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_videoFigure.scss';
@import '../../../assets/mixins.scss';

.container_auto_layout--collapsible {
    cursor: pointer;
}

.container_auto_layout {
    width: auto;
    height: 48px;
    background: #FEF6F2;
    margin-top: 40px;
    border-radius: 5px;
    padding: 10px 0;
    margin-bottom: 20px;

    @include screen-width-mobile {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    &.icon-up::after {
		content: "\25B2";
		font-size: 10px;
        float: right;
        margin-right: 20px;
        margin-top: 12px;
        cursor: pointer;
	}
	
	&.icon-down::after {
		content: "\25BC";
		font-size: 10px;
        float: right;
        margin-right: 20px;
        margin-top: 12px;
        cursor: pointer;
	}

    p {
        height: 28px;
        font-family: $hindMadurai-sb;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #333;
        margin: 0px 10px;
    }

    svg{
        float: right;
        margin: 10px;
    }
}