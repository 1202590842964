// Container restraints
// abover 1600, the container stays fixed and centered in the page
// NB: these need to be up to date with the values in appConfig javascript
$container-max-width: 1600px;
// below 375 the contain scrolls horizotally
$container-min-width: 375px;
$container-mobile-padding: 20px;
$container-large-padding: 3%;
$container-xl-padding: 5%;

// colors
$kk-primary: #EA5504;
$kk-secondary: #faf6f4;
$footer-grey: #5D6164;
$button-grey: #979797;
$button-hover-grey: #727272;
$button-disabled-grey: #DADADA;
$body-text-color: #333;
$background-grey:  #F6F6F5;
$kk-primary-light: #F9F4F2;

// fonts
$myriad-reg: "Myriad Pro Regular";
$myriad-sb: "Myriad Pro Semibold";
$myriad-light: "Myriad Pro Light";
$myriad-bold: "Myriad Pro Bold";
$hindMadurai-sb: "Hind Madurai SemiBold";
$hindMadurai-reg: "Hind Madurai Regular";

$body-font: $myriad-reg;
$body-font-size: 16px;
$title-font: $myriad-light;
$title-font-v2: $hindMadurai-sb;
$header-font: $myriad-reg;
$header-font-v2: $hindMadurai-sb;
$subtitle-font: $myriad-sb;
$section-title-font: $myriad-sb;
$call-to-action-font: $hindMadurai-sb;
$bold-font: $myriad-sb;

// box shadow for cards
// $card-box-shadow: 0px 2px 4px rgba(52, 58, 64, 0.25);  
$card-box-shadow: 0px 40px 18px -30px rgba(35, 50, 59, 0.2);

