@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

@mixin link-panel-with-intro() {
  display: flex;
  @include screen-width-mobile {
    flex-direction: column;
  }
  .title-description {
    width: 27%;
    padding-right: 20px;
    @include screen-width-mobile {
      width: 100%;
      padding-right: 0;
    }
    h2 {
      font-family: $subtitle-font;
      font-size: 30px;
      margin-top: 0;
      @include screen-width-small {
        font-size: 25px;
      }
      &:lang(no) {
        font-size: 25px;
      }
    }
    p {
      font-size: 20px;
      &:lang(no) {
        font-size: 18px;
      }
    }
  }
  .links {
    width: 73%;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 0 0 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    @include screen-width-mobile {
      width: 100%;
      padding: 0;
    }
    @include screen-width-small {
      flex-direction: column;
      flex-wrap: nowrap;
    }
    li {
      width: calc(50% - 15px);
      min-height: 160px;
      padding-bottom: 30px;
      @include screen-width-small {
        width: 100%;
        min-height: inherit;
      }
      button {
        background: none;
        border: none;
        padding: 0;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 20px;
        font-family: $subtitle-font;
        text-align: left;
        .chevron {
          margin-left: auto;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
