@import 'style/variables.scss';

.form-field-checkbox {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;

  input {
    display: block;
    width: 13px;
    height: 13px;
    margin: 0px 18px 0px 0px;
    background: $kk-secondary;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &.checkbox {
      flex-shrink: 0;
      &-checked {
        background-color: #333333;
      }
    }
  }
  .field-meta-data {
    position: relative;
    label {
      display: inline;
      font-family: $body-font;
      font-size: 12px;
      line-height: 14px;
      color: #333333;
      margin-bottom: 4px;
    };
    .form-inline-error-text {
      position: absolute;
      top: -16px;
    }
  }

  &--cookie-banner {
    margin-bottom: 0;

    input {
      margin: 0 10px 0 0;
    }
    .field-meta-data {
      label {
        display: flex;
      }
    }
  }
}






