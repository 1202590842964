@import 'style/mixins/_responsive.scss';
@import 'style/variables';

.app-layout--v1 {
    #webinarList{
    
        .container {
            // max-width: 1440px;
            @include screen-width-small{
                padding: 0px 10px;
            }
    
            @include screen-width-medium {
                padding: 0px 10px;
            }
            @media (min-width: 1560px) {
                max-width: 1440px;
                padding: 0 1em;
            }
    
            .title-section-inner {
                padding: 0 30px 60px 0;
    
                @include screen-width-small { 
                    padding: 0 30px 40px 0;
                }
                @media (max-width: #{$MIN_WIDTH_LARGE - 1px}) {
                    padding-left: 10px;
                }
    
                .description {
                    text-transform: uppercase;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 25px;
                    line-height: 29px;
                    padding-bottom: 0;
                    @media (max-width: #{$MIN_WIDTH_LARGE - 1px}) {
                        font-size: 24px;
                    }
                }
                .title-section-title {
                    font-family: $header-font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 60px;
                    line-height: 100%;
                    color: #EA5504;
    
                    @media (max-width: #{$MIN_WIDTH_LARGE - 1px}) {
                        font-family: $header-font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 40px;
                        line-height: 120%;
                    }
                }
            }
    
            .main-content-body {
                font-family: $myriad-reg;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 22px;
                margin-top: 40px;
                @include screen-width-small {
                    margin-top: 20px;
                }
                @media (max-width: #{$MIN_WIDTH_LARGE - 1px}) {
                    padding: 0 20px;
                }
            }
    
            .Related_Content_Section {
                @media (max-width: #{$MIN_WIDTH_LARGE - 1px}) {
                    padding: 20px;
                }
    
                .Related_Content {
                    padding: 20px;
                }
            }
        }
        .main-content{
            margin-right: 23px;
            margin:0px;
            padding: 0px;
            @media only screen and(min-width:1024px){
                padding: 0 15px;
            }
        }
        .text-card{
            border-radius:0px;
            box-shadow: none;
            margin-bottom: 20px;
            @include  screen-width-small {
                margin-bottom: 20px;
            }
            @media only screen and (min-width:1024px){
                // height:330px;
            }
           
        }
        .text-section{
            padding: 20px;
            @media only screen and (min-width:1024px){
                width:45%;
            }
            @include screen-width-mobile{
                order: 2;
            }
            
        }
        .card-section{
            justify-content: flex-start;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media only screen and(min-width:1024px){
                display :flex;
                flex-direction: row;
            }
        }
        .image-section {
            margin: 20px;
            @include screen-width-mobile{
                margin: 0;
                order: 1;
            }
            img{
                width:100%;
                max-width: 100%;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                max-width: none;
                object-fit: contain;
                @media only screen and(min-width:1024px){
                    height: 288px;
                }
            }
        }
        .title-feature{
            font-family:  'Myriad Pro Bold';
            font-weight :600;
            font-size:25px;
            line-height: 30px;
            padding: 20px 0px;
    
            @include screen-width-mobile{
                padding: 20px;
            }
        }
        
        .button-learn-more {
            color: #fff;
            background-color: $kk-primary;
            border: none;
            padding: 8px 10px;
            border-radius: 30px;
            display: block;
            padding: 8px 20px;
    
            &:hover {
                background-color: #EE7736;
            }
           
        }
        .sign-up-button{
            width: 70%;
            text-transform: uppercase;
        }
        .body-text{
            color:#333;
        }
       .webinar-time-section{
            display: flex;
            justify-content: space-between;
            @media (max-width: $MIN_WIDTH_LARGE) {
                justify-content: flex-start;
            }
       }
       .module-section-card-item{
           width:100%;
       }
       .cards-section-list{
           @media only screen and (min-width:1024px){
            display:flex;
            flex-wrap:wrap;
            width:100%;
            justify-content:space-between;
           }
       }
       #time-upcoming{
        .text-time{
            color:#333;
            padding-right: 10px;
        }
       }
       .module-section-card-container{
           @media only screen and (min-width:1024px){
            width:50%;
           }
          
       }
       .module-section-card{
           padding:0px;
           display:block;
       }
       .feature-body-text{
           margin-top:22px;
       }
     
    }
    
    #column-left{
        @media (max-width:$MIN_WIDTH_LARGE) {
            display: none;
        }
    }
}
.app-layout--v2 {
    #webinarList{
    
        .container {
            // max-width: 1440px;
            @include screen-width-small{
                padding: 0px 10px;
            }
    
            @include screen-width-medium {
                padding: 0px 10px;
            }
            @media (min-width: 1560px) {
                max-width: 1440px;
                padding: 0 1em;
            }
    
            .title-section-inner {
                padding: 0 30px 60px 0;
    
                @include screen-width-small { 
                    padding: 0 30px 40px 0;
                }
                @media (max-width: #{$MIN_WIDTH_LARGE - 1px}) {
                    padding-left: 10px;
                }
    
                .description {
                    text-transform: uppercase;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 25px;
                    line-height: 29px;
                    padding-bottom: 0;
                    @media (max-width: #{$MIN_WIDTH_LARGE - 1px}) {
                        font-size: 24px;
                    }
                }
                .title-section-title {
                    font-family: $header-font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 60px;
                    line-height: 100%;
                    color: #EA5504;
    
                    @media (max-width: #{$MIN_WIDTH_LARGE - 1px}) {
                        font-family: $header-font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 40px;
                        line-height: 120%;
                    }
                }
            }
    
            .main-content-body {
                font-family: $hindMadurai-reg;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 22px;
                margin-top: 40px;
                @include screen-width-small {
                    margin-top: 20px;
                }
                @media (max-width: #{$MIN_WIDTH_LARGE - 1px}) {
                    padding: 0 20px;
                }
            }
    
            .Related_Content_Section {
                .Related_Content {
                    padding: 20px 0;
                    @include  screen-width-small {
                        padding: 20px 0;
                    }
                }
                @media (max-width: #{$MIN_WIDTH_LARGE - 1px}) {
                    padding: 20px;
                }
                @include  screen-width-small {
                    padding: 20px 0;
                }
                
            }
        }
        .main-content{
            margin-right: 23px;
            margin:0px;
            padding: 0px;
            @media only screen and(min-width:1024px){
                padding: 0 15px;
            }
        }
        .text-card{
            border-radius:0px;
            box-shadow: none;
            margin-bottom: 20px;
            @include  screen-width-small {
                margin-bottom: 20px;
            }
            @media only screen and (min-width:1024px){
                // height:330px;
            }
           
        }
        .text-section{
            padding: 20px;
            @media only screen and (min-width:1024px){
                width:45%;
            }
            @include screen-width-mobile{
                order: 2;
            }
            
        }
        .card-section{
            box-shadow: 0px 2px 4px rgba(52, 58, 64, 0.25);
            border-radius: 5px;
            justify-content: flex-start;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media only screen and(min-width:1024px){
                display :flex;
                flex-direction: row;
            }
        }
        .image-section {
            margin: 20px;
            @include screen-width-mobile{
                margin: 0;
                order: 1;
            }
            img{
                width:100%;
                max-width: 100%;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                max-width: none;
                object-fit: contain;
                @media only screen and(min-width:1024px){
                    height: 288px;
                }
            }
        }
        .title-feature{
            // font-family:  'Myriad Pro Bold';
            font-family: $hindMadurai-sb;
            font-weight :600;
            font-size:25px;
            line-height: 30px;
            padding: 20px 0px;
    
            @include screen-width-mobile{
                padding: 20px;
            }
        }
        
        .button-learn-more {
            color: #fff;
            background-color: $kk-primary;
            border: none;
            padding: 8px 10px;
            border-radius: 30px;
            display: block;
            padding: 8px 20px;
    
            &:hover {
                background-color: #EE7736;
            }
           
        }
        .sign-up-button{
            width: 70%;
            text-transform: uppercase;
        }
        .body-text{
            color:#333;
        }
       .webinar-time-section{
            display: flex;
            justify-content: space-between;
            @media (max-width: $MIN_WIDTH_LARGE) {
                justify-content: flex-start;
            }
       }
       .module-section-card-item{
           width:100%;
    
           .module-section-card {
                @media (max-width: $MIN_WIDTH_LARGE) {
                    max-width: 500px;
                    margin-left: auto;
                    margin-right: auto;
                }
           }
       }
       .cards-section-list{
           @media only screen and (min-width:1024px){
            display:flex;
            flex-wrap:wrap;
            width:100%;
            justify-content:space-between;
           }
       }
       #time-upcoming{
        .text-time{
            color:#333;
            padding-right: 10px;
        }
       }
       .module-section-card-container{
           @media only screen and (min-width:1024px){
            width:50%;
           }
          
       }
       .module-section-card{
           padding:0px;
           display:block;
       }
       .feature-body-text{
           margin-top:22px;
       }
     
    }
    
    #column-left{
        @media (max-width:$MIN_WIDTH_LARGE) {
            display: none;
        }
    }
}
