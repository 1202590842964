@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

#eapc{
    .footer-banner-container {
        padding-bottom: 40px;
        &.with-background {
            padding-top: 30px;
            padding-bottom: 50px;

        }
    }

    .article-section-component {
        // margin-top: 40px;

        .eapc-image {
            border-radius: 30px;
        }

        .RichText {
            h4 {
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 29px;
                letter-spacing: 0px;
                text-align: left;
                margin-bottom: 20px;
            }

            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 19px;
                letter-spacing: 0px;
                text-align: left;
                margin-bottom: 20px;
                margin-top: 0;
            }
        }
    }

    .eapc-main-title-section {
        &.no-headline {
            .title-section-inner {
                padding-top: 86px;
                @include screen-width-small {
                    padding-top: 46px;
                }
            }
        }
        .main-title-section {
            height: auto;
        }
    }
    .container {
        .form-component {
            .input-section {
                input {
                    background-color:  #FAF6F4;
                }
                select {
                    background-color:  #FAF6F4;
                }
            }
        }
    }
    .form-submission-error {
        .RichText {
            p {
                color: #FF0000;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
            }
        }
    }
    .richtext-description {

        h2 {
            margin-bottom: 20px;
            font-family: $hindMadurai-reg;
            font-style: normal;
            font-weight: 600;
            font-size: 29px;
            line-height: 30px;

            @include screen-width-small {
                font-size: 24px;
                line-height: 24px;
            }
        }
        h3 {
            margin-bottom: 20px;
            font-family: $hindMadurai-reg;
            font-style: normal;
            font-weight: 600;
            font-size: 27px;
            line-height: 30px;

            @include screen-width-small {
                font-size: 22px;
                line-height: 24px;
            }
        }
        h4 {
            margin-bottom: 20px;
            font-family: $hindMadurai-reg;
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;

            @include screen-width-small {
                font-size: 20px;
                line-height: 24px;
            }
        }
        h5 {
            margin-bottom: 20px;
            font-family: $hindMadurai-reg;
            font-style: normal;
            font-weight: 600;
            font-size: 23px;
            line-height: 30px;

            @include screen-width-small {
                font-size: 18px;
                line-height: 24px;
            }
        }
        h6 {
            margin-bottom: 20px;
            font-family: $hindMadurai-reg;
            font-style: normal;
            font-weight: 600;
            font-size: 21px;
            line-height: 30px;

            @include screen-width-small {
                font-size: 16px;
                line-height: 24px;
            }
        }

        p {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0px;
            text-align: left;
        }
    }
    .eapc-logo {
        max-width: 1440px;
        margin: auto;
        margin-bottom: 24px;
        padding: 0 16px;
        margin-top: 15px;
    }
    .full-width-banner {
        display: block;
        margin: 0px;
        height: 514px;
    }
    .full-width-banner__image {
        display: block;
        max-width: 100%;
        width: 100%;
        height: 514px;
    }
    .main-title-section .title-section-inner {
        padding-top: 37px;
        padding-bottom: 66px;
        .title {
            .description {
                p {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 24px;
                    line-height: 29px;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                    @include screen-width-small {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 24px;
                        line-height: 29px;
                    }
                }
            }

            .title-section-title {
                // font-family: $myriad-light;
                font-family: $hindMadurai-sb;
                font-style: normal;
                font-weight: 300;
                font-size: 60px;
                line-height: 120%;
                @include screen-width-small {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 40px;
                    line-height: 120%;
                }
                @include screen-width-medium {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 45px;
                    line-height: 120%;
                }
            }
        }
    }
    .container_inner {
        display:block;
        margin-bottom: 50px;
        @include screen-width-small {
            margin-bottom: 0;
        }
        .content_item {
            max-width: 744px;
            margin: auto;
            @include screen-width-small {
                max-width: 744px;
                margin: auto;
            }
            @include screen-width-large {
                max-width: 744px;
                margin: auto;
            }
            @include screen-width-xl  {
                max-width: 900px;
                margin: auto;
            }

            .eapc-content {
                max-width: 744px;
                margin: auto;
                padding: 0 20px;

                @include screen-width-xl  {
                    max-width: 900px;
                }
            }
        }
    }
    .eapc-button{
        color: #fff;
        padding: 12px 20px;
        border-radius: 60px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        font-size: 16px;
        margin-bottom: 20px;
    }
    .form-component-wrapped {
        background-color: #ffffff;
        border-radius: 0px;
        box-shadow: none;
        padding:0px;
        width: 100%;
        margin-bottom: 0;
        
        .form-field {
            width: 78%;
            @include screen-width-small {
                width: 100%;
            }
        }

        .form-field-button {
            width: 262px;
            margin-bottom: 20px;

            @include screen-width-small {
                margin-bottom: 30px;
            }

            button {
                p {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 19px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                }
            }
        }
    }
    .formName{
        margin-bottom:25px;
        font-family: $hindMadurai-sb;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 1px;
        text-align: left;
        text-transform: capitalize;
    }

    .form-labels {
        .mandatory{
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0px;
            text-align: left;
            color: #EA5504;
            text-transform: capitalize;
        }

        .form-bottom-label {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0px;
            text-align: left;
            margin-bottom: 40px;

        }
    }

}

.eapc-speakers {
    width: 100%;
    padding: 20px 0 0 20px;
    max-width: 784px;
    margin-bottom: 40px;
    margin-right: auto;
    margin-left: auto;

    @include screen-width-small {
        margin-bottom: 30px;
    }

    @include screen-width-xl  {
        max-width: 940px;
    }

    .speakers-title {
        h2 {
            font-size: 25px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: 0px;
            text-align: left;
            margin-top: 0;
            margin-bottom: 20px;

            @include screen-width-small {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
            }
        }
    }

    .eapc-speakers-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    
    
}