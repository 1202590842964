@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.module-subcategory-filter {
  list-style-type: none;
  margin: 0 0 20px;
  padding-left: 1em;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  & .subcategory-item{
    padding: 0px 40px 0px 0px;
    @include screen-width-small {
      padding: 0px 16px 0px 0px;
    }

    & .subcategory-button {
      background: none;
      border: none;
      color: $body-text-color;
      cursor: pointer;
      padding: 0;
    }

    & .selected {
      color: $kk-primary;
      border-bottom: 3px solid $kk-primary;
    }

    & .subcategory-button:hover {
      text-decoration: none;
    }

    select {
      border: none;
      font-size: 16px;
      border: 0 !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url("../../assets/images/select-dropdown-arrow.svg") no-repeat;
      background-size: 12px;
      background-position: right center;
      background-repeat: no-repeat;
       &:focus-visible {
         border: none;
         outline: none;
       }
    }

  }
  .MuiPaginationItem-root{
    color: #ea5504;
  }
}
 
