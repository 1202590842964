@import 'style/mixins/_responsive.scss';
.timepopups {
    position: absolute;
    max-width: 548px;
    width: 100%;
    margin: 15px;
    padding: 15px 15px 20px 30px;
    border: 2px solid #3D3F5B;
    border-radius: 20px;
    box-shadow: 2px 1px 8px #888888;
    background-color: #FFFFFF;
    z-index: 999;
    opacity: 0;
    // visibility: hidden;  //disabled for AOS

    @media only screen and (max-width: 320px) {  
        width: 300px !important;
    }

    @media only screen and (max-width: 640px) {  
        width: 360px;
    }

    .timepopups__container {
        position: relative;
    }

    .timepopups__scroll {
        position: relative;
        width: 94%;
        height: 10px;
        background-color: #D8D9DE;
        border-radius: 10px;

        /*&::after {
            content: '';
            display: block;
            width: 50%;
            height: 10px;
            background-color: #3D3F5B;
            border-radius: 10px;
        }*/

        @media only screen and (max-width: 320px) { 
            width: 88% !important;   
        }

        @media only screen and (max-width: 640px) { 
            width: 92%;
        }

        .timepopups__scroll-bar {
            display: block;
            width: 0;
            height: 10px;
            background-color: #3D3F5B;
            border-radius: 10px;
            // transition: 0.2s;
           // transition: width 5s ease;
        }

        
    }

    .timepopups__close {
        position: absolute;
        top: -13px;
        right: -10px;
        cursor: pointer;

        img {
            width: 36px !important;
            height: 36px !important;
        }
    }

    .timepopups__content-cta {
        text-align: center;
    }

    .btn-popups {
        display: inline-block;
        padding: 8px 15px;
        border: 2px solid #3D3F5B;
        border-radius: 20px;
        background-color: #FFFFFF;
        font-size: 16px;
        color: #3D3F5B !important;
        cursor: pointer;
        transition: opacity 0.5s ease-in-out;
        opacity: 1;

        &:hover {
            opacity: 0.6;
        }

        p {
            margin: 0;
            padding: 0 !important;
        }
    }

    p {
        padding: 20px 0 !important;
        color: #3D3F5B !important;
        line-height: 20px !important;
        text-align: left !important;
    }

    ul {
        padding: 20px 0 0 4%;

        li {
            padding-bottom: 20px;
            text-align: left;

            &:last-of-type {
                padding-bottom: 0; 
            }
        }
    }

    &.large {
        max-width: 580px;
        width: 100%;

        @media only screen and (max-width: 320px) {  
            max-width: 300px !important;
        }
    
        @media only screen and (max-width: 640px) {  
            max-width: 360px;
        }
    }

    &.small {
        max-width: 480px;
        width: 100%;

        @media only screen and (max-width: 640px) {  
            max-width: 360px;
        }
    }

    &.xtra-small {
        max-width: 380px; 
        width: 100%;

        @media only screen and (max-width: 640px) {  
            max-width: 360px;
        }

        .timepopups__scroll {
            max-width: 90%;
        }
    }

    &.btn-left {

        .timepopups__content-cta {
            text-align: left;
        }
    }

    &.btn-right {

        .timepopups__content-cta {
            padding-right: 5%;
            text-align: right;
            
            @media only screen and (max-width: 640px) { 
                padding-right: 0;
                text-align: center;
            }
        }
    }

    &.inactive {
        display: none !important;
    }

    &.close {
        display: none !important;
    }

    &.arrow-up {

        &::after {
            content: '';
            display: block;
            width: 85px;
            height: 34px;
            background: url('../../../../../assets/images/infographics-assets/arrow-down.png') no-repeat;
            position: absolute;
            top: -34px;
            right: 134px;
            transform: rotate(180deg);

            /*content: "";
            position: absolute;
            width: 0;
            height: 0;
            top: 0;
            right: 106px;
            box-sizing: border-box;
            border: 20px solid #3D3F5B;
            border-color: transparent transparent #FFFFFF #FFFFFF;
            transform-origin: 0 0;
            transform: rotate(-225deg);
            box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.2);*/
        } 
    }

    &.arrow-down {

        &::before {
            content: '';
            display: block;
            width: 85px;
            height: 34px;
            background: url('../../../../../assets/images/infographics-assets/arrow-down.png') no-repeat;
            position: absolute;
            bottom: -34px;
            right: 0;
        }
    }

    &.fade {
        transform: translateX(0);
        visibility:visible;
        opacity: 1.0;
        transition: all 1500ms;
    }

    &.progress-fill {

        .timepopups__scroll-bar {
            width: 100%;
            transition: width 5s ease;  //5 second delay
        }
       
    }
}

.timepopups-accordion-wrap {
    position: relative;

    .timepopups {
        right: -15px;
        top: -230px;

        @media only screen and (max-width: 640px) { 
            right: -28px;
        }
    }
   
}

.timepopups-infographic-wrap {
    position: relative;
    @include screen-width-mobile {
        z-index: 10;
    }

    .timepopups {
        left: 0;
        right: 0;
        margin: 16vh auto 0 auto;
        @include screen-width-mobile {
            margin: 0 auto;
        }
    }
}