@import 'style/mixins/_responsive.scss';


.generic-message-page {
  .generic-message-content {
    @include spans-columns(6);
    @include spans-columns-small(4);
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}