@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_titleWithIcon.scss';
@import '../../../assets/mixins.scss';

.app-layout--v1 {
    .Card {

        // #region Card Block
        background-color: #ffffff;
        border-radius: 30px;
        display: flex;
        align-items: center;
        text-decoration: none;
        width: 100%;
        box-shadow: $card-box-shadow;
        color: $body-text-color;
        
        &:visited {
            color: $body-text-color;
        }
        // #endregion
    
        // #region Card Modifiers
        &--alternate-bg {
            background-color: $kk-secondary;
        }
    
        &--remove-shadow {
            box-shadow: none;
        }
    
        &--remove-border-radius {
            // border-radius: 0px;
        }
        // #endregion
    
        // #region Card Elements
    
        // #endregion
    
    }
}
.app-layout--v2 {
    .Card {
    
        // #region Card Block
        background-color: #ffffff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        text-decoration: none;
        width: 100%;
        // box-shadow: $card-box-shadow;
        box-shadow: 0px 2px 4px rgba(52, 58, 64, 0.25);
        color: $body-text-color;
        &:hover {
            box-shadow: -5px 5px 0px 0px $kk-primary, 0px 2px 4px rgba(52, 58, 64, 0.25);
        }
        
        &:visited {
            color: $body-text-color;
        }
        // #endregion
    
        // #region Card Modifiers
        &--alternate-bg {
            background-color: #ffffff;
        }
    
        &--remove-shadow {
            box-shadow: none;
        }
    
        &--remove-border-radius {
            border-radius: 0px;
        }
        // #endregion
    
        // #region Card Elements
    
        // #endregion
    
    }
}