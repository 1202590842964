@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_titleWithIcon.scss';
@import '../../assets/mixins.scss';

.app-layout--v1 {
    .Related_Content {

        &_Section {
            position: relative;
            width: 100%;
            background-color: $kk-secondary;
    
            .Related_Content {
                background-color: inherit;
            }
    
            &--fluid {
                .Related_Content {
                    &::after {
                        content: '';
                        position: absolute;
                        width: 9999px;
                        height: 100%;
                        background-color: inherit;
                        top: 0;
                        left: -4444px;
                        z-index: -1;
                    }
                }
            }
    
            @include tabletOnly {
                padding-top: 40px;
                padding-right: 40px;
                padding-bottom: 40px;
                padding-left: 40px;
            }
    
            @include desktopOnly {
                padding-top: 40px;
                padding-right: 0px;
                padding-bottom: 40px;
                padding-left: 60px;
    
                &--fluid {
                    padding-left: 0px;
                    padding-right: 0px;
    
                    &-right {
                        padding-left: 80px;
                        padding-right: 20px;
    
                        .Related_Content {
                            &::after {
                                left: 0px;
                            }
                        }
                    }
                }
    
                &--remove-top-padding {
                    padding-top: 0px;
                }
    
                &--remove-left-padding {
                    padding-left: 0px;
                }
    
                &--remove-bottom-padding {
                    padding-bottom: 0px;
                }
    
                &--remove-right-padding {
                    padding-right: 0px;
                }
            }
        }
    
        &_Heading {
            width: 100%;
            padding: 0;
            display: flex;
            justify-content: space-between;
        }
    
        &_Title {
            font-size: 25px;
            font-family: $subtitle-font;
            margin-top: 0px;
        }
    
        &_ViewAll {
            &:hover {
                text-decoration: underline;
            }
            a {
                color: $kk-primary;
                font-family: $myriad-reg;
                justify-content: space-between;
                font-size: 16px;
                text-decoration: none;
                text-transform: uppercase;
                font-family: $subtitle-font;
                line-height: 34px;
            }
    
            &--desktop {
                display: none;
            }
    
            &--mobile {
                display: block;
                margin-top: 40px;
                text-align: center;
            }
    
            @include desktopOnly {
                &--desktop {
                    display: block;
                }
    
                &--mobile {
                    display: none;
                }
            }
        }
    
        &_Chevron {
            margin-left: 20px;
        }
    
        .slick-dots {
            width: 100%;
        }
        
        .slick-arrow {
            position: absolute;
            width: 60px;
            height: 60px;
            border: none;
            background-color: #979797;
            border-radius: 50px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                color: #fff;
            }
        }

        .slick-arrow:hover {
            background-color: #727272;
        }
    
        .slick-next {
            left: auto;
            right: -70px;
        }
    
        .slick-prev {
            left: -70px;
        }
    
        .slick-disabled {
            opacity: .6;
        }
    }
}
.app-layout--v2 {
    .Related_Content {
    
        &_Section {
            position: relative;
            width: 100%;
            background-color: #fff;
            
            .Related_Content {
                background-color: inherit;
            }
    
            &--fluid {
                .Related_Content {
                    &::after {
                        content: '';
                        position: absolute;
                        width: 9999px;
                        height: 100%;
                        background-color: inherit;
                        top: 0;
                        left: -4444px;
                        z-index: -1;
                    }
                }
            }
    
            @include tabletOnly {
                padding-top: 40px;
                padding-right: 40px;
                padding-bottom: 40px;
                padding-left: 40px;
            }
    
            @include desktopOnly {
                padding-top: 40px;
                padding-right: 0px;
                padding-bottom: 40px;
                padding-left: 60px;
    
                &--fluid {
                    padding-left: 0px;
                    padding-right: 0px;
    
                    &-right {
                        padding-left: 80px;
                        padding-right: 20px;
    
                        .Related_Content {
                            &::after {
                                left: 0px;
                            }
                        }
                    }
                }
    
                &--remove-top-padding {
                    padding-top: 0px;
                }
    
                &--remove-left-padding {
                    padding-left: 0px;
                }
    
                &--remove-bottom-padding {
                    padding-bottom: 0px;
                }
    
                &--remove-right-padding {
                    padding-right: 0px;
                }
            }
        }
    
        &_Heading {
            width: 100%;
            padding: 0;
            display: flex;
            justify-content: space-between;
            padding: 0 20px;
        }
    
        &_Title {
            font-size: 25px;
            font-family: $subtitle-font;
            margin-top: 0px;
        }
    
        &_ViewAll {
            &:hover {
                text-decoration: underline;
            }
            a {
                color: $kk-primary;
                font-family: $hindMadurai-reg;
                justify-content: space-between;
                font-size: 16px;
                text-decoration: none;
                text-transform: uppercase;
                font-family: $subtitle-font;
                line-height: 34px;
            }
    
            &--desktop {
                display: none;
            }
    
            &--mobile {
                display: block;
                margin-top: 40px;
                text-align: center;
            }
    
            @include desktopOnly {
                &--desktop {
                    display: block;
                }
    
                &--mobile {
                    display: none;
                }
            }
        }
    
        &_Chevron {
            margin-left: 20px;
    
            svg {
                width: 10px;
                height: 16px;
            }
        }
    
        .slick-dots {
            width: 100%;
        }
        
        .slick-arrow {
            position: absolute;
        }
    
        .slick-next {
            left: auto;
            right: -60px;
        }
    
        .slick-prev {
            left: -60px;
        }
    
        .slick-disabled {
            opacity: .6;
        }
    }
}