@import 'style/variables.scss';


// This needs to hug the component above it, so it needs to escape the component above's bottom margin of 30px whilst also
// keeping the 30px gap between the component above and the component below.
$line-height: 14px;
$standard-margin-bottom: 30px;
$margin-top: 10px;
// Should be a negative number to make it closer to the component above
$applied-margin-top: -$standard-margin-bottom + $margin-top - 2px;
// takes into consideration it's own height to keep the 30px gap
$applied-margin-bottom: $standard-margin-bottom + $applied-margin-top;


.form-redesign {
  a {
    font-family: 'Myriad Pro Regular' !important;
    font-size:12px !important;

  }
}
.form-field-link {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: $applied-margin-top;
  margin-bottom: $applied-margin-bottom;
  a {
    font-family: $hindMadurai-sb;
    font-size: 16px;
    line-height: $line-height;
    text-align: right;
    color: #EA5504;
    text-decoration: none;
  }
}






