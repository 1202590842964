/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Myriad Pro Regular";
  font-display: fallback;
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Regular"), url("MYRIADPRO-REGULAR.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Condensed";
  font-display: fallback;
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Condensed"), url("MYRIADPRO-COND.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Condensed Italic";
  font-display: fallback;
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Condensed Italic"),
    url("MYRIADPRO-CONDIT.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Light";
  font-display: fallback;
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Light"), url("MyriadPro-Light.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Semibold";
  font-display: fallback;
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Semibold"),
    url("MYRIADPRO-SEMIBOLD.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Semibold Italic";
  font-display: fallback;
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Semibold Italic"),
    url("MYRIADPRO-SEMIBOLDIT.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold Condensed";
  font-display: fallback;
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Bold Condensed"),
    url("MYRIADPRO-BOLDCOND.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold";
  font-display: fallback;
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Bold"), url("MYRIADPRO-BOLD.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold Italic";
  font-display: fallback;
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Bold Italic"),
    url("MYRIADPRO-BOLDIT.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold Condensed Italic";
  font-display: fallback;
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Bold Condensed Italic"),
    url("MYRIADPRO-BOLDCONDIT.woff") format("woff");
}

@font-face {
  font-family: "Prata-Regular";
  font-display: fallback;
  font-style: normal;
  font-weight: normal;
  src: local("Prata-Regular"),
    url("Prata-Regular.woff") format("woff");
}
@font-face {
  font-family: "Hind Madurai Regular";
  font-display: fallback;
  font-style: normal;
  font-weight: normal;
  src: local("Hind Madurai Regular"),
    url("HindMadurai-Regular.woff") format("woff");
}

@font-face {
  font-family: "Hind Madurai SemiBold";
  font-display: fallback;
  font-style: normal;
  font-weight: normal;
  src: local("Hind Madurai SemiBold"),
    url("HindMadurai-SemiBold.woff") format("woff");
}


@font-face {
  font-family: "Hind Madurai Regular";
  font-display: fallback;
  font-style: normal;
  font-weight: normal;
  src: local("Hind Madurai Regular"),
    url("HindMadurai-Regular.woff") format("woff");
}

@font-face {
  font-family: "Hind Madurai SemiBold";
  font-display: fallback;
  font-style: normal;
  font-weight: normal;
  src: local("Hind Madurai SemiBold"),
    url("HindMadurai-SemiBold.woff") format("woff");
}

h1 {
  font-family: "Myriad Pro Regular";
}

strong {
  font-family: "Myriad Pro Bold";
}
