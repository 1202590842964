@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import '../../assets/mixins.scss';

.main-page {
  .main-title-section {
    .title-section-inner{
      width: 100%;
      
      .title {
        margin: 0 0 10px;
      }
    }
  }


  .category-row-right {
    padding: 40px 0 0px;
  
    @include desktopOnly { 
      display: flex;
    }

    .list-content-page-card-wrapper {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 30px;

      @include desktopOnly { 
        width: 33.3%;
      }

    }
  }


  &-holder {
    @include desktopOnly {
      display: flex;
      flex-direction: row;
      max-width: 1600px;
      margin: 0 auto;

    }
  }

  &-subnavsection {
    display: none;

    @include desktopOnly {
      display: block;
    }

    .content-page-promo-subnav {
      width: 85%;
      
      @include desktopOnly {
        padding: 40px 5px 40px 40px;
      }

      @include desktopOnlyLarge {
        padding: 40px 0 40px 85px;
      }

      &-link {
        &-level2 {
          padding-left: 35px;
          font-weight: normal;
        }

        &--active {
          color: #EA570D;
        }

      }

    }


  }

  &-content {
    flex: 1;

    @include desktopOnly {
      margin-left: 40px;
    }
  }

}