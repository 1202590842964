.withdraw-consent-component--whole-text {
  margin-bottom: 30px;
  
  .RichText {
    p {
      margin-top: 0;
    }
    a {
      color: #333333;
    }
  }
}
