@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import 'style/mixins/_titleWithIcon.scss';
@import '../../assets/mixins.scss';
@import '../../assets/slick.scss'; // Base
@import '../../assets/slick-theme.scss'; // Theme

$standard-margin-bottom: 16px;
$card-padding: 30px;

* {
    min-height: 0;
    min-width: 0;
}

.Carousel {
    * {
        &:focus {
            outline: none;
        }
    }

    position: relative;
    display: block;
    justify-content: center;
    width: 100%;

    &_Container {
        width: 100%;
    }

    /** Carousel Themes **/
    &--theme {
        &-primary {
            .slick {
                &-active {
                    button {
                        border: 1px solid $kk-primary !important;

                        &::before {
                            background-color: $kk-primary !important;
                        }
                    }
                }
            }
        }

        &--secondary {
            .slick {
                &-active {
                    button {
                        border: 1px solid $button-grey !important;

                        &::before {
                            background-color: $button-grey !important;
                        }
                    }
                }
            }
        }
    }

    /** Carousel Types **/
    &--type {

        &-contents,
        &-cards, &-title-cards {
            .slick {
                &-list {
                    height: 100% !important;
                    padding-bottom: 40px;
                }
            }
        }
    }

    /** Carousel Items **/
    &_Item {
        &--gap {
            &-5 {
                padding: calc(5px/2);
            }

            &-10 {
                padding: calc(10px/2);
            }

            &-15 {
                padding: calc(15px/2);
            }

            &-20 {
                padding: calc(20px/2);
            }

            &-40 {
                padding: calc(40px/2);
            }
        }
    }

    &_Slide {
        &_Image {
            img {
                width: 100%;
                border-radius: 30px;
                box-shadow: $card-box-shadow;
            }
        }
    }

    .slick {
        &-slider {
            display: flex;
            align-items: center;
            width: 100%;

            @include desktopOnly {
                padding: 0;
            }
        }

        &-list {
            width: 100%;
        }

        &-arrow {
            width: 60px;
            height: 60px;
            border: none;
            // background-color: #979797;
            // border-radius: 50px;
            padding: 5px 0 0;
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            margin: 0 15px 0 15px;
            position: relative;
            transform: none;
            top: unset;
            left: 0;
            right: 0;
    
            svg {
                color: #EA5504;
                width: 22px;
                height: 30px;
            }
        }

        &-track {
            margin: 0;
        }

        &-dots {
           margin-bottom: 20px;
        }
    }
}