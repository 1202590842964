@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.form-component {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
  &-form-redesign {
    border-radius: 0px !important;
  }

  &-wrapped {
    background-color: #ffffff;
    padding: 15px 30px 0px 30px;
    border-radius: 0px 0px 30px 30px;
    box-shadow: 0px 15px 18px rgba(35, 50, 59, 0.2);
  }
  &-mb-0 {
    margin-bottom: 0px;
  }
  .mandatory-astrix {
    color: red;
  }
  .form-field-wrapper {
    width: 100%;
    &--50 {
      width: calc(50% - 15px);
      @include screen-width-mobile {
        width: 100%;
      }
    }
    &--hidden {
      display: none;
    }
  }
  // .form-field {
  //   width: 100%;
  //   &--50 {
  //     width: calc(50% - 15px);
  //     @include screen-width-mobile {
  //       width: 100%;
  //     }
  //   }
  // }
}







