@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';


@mixin orange-button-with-hover() {
  background-color: #EA5504;
  color: #ffffff;
  &:hover {
    background-color: #EE7736;
  }
};


@mixin white-button-with-hover() {
  color: $button-grey;
  background-color: #ffffff;
  border: solid 1px $button-grey;
  &:hover {
    background-color: #F3F2F1;
  }
};