@import 'assets/kanso/styles/global.scss';

.form-radio {
  font-style: normal;
  // color: $light-black;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  // background: $off-white;
  box-shadow: 0px 7.48582px 30px rgba(50, 50, 50, 0.2);
  border-radius: 8px;
  padding: 24px;
  text-align: center;
}

.input--hidden {
  display: none;
}

.form-radio__button {
  border-radius: 50%;
  outline: 2px solid #c51d55;
  width: 28px;
  height: 28px;
  box-sizing: border-box;
  padding: 4px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 2px 2px 23px auto;

  &:after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    background: #c51d55;
    border-radius: 50%;
    transform: scale(0);
    transition: transform(0.15ms);
  }
}

[dir="rtl"] .form-radio__button {
  margin: 2px auto 23px 2px;

}

.form-radio__input:checked
  + .form-radio__button::after {
  transform: scale(1);
}

.form-radio__texts__title {
  margin-top: 19px;
  margin-bottom: 0;
}

.form-radio__texts__title,
.form-radio__text {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
}

.form-radio__texts__assistive {
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  margin-top: 8px;
}

.form-radio--selected {
  outline: 2px solid #c51d55;
}

.form-radio--error {
  // outline: 2px solid $input-error;

  .form-radio__button {
    // outline: 2px solid $input-error;
  }
}

.form-radio--disabled {
  pointer-events: none;
  opacity: 0.6;
  // background: $off-white;

  .form-radio__button { 
    // outline: 2px solid $grey;
  }
}