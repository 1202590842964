@import 'style/variables.scss';

.checkbox-group {
  width: 100%;
  margin-bottom: 15px;

  .group-field-meta-data {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }

  .checkboxes-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    .form-field {
      width: fit-content;
      display: flex;
      align-items: center;
      margin-right: 30px;
      margin-bottom: 15px;
    }
  }
}