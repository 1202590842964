@import 'style/mixins/_responsive.scss';
@import 'style/variables';

.webinarDetailPage {
  // overflow-x: visible !important; 

  .content_item--form {
    margin-top: 0;
    padding-left: 25px;
    max-width: 583px;

    @include screen-width-small {
      padding: 0;
    }

    @include screen-width-medium {
      padding: 0;
    }
  }

  .brand_logo_section {
    img {
      width: 153px;
    }
  }
  .content-separator {
    height: 25px;
  }

  .webinar-cards-container--callout {
    @include screen-width-small {
      padding: 0 3%;
    }
    @include screen-width-medium {
      padding: 0 3%;
    }
  }

  .content_wrapper--columns-2-left-webinar {
    width: 20%;
  }

  .content_wrapper--columns-2-right-webinar {
    width: 20%;
  }

  .content_wrapper--columns-1-center-webinar {
    width: 60%;

    @include screen-width-small {
      width: 100%;
    }
    @include screen-width-medium {
      width: 100%;
    }
  }

  .container_inner-data-capture {
    .content_wrapper--columns-2-left-webinar {
      width: 15%;
    }
  
    .content_wrapper--columns-2-right-webinar {
      width: 40%;
      padding-left: 15px;

      @include screen-width-small {
        padding-left: 0;
        width: 100%;
      }
      @include screen-width-medium {
        padding-left: 0;
        width: 100%;
      }
    }
  
    .content_wrapper--columns-1-center-webinar {
      width: 45%;
  
      @include screen-width-small {
        width: 100%;
      }
      @include screen-width-medium {
        width: 100%;
      }
    }

    .keynote-speakers__list {
      @media (max-width: 1503px) {
        grid-template-columns: 300px;
      }
    }
  }

  .webinar-title-section-container {
    .title {
      width: 100%;

      h1 {
        font-style: normal;
        font-weight: normal;
        font-size: 60px;
        line-height: 100%;
        font-family: $myriad-reg;

        @media (max-width: $MIN_WIDTH_MEDIUM) {
          font-style: normal;
          font-weight: normal;
          font-size: 40px;
          line-height: 120%;
        }

        @include screen-width-medium {
          font-style: normal;
          font-weight: normal;
          font-size: 40px;
          line-height: 120%;
        }
      }
    }

    .container {
      @include screen-width-large {
        padding: 0 5%;
      }
    }
  }

  .content_item {
    .container {
      padding: 0 0 0 25px;
    }
  }

  .webinar-title-section-container
    .main-title-section
    .title-section-inner
    .title {
      width: 100%;
      .title-section-title {
      margin-bottom: 50px;
    }

    @include screen-width-small {
      margin-bottom: 0;
    }
  }
  .webinar-tags-container {
    padding-left: 25px;
    @include screen-width-small {
      padding: 0;
    }
    @include screen-width-medium {
      padding: 0;
    }
  }

  .calender-dropdown {
    display: none;
  }

  .calender-dropdown--show {
    display: block;
    width: 204px;
    @include screen-width-small {
      width: auto;
    }
  }

  .webinar-calendar {
    width: 204px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    background-color: white;
    
    @include screen-width-small {
      margin-top: 10px;
      width: auto;
      margin-left: -11px;
    }

    .add-calendar-text {
      text-transform: uppercase;
    }

    h1 {
      text-align: center;
      font-size: 1.5em;
    }

    .btn-add-calender {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 14px 0;
      @include screen-width-small {
        padding: 14px 11px;
      }
    }

    .calender-link {
      text-decoration: none;
      color: #ea570d;
      font-size: 16px;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      &:hover {
        text-decoration: underline;
      }

      .calender-link__icon {
        margin-left: 8px;
      }

      p {
        margin: 0 0 0 10px;
      }
    }

    span {
      font-size: 1.2em;
      cursor: pointer;
      display: block;
      color: #ea570d;
    }

    .calendar-icon {
      width: 32px;
      display: inline-flex;
      margin-right: 8px;
      margin-left: 11px;
      @include screen-width-small {
        margin-left: 0;
      }

      
    }

    .slide {
      width: 100%;
      opacity: 0;
      transition: height 0.4s ease;
      padding: 0;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-bottom: 1px solid #ea5504;
        width: 100%;
      }

      li {
        list-style: none;
        display: flex;
        align-items: center;
        height: 48px;

        &:hover {
          background-color: #faf6f4;
        }
      }
    }

    .show-calender {
      opacity: 1;
      background-color: white;
      z-index: 2;
      margin: 0;
      position: absolute;
      width: 204px;
      filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));

      @include screen-width-small {
        width: 183px;
      }
    }
  }
  .calendar-shadow {
    box-shadow: 0px 4px 10px 0px #0000001a;
    z-index: 1;
  }
  .webinar-tags {
    padding-bottom: 50px;
    &__list {
      display: flex;
      flex-direction: row;
      position: relative;

      @include screen-width-small {
        flex-wrap: wrap;
      }

      &__detail {
        display: flex;
        flex-direction: row;
        margin-right: 16px;
        align-items: center;
        
        @include screen-width-small {
          margin-top: 10px;
          margin-right: 0;
          width: 50%;
        }

        &.desktop {
          @include screen-width-small {
            display: none;
          }
        }

        &__icon {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &__text {
          margin: auto;
          @include screen-width-small {
            margin-left: 0;
          }
        }
      }
    }
  }

  .keynote-speakers {
    padding-left: 25px;
    @include screen-width-small {
      padding: 0;
    }
    @include screen-width-medium {
      padding: 0;
    }

    &__label {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0px;
    }

    &__list {
      display: grid;
      grid-template-columns: 300px 300px;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: flex;
        flex-wrap: wrap;
        max-width: 800px;
      }

      @include screen-width-small {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .webinar-sign-up {
    margin-top: 20px;
    padding-left: 25px;

    @include screen-width-small {
      padding: 0;
    }

    @include screen-width-medium {
      padding: 0;
    }

    &__btn {
      width: 262px;
      height: 40px;
      border: none;
      background: #ea5504;
      border-radius: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 19px;

      &.disabled {
        opacity: 0.4;
        cursor: progress;
      }

      &.join-webinar {
        cursor: auto;
      }

      span {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 1px;
        text-align: left;
        color: #ffffff;
        text-transform: uppercase;
      }

      svg {
        color: #ffffff;
        width: 10px;
        height: 16px;
      }
    }
  }

  .webinar-cards-container {
    margin: auto;

    @include screen-width-medium {
      padding: 0;
    }

    @include screen-width-large {
      padding: 0 5%;
    }

    @include screen-width-xl {
      max-width: 1600px;
      padding: 0 80px;
    }

    .Related_Content_Heading {
      @include screen-width-small {
        padding-left: 20px;
      }
    }

    .slick-dots {
      margin-bottom: 0;
    }
  }

  .content-section {
    @include screen-width-small {
      padding-top: 0;
    }
    @include screen-width-medium {
      padding-top: 0;
    }
    .container {
      // position: relative;
      .container_inner {
        @media (max-width: $MIN_WIDTH_LARGE) {
          display: block;
        }
      }
    }
  }

  .container-data-capture {
    padding: 0 5%;
    margin: auto;
    @include screen-width-small {
      padding: 0 20px;
    }
    @include screen-width-medium {
      padding: 0 20px;
    }
    @include screen-width-xl {
      max-width: 1600px;
      padding: 0 80px;
    }

    .container_inner-data-capture {
      display: flex;

      @include screen-width-small {
        flex-direction: column;
      }

      @include screen-width-medium {
        flex-direction: column;
      }

      .content_wrapper--columns-2-left {
        width: 20%;
        flex: none;

        @include screen-width-small {
          display: none;
        }
        @include screen-width-medium {
          display: none;
        }
      }

      .content_wrapper--columns-1-center {
        width: 35%;
        @include screen-width-small {
          width: 100%;
        }
        @include screen-width-medium {
          padding: 0 20px;
          width: 100%;
        }
        .keynote-speakers {
          &__list {
            display: flex;
            flex-direction: column;

            @include screen-width-small {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }

      .content_wrapper--columns-2-right {
        width: 45%;
        flex: none;
        padding-left: 50px;
        display: block;

        @include screen-width-small {
          padding: 0;
          width: 100%;
          margin-bottom: 0;
        }
        @include screen-width-medium {
          padding: 0 3%;
          margin-bottom: 0;
          width: 100%;
        }

        @include screen-width-large {
          width: 45%;
          padding-left: 30px;
        }
      }
    }
  }

  .webinar-details-page-separator {
    height: 0;

    @media (min-width: 0) and (max-width: 768px) {
      height: 50px;
      width: 100%;
    }
  }

  .content_item {
    max-width: fit-content;

    .container {
      @include screen-width-small {
        padding: 0;
      }

      @include screen-width-medium {
        padding: 0;
      }
    }
  }

  .webinar-preview-container {
    padding-left: 25px;
    margin-bottom: 40px;

    @include screen-width-small {
      padding: 0;
    }
    @include screen-width-medium {
      padding: 0;
    }

    .webinar-preview {
      background: #ffffff;
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include screen-width-small {
        width: 100%;
        height: 250px;
      }

      @include screen-width-medium {
        width: 100%;
        height: 300px;
      }

      @include screen-width-large {
        width: 100%;
        height: 400px;
      }

      @include screen-width-xl {
        width: 100%;
        height: 479px;
      }

      h4 {
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: 1px;
        text-align: left;
        max-width: 593px;
        text-transform: uppercase;

        @include screen-width-small {
          font-size: 20px;
          line-height: 30px;
          max-width: 295px;
        }

        @include screen-width-medium {
          font-size: 27px;
          line-height: 34px;
          max-width: 350px;
        }

        @include screen-width-large {
          font-size: 34px;
          line-height: 40px;
          max-width: 450px;
        }
      }
    }
  }

  .webinar-container {
    .promo-banner-component {
      margin-top: 40px;
      height: 308px;
      @media (max-width: $MIN_WIDTH_XL) {
        display: none;
      }

      .inner-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        .RichText {
          p {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
          }
        }
        a {
          width: 262px;
        }
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .content_wrapper--columns-1-center {
      @include screen-width-large {
        max-width: 45%;
      }

      @include screen-width-xl {
        max-width: 45%;
      }
    }
  }

  @supports (-ms-ime-align: auto) {
    .content_wrapper--columns-1-center {
      @include screen-width-large {
        max-width: 45%;
      }

      @include screen-width-xl {
        max-width: 45%;
      }
    }
  }

  .Related_Content_Section {
    @include screen-width-small {
      padding-top: 30px;
    }
  }
}

.webinar-sign-up__error {
  color: #ff0000;
}

.webinar-sign-up__success-modal {
  z-index: 1;
  border-top: 1.03846px solid #c4c4c4;
  border-bottom: 1.03846px solid #c4c4c4;
  width: 100vw;
  left: 0;
  background: white;
  bottom: -120px;

  @media (max-width: 400px) {
    bottom: -235px;
  }

  @include screen-width-medium {
    bottom: -120px;
  }

  @include screen-width-large {
    bottom: -90px;
  }

  @include screen-width-xl {
    bottom: -90px;
  }

  .success-modal__content {
    display: flex;
    align-items: center;
    padding: 20px;
    margin: auto;

    @media (max-width: 400px) {
      flex-direction: column;
    }

    @include screen-width-medium {
      padding: 20px;
    }

    @include screen-width-large {
      padding: 20px calc(5% + 20px);
    }

    @include screen-width-xl {
      max-width: 1600px;
      padding: 20px 80px;
    }

    svg {
      min-width: 40px;
      min-height: 40px;

      @media (max-width: 400px) {
        margin-bottom: 15px;
      }
    }

    p {
      width: auto;
      margin: 0 20px;

      @media (max-width: 400px) {
        margin: 0;
      }
    }

    button {
      min-width: 80px;
      border: none;
      background-color: #ea570d;
      color: #ffffff;
      text-transform: uppercase;
      border-radius: 30px;
      padding: 12px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 1px;

      @media (max-width: 400px) {
        margin-top: 15px;
      }
    }
  }
}

// .content-section__inner {
//   position: relative;
// }

.app-layout--v1 {
  .webinarDetailPage {
    .webinar-title-section-container {
      .title {
        h1 {
          font-family: $myriad-reg;
        }
      }
    }
  }
}
.app-layout--v2 {
  .webinarDetailPage {
    .webinar-title-section-container {
      .title {
        h1 {
          font-family: $hindMadurai-sb;
        }
      }
    }
  }
}