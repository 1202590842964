.form-redesign {
    .dropdown-section { 
        select {
            border-radius: 4px !important;
        }
        select:focus {
            border:1.5px solid #EA5504 !important;
            outline: none;
        }
    }
    .form-inline-error-text {
        float: unset;
      }
}
.required-input{
    border: 1.5px solid #FF0000 !important;
  };
  
.dropdown-meta {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 4px;
}
.dropdown-section{
    margin-bottom: 30px;
    position: relative;
    outline: none;
    box-shadow: none;

    .custom-focus{
        display: block;
        width: 100%;
        padding: 12px 20px;
        font-size: 16px;
        color: #333;
        outline: 1px solid #C4C4C4;
        border: none;
        border-radius: 30px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        box-shadow: none;
    }

    select:focus {
        outline: 3px solid black;
        box-shadow: none;
    }
}

.form-inline-error-text {
    float: right;
}


.dropdown-section {
    svg {
        right: 17px;
        top: 17px;
        position: absolute;
        transform: rotate(180deg);
    }
}

.dropdown-open {
    svg {
        transform: rotate(0);
    }
}

/* IE11 hide native button (thanks Matt!) */
select::-ms-expand {
    display: none;
}

.dropdown-section select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */
  width: 100%;
  background-color: #ffffff;
  background-image: none;
  -ms-word-break: normal;
  word-break: normal;
}