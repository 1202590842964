@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.app-layout--v1 {
  .carousel {
    .carousel-container {
      @include grid-row();
      justify-content: space-between;
  
      &.no-arrows {
        justify-content: space-around;
      }
  
      .carousel-nav {
        @include spans-columns(1);
        display: flex;
        align-items: center;
  
        .chevron {
          width: 60px;
          height: 60px;
          border: none;
          background-color: $button-grey;
          border-radius: 50px;
          padding: 0px;
          display: flex;
          align-items: center;
          justify-content: center;
  
          svg {
            color: white;
            width: 22px;
            height: 30px;
          }
  
          &:hover,
          &:focus {
            background-color: $button-hover-grey;
          }
  
          &:disabled {
            background-color: $button-disabled-grey;
          }
        }
  
        &-next {
          justify-content: flex-end;
  
          svg {
            padding-left: 4px; // the chevrons aren't quite centerd!
          }
        }
  
        &-back {
          justify-content: flex-start;
  
          svg {
            padding-right: 4px; // the chevrons aren't quite centerd!
          }
        }
      }
  
      .slider-container {
        @include spans-columns(10);
        @include spans-columns-small(4);
        margin: 0 -20px;
  
        // text cards within a carousel should have a min height
        .text-card {
          min-height: 330px;
        }
  
        div {
          // The library has some tabfocus stuff built into it. Whilst setting the tab index to -1 prevents the slides from appearing
          // in the tab index, the outline still bubbles up however. As such we have to disable it here. The classNames are a little weird
          // too, so I decided it would be best just to target divs
          outline: none;
        }
  
        .carousel__slider-tray {
          &>div {
            &::after {
              content: "";
              clear: both;
              display: table;
            }
          }
  
          // pure-react-carousel css hacks until pure-react-carousel can be replaced
          .carousel__slide {
            margin: 20px 0;
            .carousel__inner-slide {
              position: static;
  
              .carousel-card-slide {
                // Non-IE style
                @supports not (-ms-high-contrast: none) {
                  height: 100% !important;
                }
  
                // IE11 Fix
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                  height: auto;
                }
                .text-card {
                  height: 100% !important;
                }
              }
            }
          }
        }
      }
      &.horizontal {
        .slider-container {
          .carousel__slider-tray {
            display: inline-flex;
            .carousel__slide {
              padding-bottom: 0 !important;
              height: 100%!important;
            } 
          } 
        } 
      }
      &.vertical {      
        flex-direction: column;
        width: 100%;
  
        .slider-container {        
          width: 100%;
          .carousel__slider-tray {
            .carousel__slide {
              margin: 0;
            }
          }
        }
  
        .carousel-nav {
          width: 100%;
          justify-content: center;
          margin-bottom: 20px;
          .chevron {
            svg {
              width: 30px;
              height: 18px;
              padding: 0
            }
          }        
  
          @include screen-width-small {
            .chevron {
              width: 24px;
              height: 24px;
  
              svg {
                width: 13px;
                height: 8px;
              }
            }
          }
        }
      }
    }
  
    .dots {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: 30px;
  
      button {
        width: 13px;
        height: 13px;
        border: solid 1px black;
        border-radius: 50%;
        background-color: white;
        margin: 0px 4px;
  
        &.carousel__dot--selected {
          background-color: $kk-primary;
          border-color: $kk-primary;
        }
      }
    }
  }
}
.app-layout--v2 {
  .carousel {
    .carousel-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .carousel-header-title {
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
  
      .carousel-navigation {
        @include screen-width-small {
          display: none;
        }
        margin-left: auto;
        .arrow-nav {
          border: none;
          background-color: transparent;
        }
  
        .arrow-nav:disabled {
          svg {
            path {
              stroke: rgb(239, 239, 239);
            }
          }
        }
      }
    }
    .carousel-container {
      @include grid-row();
      justify-content: space-between;
  
      &.no-arrows {
        justify-content: space-around;
      }
  
      .carousel-nav {
        @include spans-columns(1);
        display: flex;
        align-items: center;
  
        .chevron {
          width: 60px;
          height: 60px;
          border: none;
          background-color: $button-grey;
          border-radius: 50px;
          padding: 0px;
          display: flex;
          align-items: center;
          justify-content: center;
  
          svg {
            color: white;
            width: 22px;
            height: 30px;
          }
  
          &:hover,
          &:focus {
            background-color: $button-hover-grey;
          }
  
          &:disabled {
            background-color: $button-disabled-grey;
          }
        }
  
        &-next {
          justify-content: flex-end;
  
          svg {
            padding-left: 4px; // the chevrons aren't quite centerd!
          }
        }
  
        &-back {
          justify-content: flex-start;
  
          svg {
            padding-right: 4px; // the chevrons aren't quite centerd!
          }
        }
      }
  
      .slider-container {
        @include spans-columns(10);
        @include spans-columns-small(4);
        width: inherit;
        margin: 0 -20px;
  
        // text cards within a carousel should have a min height
        .text-card {
          min-height: 330px;
        }
  
        div {
          // The library has some tabfocus stuff built into it. Whilst setting the tab index to -1 prevents the slides from appearing
          // in the tab index, the outline still bubbles up however. As such we have to disable it here. The classNames are a little weird
          // too, so I decided it would be best just to target divs
          outline: none;
        }
  
        .carousel__slider-tray {
          & > div {
            &::after {
              content: '';
              clear: both;
              display: table;
            }
          }
  
          // pure-react-carousel css hacks until pure-react-carousel can be replaced
          .carousel__slide {
            margin: 20px 0;
            .carousel__inner-slide {
              position: static;
  
              .carousel-card-slide {
                // Non-IE style
                @supports not (-ms-high-contrast: none) {
                  height: 100% !important;
                }
  
                // IE11 Fix
                @media screen and (-ms-high-contrast: active),
                  (-ms-high-contrast: none) {
                  height: auto;
                }
                .text-card {
                  height: 100% !important;
                }
              }
            }
          }
        }
      }
      &.horizontal {
        .slider-container {
          .carousel__slider-tray {
            display: inline-flex;
            .carousel__slide {
              padding-bottom: 0 !important;
              height: 100%!important;
            } 
          } 
        } 
      }
      &.vertical {
        flex-direction: column;
        width: 100%;
  
        .slider-container {
          width: 100%;
          .carousel__slider-tray {
            .carousel__slide {
              margin: 0;
            }
          }
        }
  
        .carousel-nav {
          width: 100%;
          justify-content: center;
          margin-bottom: 20px;
          .chevron {
            svg {
              width: 30px;
              height: 18px;
              padding: 0;
            }
          }
  
          @include screen-width-small {
            .chevron {
              width: 24px;
              height: 24px;
  
              svg {
                width: 13px;
                height: 8px;
              }
            }
          }
        }
      }
    }
  
    .dots {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: 30px;
  
      button {
        width: 13px;
        height: 13px;
        border: solid 1px black;
        border-radius: 50%;
        background-color: white;
        margin: 0px 4px;
  
        &.carousel__dot--selected {
          background-color: $kk-primary;
          border-color: $kk-primary;
        }
      }
    }
  }
}
