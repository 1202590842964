@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

.expandable-content {
  position: relative;
  padding: 20px;
  margin-bottom: 30px;

  @include screen-width-small {
    margin-bottom: 20px;
  }

  &__title {
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;

    h4 {
      margin-top: 0;
      margin-bottom: 18px;

      @include screen-width-small {
        margin-bottom: 10px;
      }
    }
  }

  &__sub-title {

    h5 {
      margin-top: 0;
      margin-bottom: 25px;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }

    p {
      margin-top: 0;
      margin-bottom: 25px;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }
  }

  &__description {
    display: none;
    position: absolute;
    opacity: 0;
    z-index: -10;
    top: -10px;
    margin-bottom: 25px;
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0px;
      text-align: left;
    }
    &.opened {
      display: block;
      position: relative;
      opacity: 1;
      z-index: 1;
      top: 0;
    }
  }

  &__expand {
    cursor: pointer;
    width: fit-content;

    .expand {
      span {
        text-transform: uppercase;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 1px;
        text-align: left;
        color: #ea5504;
        margin-right: 20px;

        @include screen-width-small {
          margin-right: 20px;
        }
      }
    }
  }
}
