@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

$container-width: 1114px;

.img-responsive {
    @media only screen and (max-width: 768px) { 
        width: 100%;
        height: auto;
    }
}


.mdt-main-dopaminergic {
    position: relative;
    padding: 65px 0 57px 0;
    background-color: #FFF;
    color: #E07A5F;
    overflow: hidden;

    .controlled-example-dopamine {
        .controlled-example-dopamine_header {
            position: absolute;
            right: 5px;
            top: 6px;
            .controlled-example-title {
                font-weight: 800;
                font-size: 20px;
                text-align: center;
            }

            .close {
                cursor: pointer;
                height: 25px;
                width: 25px;
            }
        }

        .popup-content-dopamine {
            padding-top: 25px;
            .popup-example-title {
                font-weight: 900;
                margin-top: 0;
                font-size: 20px;
            }
            p {
                padding: 0;
                text-align: left;
                font-size: 18px;
                color: #3D3F5B;
            }
        }
    }

    .mdt-main-wrapper {
        margin: auto;
        @media only screen and (min-width: 1200px) { 
            width: $container-width;
        }
    }

    h2 {
        font-size: 30px;
        font-weight: normal;
        
        span {
            display: inline;

            @media only screen and (min-width: 1200px) { 
                display: block;
            }
        }

        &::after {
            content: '';
            display: block;
            margin-top: 36px;
            width: 50px;
            height: 1px;
            background-color: #E07A5F;
        }
    }

    .subtitle {
        font-size: 27px;
        font-weight: normal;

        sup {
            font-size: 18px;
        }
    }

    .info-container {

        @media only screen and (min-width: 1200px) { 
            margin: 60px 0 30px 0;
        }
    }

    .captiontext {
        padding: 25px 0 25px 0;
        font-size: 15px;
    }
}

.mdt__infographics-dopaminergic {
    position: relative;
    margin: 40px 0;
    background-color: #FFF;
    border-radius: 20px;

    @media only screen and (min-width: 1200px) { 
        display: flex;
        justify-content: center;
    }
    

    .mdt__infographics-image-container {
        padding: 50px 8px 0 8px;
        text-align: center;
        color: #3D3F5B;

        @media only screen and (min-width: 1200px) { 
            padding: 50px 8px 0 30px;
            text-align:left;
            
        }
        
        img {
            @media only screen and (max-width: 1024px) { 
                width: 100%;
                height: auto;
            }
        }

        p {
            padding: 30px 0 30px 20px;
        }
        
        .caption-container {
            .caption-container-wrap {
                position: relative;
                width: fit-content;
                margin: auto;

                .dopamin-1 {
                    position: absolute;
                    width: 11%;
                    height: 8%;
                    left: 8%;
                    top: 10%;
                    @media only screen and (max-width: 1024px) { 
                        width: 11%;
                        height: 8%;
                        left: 8%;
                        top: 10%;
                    }
                    cursor: pointer;
                    transition: all 0.5s ease;

                    &.active {
                        background-color: #E07A5F;
                        opacity: 0.4;
                    }
                }

                .dopamin-2 {
                    position: absolute;
                    width: 145px;
                    height: 50px;
                    left: 560px;
                    top: 195px;
                    @media only screen and (max-width: 1024px) { 
                        width: 16%;
                        height: 10%;
                        left: 59%;
                        top: 29%;
                    }
                    cursor: pointer;
                    border-radius: 15px;
                    transition: all 0.5s ease;

                    &.active {
                        background-color: #E07A5F;
                        opacity: 0.4;
                    }
                }

                .dopamin-3 {
                    position: absolute;
                    width: 121px;
                    height: 60px;
                    left: 308px;
                    top: 460px;
                    cursor: pointer;
                    transition: all 0.5s ease;

                    @media only screen and (max-width: 1024px) { 
                        width: 11%;
                        height: 10%;
                        left: 33%;
                        top: 69%;
                    }

                    &.active {
                        background-color: #E07A5F;
                        opacity: 0.4;
                    }
                }

                .dopamin-4 {
                    position: absolute;
                    width: 181px;
                    height: 66px;
                    left: 271px;
                    top: 571px;
                    cursor: pointer;
                    transition: all 0.5s ease;
                    @media only screen and (max-width: 1024px) { 
                        width: 25%;
                        height: 12%;
                        left: 26%;
                        top: 88%;
                    }
                    &.active {
                        background-color: #E07A5F;
                        opacity: 0.4;
                    }
                }

                .dopamin-5 {
                    position: absolute;
                    width: 155px;
                    height: 95px;
                    left: 102px;
                    top: 223px;
                    cursor: pointer;
                    transition: all 0.5s ease;
                    @media only screen and (max-width: 1024px) { 
                        width: 19%;
                        height: 15%;
                        left: 9%;
                        top: 34%;
                    }
                    &.active {
                        background-color: #E07A5F;
                        opacity: 0.4;
                    }
                }

                .dopamin-6 {
                    position: absolute;
                    width: 183px;
                    height: 58px;
                    left: 179px;
                    top: 104px;
                    border-radius: 10px;
                    cursor: pointer;
                    transition: all 0.5s ease;
                    @media only screen and (max-width: 1024px) { 
                        width: 20.3%;
                        height: 11%;
                        left: 18.2%;
                        top: 15%;
                    }
                    &.active {
                        background-color: #E07A5F;
                        opacity: 0.4;
                    }
                }

                .dopamin-7 {
                    position: absolute;
                    width: 180px;
                    height: 27px;
                    left: 642px;
                    top: 10px;
                    border-radius: 15px;
                    cursor: pointer;
                    transition: all 0.5s ease;
                    @media only screen and (max-width: 1024px) { 
                        width: 20%;
                        height: 5.1%;
                        left: 67%;
                        top: 1%;
                    }
                    &.active {
                        background-color: #E07A5F;
                        opacity: 0.4;
                    }
                }

                .dopamin-8 {
                    position: absolute;
                    width: 108px;
                    height: 54px;
                    left: 737px;
                    top: 193px;
                    border-radius: 15px;
                    cursor: pointer;
                    transition: all 0.5s ease;
                    @media only screen and (max-width: 1024px) { 
                        width: 12%;
                        height: 10.1%;
                        left: 77%;
                        top: 29%;
                    }
                    &.active {
                        background-color: #E07A5F;
                        opacity: 0.4;
                    }
                }

                .dopamin-9 {
                    position: absolute;
                    width: 166px;
                    height: 54px;
                    left: 691px;
                    top: 362px;
                    border-radius: 15px;
                    cursor: pointer;
                    transition: all 0.5s ease;
                    @media only screen and (max-width: 1024px) { 
                        width: 18.5%;
                        height: 10%;
                        left: 72%;
                        top: 55%;
                    }
                    &.active {
                        background-color: #E07A5F;
                        opacity: 0.4;
                    }
                }

                .dopamin-10 {
                    position: absolute;
                    width: 130px;
                    height: 31px;
                    left: 687px;
                    top: 594px;
                    border-radius: 15px;
                    cursor: pointer;
                    transition: all 0.5s ease;
                    @media only screen and (max-width: 1024px) { 
                        width: 15%;
                        height: 6%;
                        left: 71%;
                        top: 91%;
                    }
                    &.active {
                        background-color: #E07A5F;
                        opacity: 0.4;
                    }
                }

                .timepopups {
                    top: 28%;
                    left: 10%;

                    @media only screen and (max-width: 640px) { 
                        left: -35px;

                        .timepopups__scroll {
                            width: 90%;
                        }
                    }
                }
            }
        }
       
    }

    .mdt__infographics-selection {
        position: relative;
        padding: 140px 0 30px 100px;
        width: 280px;
        @media only screen and (max-width: 1024px) { 
            padding: 40px 0 30px 50px; 
            width:unset;
        }

        label {
            display: block;
            margin-top: 2px;
            padding-bottom: 20px;
            font-size: 16px;
            font-weight: bold;
            color: #3D3F5B;
            cursor: pointer;

        }
        span{
            word-break:break-word;
        }

        .timepopups {
            bottom: -25%;
            right: -8%;

            @media only screen and (max-width: 640px) { 

                .timepopups__scroll {
                    width: 90%;
                }
            }
        }
    }
}
/* info text*/
#normal-text{
    p{
        font-family: $hindMadurai-reg;
    }
 }
 #bold-text{
    p{
     font-family: $hindMadurai-sb;
     }
 }
 .image-caption-text{
    padding-bottom:36px;
    color:#3D3F5B;
}
.btn__click-more {
    width: 470px;
    height: 50px;
    text-align: center;
    background-color: #E07A5F;
    border-radius: 50px;
    color: #ffffff;
    font-size: 20px;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin: 40px auto 63px;
    box-shadow: 0px 3px 6px #00000029;
    @include screen-width-small {
        width: 100%;
        font-size: 16px;
        margin: 20px 0;
    }
} 
.accordion-section{
    // p{
    //     color:#3D3F5B;
    // }
}  

.mechanism-section-dopaminergic{
    @media only screen and (min-width:1024px){
        width: 1020px;
        display: flex;
    }
    .symptom-item-dopaminergic{
        display:flex;
        p {
            font-size: 20px;
            color: #3D3F5B;
            line-height: 30px;
            margin: 0;
        }
        &::before {
            content: "•";
            color: #E07A5F;
            font-size: 35px;
            margin: -9px 10px 0 0;
        }
    }
    .symptom-item2{
        @media only screen and(min-width:1024px){
            margin-left: 36px;
            margin-right: 36px;
        }
    }
    
}
.mechanism-section-dopaminergic-verticalTab{
    width:295px;
    display: flex;
    flex-flow:column;
    color:#3D3F5B;
    
}

#accordion-dopaminergic{
    .separator-space{
        padding-top:0px;
        margin-top:25px
    }
}
#info-epigenetic{
    margin:0 auto;
    .diagram-header{
        font-family: $hindMadurai-sb;
        color: #3D3F5B;
        display: block;
        text-align: center;
    }
    .diagram-caption{
        color:#3D3F5B;
        font-size:15px;
        text-align:center;
    }
}
.symptom-item-vertical2{
    padding-bottom:50px;
}


