@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';
@import '../../assets/mixins.scss';

.content-page-promo-subnav {

  @include desktopOnly {
    display: block;
    padding: 40px 0px 40px 30px;
  }

  @include desktopOnlyLarge {
    padding: 40px 0px 40px 150px;
  }

  &-link {
    display: block;
    font-size: 16px;
    color: #333333;
    text-decoration: none;
    margin-bottom: 20px;
    line-height: 19px;

    &-level1 {
      font-size: 20px;
      line-height: 24px;
      font-weight: 400;
    } 

    &-level2 {
      display: block;
      font-weight: 600;
      padding-left: 40px;
    } 

    &-level3 {
      display: block;
      padding-left: 60px;
    }

    &--active {
      color: $kk-primary;
    }
  }

}

.subnavigation {
  &__link {
    display: block;
    font-size: 16px;
    color: #333333;
    text-decoration: none;
    margin-bottom: 20px;
    line-height: 19px;
  }

  &__level1 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
  } 

  &__level2 {
    display: block;
    font-weight: 600;
    padding-left: 40px;
  } 

  &__level3 {
    display: block;
    padding-left: 40px;
  }
}