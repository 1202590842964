@import 'style/variables.scss';
@import 'style/mixins/_responsive.scss';

$container-width: 1114px;

.mdt-biomakers {
    position: relative;
    background-color: #E07A5F;
    color: #FFFFFF;
    overflow: hidden;
    
    .controlled-example-biomakers {
        .controlled-example-header-biomakers {
            position: absolute;
            right: 5px;
            top: 6px;
            .controlled-example-title {
                font-weight: 800;
                font-size: 20px;
                text-align: center;
            }

            .close {
                cursor: pointer;
                height: 25px;
                width: 25px;
            }
        }

        .popup-content-biomakers {
            padding-top: 25px;
            .popup-example-title {
                font-weight: 900;
                margin-top: 0;
                font-size: 20px;
            }
            p {
                text-align: left;
                padding: 0 20px;
                text-align: left;
                font-size: 18px;
                color: #3D3F5B;
            }
            ul {
                text-align: left;
                font-size: 18px;
                padding: 0 20px;
            }
        }
    }
    .mdt-main {
        padding: 65px 0 78px 0;
        background-color: #E07A5F;
    }

    .mdt-main-wrapper {
        margin: auto;
        @media only screen and (min-width: 1200px) { 
            width: $container-width;
        }
    }

    h2 {
        font-size: 35px;
        font-weight: normal;
        
        span {
            display: inline;

            @media only screen and (min-width: 1200px) { 
                display: block;
            }
        }

        &::after {
            content: '';
            display: block;
            margin: 20px 0;
            width: 50px;
            height: 1px;
            background-color: #ffffff;
        }
    }

    .subtitle {
        font-size: 27px;
    }

    .mdt-biomakers-content__wrapper {
        margin: 40px 0;
        padding: 30px 20px;
        background-color: #F7E0BC;
        border-radius: 20px;
    }

    .row {
        @include grid-row;
        @include screen-width-small {
            flex-direction: column;
        }
    }

    .mdt-biomakers-content__item {
        @include spans-columns(4);

        @include screen-width-small {
            @include spans-columns(12);
        }

        display: flex;

        &::before {
            content: "•";
            color: #3D3F5B;
            font-size: 50px;
            margin: -18px 20px 0;
            @include screen-width-small {
                margin: -18px 10px 0 0;
            }
        }

        p {
            margin: 0;
            font-size: 20px;
            color: #3D3F5B;
            line-height: 28px;
        }
    }

    .content-graph {
        text-align: center;
        .content-graph-container {
            width: fit-content;
            margin: auto;
            position: relative;
            img {
                @media only screen and (max-width: 974px) { 
                    width: 100%;
                    height: auto;
                }
            }
    
            .biomakers-1 {
                position: absolute;
                width: 5%;
                height: 4%;
                left: 4%;
                top: 16.5%;
                cursor: pointer;
                transition: all 0.5s ease;
    
                // &.active {
                //     background-color: #E07A5F;
                //     opacity: 0.4;
                // }
            }
            .biomakers-2 {
                position: absolute;
                width: 12%;
                height: 5%;
                left: 4%;
                top: 23%;
                cursor: pointer;
                transition: all 0.5s ease;
    
                // &.active {
                //     background-color: #E07A5F;
                //     opacity: 0.4;
                // }
            }

            .biomakers-3 {
                position: absolute;
                width: 4%;
                height: 3.1%;
                left: 1%;
                top: 38.9%;
                cursor: pointer;
                transition: all 0.5s ease;
    
                // &.active {
                //     background-color: #E07A5F;
                //     opacity: 0.4;
                // }
            }

            .biomakers-3-5 {
                position: absolute;
                width: 6%;
                height: 3.1%;
                left: 1%;
                top: 46.5%;
                cursor: pointer;
                transition: all 0.5s ease;
    
                // &.active {
                //     background-color: #E07A5F;
                //     opacity: 0.4;
                // }
            }

            .biomakers-4 {
                position: absolute;
                width: 12%;
                height: 3.5%;
                left: 1%;
                top: 54%;
                cursor: pointer;
                transition: all 0.5s ease;
    
                // &.active {
                //     background-color: #E07A5F;
                //     opacity: 0.4;
                // }
            }

            .biomakers-5 {
                position: absolute;
                width: 18%;
                height: 3%;
                left: 1%;
                top: 61.5%;
                cursor: pointer;
                transition: all 0.5s ease;
    
                // &.active {
                //     background-color: #E07A5F;
                //     opacity: 0.4;
                // }
            }

            .biomakers-6 {
                position: absolute;
                width: 12%;
                height: 3%;
                left: 1%;
                top: 65%;
                cursor: pointer;
                transition: all 0.5s ease;
    
                // &.active {
                //     background-color: #E07A5F;
                //     opacity: 0.4;
                // }
            }

            .biomakers-7 {
                position: absolute;
                width: 11%;
                height: 3.5%;
                left: 1%;
                top: 83.5%;
                cursor: pointer;
                transition: all 0.5s ease;
    
                // &.active {
                //     background-color: #E07A5F;
                //     opacity: 0.4;
                // }
            }

            .biomakers-8 {
                position: absolute;
                width: 9%;
                height: 3.5%;
                left: 1%;
                top: 91%;
                cursor: pointer;
                transition: all 0.5s ease;
    
                // &.active {
                //     background-color: #E07A5F;
                //     opacity: 0.4;
                // }
            }

            .biomakers-9 {
                position: absolute;
                width: 3.4%;
                height: 3%;
                right: 5.9%;
                top: 17%;
                cursor: pointer;
                transition: all 0.5s ease;
    
                // &.active {
                //     background-color: #E07A5F;
                //     opacity: 0.4;
                // }
            }
            .biomakers-10 {
                position: absolute;
                width: 8.5%;
                height: 3%;
                right: 9.5%;
                top: 17%;
                cursor: pointer;
                transition: all 0.5s ease;
    
                // &.active {
                //     background-color: #E07A5F;
                //     opacity: 0.4;
                // }
            }
            .biomakers-11 {
                position: absolute;
                width: 3%;
                height: 3%;
                right: 2.5%;
                top: 17%;
                cursor: pointer;
                transition: all 0.5s ease;
    
                // &.active {
                //     background-color: #E07A5F;
                //     opacity: 0.4;
                // }
            }

            .biomakers-12 {
                position: absolute;
                width: 7.5%;
                height: 3%;
                right: 13.5%;
                top: 20%;
                cursor: pointer;
                transition: all 0.5s ease;
    
                // &.active {
                //     background-color: #E07A5F;
                //     opacity: 0.4;
                // }
            }

            .biomakers-13 {
                position: absolute;
                width: 15.5%;
                height: 3%;
                right: 5.5%;
                top: 23.8%;
                cursor: pointer;
                transition: all 0.5s ease;
    
                // &.active {
                //     background-color: #E07A5F;
                //     opacity: 0.4;
                // }
            }

            .biomakers-14 {
                position: absolute;
                width: 6.5%;
                height: 3%;
                right: 11.5%;
                top: 46.2%;
                cursor: pointer;
                transition: all 0.5s ease;
    
                // &.active {
                //     background-color: #E07A5F;
                //     opacity: 0.4;
                // }
            }

            .biomakers-15 {
                position: absolute;
                width: 8%;
                height: 3%;
                right: 3%;
                top: 46.2%;
                cursor: pointer;
                transition: all 0.5s ease;
    
                // &.active {
                //     background-color: #E07A5F;
                //     opacity: 0.4;
                // }
            }

            .biomakers-16 {
                position: absolute;
                width: 18%;
                height: 4%;
                right: 1%;
                top: 73.5%;
                cursor: pointer;
                transition: all 0.5s ease;
    
                // &.active {
                //     background-color: #E07A5F;
                //     opacity: 0.4;
                // }
            }

            .timepopups {
                top: 30%;
                left: 18%;

                @media only screen and (max-width: 640px) { 
                    top: 0;
                    left: -27px;

                    .timepopups__scroll {
                        width: 90%;
                    }
                }
            }
            
        }

    }

    .content-info {
        margin: 40px 0 50px 0;
        position: relative;

        .timepopups {
            top: -8%;
            left: 23%;

            @media only screen and (max-width: 640px) { 
                top: 5%;
                left: -28px;

                .timepopups__scroll {
                    width: 90%;
                }
            }
        }
    }

    .content-info__item {
        @include spans-columns(4);

        @include screen-width-small {
            @include spans-columns(12);
        }

        .content-info__item-check {
            margin-bottom: 30px;

            /*label span:first-of-type {
                @media only screen and (max-width: 768px) { 
                    font-size: 14px;
                }
              
                @media only screen and (min-width: 769px) and (max-width: 1200px) { 
                    font-size: 14px;
                }
            }*/
        }
    }

    .reference-text {
        padding-bottom: 54px;
        font-size: 14px;

        &:last-of-type {
            padding-bottom: 0; 
        }
    }

}

.mdt-biomakers-intro {
    
    h1 {
        // font: normal normal normal 30px/36px Prata-Regular;
        margin: 0 0 50px 0;
        font-size: 35px;
        color: #E07A5F;
    }

    p {
        font-size: 18px;
        line-height: 30px;

    }

    .heading {
        margin: 30px 0;
        font-size: 25px;
    }
            
    .msgfullscreen {
        color: #E07A5F !important;
    }

    .sublist {
        padding: 0;
        list-style: none;
        font-size: 18px;
        line-height: 30px;

        li {
            padding-bottom: 20px;

            &::before {
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #E07A5F;
            }

            span {
                display: block;
                margin: -21px 0 0 30px;;
            }
        }
    }
}