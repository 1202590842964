.alternate_text{
    padding:16px 8px 40px;
    color: #3D3F5B; 
}
.description{
    padding: 40px 0;
}
.image_block-image{
    img{
        width:100%;
    }
}
.image_block_sm{
    // width:343px;
    // height:300px;
    img{
        display: block;
        @media only screen and (max-width:768px){
            width:100%;
        }
    }

}