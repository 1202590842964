@import '../../../../../assets/mixins.scss';

$max-content-size : 1440px;
$white: #FFFFFF;
$grey: #5d6164;
$grey-light : #91969b;

.Breadcrumbs_Content bookmarkbreadcomponent {
    background-color: #F9F4F2;
    height: 50px;
}

.Breadcrumbs {
    display: block;
    background-color: $white;

    &.events-library-wrap {
        box-shadow: none;
        height: 40px;
    }

    @include tabletMin {
        min-height: 47px;
        background-color: $white;
        box-shadow: inset 0 2px 4px rgba(0,0,0,0.25);
    }

    &_Content {
        display: flex;
        max-width: 1440px;
        margin: 0 auto;
        padding: 0 32px;
        &.events-library {
            display: flex;
            justify-content: flex-end;
            .Breadcrumbs_List { 
                display: none;
            }
        }
        // If browser supports css grid use
        @supports (display: grid) {
            display: grid;
            grid-template-columns: 1fr auto;
            column-gap: 1em;

            &.events-library {
                display: flex;
                justify-content: flex-end;
            }
        }

        @media screen and (min-width: $max-content-size) {
            padding: 0 1em;
        }
    }

  

    &_List {
        display: flex;
        align-items: center;
        padding: 0;
        flex-wrap: wrap;

        // @media screen and (min-width: $max-content-size) {
        //     padding: 0 1em;
        // }

        a {
            color: $grey;
            text-decoration: none;
            // margin-bottom: 5px;

            &::after {
                content: '\276F';
                padding: 0 5px;
            }
        }

        & a:last-child {
            color: $grey-light;

            &::after {
                content: '';
            }
        }
    }

    &--empty {
        display: none;
    }
}

.global{
    box-shadow: inherit;
}

.global_breadcrumbs_padding{
    max-width: 100%;
    margin: 0;
    padding: 0 5%;
}

.app-layout--v2 {
    .Breadcrumbs {
        &--empty {
            display: none;
        }
    }
}